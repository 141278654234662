import { useTranslation } from "react-i18next";
import * as S from "../../../../components/element/BoardList/style/BoardList.style";
import { EmptyCard } from "../../../../components/element/EmptyCard/EmptyCard";
import { IK } from "../../../../utils/i18n/keyStore";
import { TableSortIconList, getSortIcon } from "../../../../utils/statusUtils";
import Tr from "./Tr";

export default function Table({
  info,
  searchCondition,
  setSearchCondition,
  headList,
  setHeadList,
}) {
  const { t } = useTranslation(["translation"]);

  /**즐찾 기능  */
  const handleOnClickClassification = (e) => {
    const target = e.currentTarget;
    setSearchCondition((prev) => ({
      ...prev,
      classification: target.dataset.code,
      page: 1,
    }));
    const parentElement = target.parentNode.parentNode;
    const firstChildButton = parentElement.querySelector("button");
    const firstChildImg = firstChildButton.querySelector("img");
    const targetImg = target.querySelector("img");
    const targetSrc = targetImg.src;
    firstChildImg.src = targetSrc;
  };

  const tableHead = [
    {
      title: "patient",
      sorting: false,
      className: "align_left",
      thumbnailTitle: true,
    },
    { title: "treatment_option", sorting: false, thumbnailTitle: false },
    { title: "status", sorting: false, thumbnailTitle: false },
    { title: "create_date", sorting: false, thumbnailTitle: false },
    { title: "end_date", sorting: true, thumbnailTitle: false },
    { title: "agreement", sorting: false, thumbnailTitle: false },
  ];

  //sort[0] : 필드명, sort[1] : 차순
  const isSort = searchCondition?.sort?.split(",") || ",";

  const handleSorting = (clickedHead) => {
    if (isSort[0] !== clickedHead.title) {
      //처음클릭하면 활성화 + asc
      setSearchCondition((prev) => ({
        ...prev,
        sort: `${clickedHead.title},asc`,
        page: 1,
      }));
    } else if (isSort[0] === clickedHead.title && isSort[1] === "asc") {
      //두번클릭하면 활성화 + desc
      setSearchCondition((prev) => ({
        ...prev,
        sort: `${clickedHead.title},desc`,
        page: 1,
      }));
    } else if (isSort[0] === clickedHead.title && isSort[1] === "desc") {
      //세번클릭시 비활성화
      const { sort, page, ...rest } = searchCondition;
      setSearchCondition((prev) => ({ ...rest, page: 1 }));
    }
  };

  return (
    <S.BoardTable $BoardTableType2>
      <colgroup>
        <col width="80px"></col>
        <col width="260px"></col>
        <col width="170px"></col>
        <col width="*"></col>
        <col width="170px"></col>
        <col width="170px"></col>
        <col width="170px"></col>
      </colgroup>
      <thead>
        <tr>
          <th>
            <S.BoardTableSortIcon>
              <button type="button">
                {searchCondition.classification !== 0 ? (
                  <img
                    src={getSortIcon(searchCondition.classification)}
                    alt="icon"
                  />
                ) : (
                  <img src={getSortIcon(0)} alt="icon" />
                )}
              </button>
              <div>
                {TableSortIconList.map((con) => (
                  <button
                    type="button"
                    onClick={handleOnClickClassification}
                    data-code={con.code}
                    key={con.code}
                  >
                    <img src={con.icon} alt="icon" />
                  </button>
                ))}
              </div>
            </S.BoardTableSortIcon>
          </th>
          {tableHead.map((head) => (
            <th key={head.title} className={head.className}>
              {head.sorting ? (
                <S.BoardTableSortBox
                  type="button"
                  onClick={() => handleSorting(head)}
                  $Primary={isSort[0] === head.title}
                >
                  {/* BoardTableSortBox 클릭 시 "Primary" props 생성 */}
                  {t(IK[head.title])}
                  {/* 오름차순일 경우 "SortAsc" props 생성 / 내림차순일 경우 "SortDesc" props 생성 */}
                  <S.BoardTableSortBoxSpan
                    $SortAsc={isSort[0] === head.title && isSort[1] === "asc"}
                    $SortDesc={isSort[0] === head.title && isSort[1] === "desc"}
                  >
                    <i className="ri-arrow-up-s-fill" />
                    <i className="ri-arrow-down-s-fill" />
                  </S.BoardTableSortBoxSpan>
                </S.BoardTableSortBox>
              ) : head.thumbnailTitle ? (
                <S.BoardTableThumbnailTitle>
                  {t(IK[head.title])}
                </S.BoardTableThumbnailTitle>
              ) : (
                <>{t(IK[head.title])}</>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {info.length > 0 ? (
          info.map((item, idx) => {
            return <Tr key={idx} item={item} />;
          })
        ) : (
          <tr>
            <td colSpan="9">
              <EmptyCard />
            </td>
          </tr>
        )}
      </tbody>
    </S.BoardTable>
  );
}
