import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";

function BiteRamp({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, setStudyInfo, studyInfo, patientId, isFinish, setCurrentStepIndex }) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleChange = (e, setFieldValue) => {
    const index = e.currentTarget.value;
    if (index === "2") {
      document.getElementById("sub_2").classList.remove("hide");
      document.getElementById("sub_2_1").classList.add("hide");
    } else {
      setFieldValue("SI10_2_01", "");
      setFieldValue("SI10_2_01_1_01", []);
      document.getElementById("sub_2").classList.add("hide");
    }
  };

  const handleSubChange = (e, setFieldValue) => {
    const index = e.currentTarget.value;
    if (index === "1") {
      document.getElementById("sub_2_1").classList.remove("hide");
    } else {
      setFieldValue("SI10_2_01_1_01", []);
      document.getElementById("sub_2_1").classList.add("hide");
    }
  };

  const validationSchema = Yup.object().shape({
    SI10: Yup.string().required(t(IK.biteramp_required)),
    SI10_2_01: Yup.string().when("SI10", {
      is: "2",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
      otherwise: (val) => Yup.string(),
    }),
    SI10_2_01_1_01: Yup.array()
      .of(Yup.string())
      .when("SI10_2_01", {
        is: "1",
        then: (val) => Yup.array().min(1, t(IK.sub_option_required)),
        otherwise: (val) => Yup.array(),
      }),
  });

  const code = currentStepList[currentStepIndex].code;

  const handleSubmit = (values) => {
    const data = {
      SI10: values.SI10,
      SI10_2_01: values.SI10 === "2" ? values.SI10_2_01 : "",
      SI10_2_01_1_01: values.SI10 === "2" && values.SI10_2_01 === "1" ? values.SI10_2_01_1_01.join(",") : "",
    };

    saveStepMutation.mutate(
      {
        step: code,
        data: deleteEmptyKey(data),
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip ? setCurrentStepIndex(currentStepList.length - 2) : next();
        },
      }
    );
  };

  /*
  *이전 버튼 눌러서 악궁선택으로 이동되기 전에 경고
  RP의 경우에만 해당
  */
  const prevCaution = () => {
    if (studyInfo.packages === "RP") {
      Notiflix.Confirm.show(
        t(IK.is_edit),
        t(IK.edit_dental_arch_treated),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          prev();
        },
        () => {}
      );
    } else {
      prev();
    }
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI10: studyInfo.SI10 || "",
          SI10_2_01: studyInfo.SI10_2_01 || "",
          SI10_2_01_1_01: studyInfo.SI10_2_01_1_01 ? studyInfo.SI10_2_01_1_01.split(",") : [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.biteramp)} required openModal={openModal}>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field
                      type="radio"
                      name="SI10"
                      className="input_radio"
                      onChange={(e) => {
                        handleChange(e, setFieldValue);
                        setFieldValue("SI10", e.currentTarget.value);
                      }}
                      value="1"
                    />
                    <span>{t(IK.grant_if_necessary)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field
                      type="radio"
                      name="SI10"
                      className="input_radio"
                      onChange={(e) => {
                        handleChange(e, setFieldValue);
                        setFieldValue("SI10", e.currentTarget.value);
                      }}
                      value="2"
                    />
                    <span>{t(IK.biteramp_item2)}</span>
                  </label>
                </div>
                <div id="sub_2" className={studyInfo.SI10 !== "2" ? "hide" : ""}>
                  <div className="frm_radio_indent">
                    <div className="frm_column">
                      <div className="radio">
                        <label className="label_radio">
                          <Field
                            type="radio"
                            name="SI10_2_01"
                            onChange={(e) => {
                              handleSubChange(e, setFieldValue);
                              setFieldValue("SI10_2_01", e.currentTarget.value);
                            }}
                            className="input_radio"
                            value="1"
                          />
                          <span>{t(IK.incisor)}</span>
                        </label>
                      </div>
                      <div id="sub_2_1" className={studyInfo.SI10_2_01 !== "1" ? "hide" : ""}>
                        <div className="frm_radio_indent">
                          <div className="chk_area">
                            <div className="checkbox">
                              <label className="label_checkbox">
                                <Field type="checkbox" name="SI10_2_01_1_01" className="input_checkbox" value="1" checked={values.SI10_2_01_1_01.includes("1")} />
                                <span>{t(IK.central_incisor)}</span>
                              </label>
                            </div>
                            <div className="checkbox">
                              <label className="label_checkbox">
                                <Field type="checkbox" name="SI10_2_01_1_01" className="input_checkbox" value="2" checked={values.SI10_2_01_1_01.includes("2")} />
                                <span>{t(IK.lateral_incisor)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI10_2_01_1_01" />
                        </div>
                      </div>
                      <div className="radio">
                        <label className="label_radio">
                          <Field
                            type="radio"
                            name="SI10_2_01"
                            onChange={(e) => {
                              handleSubChange(e, setFieldValue);
                              setFieldValue("SI10_2_01", e.currentTarget.value);
                            }}
                            className="input_radio"
                            value="2"
                          />
                          <span>{t(IK.canine_teeth)}</span>
                        </label>
                      </div>
                    </div>
                    <FieldMessageErrorClick name="SI10_2_01" />
                  </div>
                </div>

                <div className="radio">
                  <label className="label_radio">
                    <Field
                      type="radio"
                      name="SI10"
                      className="input_radio"
                      onChange={(e) => {
                        handleChange(e, setFieldValue);
                        setFieldValue("SI10", e.currentTarget.value);
                      }}
                      value="3"
                    />
                    <span>{t(IK.not_necessary)}</span>
                  </label>
                </div>
              </div>
              <FieldMessageErrorClick name="SI10" />
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prevCaution}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} $primary>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.biteramp_pop_message1)}</li>
          <li>{t(IK.biteramp_pop_message2)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default BiteRamp;
