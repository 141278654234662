import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/** 승인된 치료계획 조회*/
export const useGetAccpetedStudyCount = () => {
  return useQuery(["StudyCount"], async () => {
    const { data } = await axios.get("/api/study/accepted-count");
    return data;
  });
};

/** 해당 환자의 특정 스터디 요약 정보를 조회 */
export const useGetStudySummary = (patientId, studyId, studyPlanId, isPlanSummary) => {
  const naviagte = useNavigate();
  const { t } = useTranslation(["translation"]);

  return useQuery(
    ["StudySummary", patientId, studyId, studyPlanId],
    async () => {
      const { data } = await axios.get(`/api/study/${studyId}/summary`, {
        params: {
          patientId: patientId,
          studyId: studyId,
        },
      });
      return data;
    },
    {
      enabled: isPlanSummary ? !!studyId && !!patientId && !!studyPlanId : !!studyId && !!patientId,
      cacheTime: 0,
      onError: () => {
        Notiflix.Report.info("", t(IK.Invalid_approach), t(IK.confirm), () => {
          naviagte("/patient/list");
        });
      },
    }
  );
};

/** 해당 환자의 스터디 생성 */
export const useCreateStudy = () => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (data) => {
      const response = await axios.post("/api/study", null, {
        params: data,
      });
      return response.data;
    },
    {
      onError: (error) => {
        const errorMessage = IK[error?.response?.data?.message] || IK.again_message;
        Notiflix.Notify.failure(t(errorMessage));
      },
    }
  );
};

/** 해당 환자의 스터디 업데이트(패키지) */
export const useUpdateStudy = () => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (data) => {
      const response = await axios.put("/api/study", null, {
        params: data,
      });
      return response.data;
    },
    {
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/** 해당 환자의 스터디 단계 저장 */
export const useSaveStep = () => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async ({ step, data, params }) => {
      const response = await axios.post(`/api/study/${step}`, data, {
        params: params,
      });
      return response.data;
    },
    {
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/** 해당 환자의 요약 페이지 도달 여부 판단*/
export const skipStep = async (id) => {
  const response = await axios.put(`/api/study/${id.studyId}/complete`, null, {
    params: id,
  });
  return response.data;
};

/** 해당 환자의 스터디 파일 단계 저장 */
export const useSaveFileStep = () => {
  return useMutation(async ({ step, formData, params }) => {
    const response = axios.post(`/api/study/${params.studyId}/${step}`, formData, {
      params: params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  });
};

/** 처방전 제출하기 */
export const studySubmit = async (patientId, studyId) => {
  return await axios.post(`/api/study/${studyId}/submit`, null, {
    params: {
      patientId: patientId,
    },
  });
};

/**비교중첩데이터 사진 보기 */
export const useGetOverlappingData = (additionalId) => {
  return useQuery(
    ["Overlapping", additionalId],
    async () => {
      const { data } = await axios.get(`/api/additional/${additionalId}/overlap`);
      return data;
    },
    {
      enabled: !!additionalId,
    }
  );
};

/**스터디 반려 사유 조회 */
export const useGetRejectReason = (patientId, studyStatusId, openRejetModal) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["Overlapping", patientId, studyStatusId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/${studyStatusId}/comment`);
      return data;
    },
    {
      enabled: !!patientId && !!studyStatusId && openRejetModal,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**해당 환자의 처방전 스와이퍼 조회 */
export const useGetStudySwiperList = (patientId, studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudySwiper", patientId, studyId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/tab`);
      return data;
    },
    {
      enabled: !!studyId && !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**해당 환자의 각각 처방전의 진행률 조회 */
export const useGetStudyProgress = (patientId, studyId, id) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudyProgress", patientId, studyId, id],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/progress`, {
        params: {
          additionalId: id?.additional,
          replacementId: id?.replacement,
        },
      });
      return data;
    },
    {
      enabled: !!studyId && !!patientId && !!(id?.additional || id?.replacement || id?.study),
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**해당 환자의 각각 처방전의 내역 조회 */
export const useGetStudyHistory = (patientId, studyId, id) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudyHistory", patientId, studyId, id],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/history`, {
        params: {
          additionalId: id?.additional,
          replacementId: id?.replacement,
        },
      });
      return data;
    },
    {
      enabled: !!studyId && !!patientId && !!id,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**해당 환자의 가장 최근 처방전 */
export const useGetRecentStudy = (patientId, studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["RecentStudy", patientId, studyId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/latest`);
      return data;
    },
    {
      enabled: !!patientId && !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**처방전 항목 (업데이트 되어서 사라진 프로세스) 삭제 */
export const useDeleteStudyItem = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      await axios.delete(`/api/study/${payload.studyId}/${payload.studyItem}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudySummary");
        Notiflix.Notify.success(t(IK.delete_success));
      },
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};
