import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

/**메딧스캔파일 */
export const meditScan = atom({
  key: "meditScan",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/**메딧스캔 연동만 하는지 핀딘 */
export const isOnlyMeditConnect = atom({
  key: "isOnlyMeditConnect",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

/**메딧스캔 연결여부 핀딘 */
export const meditConnect = atom({
  key: "meditConnect",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
