import { Field } from "formik";
import { useTranslation } from "react-i18next";
import Privacy1 from "../../assets/doc/pdf/join_privacy1.pdf";
import Privacy2 from "../../assets/doc/pdf/join_privacy2.pdf";
import Privacy3 from "../../assets/doc/pdf/join_privacy3.pdf";
import Terms from "../../assets/doc/pdf/join_terms.pdf";
import { FieldMessageErrorClick } from "../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Join.style";
import { TitleV1 } from "../../components/element/Title/TitleText";

const TermsForm = () => {
  const { t } = useTranslation(["translation"]);
  const data = [
    { id: "termsOfUse", title: t(IK.use_terms_title), link: Terms },
    { id: "privacy", title: t(IK.privacy_title), link: Privacy1 },
    { id: "privacyThird", title: t(IK.privacy_third_title), link: Privacy3 },
    { id: "marketing", title: t(IK.marketing_title), link: Privacy2 },
  ];

  return (
    <>
      <TitleV1 title={t(IK.terms_message)} />

      <S.JoinTerms>
        <ul>
          {data.map((data, key) => (
            <li key={key}>
              <div className="frm_div">
                <div className="checkbox">
                  <label className="label_checkbox">
                    <Field
                      type="checkbox"
                      name={data.id}
                      className="input_checkbox"
                    />
                    <span>{data.title}</span>
                  </label>
                </div>
                <a
                  href={data.link}
                  target="_blank"
                  title="새창열림"
                  rel="noreferrer noopener"
                >
                  {t(IK.show_content)}
                </a>
              </div>
              <FieldMessageErrorClick name={data.id} />
            </li>
          ))}
        </ul>
      </S.JoinTerms>
    </>
  );
};

export default TermsForm;
