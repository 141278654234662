import { IK } from "./i18n/keyStore";

export const countryLabel = {
  "01": IK.korea,
  "02": IK.japan,
  "03": IK.china,
  "04": IK.usa,
  "05": IK.mongolia,
  "06": IK.taiwan,
  "07": IK.thailand,
  "08": IK.vietnam,
  "09": IK.australia,
  10: IK.newzealand,
  11: IK.uk,
  12: IK.france,
  13: IK.italy,
  14: IK.germany,
  15: IK.egypt,
  16: IK.brazil,
  17: IK.mexico,
  18: IK.canada,
  19: IK.russia,
  20: IK.india,
  21: IK.myanmar,
  22: IK.cambodia,
};
