import * as S from "./style/VideoPreview.style";

export const VideoPreview = ({ children, youtubeLink }) => {
  return (
    <S.VideoForm>
      <dt>
        <iframe title="serafin_youtube" src={youtubeLink} frameBorder="0" allowFullScreen></iframe>
      </dt>
      <dd>{children}</dd>
    </S.VideoForm>
  );
};
