import styled from "styled-components";
import { ToolTipBox } from "../../../../components/element/ToolTip/style/ToolTip.style";

export const ProgressBox = styled.dl`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 82px;
  padding: 0 10px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  dt {
    flex: 1;
    padding-right: 10px;
    h4 {
      margin-bottom: 5px;
      font-size: 15px;
    }
    span {
      color: ${(props) => props.theme.colors.gray600};
    }
  }
  dd {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    h2 {
      max-width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
      text-align: right;
      font-size: 42px;
      font-weight: 900;
      cursor: pointer;
      &:hover {
        ${ToolTipBox} {
          display: block;
        }
      }
    }
    span {
      margin: auto 0 2px;
      font-size: 15px;
    }
  }
`;

export const PatientTabIco = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.white};
`;

export const PatientTabTxt = styled.span`
  flex: 0 0 90px;
  margin: 0 10px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
`;

export const PatientTabCount = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  text-align: right;
  margin-left: auto;
  font-size: 31px;
  font-weight: 900;
  cursor: pointer;
  &:hover {
    ${ToolTipBox} {
      display: block;
    }
  }
`;

export const PatientTab = styled.ul`
  display: flex;
  gap: 10px;
`;

export const PatientTabItem = styled.li`
  flex: 1;
  &:last-child {
    margin-right: 0;
  }
  button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 82px;
    padding: 0 10px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }

  ${({ $index, $rgbacolor, $color }) =>
    $index &&
    $rgbacolor &&
    $color &&
    `
    &:nth-child(${$index}) button {
      background: ${$rgbacolor};
    }
    &:nth-child(${$index}).active button {
      border-color: ${$color};
    }
    &:nth-child(${$index}) ${PatientTabIco} {
      background: ${$color};
    }
    &:nth-child(${$index}) ${PatientTabCount} {
      color: ${$color};
    }
  `}
`;
