import Skeleton from "react-loading-skeleton";
import * as S from "../../../../components/Layout/CardLayout/style/CardLayout.style";

export const SkeletonStudyCard = () => {
  return (
    <S.CardColWd $full>
      <Skeleton height={50} borderRadius={10} />
      <br />
      <S.CardInner>
        <S.CardTitle>
          <Skeleton width={150} height={30} />
        </S.CardTitle>
        <Skeleton height={320} />
      </S.CardInner>
    </S.CardColWd>
  );
};
