import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick, NotiMessage } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { TitleV3 } from "../../../../../components/element/Title/TitleText";
import { IK } from "../../../../../utils/i18n/keyStore";

const ApAttachment = ({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, setStudyInfo, studyInfo, patientId, isFinish, setCurrentStepIndex }) => {
  const { t } = useTranslation(["translation"]);

  const saveStepMutation = useSaveStep();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape({
    SI16: Yup.string().required(t(IK.ap_attachment_required)),
  });

  const code = currentStepList[currentStepIndex].code;

  const handleSubmit = (data) => {
    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip ? setCurrentStepIndex(currentStepList.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />
      <Formik
        initialValues={{
          SI16: studyInfo.SI16 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.ap_attachment)} required openModal={openModal}>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI16" className="input_radio" value="1" />
                    <span>{t(IK.ap_attachment_text_1)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI16" className="input_radio" value="2" />
                    <span>{t(IK.ap_attachment_text_2)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI16" className="input_radio" value="3" />
                    <span>{t(IK.ap_attachment_text_3)}</span>
                  </label>
                </div>
              </div>

              <FieldMessageErrorClick name="SI16" />
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} $primary>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <TitleV3 title={t(IK.ap_attachment_desc_1)} />
        <S2.ModalBoxBullet>
          <li>{t(IK.ap_attachment_desc_2)}</li>
          <li>{t(IK.ap_attachment_desc_3)}</li>
        </S2.ModalBoxBullet>
        <NotiMessage text={t(IK.ap_attachment_desc_4)} />
      </Modal>
    </>
  );
};

export default ApAttachment;
