import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { postMeditCode } from "../../apis/meditLinkApi";
import { isOnlyMeditConnect } from "../../atoms/meditAtom";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Medit.style";

const MeditCallBack = () => {
  document.getElementById("wrapper").style.minWidth = "630px";
  const { t } = useTranslation(["translation"]);
  const [isMedit, setIsMedit] = useState("medit_loading");
  const code = new URL(window.location.href).searchParams.get("code");
  const nav = useNavigate();
  const isOnlyConnection = useRecoilValue(isOnlyMeditConnect);

  /**사용자 언어설정과 동일하게  */
  useEffect(() => {
    const langInfo = localStorage.getItem("langInfo");
    i18next.changeLanguage(langInfo);
  }, []);

  useEffect(() => {
    postMeditCode(code)
      .then(() => {
        if (isOnlyConnection) {
          window.opener.updateParentState(); // 부모 창의 함수 호출하여 상태 변경
          setIsMedit("medit_success");
          setTimeout(() => {
            window.close();
          }, 3000);
        } else {
          nav("/medit/case-list");
        }
      })
      .catch((res) => {
        setIsMedit("medit_error");
      });
  }, [code, nav, isOnlyConnection]);

  return (
    <S.MeditBox>
      <S.MeditError>
        <i className="ri-information-line"></i>
        <h2>{t(IK[isMedit])}</h2>
      </S.MeditError>
    </S.MeditBox>
  );
};

export default MeditCallBack;
