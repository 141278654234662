import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "../../../../../components/element/Step/style/Step.style";
import { IK } from "../../../../../utils/i18n/keyStore";

function Stpes({ step, currentStepIndex }) {
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    const progressUi = document.querySelectorAll(".progress_index li");
    progressUi.forEach((item, index) => {
      if (index === step - 2) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }, [currentStepIndex, step]);

  return (
    <>
      {step > 1 && (
        <S.StepType2>
          <ol className="progress_index">
            <li className="active">
              <h3>{t(IK.details_treatment_plan)}</h3>
            </li>
            <li>
              <h3>{t(IK.extraoral)}</h3>
            </li>
            <li>
              <h3>{t(IK.intraoral)}</h3>
            </li>
            <li>
              <h3>{t(IK.radiograph)}</h3>
            </li>
            <li>
              <h3>{t(IK.scan_file_registration)}</h3>
            </li>
            <li>
              <h3>{t(IK.prescription_summary)}</h3>
            </li>
            <li>
              <h3>{t(IK.checklist)}</h3>
            </li>
          </ol>
        </S.StepType2>
      )}
    </>
  );
}

export default Stpes;
