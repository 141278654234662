import * as S from "./style/LoadingIndicator.style";

const LoadingIndicator = () => {
  return (
    <S.Loading>
      <S.Spinner />
    </S.Loading>
  );
};

export default LoadingIndicator;
