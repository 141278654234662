import { cloneElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useDoctorAuth } from "../../../Hooks/useDoctorAuth";
import useLocalStorageState from "../../../Hooks/useLocalStorageState";
import { useAuthAdminDoctor } from "../../../apis/doctorApi";
import { useGetPatientInfo } from "../../../apis/patientApi";
import { useGetStudySummary } from "../../../apis/studyApi";
import { meditScan } from "../../../atoms/meditAtom";
import { userInfo } from "../../../atoms/userAtom";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/Layout/CardLayout/style/CardLayout.style";
import LoadingIndicator from "../../../components/loading/LoadingIndicator/LoadingIndicator";
import { getSteps, stepTypes, steps, studyFormConfig } from "../../../utils/studyUtils";
import Filter from "./Sections/Filter/Filter";
import Stpes from "./Sections/Stpes/Stpes";

function Study() {
  const { patientId, studyId } = useParams();

  /**처방전 작성권한 조회 */
  useAuthAdminDoctor(patientId);

  /**담당환자 여부 조회 */
  const access = useDoctorAuth(patientId);

  /**사용자 정보 가져오기 */
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  /**처방전 정보값 저장 */
  const [studyInfo, setStudyInfo] = useState({});

  /**새로고침시 페이지, 데이터 날라가지 않도록 */
  const [currentStepType, setCurrentStepType] = useLocalStorageState("stepTypes", stepTypes.COMMON_PREPEND);
  const [currentStepIndex, setCurrentStepIndex] = useLocalStorageState("stepIndex", 0);
  const [reloading, setReloading] = useState(true);

  /**요약 수정인지 아닌지 판단 */
  const [isFinish, setIsFinish] = useState(false);

  const setMeditFiles = useSetRecoilState(meditScan);
  const navigate = useNavigate();

  /**환자 정보 가져오기 */
  const { data: patientData, isLoading: isPatientLoading } = useGetPatientInfo(patientId, studyId);
  const patientInfo = patientData?.data;

  // 현재 단계에 대한 정보 가져오기
  const currentStepList = steps[stepTypes.COMMON_PREPEND].concat(getSteps(currentStepType, countryCode)).concat(steps[stepTypes.COMMON_APPEND]);
  //교합이개 제거 (studyInfo.SI01이 하악이면 전부 제거)
  if (studyInfo.SI01 === "3") {
    for (let i = 0; i < currentStepList.length; i++) {
      if (currentStepList[i].label === "BiteRamp") {
        currentStepList.splice(i, 1);
        break;
      }
    }
  }

  //지금 보고 있는 스텝 정보
  const currentStep = currentStepList[currentStepIndex];

  /**처음 처방전 작성시 환자 이름 저장, 스터디 아이디 없을때만 조회 */
  useEffect(() => {
    //첫 작성시에만 탐색
    if (!studyId && !isPatientLoading) {
      const patientName = `${patientInfo?.firstName} ${patientInfo?.lastName}${patientInfo?.koreaName ? ` (${patientInfo.koreaName})` : ""}`;
      setStudyInfo((prev) => ({ ...prev, patientName: patientName }));
      setReloading(false);
    }
  }, [isPatientLoading, patientInfo, studyId]);

  /**처방전 정보 가져오기 */
  const { data: studyData, isLoading: isStudyLoading } = useGetStudySummary(patientId, studyId);
  const studyPatientData = studyData?.data;

  /**처방전 작성 진행한 환자 의 처방전 정보 저장*/
  useEffect(() => {
    //이어서 작성하기로 들어온 경우
    if (studyId && !isStudyLoading) {
      setCurrentStepType(studyPatientData.study.packages);
      const currentStepList = [...steps[stepTypes.COMMON_PREPEND], ...getSteps(studyPatientData.study.packages, countryCode), ...steps[stepTypes.COMMON_APPEND]];

      //교합이개 제거
      if (studyPatientData.SI01 === "3") {
        for (let i = 0; i < currentStepList.length; i++) {
          if (currentStepList[i].label === "BiteRamp") {
            currentStepList.splice(i, 1);
            break;
          }
        }
      }

      let index = currentStepList.findIndex((step, i) => {
        //인덱스 0,1은 제외(연령, 옵션)
        if (i < 2) return false;
        //치료플랜부터 코드 검사. 빈값이 있는 경우 해당 index 반환

        //파일등록하는 부분 검사
        if (step.files) {
          const requiredConfig = studyFormConfig[studyPatientData.study.packages][step.label];
          for (const key in requiredConfig) {
            //필수값 여부
            const value = requiredConfig[key];
            //필수값인데 값이 저장된게 없으면, 여기를 보여줌.
            if (value && !studyPatientData[key]) {
              return true;
            }
          }
        } else if (step.validateCode) {
          const codes = step.validateCode.split(",");

          return codes.every((code) => !studyPatientData[code]);
        }
        return false;
      });

      if (index === -1) {
        //빈값을 못찾았다 => 전부 작성완료 => 요약으로 이동
        index = currentStepList.length - 2;
      }
      /* 
        초기 작성인 경우와 이어서 작성하기로 들어온 경우에는 새롭게 인덱스를 셋팅한다. 
        그 이외의 경우에는 localStorage에 있는 값을 그대로 활용(0)한다.
        새로고침시에, 다른 화면으로 넘어가는 것 방지
      */

      if (index !== -1) {
        //빈값이 있고, 치료 플랜까지는 했음
        if (!!currentStepIndex && currentStepIndex < index) {
          setCurrentStepIndex(currentStepIndex);
        } else {
          setCurrentStepIndex(index);
        }
      }
      const { firstName, lastName, korName } = studyPatientData.patient;
      const { studyId, age, packages } = studyPatientData.study;

      setStudyInfo({
        ...studyPatientData,
        studyId,
        age,
        packages,
        patientName: `${firstName} ${lastName}${!!korName ? ` (${korName})` : ""}`,
      });
      if (studyPatientData.detail === "12") {
        setIsFinish(true);
      }
      setReloading(false);
    } else if (!studyId) {
      setCurrentStepIndex(0);
    }
    // eslint-disable-next-line
  }, [isStudyLoading, studyPatientData]);

  // 다음 단계로 이동
  function next() {
    if (currentStepIndex < currentStepList.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }

  // 이전 단계로 이동
  function prev() {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    } else {
      navigate(`/patient/${patientId}/profile`);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    /**미등록 이탈시 데이터 지우기 */
    return () => {
      setMeditFiles([]);
    };
  }, [currentStepIndex, setMeditFiles]);

  if (!access) return null; //권한 없을시 컴포넌트 렌더링 안함

  return (
    <>
      {isPatientLoading || isStudyLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Stpes step={currentStepList[currentStepIndex].step} currentStepIndex={currentStepIndex} />
          <S.CardFlex>
            <S.CardColWd $full>
              <CardInnerLayout>
                {!reloading &&
                  cloneElement(currentStep.component, {
                    next,
                    prev,
                    currentStepType,
                    setCurrentStepType,
                    setCurrentStepIndex,
                    studyInfo,
                    setStudyInfo,
                    patientId,
                    stepLabel: currentStep?.label,
                    currentStepList,
                    currentStepIndex,
                    setIsFinish,
                    isFinish,
                  })}
              </CardInnerLayout>
            </S.CardColWd>
            <Filter isStudy studyInfo={studyInfo} setStudyInfo={setStudyInfo} setCurrentStepIndex={setCurrentStepIndex} currentStepIndex={currentStepIndex} currentStepList={currentStepList} />
          </S.CardFlex>
        </>
      )}
    </>
  );
}
export default Study;
