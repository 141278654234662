import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetOverlappingData } from "../../../../../apis/studyApi";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";
import OverlapModal from "./OverlapModal";

const SubmitStudy = ({ studyInfo, progress, lastDetail, selectedId }) => {
  const { patientId } = useParams();
  const { t } = useTranslation(["translation"]);

  /**요약 이동 경로 */
  const linkList = {
    study: `/patient/${patientId}/study/${studyInfo?.studyId}/summary`,
    additional: `/patient/${patientId}/study/${studyInfo?.studyId}/summary/${studyInfo?.additionalId}`,
    replacement: `/patient/${patientId}/study/${studyInfo?.studyId}/summary/replace/${studyInfo?.replacementId}`,
  };

  /**비교중첩 장치 데이터 조회 */
  const { data: overlapData, isLoading } = useGetOverlappingData(studyInfo?.additionalId);
  const overlapDataList = overlapData?.data;

  const [overlapOpen, setOverlapOpen] = useState(false);
  const [overlapObj, setOverlapObj] = useState({});

  useEffect(() => {
    if (!isLoading && overlapDataList?.length > 0) {
      const overlaps = overlapDataList.reduce((acc, curr) => {
        acc[curr.position] = curr.distFileName;
        return acc;
      }, {});
      setOverlapObj(overlaps);
    }
  }, [isLoading, overlapDataList]);

  /**비교중첩 데이터 사진 보기 */
  const handleOverlappingData = () => {
    setOverlapOpen(true);
  };

  return (
    <>
      {progress ? (
        <S.ProfileHistoryDesc>
          <S.ProfileHistoryDescDot $complete={lastDetail >= "13"} />
          <dl>
            <dt>{t(IK.submit_study)}</dt>
            {studyInfo?.createdDate && (
              <dd>
                {lastDetail >= "13" && (
                  <>
                    <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>
                    <S2.ButtonFlex>
                      <S2.StyledSmallButton to={linkList[Object.keys(selectedId)[0]]}>{t(IK.submit_study_view)}</S2.StyledSmallButton>
                      {overlapDataList?.length > 0 && (
                        <S2.StyledSmallButton as="button" onClick={handleOverlappingData}>
                          {t(IK.overlapping_data_view)}
                        </S2.StyledSmallButton>
                      )}
                    </S2.ButtonFlex>
                  </>
                )}
              </dd>
            )}
          </dl>
        </S.ProfileHistoryDesc>
      ) : (
        <>
          <S.ProfileHistoryCircle />
          <S.ProfileHistoryModalItem>
            <S.ProfileDepth1>
              <h3>{t(IK.submit_study)}</h3>
              {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
            </S.ProfileDepth1>
          </S.ProfileHistoryModalItem>
        </>
      )}
      <OverlapModal overlapOpen={overlapOpen} setOverlapOpen={setOverlapOpen} overlapObj={overlapObj} />
    </>
  );
};

export default SubmitStudy;
