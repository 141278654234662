import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-simple-image-viewer";
import Modal from "../../../../../components/Modal/Modal";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import * as S2 from "../../../../../styles/Common";
import { IK } from "../../../../../utils/i18n/keyStore";
import { Preview } from "../../../Summary/Sections/Preview";

const OverlapModal = ({ overlapOpen, setOverlapOpen, overlapObj }) => {
  const { t } = useTranslation(["translation"]);

  const handleClose = () => {
    setOverlapOpen(false);
  };

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (src) => {
    setImageViewer({ imgSrc: [src], isView: true });
  };

  const overlapList = [
    { name: "occlusal_upper", value: overlapObj?.ovlp_occlusal_up },
    { name: "overjet_front_top", value: overlapObj?.ovlp_intraoral_up },
    { name: "buccal_right", value: overlapObj?.ovlp_intraoral_lwr },
    { name: "buccal_left", value: overlapObj?.ovlp_occlusal_lwr },
    { name: "occlusal_lower", value: overlapObj?.ovlp_buccal_r_up },
    { name: "overjet_front_bottom", value: overlapObj?.ovlp_buccal_r_lwr },
    { name: "buccal_right", value: overlapObj?.ovlp_buccal_l_up },
    { name: "buccal_left", value: overlapObj?.ovlp_buccal_l_lwr },
  ];

  return (
    <>
      <Modal open={overlapOpen} close={handleClose} header={t(IK.overlapping_data)}>
        <S.DropListWrap $overlap>
          {overlapList.map((item, index) => (
            <S.DropList key={index} className={item.name}>
              <S.DropItem>{item.value && <Preview path={item.value} handleImageViewer={handleImageViewer} />}</S.DropItem>
            </S.DropList>
          ))}
        </S.DropListWrap>
      </Modal>
      {imageViewer.isView && (
        <S2.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S2.ImageViewerBox>
      )}
    </>
  );
};

export default OverlapModal;
