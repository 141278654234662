import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useGetAlarmCount } from "../../apis/alarmApi";
import { useGetDoctorDTO } from "../../apis/doctorApi";
import { useGetCountryPopup } from "../../apis/popupApi";
import defaultImage from "../../assets/images/common/no_profile.png";
import { userInfo, userRole, userTimezone } from "../../atoms/userAtom";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import AccountUpdateModal from "../Modal/AccountUpdateModal";
import MainModalWrapper from "../Modal/MainModal";
import { LogoType2 } from "../element/Logo/Logo";
import { DownloadModal } from "./DownloadModal";
import SidebarContent from "./SidebarContent";
import SidebarNavi from "./SidebarNavi";
import AlarmPopup from "./alarm/AlarmPopup";
import * as S from "./style/Sidebar.style";

function Sidebar() {
  const { t } = useTranslation(["translation"]);

  /**사용자 정보 가져오기 */
  const [user, setUser] = useRecoilState(userInfo);
  const role = useRecoilValue(userRole);

  /**타임존 선택 */
  const setSelectedTimezone = useSetRecoilState(userTimezone);

  /**유저 정보 받아오기 */
  const { data: userData, isLoading } = useGetDoctorDTO(user.doctorNumber);

  /**코인 정보 받아오기 */
  // const { data: coinData } = useGetDoctorCoin();

  /**회원정보 업데이트 모달 */
  const [updateModal, setOpenUpdateModal] = useState(false);

  /**팝업 리스트 조회 */
  const { data: popupData } = useGetCountryPopup();

  useEffect(() => {
    if (!isLoading && userData?.data) {
      setSelectedTimezone(userData.data.accountTimeZone?.split(":")[0]);
      setUser(userData.data);
    }
  }, [isLoading, userData, setUser, setSelectedTimezone]);

  /**회원정보 업데이트 팝업 */
  useEffect(() => {
    if (userData?.data?.updateInformationYn === "N") {
      setOpenUpdateModal(true);
    }
  }, [userData]);

  /**알람창 열고 닫기 */
  const [alarmPopOpen, setAlarmPopOpen] = useState(false);

  /**알림창 컨트롤 */
  const alarmModalRef = useRef(null);

  /**30분마다 알람 조회 */
  const { data } = useGetAlarmCount();
  const alarmCount = data?.data;

  //모달 열기
  const handleOpenAlarmPopup = useCallback(() => {
    setAlarmPopOpen((prev) => !prev);
  }, []);

  // 외부 클릭 시 모달 닫기
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (alarmModalRef?.current && !alarmModalRef?.current.contains(event.target)) {
        setAlarmPopOpen(false);
      }
    };

    // 모달 외부 클릭 감지 이벤트 리스너 등록
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**세라뷰 다운로드 */
  const [downloadModal, setDownloadModal] = useState(false);
  const handleOpenDownloadModal = () => setDownloadModal(true);
  const handleCloseDownloadModal = () => setDownloadModal(false);

  /**사이드바 메뉴 */
  const naviList = [
    { title: t(IK.patient), nav: "/patient", icon: "ri-user-heart-fill" },
    {
      title: t(IK.account),
      nav: "/account",
      icon: "ri-user-settings-fill",
      depth: [
        { title: t(IK.mypage), nav: "/account/mypage", roleAuth: true },
        {
          title: t(IK.payment),
          nav: "/account/billing-list",
          roleAuth: user.countryCode === "01" && user.doctorType !== "DENTIS" && !role?.STAFF,
        },
        {
          title: t(IK.pay_price_discount),
          nav: "/account/grade",
          roleAuth: true,
        },
        {
          title: t(IK.clinical_configurations),
          nav: "/account/clinical-configurations",
          roleAuth: true,
        },
      ],
    },
    {
      title: t(IK.educational),
      nav: "/education",
      icon: "ri-health-book-fill",
      depth: [
        {
          title: t(IK.support_link),
          nav: "/education/certified-training",
          roleAuth: true,
        },
        {
          title: t(IK.do_better),
          nav: "/education/correction-image-list",
          roleAuth: true,
        },
        {
          title: t(IK.ebook),
          nav: "/education/educational-materials-list",
          roleAuth: true,
        },
        {
          title: t(IK.sample_seraview),
          nav: "/education/sample-seraview-list",
          roleAuth: true,
        },
        {
          title: t(IK.clinical_gallery),
          nav: "/education/clinical-cases-list",
          roleAuth: true,
        },
        {
          title: t(IK.resources),
          nav: "/education/library-list",
          roleAuth: true,
        },
      ],
    },
    {
      title: t(IK.support),
      nav: "/support",
      icon: "ri-dossier-fill",
      depth: [
        {
          title: t(IK.frequently_asked_questions),
          nav: "/support/faq",
          roleAuth: true,
        },
        { title: t(IK.event), nav: "/support/event-list", roleAuth: true },
        {
          title: t(IK.notice_board),
          nav: "/support/notice-list",
          roleAuth: true,
        },
      ],
    },
  ];

  return (
    <>
      {/**팝업 디스플레이 */}
      <MainModalWrapper modals={popupData} />
      {/**회원정보 업데이트 모달 */}
      <AccountUpdateModal updateModal={updateModal} setOpenUpdateModal={setOpenUpdateModal} user={user} />
      {/**알람 팝업 */}
      {alarmPopOpen && (
        <div ref={alarmModalRef}>
          <AlarmPopup open={alarmPopOpen} handleCloseAlarmPopup={handleOpenAlarmPopup} header={t(IK.alarm)} />
        </div>
      )}
      {/**다운로드 모달 */}
      <DownloadModal downloadModal={downloadModal} handleCloseDownloadModal={handleCloseDownloadModal} />

      {/**사이드바 */}
      <S.SidebarArea>
        <S.SidebarHead>
          <LogoType2 />
        </S.SidebarHead>
        <S.SidebarProfile>
          <button type="button" onClick={handleOpenAlarmPopup}>
            <img src={user?.logoPath || defaultImage} alt="proLogo" />
            <S2.CountBadge $red>{alarmCount}</S2.CountBadge>
          </button>
          <Link to="/account/mypage">
            <h3>
              {user?.doctorName || user?.name}
              {/**한국이름 */}
              {user?.countryCode === "01" && user?.koreaName && (
                <span>
                  {`${user?.koreaName}`} {role?.STAFF && "(STAFF)"}
                </span>
              )}
            </h3>
            {/* <p>
              {t(IK.coin)} : {coinData}
            </p> */}
          </Link>
        </S.SidebarProfile>
        <S.SidebarNav>
          <S.SidebarNavDepth1>
            {naviList.map((navi) => (
              <SidebarNavi key={navi.nav} navi={navi} />
            ))}
          </S.SidebarNavDepth1>
        </S.SidebarNav>
        {/**사이드바 하단 컨텐츠 */}
        <SidebarContent user={user} handleOpenDownloadModal={handleOpenDownloadModal} />
      </S.SidebarArea>
    </>
  );
}

export default Sidebar;
