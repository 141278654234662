import axios from "axios";
import qs from "qs";
import { ErrorBoundary } from "react-error-boundary";
import { RecoilRoot } from "recoil";
import ErrorFallback from "./pages/error/ErrorFallback";
import Router from "./router/Router";

/**스타일 관련*/
import "fontawesome-4.7/css/font-awesome.min.css";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "remixicon/fonts/remixicon.css";
import { ThemeProvider } from "styled-components";
import "../src/components/Notiflix/Notiflix";
import "./styles/Font.css";
import GlobalStyle from "./styles/GlobalStyle";
import { Theme } from "./styles/Theme";

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};
axios.defaults.headers.common["fe_request"] = true;

const App = () => {
  const token = localStorage.getItem("accessToken");
  if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  useEffect(() => {
    if (!localStorage.getItem("langInfo")) {
      const lang = window?.navigator?.language;
      localStorage.setItem("langInfo", lang === "ko-KR" || lang === "ko" ? "ko" : "en");
    }
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <RecoilRoot>
        <GlobalStyle />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Router />
        </ErrorBoundary>
      </RecoilRoot>
    </ThemeProvider>
  );
};

export default App;
