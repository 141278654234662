import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CommonImage from "../../../../components/common/CommonImage";
import { IK } from "../../../../utils/i18n/keyStore";

const Card = ({ item }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <li>
      <Link to={`/education/clinical-cases-list/${item.clinicalId}`}>
        <dl>
          <dt>
            <CommonImage filePath={item.distFileName} />
            <span className="keyword">#{item.clinicalId}</span>
          </dt>
          <dd>
            <ul>
              <li>{t(IK[item?.gender])}</li>
              <li>{item.age}</li>
              <li>{t(IK[item.packages])}</li>
            </ul>
          </dd>
        </dl>
      </Link>
    </li>
  );
};

export default Card;
