import { useEffect, useState } from "react";

export const useRememberPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const page = Number(urlParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    const pageUrl = `?page=${currentPage}`;
    window.history.pushState("", null, pageUrl);
  }, [currentPage]);

  return [currentPage, setCurrentPage];
};
