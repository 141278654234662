import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../../atoms/userAtom";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../components/element/Table/style/table.style";
import { TitleV3 } from "../../../components/element/Title/TitleText";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/Grade.style";

function Grade() {
  const { t } = useTranslation(["translation"]);
  /**사용자 정보 가져오기 */
  const user = useRecoilValue(userInfo);

  return (
    <CardInnerLayout>
      {user?.countryCode === "01" && (
        <>
          <TitleV3 title={t(IK.membership_levels)} />
          <S2.TableType1>
            <colgroup>
              <col width="190px" />
              <col width="190px" />
              <col width="190px" />
              <col width="190px" />
              <col width="190px" />
              <col width="190px" />
              <col width="190px" />
              <col width="190px" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{t(IK.grade)}</th>
                <td
                  style={{
                    backgroundColor: "green",
                    borderRightColor: "rgba(255,255,255,0.2)",
                    color: "#fff",
                  }}
                >
                  {t(IK.green)}
                </td>
                <td
                  style={{
                    backgroundColor: "red",
                    borderRightColor: "rgba(255,255,255,0.2)",
                    color: "#fff",
                  }}
                >
                  {t(IK.red)}
                </td>
                <td
                  style={{
                    backgroundColor: "blue",
                    borderRightColor: "rgba(255,255,255,0.2)",
                    color: "#fff",
                  }}
                >
                  {t(IK.blue)}
                </td>
                <td
                  style={{
                    backgroundColor: "#cc3399",
                    borderRightColor: "rgba(255,255,255,0.2)",
                    color: "#fff",
                  }}
                >
                  {t(IK.ruby)}
                </td>
                <td
                  style={{
                    backgroundColor: "purple",
                    borderRightColor: "rgba(255,255,255,0.2)",
                    color: "#fff",
                  }}
                >
                  {t(IK.purple)}
                </td>
                <td
                  style={{
                    backgroundColor: "black",
                    borderRightColor: "rgba(255,255,255,0.2)",
                    color: "#fff",
                  }}
                >
                  {t(IK.black)}
                </td>
                <td style={{ backgroundColor: "#2161ab", color: "#fff" }}>{t(IK.serafin)}</td>
              </tr>
              <tr>
                <th scope="row">{t(IK.discount)}</th>
                <td>15%</td>
                <td>20%</td>
                <td>25%</td>
                <td>27%</td>
                <td>30%</td>
                <td>32%</td>
                <td>35%</td>
              </tr>
              <tr>
                <th scope="row">{t(IK.cumulative_usage)}</th>
                <td>21 - 50</td>
                <td>51 - 100</td>
                <td>101 - 200</td>
                <td>201 - 300</td>
                <td>301 - 500</td>
                <td>501 - 1000</td>
                <td>1001 - 2000</td>
              </tr>
            </tbody>
          </S2.TableType1>
        </>
      )}

      <S3.ContentBox $large>
        <TitleV3 title={t(IK.price)} />
        <S2.TableType1>
          <colgroup>
            <col width="220px" />
            <col width="141.25px" />
            <col width="141.25px" />
            <col width="141.25px" />
            <col width="141.25px" />
            <col width="141.25px" />
            <col width="141.25px" />
            <col width="141.25px" />
            <col width="141.25px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="row">{t(IK.serafin_options)}</th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#459e9a",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                R-passive
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#2161ab",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                R-active
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#2161ab",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                10
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#2161ab",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                20
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#2161ab",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                {t(IK.regular)}
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#2161ab",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                {t(IK.regular)} AP
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#5fa8dd",
                  borderRightColor: "rgba(255,255,255,0.2)",
                  color: "#fff",
                }}
              >
                i
              </th>
              <th scope="col" style={{ backgroundColor: "#5fa8dd", color: "#fff" }}>
                iAP
              </th>
            </tr>
          </thead>
          <tbody>
            {user.countryCode === "01" && (
              <tr>
                <th scope="row">{t(IK.price_krw)}</th>
                <td>
                  {t(IK.maxilla2)} - 31
                  <br />
                  {t(IK.bimaxillay)} - 41
                </td>
                <td>
                  {t(IK.maxilla2)} - 31
                  <br />
                  {t(IK.bimaxillay)} - 41
                </td>
                <td>
                  {t(IK.maxilla2)} - 62
                  <br />
                  {t(IK.bimaxillay)} - 82
                </td>
                <td>
                  {t(IK.maxilla2)} - 125
                  <br />
                  {t(IK.bimaxillay)} - 162
                </td>
                <td>225</td>
                <td>250</td>
                <td>
                  {t(IK.maxilla2)} - 62
                  <br />
                  {t(IK.bimaxillay)} - 82
                </td>
                <td>102</td>
              </tr>
            )}
            <tr>
              <th scope="row">{t(IK.number_of_steps)}</th>
              <td>8 {t(IK.pieces)}</td>
              <td>4 {t(IK.level)}</td>
              <td>10 {t(IK.level)}</td>
              <td>20 {t(IK.level)}</td>
              <td>{t(IK.no_limit)}</td>
              <td>{t(IK.no_limit)}</td>
              <td>{t(IK.no_limit)}</td>
              <td>{t(IK.no_limit)}</td>
            </tr>
            <tr>
              <th scope="row">
                {t(IK.additional_device)}
                <br />
              </th>
              <td>-</td>
              <td>{t(IK.time1_step4)}</td>
              <td>{t(IK.time1_step10)}</td>
              <td>{t(IK.time1_step20)}</td>
              <td>{t(IK.no_limit)}</td>
              <td>{t(IK.no_limit)}</td>
              <td>{t(IK.no_limit)}</td>
              <td>{t(IK.no_limit)}</td>
            </tr>
            <tr>
              <th scope="row">
                {t(IK.pay_need)}
                {t(IK.additional_device)}
                <br />
                {t(IK.number_of_steps)}
                <br />
                {t(IK.price_krw)}
              </th>
              <td>-</td>
              <td>-</td>
              <td>
                ○
                <br />
                {t(IK.time1_step10)}
              </td>
              <td>
                ○
                <br />
                {t(IK.time1_step10)}
              </td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <th scope="row">{t(IK.end_date)}</th>
              <td>8{t(IK.months)}</td>
              <td>8{t(IK.months)}</td>
              <td>1{t(IK.year)}</td>
              <td>2{t(IK.years)}</td>
              <td>5{t(IK.years)}</td>
              <td>5{t(IK.years)}</td>
              <td>1{t(IK.year)}</td>
              <td>18{t(IK.months)}</td>
            </tr>
          </tbody>
        </S2.TableType1>
      </S3.ContentBox>
      {user.countryCode === "01" && (
        <S3.ContentBox $large>
          <TitleV3 title={`Additional ${t(IK.package)}`} />
          <S.AdditionalBox>
            <dl>
              <dt>{t(IK.additional_package_title1)}</dt>
              <dd>
                <p>{t(IK.additional_package_txt1_1)}</p>
                <p>{t(IK.additional_package_txt1_2)}</p>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.alternative_correction_devices)}</dt>
              <dd>
                <p>{t(IK.additional_package_txt2_1)}</p>
                <p>{t(IK.additional_package_txt2_2)}</p>
              </dd>
            </dl>
            <dl>
              <dt>Passive</dt>
              <dd>
                <p>{t(IK.additional_package_txt3_1)}</p>
              </dd>
            </dl>
          </S.AdditionalBox>
        </S3.ContentBox>
      )}
    </CardInnerLayout>
  );
}

export default Grade;
