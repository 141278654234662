import cx from "classnames";
import { useTranslation } from "react-i18next";
import { IK } from "../../../../utils/i18n/keyStore";
import * as S from '../../../../components/element/Step/style/Step.style';

const CertifiedTrainingSteps = ({ currentStepIndex }) => {
  const { t } = useTranslation(["translation"]);
  const stepsList = [
    t(IK.support_link_step_title1_1) + "\n" + t(IK.support_link_step_title1_2),
    t(IK.support_link_step_title2_1) + "\n" + t(IK.support_link_step_title2_2),
    t(IK.support_link_step_title3_1) + "\n" + t(IK.support_link_step_title3_2),
    t(IK.support_link_step_title4_1) + "\n" + t(IK.support_link_step_title4_2),
  ];

  return (
    <S.StepType1>
      <ol>
        {stepsList.map((step, idx) => (
          <li key={idx} className={cx("", currentStepIndex > idx && "active", idx === currentStepIndex && "active")}>
            <span>{idx + 1}</span>
            <h3>{step}</h3>
          </li>
        ))}
      </ol>
    </S.StepType1>
  );
};

export default CertifiedTrainingSteps;
