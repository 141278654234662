import Skeleton from "react-loading-skeleton";
import * as S from '../../../../components/Layout/CardLayout/style/CardLayout.style';

export const SkeletonProfileCard = () => {
  const skeletonCard = [
    { wt: 40, wd: 40 },
    { wt: 60, wd: 100 },
    { wt: 80, wd: 100 },
    { wt: 60, wd: 80 },
    { wt: 60, wd: 80 },
    { wt: 60, wd: 120 },
    { wt: 60, wd: 120 },
  ];
  return (
    <S.CardProfileWrapper>
      <S.CardProfileBox>
        <S.CardProfileHead>
          <S.CardProfileHeadInfo>
            <S.CardProfileHeadInfoImg>
              <Skeleton circle width={80} height={80} />
            </S.CardProfileHeadInfoImg>
            <S.CardProfileHeadInfoTxt>
              <Skeleton width={100} height={30} />
              <Skeleton width={100} height={20} />
            </S.CardProfileHeadInfoTxt>
          </S.CardProfileHeadInfo>
        </S.CardProfileHead>

        <S.CardProfileBodyInfo>
          {skeletonCard.map((card, i) => (
            <SkeletonCardText key={i} card={card} />
          ))}
        </S.CardProfileBodyInfo>
      </S.CardProfileBox>
    </S.CardProfileWrapper>
  );
};

export const SkeletonCardText = ({ card }) => {
  return (
    <dl>
      <dt>
        <Skeleton height={20} width={card.wt} />
      </dt>
      <dd>
        <Skeleton height={20} width={card.wd} />
      </dd>
    </dl>
  );
};
