import styled from "styled-components";

export const StepType1 = styled.div`
  margin-bottom: 40px;
  ol {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    li {
      position: relative;
      flex: 1;
      padding: 0 20px;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        top: 23px;
        right: 50%;
        width: 100%;
        height: 2px;
        background-color: ${(props) => props.theme.colors.gray300};
      }
      &:first-child:before {
        display: none;
      }
      &.active::before {
        background-color: ${(props) => props.theme.colors.blue400};
      }
      span {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.gray300};
        font-weight: 600;
      }
      h3 {
        margin-top: 13px;
        font-size: 15px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.gray600};
        white-space: pre-line;
      }
      &.active span {
        background-color: ${(props) => props.theme.colors.blue400};
        color: ${(props) => props.theme.colors.white};
      }
      &.active h3 {
        font-weight: 600;
        color: ${(props) => props.theme.colors.blue400};
      }
    }
  }
`;

export const StepType2 = styled.ol`
  margin-bottom: 35px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  ol {
    display: flex;
    li {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      height: 70px;
      padding: 15px 30px;
      text-align: center;
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
      &:last-child {
        border-right: 0;
      }
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -9px;
        margin-left: -16px;
        width: 16px;
        height: 16px;
        border-top: 1px solid ${(props) => props.theme.colors.gray200};
        border-right: 1px solid ${(props) => props.theme.colors.gray200};
        background-color: ${(props) => props.theme.colors.white};
        transform: rotate(135deg);
        opacity: 0;
      }
      &.active:before {
        opacity: 1;
      }
      h3 {
        flex: 1;
        font-size: 15px;
      }
      &.active h3 {
        color: ${(props) => props.theme.colors.blue400};
      }
    }
  }
`;
