import dayjs from "dayjs";
import { useSearchParam } from "../../../Hooks/useSearchParam";
import { useGetPatientList } from "../../../apis/patientApi";
import { BoardBottom } from "../../../components/Layout/BoardLayout/BoardLayout";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/Layout/CardLayout/style/CardLayout.style";
import { SkeletonBoardTable } from "../../../components/element/BoardList/SkeletonBoardTable";
import Paging from "../../../components/element/Paging/Paging";
import { changeParam } from "../../../utils/searchUtils";
import ProgressCard from "./Sections/ProgressCard";
import SearchFilter from "./Sections/SearchFilter";
import TabType from "./Sections/TabType";
import Table from "./Sections/Table";

const PatientsList = () => {
  /**검색 조건 설정 */
  const { searchCondition, setSearchCondition } = useSearchParam({
    status: "PS01",
    page: 1,
  });

  /**환자 리스트 조회 */
  const { data, isLoading, isError } = useGetPatientList(changeParam(searchCondition));
  const patientList = data?.data;

  /**페이지 변경 */
  const handleChangePage = (page) => {
    setSearchCondition((prev) => ({ ...prev, page }));
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardFlex>
          <S.CardColWd $wd300>
            <ProgressCard startDate={dayjs().year() + ". 1. 1"} endDate={dayjs().year() + ". 12. 31"} />
          </S.CardColWd>

          <S.CardColWd $full>
            <TabType setSearchCondition={setSearchCondition} searchCondition={searchCondition} />
          </S.CardColWd>
        </S.CardFlex>
      </S.CardCol>

      <S.CardCol>
        <CardInnerLayout>
          <SearchFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} />
          {isLoading || isError ? (
            <SkeletonBoardTable />
          ) : (
            <>
              <Table searchCondition={searchCondition} setSearchCondition={setSearchCondition} info={patientList?.content} isLoading={isLoading} />
              <BoardBottom>
                <Paging totalCount={patientList.totalElements} currentPage={searchCondition.page} size={7} handleChangePage={handleChangePage} />
              </BoardBottom>
            </>
          )}
        </CardInnerLayout>
      </S.CardCol>
    </S.CardRow>
  );
};

export default PatientsList;
