import dayjs from "dayjs";
import { Field, Formik } from "formik";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import * as S4 from "../../../components/Layout/CardLayout/style/CardLayout.style";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "../Button/style/ButtonLayout.style";
import { ButtonCtBox } from "../Button/style/ButtonLayout.style";
import { DatePicker } from "../DatePicker/DatePicker";
import * as S3 from "../Form/style/Form.style";
import * as S2 from "../Table/style/table.style";

const BoardSearchBar = ({ searchCondition, setSearchCondition, boardSort, isDate }) => {
  const { t } = useTranslation(["translation"]);

  /**검색어 추가 */
  const handleSubmit = (e, values) => {
    e.preventDefault();
    const data = { ...values };

    if ((data.searchStart || data.searchEnd) && !(data.searchStart && data.searchEnd)) {
      // data.startDate와 data.endDate 중 하나는 값이 있고 다른 하나는 없는 경우
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }

    if (boardSort) {
      delete data.searchStart;
      delete data.searchEnd;
    }
    setSearchCondition((prev) => ({ ...prev, page: 1, ...data }));
  };

  /**검색어 초기화 */
  const handleReset = (resetForm) => {
    const resetValue = {
      page: 1,
    };

    if (boardSort) {
      resetValue.sort = boardSort;
    }

    if (isDate) {
      resetValue.endEvent = "N";
    }

    setSearchCondition(resetValue);

    resetForm({
      values: {
        searchSelect: "title",
        searchText: "",
        searchStart: "",
        searchEnd: "",
      },
    });
  };

  /** 기간 검색*/
  const handleDateChange = (event, setFieldValue) => {
    const dateValue = event.target.value;
    const [gap, gapDate] = dateValue.split(",");
    if (gap) {
      const startDate = new Date(dayjs().subtract(gap, gapDate).format("YYYY-MM-DD"));
      const endDate = new Date();
      setFieldValue("searchStart", dayjs(startDate).format("YYYY-MM-DD"));
      setFieldValue("searchEnd", dayjs(endDate).format("YYYY-MM-DD"));
    } else {
      setFieldValue("searchStart", "");
      setFieldValue("searchEnd", "");
    }
  };

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date, setFieldValue) => {
    if (date) {
      setFieldValue("searchStart", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setFieldValue("searchStart", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handleEndDate = (date, setFieldValue) => {
    if (date) {
      setFieldValue("searchEnd", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setFieldValue("searchEnd", "");
    }
  };

  return (
    <S4.CardCol>
      <S4.CardInner>
        <Formik
          initialValues={{
            searchSelect: searchCondition.searchSelect || "title",
            searchText: searchCondition.searchText || "",
            searchStart: searchCondition.searchStart || "",
            searchEnd: searchCondition.searchEnd || "",
          }}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({ values, resetForm, setFieldValue }) => (
            <form>
              <S2.TableType2>
                <colgroup>
                  <col width="200px"></col>
                  <col width="*"></col>
                  {isDate && (
                    <>
                      <col width="200px"></col>
                      <col width="*"></col>
                    </>
                  )}
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      {t(IK.search_title)}/{t(IK.search_content)}
                    </th>
                    <td>
                      <S3.FormControlsFlex $rowCenter>
                        <Field name="select">
                          {({ field }) => (
                            <S3.FormControls $select as="select" {...field} name="searchSelect" id="searchSelect" value={values.searchSelect}>
                              <option value="title">{t(IK.search_title)}</option>
                              <option value="content">{t(IK.search_content)}</option>
                            </S3.FormControls>
                          )}
                        </Field>
                        <Field type="text" name="searchText" as={S3.FormControls} />
                      </S3.FormControlsFlex>
                    </td>
                    {isDate && (
                      <>
                        <th scope="row">{t(IK.create_date)}</th>
                        <td>
                          <S3.FormControlsFlex $rowCenter>
                            <Field name="select">
                              {({ field }) => (
                                <S3.FormControls $select as="select" {...field} name="searchPeriod" onChange={(e) => handleDateChange(e, setFieldValue)}>
                                  <option value="">{t(IK.all_date)}</option>
                                  <option value={[1, "day"]}>{t(IK.day1)}</option>
                                  <option value={[7, "day"]}>{t(IK.day7)}</option>
                                  <option value={[1, "month"]}>{t(IK.month1)}</option>
                                  <option value={[6, "month"]}>{t(IK.month6)}</option>
                                  <option value={[12, "month"]}>{t(IK.year1)}</option>
                                </S3.FormControls>
                              )}
                            </Field>
                            <S3.FormControlsFlex>
                              <DatePicker
                                placeholderText={t(IK.date_example)}
                                selected={values?.searchStart && new Date(values?.searchStart)}
                                handleDataChange={(e) => handleStartDate(e, setFieldValue)}
                                formControls
                              />

                              <DatePicker
                                placeholderText={t(IK.date_example)}
                                selected={values?.searchEnd && new Date(values?.searchEnd)}
                                handleDataChange={(e) => handleEndDate(e, setFieldValue)}
                                formControls
                              />
                            </S3.FormControlsFlex>
                          </S3.FormControlsFlex>
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </S2.TableType2>
              <ButtonCtBox>
                <S.StyledButton $primary as="button" type="submit" onClick={(e) => handleSubmit(e, values)}>
                  <i className="ri-search-2-line"></i> {t(IK.search)}
                </S.StyledButton>
                <S.StyledButton as="button" type="reset" onClick={() => handleReset(resetForm)}>
                  <i className="ri-refresh-line"></i> {t(IK.reset)}
                </S.StyledButton>
              </ButtonCtBox>
            </form>
          )}
        </Formik>
      </S4.CardInner>
    </S4.CardCol>
  );
};

export default BoardSearchBar;
