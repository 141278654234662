import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRememberPage } from "../../Hooks/useRememberPage";
import { useGetMeditCaseList } from "../../apis/meditLinkApi";
import { CardInnerLayout, CardTail } from "../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import Paging from "../../components/element/Paging/Paging";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Medit.style";

const MeditCaseList = () => {
  document.getElementById("wrapper").style.minWidth = "630px";
  const { t } = useTranslation(["translation"]);
  const nav = useNavigate();

  /**사용자 언어설정과 동일하게  */
  useEffect(() => {
    const langInfo = localStorage.getItem("langInfo");
    i18next.changeLanguage(langInfo);
  }, []);

  /**페이지 설정*/
  const [currentPage, setCurrentPage] = useRememberPage();
  /**페이지 변경 */
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  /**메딧 데이터 가져오기 */
  const { data: MeditData, isLoading, isError } = useGetMeditCaseList(currentPage - 1);
  const meditCases = MeditData?.data.page.content;

  if (isLoading || isError) {
    return <LoadingIndicator />; // 로딩 진행
  }

  return (
    <S.MeditBox>
      <CardInnerLayout>
        <S.MeditList>
          {meditCases.map((medit) => (
            <li key={medit.uuid}>
              <button
                type="button"
                onClick={() =>
                  nav(`/medit/case-list/${medit.uuid}`, {
                    state: { page: currentPage },
                  })
                }
              >
                <dl>
                  <dt>Name</dt>
                  <dd>{medit.name}</dd>
                </dl>
                <dl>
                  <dt>Patient</dt>
                  <dd>{medit.patient.name}</dd>
                </dl>
                <dl>
                  <dt>status</dt>
                  <dd>{medit.status}</dd>
                </dl>
              </button>
            </li>
          ))}
        </S.MeditList>

        <CardTail line>
          <Paging totalCount={MeditData?.data.page.totalElements} currentPage={currentPage} size={10} handleChangePage={handleChangePage} />
          <S2.StyledButton as="button" type="button" $primary onClick={() => window.close()}>
            {t(IK.close)}
          </S2.StyledButton>
        </CardTail>
      </CardInnerLayout>
    </S.MeditBox>
  );
};

export default MeditCaseList;
