import { useTranslation } from "react-i18next";
import * as S2 from "../../../components/element/Form/style/Form.style";
import * as S from "../../../components/element/Summary/style/Summary.style";
import { SummaryTableLi } from "../../../components/element/Summary/summaryTableLi";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";

const ReplaceSummaryTable = ({ replacementInfo }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <S.SummaryList>
      {/* 의사명 */}
      <SummaryTableLi title={t(IK.doctor_name)}>
        <p>
          {replacementInfo?.doctorName} {replacementInfo?.doctorKorName ? `(${replacementInfo.doctorKorName})` : ""}
        </p>
      </SummaryTableLi>

      {/* 인쇄시 환자명, 치료옵션 보이도록 */}
      <SummaryTableLi printshow title={t(IK.patient_name)}>
        {replacementInfo?.patientName && <p>{`${replacementInfo.patientName} ${replacementInfo.patientKorName ? ` (${replacementInfo.patientKorName})` : ""}`}</p>}
      </SummaryTableLi>
      <SummaryTableLi printshow title={t(IK.treatment_option)}>
        {replacementInfo?.studyPackages && <p>{`${t(IK[replacementInfo.studyPackages])}`}</p>}
      </SummaryTableLi>

      {/* 배송지 */}
      <SummaryTableLi title={t(IK.shipping_address)}>
        <p>{replacementInfo?.patientShipAddress}</p>
      </SummaryTableLi>

      {/* 청구지 */}
      <SummaryTableLi title={t(IK.billing_address)}>
        <p>{replacementInfo?.patientBillAddress}</p>
      </SummaryTableLi>

      {/* 주문할 대체 교정 장치의 단계 */}
      <SummaryTableLi title={t(IK.order_replace_device_step)}>
        <S2.FormControlsColumn>
          {replacementInfo?.UPPER && <p>{`${t(IK.maxilla)} ${replacementInfo.UPPER}`}</p>}
          {replacementInfo?.LOWER && <p>{`${t(IK.mandible)} ${replacementInfo.LOWER}`}</p>}
          {replacementInfo?.UPPER_ATTACH && <p>{`Attach template ${t(IK.maxilla)}  ${replacementInfo.UPPER_ATTACH}`}</p>}
          {replacementInfo?.LOWER_ATTACH && <p>{`Attach template ${t(IK.mandible)} ${replacementInfo.LOWER_ATTACH}`}</p>}
        </S2.FormControlsColumn>
      </SummaryTableLi>

      {/* 특별 지시 사항 */}
      <SummaryTableLi title={t(IK.special_instructions)}>
        <S3.TextDiv $textpreline>{replacementInfo?.SPECIAL_INSTRUCTIONS}</S3.TextDiv>
      </SummaryTableLi>
    </S.SummaryList>
  );
};

export default ReplaceSummaryTable;
