import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import ClinicalConfigurationsForm from "./Sections/Form";
import { CardInnerLayout, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";

function ClinicalConfigurations() {
  const { t } = useTranslation(["translation"]);

  return (
    <CardInnerLayout>
      <CardTitle title={t(IK.clinical_configurations)} required />
      <ClinicalConfigurationsForm />
    </CardInnerLayout>
  );
}

export default ClinicalConfigurations;
