import React, { useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useGetAddress, useGetDefaultAddress } from "../../../apis/addressApi";
import {
  CardInnerLayout,
  CardTail,
} from "../../../components/Layout/CardLayout/CardLayout";
import { IK } from "../../../utils/i18n/keyStore";
import { AccountTabType } from "../AccountTabType";
import AddressModal from "./AddressModal";
import AddressManageTable from "./Section/Table";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";

function AddressManage() {
  const { t } = useTranslation(["translation"]);

  const { data, isLoading } = useGetAddress();
  const addressData = data?.data;

  const [editAddressData, setEditAddressData] = useState("");
  //주소지 등록
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  const openAddressModal = () => {
    setEditAddressData("");
    setAddressModalOpen(true);
  };

  const closeAddressModal = () => {
    setAddressModalOpen(false);
  };

  /**대표주소 조회 */
  const { data: defaultAddress, isLoading: isDefaultLoading } =
    useGetDefaultAddress();
  const defaultAddressData = defaultAddress?.data;

  return (
    <>
      <Beforeunload onBeforeunload={() => ""}>
        <CardInnerLayout>
          <AccountTabType now="주소지 관리" />
          <AddressManageTable
            addressData={addressData}
            setEditAddressData={setEditAddressData}
            setAddressModalOpen={setAddressModalOpen}
            isLoading={isLoading || isDefaultLoading}
            defaultAddressData={defaultAddressData}
          />
          <CardTail>
            <S.ButtonLtBox>
              <S.StyledButton
                as="button"
                $primary
                type="button"
                onClick={openAddressModal}
              >
                {t(IK.registration)}
              </S.StyledButton>
            </S.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
        {/**주소관리 모달 */}
        <AddressModal
          addressModalOpen={addressModalOpen}
          closeAddressModal={closeAddressModal}
          editAddressData={editAddressData}
        />
      </Beforeunload>
    </>
  );
}

export default AddressManage;
