import React, { useEffect, useState } from "react";
import loading from "../../../../assets/images/common/loading.gif";
import * as S from "../../../../components/element/Drop/style/Drop.style";

export const Preview = ({ path, handleImageViewer }) => {
  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function fetchImage() {
      try {
        setImageData(path);
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
      } finally {
        setIsLoading(false);
      }
    }
    fetchImage();
  }, [path]);

  return success ? (
    <S.DropItemImg>
      <img alt="drop_Img" src={isLoading ? loading : imageData} />
      {handleImageViewer && (
        <S.DropItemControlBtn type="button" onClick={() => handleImageViewer(path)} $enlargebtn>
          <i className="ri-fullscreen-line"></i>
        </S.DropItemControlBtn>
      )}
    </S.DropItemImg>
  ) : null;
};
