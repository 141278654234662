import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 지불 내역 리스트 조회 */
export const useGetBillsList = (page) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["BillsList", page],
    async () => {
      const { data } = await axios.get(`/api/billing/list?page=${page}`);
      return data;
    },
    {
      cacheTime: 0,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 지불 내역 상세조회 */
export const useGetPaymentList = (billingId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PaymentList", billingId],
    async () => {
      const { data } = await axios.get(`/api/billing/${billingId}/items`);
      return data;
    },
    {
      cacheTime: 0,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 사용가능한 쿠폰 내역 상세조회 */
export const useGetCouponList = (couponModal) => {
  return useQuery(
    ["CouponList"],
    async () => {
      const { data } = await axios.get(`/api/coupon/list`);
      return data;
    },
    {
      cacheTime: 0,
      enabled: !!couponModal,
    }
  );
};

/**결제 모듈전 결제 아이디 생성*/
export const usePrepayment = () => {
  return useMutation(async (data) => {
    const response = await axios.post(`/api/payment/pre-payment`, data);
    return response;
  });
};

/**결제금액 0원 처리(선수금, 할인 등) */
export const usePrePaidpayment = () => {
  return useMutation(async (data) => {
    const response = await axios.post(`/api/payment/prepaid-payment`, data);
    return response;
  });
};

/**결제모듈 호출 후 결제 성공 */
export const useModulePayment = () => {
  return useMutation(async (data) => {
    const response = await axios.post(`/api/payment`, data);
    return response;
  });
};

/**결제모듈 호출 후 결제 실패*/
export const useFailModulePayment = () => {
  return useMutation(async (data) => {
    const response = await axios.post(`/api/payment/payment-fail`, data);
    return response;
  });
};

/** 결제영수증 상세 조회*/
export const useGetPaymentDetail = (paymentId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PaymentDetail"],
    async () => {
      const { data } = await axios.get(`/api/payment/list/${paymentId}`);
      return data;
    },
    {
      cacheTime: 0,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
      enabled: !!paymentId,
    }
  );
};
