import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { useSearchParam } from "../../../Hooks/useSearchParam";
import { useGetBoardList } from "../../../apis/boardApi";
import { CardTail } from "../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../components/Layout/CardLayout/style/CardLayout.style";
import CommonImage from "../../../components/common/CommonImage";
import { BoardSkeleton } from "../../../components/element/BoardList/BoardSkeleton";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import BoardSearchBar from "../../../components/element/BoardSearch/BoardSearchBar";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";
import { changeBoardParam } from "../../../utils/searchUtils";

function EducationalMaterialsList() {
  /**검색 조건 설정 */
  const { searchCondition, setSearchCondition } = useSearchParam({
    page: 1,
    sort: "REFERENCES",
  });

  /**페이지 변경 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /**이북 게시판 조회 */
  const { data, isLoading } = useGetBoardList(changeBoardParam(searchCondition));
  const ebookList = data?.data;

  return (
    <S2.CardRow>
      <BoardSearchBar searchCondition={searchCondition} setSearchCondition={setSearchCondition} boardSort="REFERENCES" />

      <S2.CardCol>
        <S2.CardInner>
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              {ebookList.content.length > 0 ? (
                <S.BoardCardList>
                  {ebookList.content.map((item, index) => (
                    <li key={index}>
                      <Link to={`/education/educational-materials-list/${item.boardId}`} state={{ page: searchCondition.page }}>
                        <dl>
                          <dt>
                            <CommonImage filePath={item?.distFileName} />
                          </dt>
                          <dd>
                            <h3>{item.title}</h3>
                            <span>{dayjs(item.createdDate).format("YYYY.MM.DD")}</span>
                          </dd>
                        </dl>
                      </Link>
                    </li>
                  ))}
                </S.BoardCardList>
              ) : (
                <EmptyCard />
              )}
              <CardTail line>
                <Paging totalCount={ebookList.totalElements} currentPage={searchCondition.page} size={10} handleChangePage={handleChangePage} />
              </CardTail>
            </>
          )}
        </S2.CardInner>
      </S2.CardCol>
    </S2.CardRow>
  );
}

export default EducationalMaterialsList;
