import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useDoctorAuth } from "../../../Hooks/useDoctorAuth";
import { useGetAdditionalSummary } from "../../../apis/additionalStudyApi";
import {
  CardInnerLayout,
  CardTitle,
  CardTail,
} from "../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import { AdditionalSummaryTable } from "../../../components/element/Summary/AdditionalSummaryTable";
import * as S from "../../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../../components/loading/LoadingIndicator/LoadingIndicator";
import { additionalSteps } from "../../../utils/additionalStudyUtils";
import { IK } from "../../../utils/i18n/keyStore";

const AdditionalSummary = () => {
  const { patientId, studyId, additionalId } = useParams();
  const { t } = useTranslation(["translation"]);

  /**서버에서 데이터 받아와 새로 넣어주기 */
  const { data: additionalStudyData, isLoading } = useGetAdditionalSummary(
    additionalId,
    patientId,
    studyId
  );
  const additionatData = additionalStudyData?.data;
  const patientData = additionalStudyData?.data.patient;
  const currentStepList = additionalSteps[additionatData?.study.packages];

  /**본인 환자인지 판단 */
  const access = useDoctorAuth(patientId);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!access) {
    //권한 없을시 컴포넌트 렌더링 안함
    return null;
  }

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <CardInnerLayout>
          <CardTitle
            title={
              <>
                {`${t(IK.additional_device)} ${t(IK.prescription_summary)}`}
                <span>{`${patientData?.firstName} ${patientData?.lastName}${
                  patientData?.korName ? ` (${patientData?.korName})` : ""
                }`}</span>
              </>
            }
          />
          <S.PrintWrapper ref={componentRef}>
            <AdditionalSummaryTable
              additionalInfo={additionatData}
              currentStepList={currentStepList}
            />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton to={`/patient/${patientId}/profile`}>
                {t(IK.patient_profile)}
              </S2.StyledButton>
              <S2.StyledButton
                as="button"
                $primary
                type="button"
                onClick={handlePrint}
              >
                {t(IK.print)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      )}
    </>
  );
};

export default AdditionalSummary;
