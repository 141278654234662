import Skeleton from "react-loading-skeleton";
import * as S from "./style/BoardList.style";

export const BoardSkeleton = () => {
  return (
    <S.BoardCardList>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
        <BoardSkeletonCard key={index} />
      ))}
    </S.BoardCardList>
  );
};

export const BoardSkeletonCard = () => {
  return (
    <li>
      <dl>
        <dt>
          <Skeleton width="100%" height={180} />
        </dt>
        <dd>
          <h3>
            <Skeleton width="100%" height={45} />
          </h3>
        </dd>
      </dl>
    </li>
  );
};
