import { ErrorMessage } from "formik";
import * as S from "./style/FieldMessage.style";

/**오류 메세지(클릭없이출력) */
export const FieldMessageError = ({ text }) => {
  return (
    <S.FieldMessage $errorMessage>
      <i className="ri-error-warning-fill" /> {text}
    </S.FieldMessage>
  );
};

/**오류 메세지(클릭이있을때)*/
export const FieldMessageErrorClick = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <S.FieldMessage $errorMessage>
          <i className="ri-error-warning-fill" />
          {msg}
        </S.FieldMessage>
      )}
    </ErrorMessage>
  );
};

/**성공 메세지 */
export const FieldMessageSuccess = ({ text }) => {
  return (
    <S.FieldMessage $successMessage>
      <i className="ri-checkbox-circle-fill" /> {text}
    </S.FieldMessage>
  );
};

/**공지 메세지 */
export const NotiMessage = ({ text }) => {
  return (
    <S.FieldMessage>
      <i className="ri-error-warning-fill" /> {text}
    </S.FieldMessage>
  );
};
