import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SerafinLogo from "../../../assets/images/common/symbol.png";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "../../../styles/Common";

const SeraquickLoading = () => {
  const { t } = useTranslation(["translation"]);
  const { state } = useLocation();

  /**사용자 언어설정과 동일하게  */
  useEffect(() => {
    const langInfo = localStorage.getItem("langInfo");
    i18next.changeLanguage(langInfo);
  }, []);

  useEffect(() => {
    if (state?.status === "link") {
      //새창 열어 세라뷰 실행
      window.open(state?.seraQuickUrl, "_blank");
    }
  }, [state]);

  return (
    <S.ContentLoadingWrap>
      <S.ContentLoadingBox>
        <img src={SerafinLogo} alt="serafin_logo" />

        <div>
          <h1>{t(IK.seraquick_txt1)}</h1>
          <h2>
            {t(IK.seraquick_txt2)}
            <br />
            {t(IK.seraquick_txt3)}
          </h2>
        </div>

        <S2.StyledButton $primary to="/">
          {t(IK.home)}
        </S2.StyledButton>
      </S.ContentLoadingBox>
    </S.ContentLoadingWrap>
  );
};

export default SeraquickLoading;
