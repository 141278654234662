import styled, { keyframes } from "styled-components";

export const Rotate = keyframes`
  to {
    transform:rotate(360deg);
  }
`;

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top-color: ${(props) => props.theme.colors.blue400};
  animation: ${Rotate} 1s linear infinite;
`;
