import { IK } from "./i18n/keyStore";

/**원화 세자리씩 끊어서 표기 */
export function formatCurrency(number) {
  return number ? `${number.toLocaleString("ko-KR")}` : 0;
}

export const payMethod = {
  1: "card", //신용카드
  2: "trans", //실시간 계좌이체
  3: "vbank", //가상계좌, 무통장
  4: "prepaid", //쿠폰
};

export const payMethodStatus = {
  card: IK.card, //신용카드
  trans: IK.trans, //실시간 계좌이체
  vbank: IK.vbank, //가상계좌, 무통장
  prepaid: IK.prepaid, //쿠폰 결제
};

/**결제 상태 */
export const paymentStatus = {
  prepare: "결제 준비",
  ready: "가상계좌 발급",
  paid: "결제 완료",
  cancel: "모듈 취소",
  failed: "결제 실패",
  refund: "환불",
  prepaid: "쿠폰 결제",
  cancelled: "결제 취소",
};
