import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAdditionalSubmit, useGetAdditionalSummary } from "../../../../../apis/additionalStudyApi";
import { skipStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { AdditionalSummaryTable } from "../../../../../components/element/Summary/AdditionalSummaryTable";
import * as S from "../../../../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../../utils/i18n/keyStore";

function Summary({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, additionalInfo, setAdditionalInfo, setCurrentStepIndex, setIsFinish, additionalId, studyId, patientId }) {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const additionalSubmitMuatation = useAdditionalSubmit();

  /**제출 중에 버튼 상태 비활성화 */
  const [isDisabled, setIsDisabled] = useState(false);

  /**서버에서 데이터 받아와 새로 넣어주기 */
  const { data: additionalStudyData, isLoading } = useGetAdditionalSummary(additionalId, patientId, studyId);
  const additionalStudyPatientData = additionalStudyData?.data;

  /**처방전 요약까지 작성으로 상태 업데이트 */
  useEffect(() => {
    skipStep({ studyId, patientId }).then((res) => {
      setIsFinish(true); //성공시 스킵 버튼 활성화
    });
  }, [studyId, patientId, setIsFinish]);

  /**수정 페이지로 찾기 */
  function getIndexByLocation(location) {
    for (let i = 0; i < currentStepList.length; i++) {
      if (currentStepList[i].label === location) {
        return i;
      }
    }
  }
  /**수정 페이지로 이동 */
  const handleClickForUpdate = (e) => {
    const location = e.currentTarget.dataset.location;
    const index = getIndexByLocation(location);
    if (typeof index === "number") {
      setCurrentStepIndex(index);
    }
  };

  /**처방전 제출 */
  const hanldeSubmit = function () {
    setIsDisabled(true);
    additionalSubmitMuatation.mutate(
      {
        params: {
          patientId,
          studyId,
          additionalId,
        },
      },
      {
        onSuccess: () => {
          navigate(`/patient/${patientId}/profile`);
          setIsDisabled(false);
        },
        onError: () => {
          setIsDisabled(false);
        },
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CardTitle title={additionalInfo.patientName} />
          <S.PrintWrapper>
            <AdditionalSummaryTable additionalInfo={additionalStudyPatientData} handleClickForUpdate={handleClickForUpdate} currentStepList={currentStepList} />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={prev}>
                {t(IK.prev)}
              </S2.StyledButton>

              <S2.StyledButton
                as="button"
                $primary
                type="submit"
                disabled={isDisabled}
                onClick={() => {
                  hanldeSubmit();
                }}
              >
                {t(IK.submitted)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </>
      )}
    </>
  );
}

export default Summary;
