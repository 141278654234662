import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const WritingStudy = ({ studyInfo, progress, lastDetail, selectedId }) => {
  const { patientId } = useParams();
  const { t } = useTranslation(["translation"]);

  const linkList = {
    study: `/patient/${patientId}/study/${studyInfo?.studyId}`,
    additional: `/patient/${patientId}/study/${studyInfo?.studyId}/additional/${studyInfo?.additionalId}`,
    replacement: `/patient/${patientId}/study/${studyInfo?.studyId}/replace/${studyInfo?.replacementId}`,
  };

  return (
    <>
      {progress ? (
        <S.ProfileHistoryDesc>
          <S.ProfileHistoryDescDot $complete={lastDetail >= "01"} />
          <dl>
            <dt>{t(IK.writing_study)}</dt>
            {studyInfo?.createdDate && (
              <dd>
                <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>
                {/**처방전 제출 전에만 작성중으로 이동 가능 */}
                {(lastDetail === "01" || lastDetail === "12") && (
                  <S2.ButtonFlex>
                    <S2.StyledSmallButton to={linkList[Object.keys(selectedId)[0]]}>{t(IK.continue_writing)}</S2.StyledSmallButton>
                  </S2.ButtonFlex>
                )}
              </dd>
            )}
          </dl>
        </S.ProfileHistoryDesc>
      ) : (
        <>
          <S.ProfileHistoryCircle />
          <S.ProfileHistoryModalItem>
            <S.ProfileDepth1>
              <h3>{t(IK.writing_study)}</h3>
              {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
            </S.ProfileDepth1>
          </S.ProfileHistoryModalItem>
        </>
      )}
    </>
  );
};

export default WritingStudy;
