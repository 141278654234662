import { useTranslation } from "react-i18next";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../utils/i18n/keyStore";
import { Previews } from "../../preview/Preview";
import * as S from "../../../../components/element/Drop/style/Drop.style";
import { CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";

const Extraoral = ({ setFieldValue, getFieldProps, errors, values }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <CardTitle
        message={t(IK.image_required_message_1)}
        icon={<i className="ri-fullscreen-line"></i>}
      />

      <S.DropListWrap>
        <S.DropList className="required_img extraoral_front">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="extraoral_front"
          />
          {errors.extraoral_front && (
            <FieldMessageError text={errors.extraoral_front} />
          )}
        </S.DropList>
        <S.DropList className="front_rest">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="front_rest"
          />
        </S.DropList>
        <S.DropList className="required_img front_smile">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="front_smile"
          />
          {errors.front_smile && (
            <FieldMessageError text={errors.front_smile} />
          )}
        </S.DropList>
        <S.DropList className="side45">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="side45"
          />
        </S.DropList>
        <S.DropList className="required_img side90">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="side90"
          />
          {errors.side90 && <FieldMessageError text={errors.side90} />}
        </S.DropList>
        <S.DropList className="side90_smile">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="side90_smile"
          />
        </S.DropList>
      </S.DropListWrap>

      <div className="noti_box">
        <i className="ri-upload-cloud-fill"></i>
        {t(IK.file_drag_message)}
      </div>
    </>
  );
};

export default Extraoral;
