import Extraoral from "../pages/Patients/AdditionalStudy/Section/Extraoral/Extraoral";
import Intraoral from "../pages/Patients/AdditionalStudy/Section/Intraoral/Intraoral";
import Radiation from "../pages/Patients/AdditionalStudy/Section/Radiation/Radiation";
import Scan from "../pages/Patients/AdditionalStudy/Section/Scan/Scan";
import Summary from "../pages/Patients/AdditionalStudy/Section/Summary/Summary";
import ApAttachment from "../pages/Patients/AdditionalStudy/Section/item/ApAttachment";
import Attachment from "../pages/Patients/AdditionalStudy/Section/item/Attachment";
import CurrentDeviceNumber from "../pages/Patients/AdditionalStudy/Section/item/CurrentDeviceNumber";
import Instructions from "../pages/Patients/AdditionalStudy/Section/item/Instructions";
import TeethLimitedMobility from "../pages/Patients/AdditionalStudy/Section/item/TeethLimitedMobility";
import UpdateStudy from "../pages/Patients/AdditionalStudy/Section/item/UpdateStudy";
import { stepTypes } from "./studyUtils";

//validateCode는 이어서 작성하기 진입시 유효성 검사용 코드
const CommonSteps = {
  CurrentDeviceNumber: {
    label: "CurrentDeviceNumber",
    component: <CurrentDeviceNumber />,
    validateCode: "AI02_01,AI02_02",
    stepLabel: "details_treatment_plan",
  },
  UpdateStudy: {
    label: "UpdateStudy",
    component: <UpdateStudy />,
    code: "process",
    validateCode: "process",
    stepLabel: "additional_option",
  },
  TeethLimitedMobility: {
    label: "TeethLimitedMobility",
    component: <TeethLimitedMobility />,
    code: "AI05",
    validateCode: "AI05",
    stepLabel: "details_treatment_plan",
  },
  Attachment: {
    label: "Attachment",
    component: <Attachment />,
    code: "AI04",
    validateCode: "AI04",
    stepLabel: "details_treatment_plan",
  },
  Instructions: {
    label: "Instructions",
    component: <Instructions />,
    code: "AI06",
    // validateCode: "AI06",
    stepLabel: "instructions",
  },
  ApAttachment: {
    label: "ApAttachment",
    component: <ApAttachment />,
    code: "AI07",
    validateCode: "AI07",
    stepLabel: "ap_attachment",
  },
  Extraoral: {
    label: "Extraoral",
    component: <Extraoral />,
    stepLabel: "extraoral",
  },
  Intraloral: {
    label: "Intraoral",
    component: <Intraoral />,
    validateCode: "occlusal_upper",
    stepLabel: "intraoral",
    files: true,
  },
  Radiation: {
    label: "Radiation",
    component: <Radiation />,
    // validateCode: "panorama",
    stepLabel: "radiograph",
    files: true,
  },
  Scan: {
    label: "Scan",
    component: <Scan />,
    validateCode: "AIPlasterModel",
    stepLabel: "scan_file_registration",
    files: true,
  },
  Summary: {
    label: "Summary",
    component: <Summary />,
    stepLabel: "prescription_summary",
  },
};

//RP는 추가처방전 없음
export const additionalSteps = {
  [stepTypes.NOT_EDIT]: [CommonSteps.UpdateStudy, CommonSteps.Scan, CommonSteps.Instructions, CommonSteps.Summary],
  [stepTypes.R]: [
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
  [stepTypes.S10]: [
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
  [stepTypes.S20]: [
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
  [stepTypes.SR]: [
    CommonSteps.UpdateStudy,
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
  [stepTypes.Si]: [
    CommonSteps.UpdateStudy,
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
  [stepTypes.SAP]: [
    CommonSteps.UpdateStudy,
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
  [stepTypes.SiAP]: [
    CommonSteps.UpdateStudy,
    CommonSteps.CurrentDeviceNumber,
    CommonSteps.Attachment,
    CommonSteps.ApAttachment,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Instructions,
    CommonSteps.Extraoral,
    CommonSteps.Intraloral,
    CommonSteps.Radiation,
    CommonSteps.Scan,
    CommonSteps.Summary,
  ],
};

export const additionalFormConfig = {
  teeth_limited_mobility_item2_teeth: {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true,
    13: true,
    14: true,
    15: true,
    16: true,
    17: true,
    18: true,
    19: true,
    20: true,
    21: true,
    22: true,
    23: true,
    24: true,
    25: true,
    26: true,
    27: true,
    28: true,
    29: true,
    30: true,
    31: true,
    32: true,
  },
};
