import styled from "styled-components";

export const VideoForm = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  overflow: hidden;
  dt {
    padding: 20px;
    background-color: ${(props) => props.theme.colors.gray300};
    iframe {
      width: 100%;
      height: 500px;
    }
  }
  dd {
    padding: 20px;
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: #efede8;
  }
`;
