import EmptyImage from "../../../assets/images/common/symbol.png";
import * as S from "./style/Error.style";

export const ErrorCard = ({ title, subTitle, contnetTxt, children }) => {
  return (
    <S.ErrorContainer>
      <S.ErrorBox>
        <img src={EmptyImage} alt="errorCard" />
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
        <p>{contnetTxt}</p>
        {children}
      </S.ErrorBox>
    </S.ErrorContainer>
  );
};
