import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const EndStudy = ({ studyInfo }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <S.ProfileHistoryCircle />
      <S.ProfileHistoryModalItem>
        <S.ProfileDepth1>
          <h3>{t(IK.end_of_treatment)}</h3>
          {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
        </S.ProfileDepth1>
      </S.ProfileHistoryModalItem>
    </>
  );
};

export default EndStudy;
