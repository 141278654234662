import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardTitle, CardTail } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import { OptionBtn } from "./OptionBtn";
import { OptionModal } from "./OptionModal";
import * as S from "./style/Option.style";

function SelectAge({ prev, next, studyInfo, setStudyInfo }) {
  const { t } = useTranslation(["translation"]);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**환자 이름, 나이 저장 */
  const handleClick = (e) => {
    const { studyId } = studyInfo;
    setStudyInfo({
      studyId,
      patientName: studyInfo.patientName,
      age: e.currentTarget.id,
    });
    next();
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`}>
        <S2.StyledButton as="button" $primary type="button" onClick={openModal}>
          {t(IK.option_guide)}
        </S2.StyledButton>
      </CardTitle>

      <S.OptionList>
        <OptionBtn BtnId="adult" onHandle={handleClick} adult>
          <dt></dt>
          <dd>
            <h3>{t(IK.selectage_title1)}</h3>
            <p>
              {t(IK.selectage_txt1_1)}
              <br />
              {t(IK.selectage_txt1_2)}
            </p>
          </dd>
        </OptionBtn>
        <OptionBtn BtnId="child" onHandle={handleClick} child>
          <dt></dt>
          <dd>
            <h3>{t(IK.selectage_title2)}</h3>
            <p>{t(IK.selectage_txt2)}</p>
          </dd>
        </OptionBtn>
      </S.OptionList>
      <CardTail line>
        <S2.ButtonLtBox>
          <S2.StyledButton as="button" type="button" onClick={prev}>
            {t(IK.prev)}
          </S2.StyledButton>
        </S2.ButtonLtBox>
      </CardTail>
      <OptionModal modalOpen={modalOpen} closeModal={closeModal} child adult />
    </>
  );
}

export default SelectAge;
