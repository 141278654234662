import styled from "styled-components";

export const ScanBox = styled.div`
  width: 1100px;
  margin: 0 auto;
  display: flex;
  border: 3px dashed ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  overflow: hidden;
`;

export const ScanDrop = styled.div`
  width: 547px;
  padding: 40px;
  text-align: center;
  h3 {
    margin: 20px 0 15px;
    font-size: 24px;
  }
  p {
    font-size: 18px;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const ScanIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 70px;
  color: ${(props) => props.theme.colors.blue400};
`;

export const ScanList = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.gray300};
  ul {
    max-height: 274px;
    overflow: auto;
    padding: 0 10px;
    margin: 0 -10px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46.5px;
      padding: 0 15px;
      margin-bottom: 10px;
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.gray200};
      border-radius: 5px;
      font-size: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        flex: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
      }
    }
  }
`;
