import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetPatientExtraoral, useUpdatePatientExtraoral } from "../../../../apis/patientApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../components/element/Drop/style/Drop.style";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import LoadingIndicator from "../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../utils/objectUtils";
import { Previews } from "../Preview/Preview";

const ExtraoralEdit = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetPatientExtraoral(patientId);
  const extraoralInfo = data?.data;

  const { t } = useTranslation(["translation"]);
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  const validationSchema = Yup.object().shape({
    extraoral_front: Yup.mixed().required(t(IK.image_required)),
    front_smile: Yup.mixed().required(t(IK.image_required)),
    side90: Yup.mixed().required(t(IK.image_required)),
  });

  const extraoralMutation = useUpdatePatientExtraoral();

  /**사진 업데이트 */
  const handleSubmit = (values) => {
    const data = deleteEmptyKey(values);
    const formData = new FormData();

    let formDataLength = 0;
    Object.entries(data).forEach(([key, value]) => {
      if (!value.patientFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      Notiflix.Loading.standard("");
      //삭제할 파일이 있을 경우
      if (deleteStudyFileIds.length > 0) {
        formData.append("deletePatientFileIds", deleteStudyFileIds.join(","));
      }

      extraoralMutation.mutate(
        { patientId, formData },
        {
          onSuccess: () => {
            setDeleteStudyFileIds([]);
            Notiflix.Loading.remove();
            Notiflix.Notify.success(t(IK.update_success));
          },
          onError: (err) => {
            Notiflix.Loading.remove();
            if (err?.response?.status !== 403) {
              Notiflix.Notify.failure(t(IK.again_message));
            }
          },
        }
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Formik
          initialValues={{
            extraoral_front: extraoralInfo.extraoral_front || "",
            front_rest: extraoralInfo.front_rest || "",
            front_smile: extraoralInfo.front_smile || "",
            side45: extraoralInfo.side45 || "",
            side90: extraoralInfo.side90 || "",
            side90_smile: extraoralInfo.side90_smile || "",
          }}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <CardInnerLayout>
                <CardTitle title={t(IK.extraoral)} icon={<i className="ri-fullscreen-line" />} message={t(IK.image_required_message_1)} />
                <S.DropListWrap>
                  <S.DropList className="required_img extraoral_front">
                    <Previews
                      setFieldValue={setFieldValue}
                      setDeleteStudyFileIds={setDeleteStudyFileIds}
                      deleteStudyFileIds={deleteStudyFileIds}
                      filesData={values.extraoral_front}
                      name="extraoral_front"
                    />
                    {errors.extraoral_front && <FieldMessageError text={errors.extraoral_front} />}
                  </S.DropList>
                  <S.DropList className="front_rest">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.front_rest} name="front_rest" />
                  </S.DropList>
                  <S.DropList className="required_img front_smile">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.front_smile} name="front_smile" />
                    {errors.front_smile && <FieldMessageError text={errors.front_smile} />}
                  </S.DropList>
                  <S.DropList className="side45">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.side45} name="side45" />
                  </S.DropList>
                  <S.DropList className="required_img side90">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.side90} name="side90" />
                    {errors.side90 && <FieldMessageError text={errors.side90} />}
                  </S.DropList>
                  <S.DropList className="side90_smile">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.side90_smile} name="side90_smile" />
                  </S.DropList>
                </S.DropListWrap>
                <div className="noti_box">
                  <i className="ri-upload-cloud-fill"></i>
                  {t(IK.file_drag_message)}
                </div>
                <CardTail error={errors.conditionItemMap} line>
                  <S2.ButtonLtBox>
                    <S2.StyledButton as="button" type="button" onClick={() => navigate(`/patient/${patientId}/profile`)}>
                      {t(IK.list)}
                    </S2.StyledButton>
                    <S2.StyledButton
                      as="button"
                      $primary
                      type="submit"
                      onClick={() => {
                        validationSchema
                          .validate(values)
                          .then(() => console.log("Validation succeeded"))
                          .catch((err) => {
                            console.log(err.errors);
                          });
                      }}
                    >
                      {t(IK.save)}
                    </S2.StyledButton>
                  </S2.ButtonLtBox>
                </CardTail>
              </CardInnerLayout>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ExtraoralEdit;
