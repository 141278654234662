import axios from "axios";
import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { SingleLayout, SingleLayoutTail } from "../../components/Layout/SingleLayout/SingleLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import * as S from "../../components/element/Tab/style/Tab.style";
import { TitleV1 } from "../../components/element/Title/TitleText";
import { IK } from "../../utils/i18n/keyStore";
import Success from "./Section/Success";

function FindPw() {
  const { t } = useTranslation(["translation"]);
  const [findFlag, setFindFlag] = useState(false);

  const validationSchema = Yup.object().shape({
    loginId: Yup.string()
      .matches(/^[A-Za-z0-9]+$/, t(IK.id_validate))
      .required(t(IK.id_required)),
    email: Yup.string().email(t(IK.email_valid)).required(t(IK.email_required)),
    recaptcha: Yup.string().required(t(IK.recaptcha_required)),
  });

  /**리캡챠 설정 */
  const recaptchaRef = useRef();

  /**로봇이 아님니다 인증 */
  const handleRecaptcha = (e, setFieldValue) => {
    setFieldValue("recaptcha", e);
  };

  const handleSubmit = (value, { setFieldValue }) => {
    const formData = new FormData();
    formData.append("email", value.email);
    formData.append("loginId", value.loginId);
    formData.append("recaptcha", value.recaptcha);

    axios
      .post("/api/account/find-password", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFindFlag(true);
      })
      .catch((err) => {
        //리캡챠 초기화
        recaptchaRef.current.reset();
        setFieldValue("recaptcha", "");
        Notiflix.Report.failure(t(IK.not_exist_account), t(IK.check_your_information), t(IK.confirm));
      });
  };

  return (
    <Formik
      initialValues={{
        loginId: "",
        email: "",
        recaptcha: "",
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <SingleLayout>
          <S.TabType1>
            <S.TabType1Item>
              <Link to="/find-id-form">{t("find_id")}</Link>
            </S.TabType1Item>
            <S.TabType1Item $active>
              <Link to="/find-pw">{t(IK.find_pw)}</Link>
            </S.TabType1Item>
          </S.TabType1>
          {findFlag ? (
            <Success />
          ) : (
            <>
              <TitleV1 title={t(IK.find_pw_title)} titleSub={t(IK.find_id_pw_sub_title)} />
              <Form>
                <div className="frm_wrap">
                  <FormControlsBox required regular title={t(IK.id)}>
                    <div className="frm_div">
                      <Field type="text" name="loginId" className="frm_control v1" maxLength="20" />
                      <FieldMessageErrorClick name="loginId" />
                    </div>
                  </FormControlsBox>
                  <FormControlsBox required regular title={t(IK.email)}>
                    <div className="frm_div">
                      <Field type="text" name="email" className="frm_control v1" maxLength="30" />
                      <FieldMessageErrorClick name="email" />
                    </div>
                  </FormControlsBox>
                  <FormControlsBox regular>
                    <div className="frm_div">
                      <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={(e) => handleRecaptcha(e, setFieldValue)} ref={recaptchaRef} />
                      <FieldMessageErrorClick name="recaptcha" />
                    </div>
                  </FormControlsBox>
                </div>
                <SingleLayoutTail>
                  <S2.StyledButton to="/">{t(IK.cancel)}</S2.StyledButton>
                  <S2.StyledButton as="button" $primary type="submit">
                    {t(IK.confirm)}
                  </S2.StyledButton>
                </SingleLayoutTail>
              </Form>
            </>
          )}
        </SingleLayout>
      )}
    </Formik>
  );
}

export default FindPw;
