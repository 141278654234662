import Notiflix from "notiflix";
import "../Notiflix/Notiflix.css";

Notiflix.Notify.init({
  className: "notiflix",
  fontFamily: "Pretendard",
  zindex: "6",
});

Notiflix.Report.init({
  className: "notiflix notiflix-report",
  fontFamily: "Pretendard",
  titleMaxLength: 200,
  messageMaxLength: 400,
  lineHeight: "1.5",
  borderRadius: "10px",
  titleFontSize: "18px",
  messageFontSize: "15px",
  buttonsFontSize: "14px",
  zindex: "6",
});

Notiflix.Confirm.init({
  width: "350px",
  className: "notiflix notiflix-confirm",
  fontFamily: "Pretendard",
  titleMaxLength: 200,
  messageMaxLength: 400,
  backOverlayColor: "rgba(0,0,0,0.7)",
  backgroundColor: "#fff",
  lineHeight: "1.5",
  borderRadius: "10px",
  titleColor: "#377dff",
  titleFontSize: "18px",
  messageFontSize: "15px",
  buttonsFontSize: "14px",
  okButtonColor: "#fff",
  okButtonBackground: "#377dff",
  cancelButtonColor: "#7E8299",
  cancelButtonBackground: "#f5f5f5",
  zindex: "6",
});

Notiflix.Loading.init({
  svgColor: "#377dff",
});
