import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { getSampleSeraview } from "../../../apis/boardApi";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import { IK } from "../../../utils/i18n/keyStore";

function SampleSeraviewList() {
  const { t } = useTranslation(["translation"]);

  const sampleList = [
    {
      studyId: 625,
      studyDesignId: 1239,
      titleSub: "SERAFIN 10",
      title: "Spacing",
    },
    {
      studyId: 610,
      studyDesignId: 1215,
      titleSub: "SERAFIN 20",
      title: "Crowding",
    },
    {
      studyId: 611,
      studyDesignId: 1216,
      titleSub: "SERAFIN 20",
      title: "Deep bite",
    },
    {
      studyId: 627,
      studyDesignId: 1248,
      titleSub: "SERAFIN regular",
      title: "Class II",
    },
    {
      studyId: 615,
      studyDesignId: 1225,
      titleSub: "SERAFIN regular",
      title: "Class III",
    },
    {
      studyId: 613,
      studyDesignId: 1219,
      titleSub: "SERAFIN regular",
      title: "premolar Extraction",
    },
  ];

  /**세라뷰 오픈 */
  const handleSeraviewOpen = (studyId, studyDesignId) => {
    getSampleSeraview(studyId, studyDesignId)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((err) => {
        Notiflix.Notify.failure(t(IK.again_message));
      });
  };

  return (
    <CardInnerLayout>
      <S.BoardCardList>
        {sampleList?.map((item, index) => (
          <li key={index}>
            <button onClick={() => handleSeraviewOpen(item.studyId, item.studyDesignId)}>
              <dl>
                <dt>
                  <div>
                    <h3>{item.titleSub}</h3>
                    <h1>{item.title}</h1>
                  </div>
                </dt>
                <dd>
                  <h3>{t(IK.view_seraview)}</h3>
                </dd>
              </dl>
            </button>
          </li>
        ))}
      </S.BoardCardList>
    </CardInnerLayout>
  );
}

export default SampleSeraviewList;
