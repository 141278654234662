import axios from "axios";
import { useQuery } from "react-query";

/**로그인 전 팝업 노출 */
export const useGetPopup = () => {
  return useQuery(["popup"], async () => {
    const { data } = await axios.get("/api/v1/popups/display");
    return data;
  });
};

/**로그인 후 팝업 노출 */
export const useGetCountryPopup = () => {
  return useQuery(["popup"], async () => {
    const { data } = await axios.get("/api/v1/popups/display-country");
    return data;
  });
};
