import * as S from './style/LoadingSpinner.style';

const LoadingSpinner = () => {
  return (
    <S.Loading>
      <S.Spinner/>
    </S.Loading>
  );
};

export default LoadingSpinner;
