import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as S from "../../../../components/element/Drop/style/Drop.style";
import imageRotate from "../../../../utils/imageRotate";

export function Previews({ filesData, setFieldValue, setDeleteStudyFileIds, deleteStudyFileIds, name, radiation }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFieldValue(name, acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploaded: false,
          })
        )
      );
    },
  });

  /**파일 삭제 */
  const handleOnClickClose = (e) => {
    setFiles([]);
    setFieldValue(name, "");
    const fileId = e.currentTarget.dataset.fileId;
    if (fileId) {
      setDeleteStudyFileIds([...deleteStudyFileIds, fileId]);
    }
  };

  /**이미지 회전 */
  const generateHandler = (orientation) => (e) => {
    imageRotate(files, setFiles, orientation, setFieldValue, name);
  };

  const handleOnClickRefresh = generateHandler(6);
  const handleOnClickArrow1 = generateHandler(2);
  const handleOnClickArrow2 = generateHandler(4);

  useEffect(() => {
    if (filesData?.fileId) {
      //등록된 이미지 파일이 있는 경우
      setFiles([
        Object.assign(
          {},
          {
            preview: filesData.distFileName,
            uploaded: true,
            patientFileId: filesData.patientFileId,
          }
        ),
      ]);
    }
  }, [filesData]);

  const thumbs = files.map((file, index) => {
    return (
      <S.DropItemImg key={`${file.name}-${index}`}>
        <img src={file.preview} alt="preview" />
        {!file.uploaded && (
          <>
            <S.DropItemControlBtn type="button" onClick={handleOnClickRefresh} $refresh>
              <i className="ri-refresh-line"></i>
            </S.DropItemControlBtn>
            <S.DropItemControlBtn type="button" onClick={handleOnClickArrow1} $arrowbtn1>
              <i className="ri-arrow-left-right-line"></i>
            </S.DropItemControlBtn>
            <S.DropItemControlBtn type="button" onClick={handleOnClickArrow2} $arrowbtn2>
              <i className="ri-arrow-up-down-line"></i>
            </S.DropItemControlBtn>
          </>
        )}
        <S.DropItemControlBtn type="button" onClick={handleOnClickClose} data-file-id={file.patientFileId} $close>
          <i className="ri-close-line"></i>
        </S.DropItemControlBtn>
      </S.DropItemImg>
    );
  });

  return (
    <S.DropItem $radiation={radiation}>
      <S.DropItemZone {...getRootProps()}>
        <input id={name} name={name} {...getInputProps()} />
      </S.DropItemZone>
      {thumbs}
    </S.DropItem>
  );
}
