import { useTranslation } from "react-i18next";
import { useGetStudyHistory } from "../../../../../apis/studyApi";
import Modal from "../../../../../components/Modal/Modal";
import { studyStatusComponents } from "../../../../../json/studyStatusObject";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";
import SkeletonHistoryModal from "./SkeletonHistoryModal";

const StudyHistoryModal = ({ historyOpen, closeHistory, patientId, studyId, selectedId, lastDetail }) => {
  const { t } = useTranslation(["translation"]);

  /**선택된 스터디의 처방전 상태 정보 리스트 조회 */
  const { data, isLoading } = useGetStudyHistory(patientId, studyId, selectedId);
  const studyStatusList = data?.data;

  return (
    <Modal open={historyOpen} close={closeHistory} header={t(IK.study_history)}>
      {isLoading ? (
        <SkeletonHistoryModal />
      ) : (
        <S.ProfileHistoryModalList>
          {studyStatusList?.map((study) => (
            <li key={study.statusId}>
              {studyStatusComponents(
                {
                  studyInfo: { ...study, patientId },
                  lastDetail,
                  selectedId,
                  recentStatusId: studyStatusList[0]?.statusId,
                },
                study.detail
              )}
            </li>
          ))}
        </S.ProfileHistoryModalList>
      )}
    </Modal>
  );
};

export default StudyHistoryModal;
