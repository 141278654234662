import * as S from './style/BoardLayout.style';

/**board bottom */
export const BoardBottom = ({ children }) => {
  return (
    <S.BoardBottom>
      {children}
    </S.BoardBottom>
  );
};
