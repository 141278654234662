import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getLink } from "../../../../../apis/patientApi";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const ApprovedSeraview = ({ studyInfo, progress, lastDetail }) => {
  const { t } = useTranslation(["translation"]);
  const { patientId } = useParams();

  /**세라뷰 링크 받아오기 */
  const handleOnClick = function (type) {
    getLink(patientId, type, studyInfo?.statusId, studyInfo?.studyId)
      .then((response) => {
        if (response.data.code === 200) {
          window.open(response.data.data);
        }
      })
      .catch((error) => Notiflix.Notify.failure(t(IK.again_message)));
  };

  return (
    <>
      {progress ? (
        <S.ProfileHistoryDesc>
          <S.ProfileHistoryDescDot $complete={lastDetail >= "17"} />
          <dl>
            <dt>{t(IK.approved_seraview)}</dt>
            {studyInfo?.createdDate && lastDetail >= "17" && (
              <dd>
                <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>
                {lastDetail >= "17" && (
                  <S2.ButtonFlex>
                    <S2.StyledSmallButton
                      as="button"
                      onClick={(e) => {
                        handleOnClick("SERAVIEW");
                      }}
                    >
                      {t(IK.seraview)}
                    </S2.StyledSmallButton>
                    <S2.StyledSmallButton
                      as="button"
                      onClick={(e) => {
                        handleOnClick("SERAVIEW_WEB");
                      }}
                    >
                      {t(IK.seraview)} WEB
                    </S2.StyledSmallButton>
                  </S2.ButtonFlex>
                )}
              </dd>
            )}
          </dl>
        </S.ProfileHistoryDesc>
      ) : (
        <>
          <S.ProfileHistoryCircle />
          <S.ProfileHistoryModalItem>
            <S.ProfileDepth1>
              <h3>{t(IK.approved_seraview)}</h3>
              {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
            </S.ProfileDepth1>
          </S.ProfileHistoryModalItem>
        </>
      )}
    </>
  );
};

export default ApprovedSeraview;
