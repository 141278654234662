import React from "react";
import Skeleton from "react-loading-skeleton";
import CertifiedTrainingSteps from "./Sections/Steps";
import * as S from "./Sections/style/VideoPreview.style";

const SkeletonTraining = () => {
  return (
    <>
      <CertifiedTrainingSteps currentStepIndex={0} />
      <S.VideoForm>
        <dt>
          <Skeleton width="100%" height="500px" />
        </dt>
        <dd>
          <Skeleton width="100%" height="30px" />
        </dd>
      </S.VideoForm>
    </>
  );
};

export default SkeletonTraining;
