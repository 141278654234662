import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { updateMemo } from "../../../../../apis/patientApi";
import Modal from "../../../../../components/Modal/Modal";
import * as S from "../../../../../components/Modal/style/Modal.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import {
  FieldMessageErrorClick,
  FieldMessageError,
} from "../../../../../components/element/FieldMessage/FieldMessage";
import * as S3 from "../../../../../components/element/Form/style/Form.style";
import { IK } from "../../../../../utils/i18n/keyStore";

export const PatientMemoModal = ({
  memoUpdate,
  setMemoUpdate,
  patientId,
  memoModalOpen,
  setMemoModalOpen,
}) => {
  const { t } = useTranslation(["translation"]);

  const closeMemoModal = () => setMemoModalOpen(false);

  const handleSubmit = async (value) => {
    try {
      const res = await updateMemo(patientId, value);
      setMemoUpdate(res.data.data);
      closeMemoModal();
    } catch (err) {
      const error = IK[err?.response?.data?.message] || IK.again_message;
      Notiflix.Notify.failure(t(error));
    }
  };

  return (
    <Modal
      open={memoModalOpen}
      close={closeMemoModal}
      header={t(IK.patient_memo)}
    >
      <Formik
        initialValues={{
          memo: memoUpdate,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          memo: Yup.string().max(1000, t(IK.limit_memo_text)),
        })}
      >
        <Form>
          <Field name="memo">
            {({ field }) => (
              <S3.FormControls
                as="textarea"
                $textarea
                $full
                {...field}
                maxLength="1000"
              />
            )}
          </Field>
          <FieldMessageErrorClick name="memo" />
          <FieldMessageError text={t(IK.memo_noti)} />
          <S.ModalBoxBtn>
            <S2.ButtonFlex>
              <S2.StyledButton
                as="button"
                type="button"
                onClick={closeMemoModal}
              >
                {t(IK.cancel)}
              </S2.StyledButton>
              <S2.StyledButton as="button" type="submit" $primary>
                {t(IK.compelete)}
              </S2.StyledButton>
            </S2.ButtonFlex>
          </S.ModalBoxBtn>
        </Form>
      </Formik>
    </Modal>
  );
};
