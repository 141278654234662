import { Formik } from "formik";
import React, { useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useGetCertifiedVideoList, useGetCountryVideoUrl, useUpdateCertifiedVideo } from "../../../apis/educationApi";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { CardInnerLayout, CardTail } from "../../../components/Layout/CardLayout/CardLayout";
import { IK } from "../../../utils/i18n/keyStore";
import EtcVideo from "./Sections/EtcVideo";
import IndicationVideo from "./Sections/IndicationVideo";
import OrderVideo from "./Sections/OrderVideo";
import SeraviewVideo from "./Sections/SeraviewVideo";
import CertifiedTrainingSteps from "./Sections/Steps";
import SkeletonTraining from "./SkeletonTraining";

function CertifiedTraining() {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  const { data, isLoading } = useGetCertifiedVideoList();
  const videoCheck = data?.data;

  const { data: videoUrls, isLoading: isVideoUrlsLoading } = useGetCountryVideoUrl();
  const videoUrlsData = videoUrls?.supportVideo;

  const VideoList = [<OrderVideo />, <SeraviewVideo />, <IndicationVideo />, <EtcVideo />];

  /**스텝 선택 */
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  /**다음으로 이동 */
  const onHandleNext = (validateForm, setTouched) => {
    let nextIndex = currentStepIndex + 1;

    //수동으로 유휴성 검사
    validateForm().then((errors) => {
      if (Object.entries(errors).length === 0 && errors.constructor === Object) {
        currentStepIndex === 3 ? navigate("/patient") : setCurrentStepIndex(nextIndex);
      } else {
        setTouched(errors);
      }
    });
  };

  /**이전으로 이동 */
  const onHandlePrev = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const videoMutation = useUpdateCertifiedVideo();
  /**비디오 확인 체크 전송 */
  const handleVideoCheck = (values) => {
    videoMutation.mutate(values);
  };

  const validationSchemas = [
    Yup.object().shape({
      certVideo1: Yup.bool().isTrue(t(IK.support_link_step1_required)),
    }),
    Yup.object().shape({
      certVideo2: Yup.bool().isTrue(t(IK.support_link_step2_required)),
    }),
    Yup.object().shape({
      certVideo3: Yup.bool().isTrue(t(IK.support_link_step3_required)),
    }),
    Yup.object().shape({
      certVideo4: Yup.bool().isTrue(t(IK.support_link_step4_required)),
    }),
  ];

  const schema = Yup.lazy(() => validationSchemas[currentStepIndex]);

  return (
    <Beforeunload onBeforeunload={() => ""}>
      <CardInnerLayout>
        {isLoading || isVideoUrlsLoading ? (
          <SkeletonTraining />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              certVideo1: videoCheck?.video1Yn === "Y" ? true : false,
              certVideo2: videoCheck?.video2Yn === "Y" ? true : false,
              certVideo3: videoCheck?.video3Yn === "Y" ? true : false,
              certVideo4: videoCheck?.video4Yn === "Y" ? true : false,
              video1: videoUrlsData?.video1,
              video2: videoUrlsData?.video2,
              video3: videoUrlsData?.video3,
              video4: videoUrlsData?.video4,
            }}
            enableReinitialize
          >
            {({ values, validateForm, setTouched }) => (
              <>
                <CertifiedTrainingSteps currentStepIndex={currentStepIndex} />
                {React.cloneElement(VideoList[currentStepIndex], {
                  values,
                  handleVideoCheck,
                })}

                <CardTail line>
                  <S.ButtonLtBox>
                    {currentStepIndex > 0 && (
                      <S.StyledButton as="button" type="button" onClick={onHandlePrev}>
                        {t(IK.prev)}
                      </S.StyledButton>
                    )}
                    <S.StyledButton as="button" $primary type="button" onClick={() => onHandleNext(validateForm, setTouched)}>
                      {currentStepIndex === 3 ? t(IK.confirm) : t(IK.next)}
                    </S.StyledButton>
                  </S.ButtonLtBox>
                </CardTail>
              </>
            )}
          </Formik>
        )}
      </CardInnerLayout>
    </Beforeunload>
  );
}

export default CertifiedTraining;
