import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/**콜백 코드 전송*/
export const postMeditCode = async (code) => {
  const response = await axios.post(
    `/api/medit-link`,
    { code: code },
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return response;
};

/**케이스 파일 조회 */
export const useGetMeditCaseList = (page) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["MeditCaseList", page],
    async () => {
      const { data } = await axios.get(`/api/medit-link?page=${page}`);
      return data;
    },
    {
      onError: (error) => {
        if (error.response.status === 400) {
          window.location.href = error.response.data.message;
        } else {
          Notiflix.Report.failure(t(IK.medit_error), t(IK.again_message), t(IK.confirm), () => window.close());
        }
      },
    }
  );
};

/**메딧 연동해제 */
export const deleteMedit = async () => {
  const response = await axios.delete(`/api/medit-link`);
  return response;
};

/**메딧 상세조회 */
export const useGetMeditInfo = (id) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["MeditInfo", id],
    async () => {
      const { data } = await axios.get(`/api/medit-link/info?uuid=${id}`);
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};
