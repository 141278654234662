import * as S from "./style/TeethTable.style";
const TeethCheckItem = ({ keys, value, teethLabel, values, handleChange, code, itemName, readonly, TeethCheckbox, TeethRadio }) => {
  return (
    <S.TeethItem key={keys}>
      <S.TeethItemLabel>
        <S.TeethItemInput
          $TeethCheckbox={TeethCheckbox}
          $TeethRadio={TeethRadio}
          type="checkbox"
          onChange={handleChange}
          checked={values[code] === "2" && Array.isArray(values[itemName]) && values[itemName].includes(value)}
          name={itemName}
          value={value}
          disabled={values[code] === "1" || !values[code] ? true : false}
          readOnly={readonly}
        />
        <S.TeethItemSpan>{teethLabel}</S.TeethItemSpan>
      </S.TeethItemLabel>
    </S.TeethItem>
  );
};

export default TeethCheckItem;
