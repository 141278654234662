import Skeleton from "react-loading-skeleton";
import * as S from './style/Form.style';

export const SkeletonFormCardList = () => {
  return (
    <S.FormCardList>
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
      <SkeletonBox />
    </S.FormCardList>
  );
};

export const SkeletonBox = () => {
  return (
    <li>
        <Skeleton width={80} height={20} />
        <Skeleton height={20} width={200} count={2} />
    </li>
  );
};
