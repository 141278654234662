import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import 'core-js/stable';

const root = ReactDOM.createRoot(document.getElementById("wrapper")); //webpackDevServer.config.js
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 기본 쿼리 옵션 설정
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);
