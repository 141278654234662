import Skeleton from "react-loading-skeleton";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import * as S from "../../components/element/Form/style/Form.style";

const SkeletonAccount = () => {
  return (
    <>
      <S.FormControlsColumn $large>
        <FormSkeleton />
        <FormSkeleton />
        <FormSkeleton />
        <FormSkeleton />
        <FormSkeleton />
        <FormSkeleton />
        <FormSkeleton />
        <FormSkeleton />
      </S.FormControlsColumn>
    </>
  );
};

export default SkeletonAccount;

export const FormSkeleton = () => {
  return (
    <FormControlsBox title={<Skeleton width={150} height={35} />}>
      <Skeleton width="400px" height="35px" />
    </FormControlsBox>
  );
};
