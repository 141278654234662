import Skeleton from "react-loading-skeleton";
import * as S from "../../style/Profile.style";

const SkeletonHistoryModal = () => {
  return (
    <S.ProfileHistoryModalList>
      <li>
        <S.ProfileHistoryCircle />
        <S.ProfileHistoryModalItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.ProfileHistoryModalItem>
      </li>
      <li>
        <S.ProfileHistoryCircle />
        <S.ProfileHistoryModalItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.ProfileHistoryModalItem>
      </li>
      <li>
        <S.ProfileHistoryCircle />
        <S.ProfileHistoryModalItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.ProfileHistoryModalItem>
      </li>
      <li>
        <S.ProfileHistoryCircle />
        <S.ProfileHistoryModalItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.ProfileHistoryModalItem>
      </li>
      <li>
        <S.ProfileHistoryCircle />
        <S.ProfileHistoryModalItem>
          <Skeleton height={25} width={400} />
          <Skeleton height={25} width={400} />
        </S.ProfileHistoryModalItem>
      </li>
    </S.ProfileHistoryModalList>
  );
};

export default SkeletonHistoryModal;
