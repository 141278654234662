import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import TeethCheck from "../../../../../components/element/TeethTable/TeethCheck";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";

function Extraction({
  next,
  prev,
  currentStepType,
  stepLabel,
  currentStepList,
  currentStepIndex,
  setStudyInfo,
  studyInfo,
  patientId,
  isFinish,
  setCurrentStepIndex,
}) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    SI12: Yup.string().required(t(IK.extraction_required)),
    SI12_2_01: Yup.array().when("SI12", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { SI12_2_02 } = this.parent;
            return value.length > 0 || SI12_2_02.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
    SI12_2_02: Yup.array().when("SI12", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { SI12_2_01 } = this.parent;
            return value.length > 0 || SI12_2_01.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
  });

  const code = currentStepList[currentStepIndex].code;

  const handleSubmit = (values) => {
    const data = {
      SI12: values.SI12,
      SI12_2_01: values.SI12 === "2" ? values.SI12_2_01.join(",") : "",
      SI12_2_02: values.SI12 === "2" ? values.SI12_2_02.join(",") : "",
    };
    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip
            ? setCurrentStepIndex(currentStepList.length - 2)
            : next();
        },
      }
    );
  };
  const config = studyFormConfig[currentStepType][stepLabel];

  /**이전 버튼 눌러서 악궁선택으로 이동되기 전에 경고 */
  const prevCaution = () => {
    if (currentStepIndex === 3) {
      Notiflix.Confirm.show(
        t(IK.is_edit),
        t(IK.edit_dental_arch_treated),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          prev();
        },
        () => {}
      );
    } else {
      prev();
    }
  };

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
        required
      />

      <Formik
        initialValues={{
          SI12: studyInfo.SI12 || "",
          SI12_2_01: studyInfo.SI12_2_01 ? studyInfo.SI12_2_01.split(",") : [],
          SI12_2_02: studyInfo.SI12_2_02 ? studyInfo.SI12_2_02.split(",") : [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, handleChange, values }) => (
          <Form>
            <FormControlsStudyBox
              title={t(IK.extraction)}
              required
              openModal={openModal}
            >
              {config.extraction_item1 && (
                <>
                  <div className="frm_column">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI12"
                          className="input_radio"
                          value="1"
                        />
                        <span>{t(IK.extraction_item1)}</span>
                      </label>
                    </div>

                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI12"
                          className="input_radio"
                          value="2"
                        />
                        <span>{t(IK.extraction_item2)}</span>
                      </label>
                    </div>
                    <div className="frm_radio_indent">
                      <TeethCheck
                        label="permanent"
                        baby
                        code={code}
                        studyInfo={studyInfo}
                        handleChange={handleChange}
                        values={values}
                        config={config.extraction_item2}
                      />
                      <FieldMessageErrorClick name="SI12_2_01" />
                    </div>
                  </div>
                  <FieldMessageErrorClick name="SI12" />
                </>
              )}
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton
                  $secondary
                  as="button"
                  type="button"
                  onClick={() => setCurrentStepIndex(1)}
                >
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton
                    as="button"
                    type="button"
                    onClick={prevCaution}
                  >
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton
                      as="button"
                      type="submit"
                      onClick={() => setSkip(true)}
                      $primary
                    >
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.extraction_pop_message1)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Extraction;
