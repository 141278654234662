import Notiflix from "notiflix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal/Modal";
import * as S3 from "../../../components/Modal/style/Modal.style";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import * as S from "../../../components/element/Table/style/table.style";
import LoadingSpinner from "../../../components/loading/LoadingSpinner/LoadingSpinner";
import { IK } from "../../../utils/i18n/keyStore";
import { formatCurrency } from "../../../utils/paymentUtils";

const CouponModal = ({ setApplyCoupon, couponModal, setCouponModal, couponList, setCouponList, isCouponLoading, couponId, setBillsInfo, billsInfo }) => {
  const { t } = useTranslation(["translation"]);
  /**쿠폰 적용하기 */
  const [selectedCoupon, setSelectedCoupon] = useState("");

  /**모달 닫기 */
  const onCloseModal = () => setCouponModal(false);

  /**쿠폰 적용 */
  const applyCoupon = () => {
    //선택된 값이 없을 경우 리턴
    if (!selectedCoupon) return;

    const couponItemIndex = billsInfo.findIndex((bill) => bill.billingDetailId === couponId);

    //쿠폰 값이 금액보다 크다면 미적용
    const couponItem = billsInfo[couponItemIndex];
    if (couponItem.paymentPrice < selectedCoupon.amount) {
      Notiflix.Report.info(t(IK.disable_coupon), t(IK.error_coupon), t(IK.confirm));
      return;
    }

    // couponId에 해당하는 billingDetailId 찾아 데이터 추가
    const updatedBill = {
      ...couponItem,
      discountCoupon: selectedCoupon.amount,
      paymentPrice: couponItem.paymentPrice - selectedCoupon.amount,
      couponId: selectedCoupon.couponId,
    };
    setBillsInfo((bills) => {
      const updatedBills = [...bills];
      updatedBills[couponItemIndex] = updatedBill;
      return updatedBills;
    });
    setApplyCoupon((prev) => [...prev, { ...selectedCoupon, billingDetailId: couponId }]);
    setCouponList((prevCouponList) => prevCouponList.filter((coupon) => coupon.couponId !== selectedCoupon.couponId));
    onCloseModal();
  };

  return (
    <Modal open={couponModal} close={onCloseModal} header={`${t(IK.available_coupons)} ${t(IK.list)}`}>
      {isCouponLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <S.TableType1>
            <colgroup>
              <col width="200px"></col>
              <col width="200px"></col>
              <col width="200px"></col>
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t(IK.coupon_name)}</th>
                <th scope="col">{t(IK.pay_discount)}</th>
                <th scope="col">{t(IK.pay_end_date)}</th>
              </tr>
            </thead>
            <tbody>
              {couponList?.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    <EmptyCard />
                  </td>
                </tr>
              ) : (
                couponList?.map((coupon) => (
                  <tr key={coupon.couponId}>
                    <td>
                      <div className="radio">
                        <label className="label_radio">
                          <input type="radio" checked={selectedCoupon.couponId === coupon.couponId} onChange={() => setSelectedCoupon(coupon)} className="input_radio" />
                          <span>{coupon.couponName}</span>
                        </label>
                      </div>
                    </td>
                    <td>
                      {formatCurrency(coupon.amount)}
                      {t(IK.krw)}
                    </td>
                    <td>{coupon.noLimitYn === "N" ? `${coupon.startDate}~${coupon.endDate}` : t(IK.pay_no_end_date)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </S.TableType1>
          <S3.ModalBoxBtn>
            <S2.ButtonFlex>
              <S2.StyledButton as="button" onClick={onCloseModal}>
                {t(IK.cancel)}
              </S2.StyledButton>
              <S2.StyledButton as="button" $primary onClick={applyCoupon}>
                {t(IK.able_coupon)}
              </S2.StyledButton>
            </S2.ButtonFlex>
          </S3.ModalBoxBtn>
        </>
      )}
    </Modal>
  );
};

export default CouponModal;
