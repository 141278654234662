import Skeleton from "react-loading-skeleton";
import * as S from "./style/BoardList.style";

export const SkeletonBoardTable = ({ isBill }) => {
  return (
    <S.BoardTable>
      <thead>
        <tr>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
          <th>
            <Skeleton width={50} height={20} />
          </th>
        </tr>
      </thead>
      <tbody>
        <SkeletonTrCard />
        <SkeletonTrCard />
        <SkeletonTrCard />
        <SkeletonTrCard />
        <SkeletonTrCard />
        <SkeletonTrCard />
        <SkeletonTrCard />
        {isBill && (
          <>
            <SkeletonTrCard />
            <SkeletonTrCard />
            <SkeletonTrCard />
          </>
        )}
      </tbody>
    </S.BoardTable>
  );
};

export const SkeletonTrCard = () => {
  return (
    <tr>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
      <td>
        <Skeleton width={50} height={20} />
      </td>
    </tr>
  );
};
