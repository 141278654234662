import { css, styled } from "styled-components";
import AdultsIco from "../../../../../../assets/images/patients/study/adults_ico.png";
import ChildrenIco from "../../../../../../assets/images/patients/study/children_ico.png";

export const OptionList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  width: ${(props) => (props.$full ? "100%" : "1100px")};
  margin: 0 auto;
`;

export const OptionListItem = styled.li`
  width: 350px;
  button {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 25px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 10px;
    &:hover {
      border-color: ${(props) => (props.$type1 ? props.theme.colors.green200 : props.$type2 ? props.theme.colors.blue300 : props.$type3 ? props.theme.colors.blue100 : props.theme.colors.blue400)};
      dd {
        h3 {
          color: ${(props) => (props.$type1 ? props.theme.colors.green200 : props.$type2 ? props.theme.colors.blue300 : props.$type3 ? props.theme.colors.blue100 : props.theme.colors.blue400)};
        }
      }
    }

    dt {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: ${(props) => (props.$type1 ? props.theme.colors.green200 : props.$type2 ? props.theme.colors.blue300 : props.$type3 ? props.theme.colors.blue100 : props.theme.colors.gray300)};
      background-repeat: no-repeat;
      background-position: bottom;
      background-image: url(${(props) => (props.$adult ? `${AdultsIco}` : props.$child ? `${ChildrenIco}` : 0)});
      color: ${(props) => (props.$type1 ? props.theme.colors.white : props.$type2 ? props.theme.colors.white : props.$type3 ? props.theme.colors.white : props.theme.colors.blue400)};
      h3 {
        line-height: 1;
        font-size: 30px;
        font-weight: 600;
      }
      span {
        display: block;
        margin-top: 3px;
      }
      i {
        font-size: 40px;
      }
    }

    dd {
      h3 {
        line-height: 1.3;
        margin-top: 10px;
        font-size: 22px;
        color: ${(props) => (props.$type1 ? props.theme.colors.green200 : props.$type2 ? props.theme.colors.blue300 : props.$type3 ? props.theme.colors.blue100 : props.theme.colors.black)};
      }

      p {
        margin-top: 10px;
        font-size: 18px;
      }

      span {
        display: inline-flex;
        gap: 3px;
        margin-top: 5px;
        color: ${(props) => props.theme.colors.gray600};
        word-break: break-all;
      }
    }
  }
`;

export const Hexagon = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 70px;
  position: relative;
  color: ${(props) => props.theme.colors.white};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left: 33px solid transparent;
    border-right: 33px solid transparent;
    border-bottom: 15px solid transparent;
    background-color: ${(props) => props.theme.colors.white};
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left: 33px solid transparent;
    border-right: 33px solid transparent;
    border-top: 15px solid transparent;
    background-color: ${(props) => props.theme.colors.white};
  }

  ${(props) =>
    props.$HexagonGreen &&
    css`
      background-color: ${(props) => props.theme.colors.green200};
      &:before {
        border-bottom-color: ${(props) => props.theme.colors.green200};
      }
      &:after {
        border-top-color: ${(props) => props.theme.colors.green200};
      }
    `}

  ${(props) =>
    props.$HexagonBlue &&
    css`
      background-color: ${(props) => props.theme.colors.blue300};
      &:before {
        border-bottom-color: ${(props) => props.theme.colors.blue300};
      }
      &:after {
        border-top-color: ${(props) => props.theme.colors.blue300};
      }
    `}

  ${(props) =>
    props.$HexagonSkyblue &&
    css`
      background-color: ${(props) => props.theme.colors.blue100};
      &:before {
        border-bottom-color: ${(props) => props.theme.colors.blue100};
      }
      &:after {
        border-top-color: ${(props) => props.theme.colors.blue100};
      }
    `}
`;

export const HexagonTit = styled.h2`
  line-height: 1;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
`;

export const HexagonSpan = styled.span`
  line-height: 1;
  margin-top: 4px;
  font-size: 13px;
`;
