import { Field } from "formik";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { checkLoginId } from "../../apis/joinApi";
import { FieldMessageError, FieldMessageErrorClick, FieldMessageSuccess } from "../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import { IK } from "../../utils/i18n/keyStore";
import { TitleV1 } from "../../components/element/Title/TitleText";
import * as S from "../../components/element/Form/style/Form.style";
import * as S2 from "../../components/element/SelectionControls/style/SelectionControls.style";

const JoinTerms = ({ errors, values, setFieldValue, setFieldError, recaptchaRef }) => {
  const { t } = useTranslation(["translation"]);
  const [idCheckFlag, setIdCheckFlag] = useState(false);

  /**staff아이디 중복 검사 */
  const handleClickLoginIdCheck = () => {
    /**의사 아이디와 동일한 아이디 입력 */
    if (values.loginId === values.staffLoginId) {
      setFieldError("staffLoginIdCheck", t(IK.duplicate_id));
      return;
    }
    if (values.staffLoginId) {
      checkLoginId({ loginId: values.staffLoginId })
        .then((response) => {
          setIdCheckFlag(true);
          setFieldValue("staffLoginIdCheck", true);
        })
        .catch((err) => {
          setFieldError("staffLoginIdCheck", t(IK.duplicate_id));
        });
    }
  };

  /**중복 검사 */
  const handleKeyDown = (event) => {
    setFieldValue(event.target.id + "Check", false);
    setIdCheckFlag(false);
  };

  /*사용자 로봇아님 인증 */
  const handleRecaptcha = (value) => {
    setFieldValue("recaptcha", value);
  };

  return (
    <>
      <TitleV1 title={t(IK.etc_form_message)} />

      <S.FormControlsColumn $large>
        {/* 스태프 아이디 */}
        <FormControlsBox regular title={t(IK.staff_id)}>
          <S.FormControlsFlex>
            <Field as={S.FormControls} $full type="text" id="staffLoginId" name="staffLoginId" onKeyDown={handleKeyDown} maxLength="15" autoComplete="new-password" />
            <S.FormControlsBtn as="label" type="button" onClick={handleClickLoginIdCheck}>
              {t(IK.duplicate_btn)}
            </S.FormControlsBtn>
          </S.FormControlsFlex>
          <FieldMessageErrorClick name="staffLoginId" />
          {errors.staffLoginIdCheck && <FieldMessageError text={errors.staffLoginIdCheck} />}
          {idCheckFlag && <FieldMessageSuccess text={t(IK.available_id_message)} />}
        </FormControlsBox>

        {/* 스태프 비밀번호 */}
        <FormControlsBox regular title={t(IK.staff_pw)} example={`ex) serafin2021!`}>
          <Field as={S.FormControls} $full type="password" id="staffLoginPw" name="staffLoginPw" maxLength="16" autoComplete="new-password" />
          <FieldMessageErrorClick name="staffLoginPw" />
        </FormControlsBox>

        {/* 스태프 비밀번호 확인 */}
        <FormControlsBox regular title={t(IK.staff_pw_confirm)}>
          <Field as={S.FormControls} $full type="password" id="staffReLoginPw" name="staffReLoginPw" />
          <FieldMessageErrorClick name="staffReLoginPw" />
        </FormControlsBox>

        {/* 스태프 연락처1 */}
        <FormControlsBox regular title={values.countryCode === "01" ? `${t(IK.staff_contact)} 1` : `${t(IK.staff_email)} 1`}>
          <Field as={S.FormControls} $full type="text" name="staffContact1" />
          <FieldMessageErrorClick name="staffContact1" />
        </FormControlsBox>

        {/* 스태프 연락처2 */}
        <FormControlsBox regular title={values.countryCode === "01" ? `${t(IK.staff_contact)} 2` : `${t(IK.staff_email)} 2`}>
          <Field as={S.FormControls} $full type="text" name="staffContact2" />
          <FieldMessageErrorClick name="staffContact2" />
        </FormControlsBox>

        {/* 담당자 */}
        {values.countryCode === "01" && (
          <FormControlsBox regular title={t(IK.recommendation)} required>
            <S2.SelectionItemList>
              <S2.SelectionItem>
                <S2.SelectionItemLabel>
                  <Field as={S2.SelectionItemInput} $radio type="radio" name="recommender" value="티에네스" />
                  <S2.SelectionItemSpan>{t(IK.tns_join)}</S2.SelectionItemSpan>
                </S2.SelectionItemLabel>
              </S2.SelectionItem>
              <S2.SelectionItem>
                <S2.SelectionItemLabel>
                  <Field as={S2.SelectionItemInput} $radio type="radio" name="recommender" value="덴티스" />
                  <S2.SelectionItemSpan>{t(IK.dentis)}</S2.SelectionItemSpan>
                </S2.SelectionItemLabel>
              </S2.SelectionItem>
            </S2.SelectionItemList>

            <FieldMessageErrorClick name="recommender" />
          </FormControlsBox>
        )}

        {/* 리캡차 */}
        <FormControlsBox regular>
          <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleRecaptcha} ref={recaptchaRef} />
          <FieldMessageErrorClick name="recaptcha" />
        </FormControlsBox>
      </S.FormControlsColumn>
    </>
  );
};

export default JoinTerms;
