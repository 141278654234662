import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../apis/boardApi";
import { getSeraquickFileId, getSeraviewFileId } from "../../apis/seraviewApt";
import { IK } from "../../utils/i18n/keyStore";
import Modal from "../Modal/Modal";
import * as S3 from "../element/Button/style/ButtonLayout.style";
import * as S2 from "../element/Table/style/table.style";
import { TitleV3 } from "../element/Title/TitleText";
import * as S from "./style/DownloadModal.style";

export const DownloadModal = ({ downloadModal, handleCloseDownloadModal }) => {
  const { t } = useTranslation(["translation"]);

  /**세라뷰, 세라퀵 탭 선택 */
  // const [tabs, setTabs] = useState("seraview");
  const tabs = "seraview"; // 세라뷰만 사용

  // const tabList = [
  //   { id: "seraview", title: t(IK.download_seraview) },
  //   { id: "seraquick", title: t(IK.download_seraquick) },
  // ];

  /**프로그램 다운로드 */
  const downloadProgram = async () => {
    // 파일 다운로드
    const downloadFileWithNotification = async (getFileIdFn) => {
      try {
        const { data } = await getFileIdFn();

        if (!data.orgFileName || !data.fileId) {
          throw new Error("파일 정보 오류");
        }

        await downloadFile(data.fileId, data.orgFileName, t);
        Notiflix.Notify.success(t(IK.program_download));
      } catch (error) {
        Notiflix.Notify.failure(t(IK.program_download_fail));
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    };

    // 탭에 따라 다른 파일 ID 조회 함수를 사용
    const getFileIdFn = tabs === "seraview" ? getSeraviewFileId : getSeraquickFileId;

    await downloadFileWithNotification(getFileIdFn);
  };

  //최소사양 같다고 해서 일단 이름이랑 다운로드 로직만 변경 => 추후 다르면 ui 분리 예정

  return (
    <Modal open={downloadModal} close={handleCloseDownloadModal} header={`${t(IK.program)} ${t(IK.download)}`}>
      {/* <S4.TabType1>
        {tabList.map((tab) => (
          <S4.TabType1Item $active={tabs === tab.id} key={tab.id} onClick={() => setTabs(tab.id)}>
            {tab.title}
          </S4.TabType1Item>
        ))}
      </S4.TabType1> */}

      <S.DownloadBox>
        <S.DownloadBoxIcon>
          <i className="ri-download-cloud-2-line"></i>
        </S.DownloadBoxIcon>
        {tabs === "seraview" ? <h3>{t(IK.download_seraview)}</h3> : <h3>{t(IK.download_seraquick)}</h3>}
        <p>{t(IK.download_seraview_txt_1)}</p>
        <S3.ButtonCtBox>
          <S3.StyledButton as="button" $primary onClick={downloadProgram}>
            {t(IK.download)}
          </S3.StyledButton>
        </S3.ButtonCtBox>
      </S.DownloadBox>

      <TitleV3 title={tabs === "seraview" ? t(IK.download_seraview_txt_2) : t(IK.download_seraquick_txt_2)} />
      <S2.TableType1>
        <colgroup>
          <col width="50%"></col>
          <col width="50%"></col>
        </colgroup>
        <thead>
          <tr>
            <th>Item</th>
            <th>Specifications</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Operating System</td>
            <td>Windows 7 32/64 bit {t(IK.more)}</td>
          </tr>
          <tr>
            <td>CPU</td>
            <td>Intel i5 CPU 2.5 GHz {t(IK.more)}</td>
          </tr>
          <tr>
            <td>RAM</td>
            <td>8GB {t(IK.more)}</td>
          </tr>
          <tr>
            <td>Resolution</td>
            <td>1920 x 1080 {t(IK.more)}</td>
          </tr>
        </tbody>
      </S2.TableType1>
    </Modal>
  );
};
