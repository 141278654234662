import { Field } from "formik";
import { useTranslation } from "react-i18next";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S2 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import { IK } from "../../../../utils/i18n/keyStore";
import { ClinicalCheckBox } from "./ClinicalCheckBox";

const ClinicalCondition = ({ setFieldValue, values }) => {
  const { t } = useTranslation(["translation"]);

  /**임상상태 미선택 검사 */
  const handleOnClickOther = (e) => {
    if (!e.currentTarget.checked) {
      setFieldValue("conditionItemMap.otherText", "");
    }
  };

  return (
    <>
      <S.FormControlsFlex>
        <ClinicalCheckBox />
        <S.FormControlsColumn as="li">
          <S2.SelectionItem>
            <S2.SelectionItemLabel>
              <Field type="checkbox" name="conditionItemMap.other">
                {({ field }) => <S2.SelectionItemInput $checkbox type="checkbox" {...field} onClick={handleOnClickOther} />}
              </Field>
              <S2.SelectionItemSpan>{t(IK.other)}</S2.SelectionItemSpan>
            </S2.SelectionItemLabel>
          </S2.SelectionItem>

          <Field name="conditionItemMap.otherText">
            {({ field }) => <S.FormControls as="textarea" placeholder={t(IK.otherText_ex)} maxLength={80} $textarea {...field} id="otherText" disabled={!values.conditionItemMap.other} />}
          </Field>
        </S.FormControlsColumn>
      </S.FormControlsFlex>
    </>
  );
};

export default ClinicalCondition;
