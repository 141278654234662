import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../utils/i18n/keyStore";
import { VideoPreview } from "./VideoPreview";

const EtcVideo = ({ values, handleVideoCheck }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <VideoPreview youtubeLink={`https://www.youtube.com/embed/${values.video4}`}>
      <div className="checkbox">
        <label className="label_checkbox">
          <Field
            type="checkbox"
            name="certVideo4"
            className="input_checkbox"
            onClick={(e) => {
              const target = e.currentTarget;
              values.certVideo4 ? (target.checked = true) : handleVideoCheck("VIDEO4");
            }}
            checked={values.certVideo4}
          />
          <span>{t(IK.support_link_step4_txt)}</span>
        </label>
      </div>
      <FieldMessageErrorClick name="certVideo4" />
    </VideoPreview>
  );
};

export default EtcVideo;
