import dayjs from "dayjs";
import ApprovedSeraview from "../pages/Patients/Profile/section/statusItem/ApprovedSeraview";
import ConfirmSeraview from "../pages/Patients/Profile/section/statusItem/ConfirmSeraview";
import DevelopeStudyPlan from "../pages/Patients/Profile/section/statusItem/DevelopeStudyPlan";
import EndStudy from "../pages/Patients/Profile/section/statusItem/EndStudy";
import ProgressAligner from "../pages/Patients/Profile/section/statusItem/ProgressAligner";
import ReceiptStudy from "../pages/Patients/Profile/section/statusItem/ReceiptStudy";
import RejectStudy from "../pages/Patients/Profile/section/statusItem/RejectStudy";
import ShippedDevice from "../pages/Patients/Profile/section/statusItem/ShippedDevice";
import SubmitStudy from "../pages/Patients/Profile/section/statusItem/SubmitStudy";
import TransitDevice from "../pages/Patients/Profile/section/statusItem/TransitDevice";
import WritingStudy from "../pages/Patients/Profile/section/statusItem/WritingStudy";
import { IK } from "../utils/i18n/keyStore";
import { rejectReasonList } from "../utils/statusUtils";

export function studyStatusComponents(props, key) {
  const data = {
    "01": <WritingStudy {...props} />,
    11: <RejectStudy {...props} />,
    13: <SubmitStudy {...props} />,
    14: <ReceiptStudy {...props} />,
    15: <DevelopeStudyPlan {...props} />,
    16: <ConfirmSeraview {...props} />,
    17: <ApprovedSeraview {...props} />,
    18: <ProgressAligner {...props} />,
    19: <TransitDevice {...props} />,
    20: <ShippedDevice {...props} />,
    22: <EndStudy {...props} />,
  };
  return data[key] ? data[key] : key < 13 ? data["01"] : "";
}

/**처방전 상태 라벨 */
export function studyStatusLabel(key, t) {
  const data = {
    "01": t(IK.writing_study), //처방전 작성중
    11: t(IK.reject_study), //처방전 반려
    13: t(IK.submit_study), //처방전 제출
    14: t(IK.receipt_study), //처방전 접수
    15: t(IK.develope_study_plan), //세라뷰 디자인중
    16: t(IK.confirm_seraview), //세라뷰 검토요청
    17: t(IK.approved_seraview), //세라뷰 승인
    18: t(IK.progress_aligner), //장치 제작중
    19: t(IK.transit_device), //장치 배송중
    20: t(IK.shipped_device), //장치 배송완료
    22: t(IK.end_of_treatment), //치료 종료
  };

  return data[key] ? data[key] : key ? data["01"] : "";
}

/**처방전 리스트 상태,세부 항목 표기 */
export const studyStatusListLabel = (item, t) => {
  const detail = item?.detail;
  const status = studyStatusLabel(detail, t);

  if (detail === "11" && !!item?.rejectReason) {
    return `${status} [${t(rejectReasonList[item.rejectReason])}]`;
  }

  if ((detail === "14" || detail === "18") && !!item.expectedDate) {
    const labelKey = detail === "14" ? IK.seraview_expected : IK.expacted_date_device;
    return `${status} (${t(labelKey)}: ${dayjs(item.expectedDate).format("MM/DD")})`;
  }

  if (detail === "16" && item?.reviseStudyDesignYn === "Y") {
    return t(IK.seraviw_3d_modify);
  }

  return status;
};
