import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { submitErrorLog } from "../../apis/errorFallbackApi";
import * as S from "../../components/element/Button/style/ButtonLayout.style";
import { ErrorCard } from "../../components/element/Error/ErrorCard";
import { IK } from "../../utils/i18n/keyStore";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation(["translation"]);
  console.log("에러사유:", error.message);

  useEffect(() => {
    // 화면 에러 발생시 전송
    if (process.env.NODE_ENV !== "development") {
      submitErrorLog(`VIP

      ${window.location.href}

      ${error?.stack}`);
    }
  }, [error]);

  const goToHome = () => {
    window.location.href = "/patient"; // 홈 경로로 이동
  };

  return (
    <ErrorCard title={"ERROR"} subTitle={t(IK.error_subtitle)} contnetTxt={t(IK.error_content_txt1)}>
      <S.ButtonCtBox>
        <S.StyledButton $primary as="button" type="button" onClick={resetErrorBoundary}>
          {t(IK.try_again)}
        </S.StyledButton>
        <S.StyledButton as="button" type="button" onClick={goToHome}>
          {t(IK.home)}
        </S.StyledButton>
      </S.ButtonCtBox>
    </ErrorCard>
  );
};

export default ErrorFallback;
