import axios from "axios";

/**유저의 타임존 정보 전송
 *@param {string} timeZone - ex)America/Tijuana:GMT-07:00
 */
export const converterTimeZone = async (timeZone) => {
  const response = await axios.put(`/api/account/time-zone`, null, {
    params: {
      timeZone,
    },
  });
  return response;
};
