import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/**선호 치료계획 저장 */

export const useSavePreferredPlan = () => {
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (payload) => {
      await axios.post(`/api/study/${payload.studyId}/study-plan`, payload.data);
    },
    {
      onSuccess: () => {
        Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/**선호 치료계획 리스트 조회 */
export const useGetPreferredPlanList = (payload) => {
  const { t } = useTranslation(["translation"]);
  const { studyId, page, size } = payload;

  return useQuery(
    ["StudyPlan", payload],
    async () => {
      const { data } = await axios.get(`/api/study/${studyId}/study-plan`, {
        params: {
          page,
          size,
        },
      });
      return data;
    },
    {
      enabled: !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**선호 치료계획 상세 조회 */
export const useGetPreferredPlanDetail = (payload) => {
  const { t } = useTranslation(["translation"]);
  const { studyId, studyPlanId } = payload;

  return useQuery(
    ["StudyPlanDetail", payload],
    async () => {
      const { data } = await axios.get(`/api/study/${studyId}/study-plan/${studyPlanId}`);
      return data;
    },
    {
      enabled: !!studyPlanId && !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**선호 치료계획 선택 */
export const useSelectPreferredPlan = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      await axios.post(`/api/study/${payload.studyId}/study-plan/${payload.studyPlanId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("StudyPlan");
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/**선호 치료계획 삭제 */
export const useDeletePreferredPlan = () => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (payload) => {
      await axios.delete(`/api/study/${payload.studyId}/study-plan/${payload.studyPlanId}`);
    },
    {
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};
