import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import {
  CardInnerLayout,
  CardTitle,
  CardTail,
} from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { FormConfirm } from "../../../components/element/Form/FormLayout";
import { IK } from "../../../utils/i18n/keyStore";

const BillingDone = () => {
  const { t } = useTranslation(["translation"]);
  const { state } = useLocation();

  /**결제이동외 접근 금지 */
  if (!state) return <Navigate to="/patient" />;

  return (
    <CardInnerLayout>
      <CardTitle title={t(IK.complete_billing)} />

      {state?.vbank ? (
        <FormConfirm title={t(IK.pay_comfirm_message1)}>
          <p>
            {t(IK.deposit_bank)} : {state?.vbankInfo?.vbankName}
            <br />
            {t(IK.deposit_account)} : {state?.vbankInfo?.vbankNum}
            <br />
            {t(IK.deposit_deadline)} : {state?.vbankInfo?.vbankDate}
          </p>
        </FormConfirm>
      ) : (
        <FormConfirm title={t(IK.pay_comfirm_message2)}>
          <p>{t(IK.pay_comfirm_message3)}</p>
        </FormConfirm>
      )}

      <CardTail line>
        <S.ButtonLtBox>
          <S.StyledButton $primary to="/account/billing-list">
            {t(IK.compelete)}
          </S.StyledButton>
        </S.ButtonLtBox>
      </CardTail>
    </CardInnerLayout>
  );
};

export default BillingDone;
