import { useTranslation } from "react-i18next";
import { IK } from "../../../../utils/i18n/keyStore";
import cx from "classnames";
import * as S from "../../../../components/element/Step/style/Step.style";

const Steps = ({ currentStepIndex }) => {
  const { t } = useTranslation(["translation"]);
  const stepsList = [
    t(IK.enter_patient_information),
    t(IK.clinical_condition),
    t(IK.extraoral),
    t(IK.intraoral),
    t(IK.radiograph),
    t(IK.scan_file_registration),
    t(IK.patient_registration_completed),
  ];
  return (
    <S.StepType2>
      <ol>
        {stepsList.map((step, idx) => (
          <li
            key={idx}
            className={cx(
              "",
              currentStepIndex > idx && "",
              idx === currentStepIndex && "active"
            )}
          >
            <h3>{step}</h3>
          </li>
        ))}
      </ol>
    </S.StepType2>
  );
};

export default Steps;
