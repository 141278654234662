import { useTranslation } from "react-i18next";
import { useGetAccpetedStudyCount } from "../../../../apis/studyApi";
import { IK } from "../../../../utils/i18n/keyStore";
import * as S from "../style/PatientsList.style";
import ToolTip from "../../../../components/element/ToolTip/ToolTip";

const ProgressCard = ({ startDate, endDate }) => {
  const { t } = useTranslation(["translation"]);

  /**승인된 치료계획 수 */
  const { data: studyCount } = useGetAccpetedStudyCount();

  return (
    <S.ProgressBox>
      <dt>
        <h4>{t(IK.approved_treatment_plan)}</h4>
        <span>{startDate + " ~ " + endDate}</span>
      </dt>
      <dd>
        <h2>
          {studyCount?.data}
          <ToolTip content={studyCount?.data} />
        </h2>
        <span>{t(IK.number_case)}</span>
      </dd>
    </S.ProgressBox>
  );
};

export default ProgressCard;
