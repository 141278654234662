import Skeleton from "react-loading-skeleton";
import * as S from "./style/AlarmPopup.style";

const AlarmSkeleton = () => {
  return (
    <ul>
      <AlarmBox />
      <AlarmBox />
      <AlarmBox />
      <AlarmBox />
      <AlarmBox />
    </ul>
  );
};

export default AlarmSkeleton;

export const AlarmBox = () => {
  return (
    <S.AlarmPopListItem>
      <div>
        <Skeleton width={70} height={20} />
        <Skeleton width={230} height={20} />
        <Skeleton width={230} height={20} />
      </div>
    </S.AlarmPopListItem>
  );
};
