import { css, keyframes, styled } from "styled-components";
import elastic_bg from "../../../assets/images/elastic/elastic_bg.jpg";

export const ModalShow = keyframes`
  from {
    opacity: 0;
    margin-top: -500px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;

export const ModalBgShow = keyframes`
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
`;

export const ModalBox = styled.div`
  display: ${(props) => (props.$openModal ? "flex" : "none")};
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.7);
  ${(props) =>
    props.$openModal &&
    css`
      animation: ${ModalBgShow} 0.3s;
    `}
  section {
    position: fixed;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 500px;
    max-width: 1280px;
    animation: ${ModalShow} 0.3s;
  }
`;

export const ModalBoxHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.$headernone ? "5px 0px" : "13px 20px")};
  background-color: ${(props) => (props.$headernone ? "transparent" : props.theme.colors.blue400)};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  h3 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.white};
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  button {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ModalBoxCont = styled.div`
  max-height: calc(100% - 66px);
  overflow: auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => (props.$headernone ? "10px" : "0 0 10px 10px")};
`;

export const ModalBoxList = styled.ul``;

export const ModalBoxItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const ModalBoxCard = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.gray300};
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const ModalBoxBullet = styled.ul`
  > li {
    position: relative;
    padding-left: 10px;
    font-size: 15px;
    &:before {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.blue400};
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const ModalBoxNumList = styled.ol`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  li a {
    display: flex;
    align-items: center;
    min-width: 210px;
    height: 70px;
    padding: 15px;
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
    h3 {
      flex: 1;
      text-align: center;
      font-size: 15px;
      transition: all 0.3s;
    }
  }
  li:hover a h3 {
    color: ${(props) => props.theme.colors.blue400};
  }
`;

export const ModalBoxBtn = styled.div`
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;

// 메인 팝업
export const MainModalWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

export const MainModalBox = styled.div`
  width: 560px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  a {
    display: block;
  }
  img {
    max-width: 100%;
  }
  iframe {
    width: 560px;
    height: 560px;
  }
`;

export const MainModalBottom = styled.div`
  display: flex;
  button {
    flex: 1;
    padding: 10px;
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    &:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`;

/**프리시젼 컷 치아선택 */
export const ElasticCutList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 30px 0;
  li {
    text-align: center;
    h4 {
      margin-top: 5px;
      font-size: 15px;
    }
  }
`;

/**프리시젼 컷 치아박스 */
export const ElasticCutTeethBox = styled.ul`
  background: url(${elastic_bg}) center no-repeat;
  width: 727px;
  height: 176px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  li {
    position: relative;
  }
`;

/**프리시젼 컷 치아 */
export const ElasticCutTeethItem = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  border: 1px dashed ${(props) => (props.$active ? props.theme.colors.blue400 : "transparent")};
  span {
    img {
      display: block;
    }
  }
`;

/**프리시젼 컷 삭제 */
export const ElasticCutDelete = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 15px 15px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.gray300};
  i {
    margin-right: 5px;
  }
`;
