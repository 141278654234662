import { LogoType1 } from "../../element/Logo/Logo";
import * as S from "./style/SingleLayout.style";

export const SingleLayout = ({ children }) => {
  return (
    <S.SingleLayout>
      <S.SingleLayoutHeader>
        <LogoType1 />
      </S.SingleLayoutHeader>
      <S.SingleLayoutBox>{children}</S.SingleLayoutBox>
    </S.SingleLayout>
  );
};

export const SingleLayoutTail = ({ children }) => {
  return <S.SingleLayoutBtn>{children}</S.SingleLayoutBtn>;
};
