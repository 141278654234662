import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../apis/boardApi";
import * as S4 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S2 from "../../../components/element/Drop/style/Drop.style";
import * as S from "../../../components/element/Summary/style/Summary.style";
import { Preview } from "../../../pages/Patients/Summary/Sections/Preview";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { getSummaryLabel } from "../../../utils/studyUtils";
import { FormControlsBox } from "../Form/FormLayout";
import TeethSummaryBox from "../TeethTable/TeethSummaryBox";
import { SummaryTableLi } from "./summaryTableLi";

export const SummaryTable = ({ studyInfo, config, handleClickForUpdate }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <S.SummaryList>
      {/* 의사명 */}
      <SummaryTableLi title={t(IK.doctor_name)}>
        {studyInfo.doctor && (
          <p>
            {studyInfo.doctor.name} {studyInfo.doctor?.koreaName ? `(${studyInfo.doctor.koreaName})` : ""}
          </p>
        )}
      </SummaryTableLi>

      {/* 인쇄시 환자명, 치료옵션 보이도록 */}
      <SummaryTableLi printshow title={t(IK.patient_name)}>
        {studyInfo?.patient && <p>{`${studyInfo.patient?.firstName} ${studyInfo.patient?.lastName}${studyInfo.patient?.korName ? ` (${studyInfo.patient?.korName})` : ""}`}</p>}
      </SummaryTableLi>
      <SummaryTableLi printshow title={t(IK.treatment_option)}>
        {studyInfo.study && <p>{`${t(IK[studyInfo.study?.packages])}`}</p>}
      </SummaryTableLi>

      {/* 배송지 */}
      <SummaryTableLi title={t(IK.shipping_address)}>{studyInfo.patient && <p>{studyInfo.patient.shipAddressName}</p>}</SummaryTableLi>
      {/* 청구지 */}
      <SummaryTableLi title={t(IK.billing_address)}>{studyInfo.patient && <p>{studyInfo.patient.billAddressName}</p>}</SummaryTableLi>
      {/* 구외 사진 */}
      <SummaryTableLi title={t(IK.extraoral)} handleClickForUpdate={handleClickForUpdate} dataId="Extraoral">
        <S2.DropListWrap>
          <S2.DropList className="extraoral_front">
            <S2.DropItem>{studyInfo.extraoral_front && <Preview path={studyInfo.extraoral_front?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="front_rest">
            <S2.DropItem>{studyInfo.front_rest && <Preview path={studyInfo.front_rest?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="front_smile">
            <S2.DropItem>{studyInfo.front_smile && <Preview path={studyInfo.front_smile?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="side45">
            <S2.DropItem>{studyInfo.side45 && <Preview path={studyInfo.side45?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="side90">
            <S2.DropItem>{studyInfo.side90 && <Preview path={studyInfo.side90?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="side90_smile">
            <S2.DropItem>{studyInfo.side90_smile && <Preview path={studyInfo.side90_smile?.distFileName} />}</S2.DropItem>
          </S2.DropList>
        </S2.DropListWrap>
      </SummaryTableLi>
      {/* 구내 사진 */}
      <SummaryTableLi title={t(IK.intraoral)} handleClickForUpdate={handleClickForUpdate} dataId="Intraoral">
        <S2.DropListWrap>
          <S2.DropList className="occlusal_upper">
            <S2.DropItem>{studyInfo.occlusal_upper && <Preview path={studyInfo.occlusal_upper?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="intraoral_front">
            <S2.DropItem>{studyInfo.intraoral_front && <Preview path={studyInfo.intraoral_front?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="occlusal_lower">
            <S2.DropItem>{studyInfo.occlusal_lower && <Preview path={studyInfo.occlusal_lower?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="buccal_right">
            <S2.DropItem>{studyInfo.buccal_right && <Preview path={studyInfo.buccal_right?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="overjet">
            <S2.DropItem>{studyInfo.overjet && <Preview path={studyInfo.overjet?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="buccal_left">
            <S2.DropItem>{studyInfo.buccal_left && <Preview path={studyInfo.buccal_left?.distFileName} />}</S2.DropItem>
          </S2.DropList>
        </S2.DropListWrap>
      </SummaryTableLi>
      {/* 방사선 사진 */}
      <SummaryTableLi title={t(IK.radiograph)} handleClickForUpdate={handleClickForUpdate} dataId="Radiation">
        <S2.DropListWrap $radiation>
          <S2.DropList className="panorama">
            <S2.DropItem $radiation>{studyInfo.panorama && <Preview path={studyInfo.panorama?.distFileName} />}</S2.DropItem>
          </S2.DropList>
          <S2.DropList className="cephalo">
            <S2.DropItem $radiation>{studyInfo.cephalo && <Preview path={studyInfo.cephalo?.distFileName} />}</S2.DropItem>
          </S2.DropList>
        </S2.DropListWrap>
      </SummaryTableLi>
      {/* 스캔파일 */}
      <SummaryTableLi title={t(IK.scan_file_registration)} handleClickForUpdate={handleClickForUpdate} dataId="Scan">
        {studyInfo?.scan?.length > 0 ? (
          <S4.ButtonFlex>
            {studyInfo.scan.map((item, index) => {
              return (
                <S4.StyledSmallButton as="button" key={index} onClick={() => downloadFile(item.fileId, item.orgFileName)}>
                  {item.orgFileName}
                </S4.StyledSmallButton>
              );
            })}
          </S4.ButtonFlex>
        ) : (
          <p>
            {/** 석고모델 하위옵션이 있을때만 표출 */}
            {t(IK.send_model)}
            {studyInfo?.SIPlasterModel && `: ${getSummaryLabel(studyInfo, "SIPlasterModel", t)}`} {studyInfo?.SIPhoneNumber && `(${studyInfo?.SIPhoneNumber})`}
          </p>
        )}
      </SummaryTableLi>
      {/* 치료해야 할 악궁 */}
      {config.DentalArchTreated && (
        <SummaryTableLi title={t(IK.dental_arch_treated)} handleClickForUpdate={handleClickForUpdate} dataId="DentalArchTreated">
          <p>
            {getSummaryLabel(studyInfo, "SI01", t)}
            {studyInfo.SI01 === "2" && !!studyInfo.SI01_2_01 && `(${getSummaryLabel(studyInfo, "SI01_2_01", t)})`}
            {studyInfo.SI01 === "3" && !!studyInfo.SI01_3_01 && `(${getSummaryLabel(studyInfo, "SI01_3_01", t)})`}
          </p>
        </SummaryTableLi>
      )}
      {/* 발치 할 치아 */}
      {config.Extraction && (
        <SummaryTableLi title={t(IK.extraction)} handleClickForUpdate={handleClickForUpdate} dataId="Extraction">
          {studyInfo.SI12 === "1" && <p>{t(IK.extraction_item1)}</p>}
          {studyInfo.SI12 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} config={config?.Extraction?.extraction_item2} code="SI12" />}
        </SummaryTableLi>
      )}
      {/* 이동에 제한이 있는 치아 */}
      {config.TeethLimitedMobility && (
        <SummaryTableLi title={t(IK.teeth_limited_mobility)} handleClickForUpdate={handleClickForUpdate} dataId="TeethLimitedMobility">
          {studyInfo.SI02 === "1" && <p>{t(IK.teeth_limited_mobility_item1)}</p>}
          {studyInfo.SI02 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} config={config?.TeethLimitedMobility?.teeth_limited_mobility_item2_teeth} code="SI02" />}
        </SummaryTableLi>
      )}
      {/* 어태치먼트 부여를 원치 않는 치아 */}
      {config.Attachment && (
        <SummaryTableLi title={t(IK.attachment)} handleClickForUpdate={handleClickForUpdate} dataId="Attachment">
          {studyInfo.SI03 === "1" && <p>{t(IK.attachment_item1)}</p>}
          {studyInfo.SI03 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} config={config?.Attachment?.attachment_item2_teeth} code="SI03" />}
        </SummaryTableLi>
      )}

      {/* 어태치먼트 AP*/}
      {config.ApAttachment && (
        <SummaryTableLi title={t(IK.ap_attachment)} handleClickForUpdate={handleClickForUpdate} dataId="ApAttachment">
          <p>{getSummaryLabel(studyInfo, "SI16", t)}</p>
        </SummaryTableLi>
      )}

      {/* 악궁 확장 */}
      {config.DentalArchExtension && (
        <SummaryTableLi title={t(IK.dental_arch_extension)} handleClickForUpdate={handleClickForUpdate} dataId="DentalArchExtension">
          <div className="frm_column">
            {studyInfo.SI04_01 && (
              <p>
                {t(IK.maxilla)} : {getSummaryLabel(studyInfo, "SI04_01", t)}
                {studyInfo?.SI04_01 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_01_01} mm`}
              </p>
            )}
            {studyInfo.SI04_02 && (
              <p>
                {t(IK.mandible)} : {getSummaryLabel(studyInfo, "SI04_02", t)}
                {studyInfo?.SI04_02 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_02_01} mm`}
              </p>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 전후방관계(A-P) */}
      {config.AP && (
        <SummaryTableLi title={t(IK.ap_relation)} handleClickForUpdate={handleClickForUpdate} dataId="AP">
          <div className="frm_column">
            {studyInfo?.SI05_01 && (
              <p>
                {`${t(IK.ap_item1_title)} : ${getSummaryLabel(studyInfo, "SI05_01", t)}`}
                {studyInfo["SI05_01"] === "2" && `(${studyInfo["SI05_01_2_01"]}mm)`}
                {studyInfo["SI05_01"] === "3" && `(${studyInfo["SI05_01_3_01"]}mm)`}
              </p>
            )}
            {studyInfo["SI05_05"] === "1" ? (
              <p>{t(IK.ap_item2_title)}</p>
            ) : (
              <div>
                {studyInfo["SI05_02"] && <p>{getSummaryLabel(studyInfo, "SI05_02", t)}</p>}
                {studyInfo["SI05_03"] && <p>{getSummaryLabel(studyInfo, "SI05_03", t)}</p>}
              </div>
            )}
            {studyInfo["SI05_04"] && (
              <div>
                <p>
                  {t(IK.teeth_move_option)} : {getSummaryLabel(studyInfo, "SI05_04", t)}
                </p>
              </div>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 수평피개(Overjet) */}
      {config.Overjet && (
        <SummaryTableLi title={t(IK.overjet)} handleClickForUpdate={handleClickForUpdate} dataId="Overjet">
          <p>
            {getSummaryLabel(studyInfo, "SI06", t)}
            {studyInfo.SI06 === "3" && `(${studyInfo.SI06_3_01}mm)`}
          </p>
        </SummaryTableLi>
      )}
      {/* 수직피개(Overbite) */}
      {config.Deepbite && (
        <SummaryTableLi title={t(IK.deepbite_title)} handleClickForUpdate={handleClickForUpdate} dataId="Deepbite">
          <div className="frm_column">
            {studyInfo?.SI07_01 && (
              <p>
                {t(IK.deepbite_item1)} : {getSummaryLabel(studyInfo, "SI07_01", t)}
                {studyInfo.SI07_01 === "2" && `(${studyInfo.SI07_01_2_01}mm)`}
                {studyInfo.SI07_01 === "3" && `(${studyInfo.SI07_01_3_01}mm)`}
              </p>
            )}
            {studyInfo.SI07_02 && (
              <p>
                {t(IK.overbite_item)} : {getSummaryLabel(studyInfo, "SI07_02", t)}
                {studyInfo.SI07_02 === "3" && `(${studyInfo.SI07_02_3_01}mm)`}
              </p>
            )}
            {studyInfo.SI07_03 && (
              <p>
                {t(IK.deepbite_item3)}
                {studyInfo["SI07_03"] < 5 ? (
                  <>
                    {`: ${getSummaryLabel(studyInfo, "SI07_03", t)}`}
                    {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("1") && `(${t(IK.maxilla)})`}
                    {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("2") && `(${t(IK.mandible)})`}
                  </>
                ) : studyInfo?.SI07_03_5_01 ? (
                  `: ${studyInfo.SI07_03_5_01}(${getSummaryLabel(studyInfo, "SI07_03", t)})`
                ) : (
                  ""
                )}
              </p>
            )}
            {studyInfo.SI07_04 && (
              <p>
                {t(IK.deepbite_item4)}
                {studyInfo["SI07_04"] < 5 ? (
                  <>
                    {`: ${getSummaryLabel(studyInfo, "SI07_04", t)}`}
                    {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("1") && `(${t(IK.maxilla)})`}
                    {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("2") && `(${t(IK.mandible)})`}
                  </>
                ) : studyInfo?.SI07_04_5_01 ? (
                  `: ${studyInfo.SI07_04_5_01}(${getSummaryLabel(studyInfo, "SI07_04", t)})`
                ) : (
                  ""
                )}
              </p>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 교합이개장치(Bite Ramps) */}
      {/**교합이개를 처방전 작성시에 표출안하면 요약상세에도 없도록 분기 */}
      {config.Biteramp && studyInfo?.SI10 && (
        <SummaryTableLi title={t(IK.biteramp)} handleClickForUpdate={handleClickForUpdate} dataId="BiteRamp">
          <p>
            {getSummaryLabel(studyInfo, "SI10", t)}
            {studyInfo["SI10"] === "2" && `(${getSummaryLabel(studyInfo, "SI10_2_01", t)}${studyInfo["SI10_2_01"] === "1" ? " : " + getSummaryLabel(studyInfo, "SI10_2_01_1_01", t) : ""})`}
          </p>
        </SummaryTableLi>
      )}
      {/* 정중선(Midline) */}
      {config.Midline && (
        <SummaryTableLi title={t(IK.midline)} handleClickForUpdate={handleClickForUpdate} dataId="Midline">
          <div className="frm_column">
            <p>
              {getSummaryLabel(studyInfo, "SI08", t)} {studyInfo.SI08_3_02 ? `: ${getSummaryLabel(studyInfo, "SI08_3_02", t)}` : ""}
            </p>
            {studyInfo["SI08"] === "3" && studyInfo["SI08_3_02"] === "3" && (
              <>
                <p> {studyInfo["SI08_3_01"].split(",").includes("1") && `- ${t(IK.maxilla)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_1_01"]}mm)`}</p>
                <p>{studyInfo["SI08_3_01"].split(",").includes("2") && `- ${t(IK.maxilla)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_2_01"]}mm)`}</p>
                <p> {studyInfo["SI08_3_01"].split(",").includes("3") && `- ${t(IK.mandible)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_3_01"]}mm)`}</p>
                <p>{studyInfo["SI08_3_01"].split(",").includes("4") && `- ${t(IK.mandible)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_4_01"]}mm)`}</p>
              </>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* Spacing */}
      {config.Spacing && (
        <SummaryTableLi title="Spacing" handleClickForUpdate={handleClickForUpdate} dataId="Spacing">
          <p>{getSummaryLabel(studyInfo, "SI09_01", t)}</p>
        </SummaryTableLi>
      )}
      {/* Crowding */}
      {config.Crowding && (
        <SummaryTableLi title="Crowding" handleClickForUpdate={handleClickForUpdate} dataId="Crowding">
          <div className="card_flex">
            {(studyInfo?.SI01 === "1" || studyInfo?.SI01 === "2") && (
              <div className="col_wd_pre frm_column">
                <FormControlsBox title={`${t(IK.maxilla)} ${t(IK.crowding_item1_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_01", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item2_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_02", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item3_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_03", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item4_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_04", t)}</div>
                  </div>
                </FormControlsBox>
              </div>
            )}
            {(studyInfo?.SI01 === "1" || studyInfo?.SI01 === "3") && (
              <div className="col_wd_pre frm_column">
                <FormControlsBox title={`${t(IK.mandible)} ${t(IK.crowding_item5_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_01", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item6_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_02", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item7_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_03", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item8_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_04", t)}</div>
                  </div>
                </FormControlsBox>
              </div>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 구치부 반대교합(Crossbite) */}
      {config.Crossbite && (
        <SummaryTableLi title={t(IK.posterior_crossbite)} handleClickForUpdate={handleClickForUpdate} dataId="Crossbite">
          <p>{getSummaryLabel(studyInfo, "SI11", t)}</p>
        </SummaryTableLi>
      )}
      {/* 영구치예측 */}
      {config.PermanentTooth && (
        <SummaryTableLi title={t(IK.prmanent_tooth)} handleClickForUpdate={handleClickForUpdate} dataId="PermanentTooth">
          {studyInfo["SI14_01"] === "2" ? (
            <div className="frm_column">
              <TeethSummaryBox label="permanent_predict" studyInfo={studyInfo} config={config?.PermanentTooth.permanent_tooth_item1_title?.permanent_tooth_item1_2} code="SI14_01" />
              <p>
                {t(IK.prmanent_tooth_item2_title)}
                {!!studyInfo["SI14_03"] ? `: ${studyInfo["SI14_03"] + t(IK.level)}` : `: ${t(IK.not_applicable)}`}
              </p>
            </div>
          ) : (
            <p>{t(IK.not_applicable)}</p>
          )}
        </SummaryTableLi>
      )}
      {/* 특별지시사항 */}
      {config.Instructions && (
        <SummaryTableLi title={t(IK.special_instructions)} handleClickForUpdate={handleClickForUpdate} dataId="Instructions">
          <S3.TextDiv $textpreline>{studyInfo.SI13}</S3.TextDiv>
        </SummaryTableLi>
      )}
      {/* Every10 (치아이동촉진기) */}
      {config.Every10 && (
        <SummaryTableLi title={t(IK.every10)} handleClickForUpdate={handleClickForUpdate} dataId="Every10">
          <p>{getSummaryLabel(studyInfo, "SI15", t)}</p>
        </SummaryTableLi>
      )}
    </S.SummaryList>
  );
};
