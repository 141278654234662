import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRememberPage } from "../../Hooks/useRememberPage";
import { useGetPreferredPlanList } from "../../apis/preferredTreatmentApi";
import { CardInnerLayout, CardTail } from "../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { EmptyCard } from "../../components/element/EmptyCard/EmptyCard";
import Paging from "../../components/element/Paging/Paging";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../utils/i18n/keyStore";
import { getArchLabel, seraphinOption } from "../../utils/studyUtils";
import * as S from "../Medit/style/Medit.style";

const PreferStudyPlan = () => {
  document.getElementById("wrapper").style.minWidth = "630px";
  const { t } = useTranslation(["translation"]);
  const { studyId } = useParams();
  const navigate = useNavigate();

  /**페이지 설정*/
  const [currentPage, setCurrentPage] = useRememberPage();

  /**선호 치료계획 리스트 가져오기 */
  const {
    data: studyPlans,
    isLoading,
    isError,
  } = useGetPreferredPlanList({
    studyId,
    size: 10,
    page: currentPage - 1,
  });

  const studyPlanList = studyPlans?.data.content;

  /**사용자 언어설정과 동일하게  */
  useEffect(() => {
    const langInfo = localStorage.getItem("langInfo");
    i18next.changeLanguage(langInfo);
  }, []);

  /**페이지 변경 */
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  if (isLoading || isError) {
    return <LoadingIndicator />; // 로딩 진행
  }

  return (
    <S.MeditBox>
      <CardInnerLayout>
        <S.MeditList>
          {studyPlanList.length > 0 ? (
            studyPlanList.map((plan) => (
              <li key={plan.studyPlanId}>
                <button
                  type="button"
                  onClick={() =>
                    navigate(`/study-plan/${studyId}/${plan.studyPlanId}`, {
                      state: { page: currentPage },
                    })
                  }
                >
                  <dl>
                    <dt>{t(IK.name)}</dt>
                    <dd>{plan.name}</dd>
                  </dl>
                  <dl>
                    <dt>{t(IK.package)}</dt>
                    <dd>
                      {seraphinOption[plan.packages]} ({getArchLabel(plan.arch, t)})
                    </dd>
                  </dl>
                  <dl>
                    <dt>{t(IK.creation_date)}</dt>
                    <dd>{plan.createdDate}</dd>
                  </dl>
                </button>
              </li>
            ))
          ) : (
            <EmptyCard />
          )}
        </S.MeditList>

        <CardTail line>
          <Paging totalCount={studyPlans?.data.totalElements} currentPage={currentPage} size={10} handleChangePage={handleChangePage} />
          <S2.StyledButton as="button" type="button" $primary onClick={() => window.close()}>
            {t(IK.close)}
          </S2.StyledButton>
        </CardTail>
      </CardInnerLayout>
    </S.MeditBox>
  );
};

export default PreferStudyPlan;
