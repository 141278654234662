import { styled } from "styled-components";

export const PaginationContainer = styled.div`
  .pagination {
    display: flex;
    gap: 8px;
  }

  .pg_page a {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.gray300};
    font-size: 13px;
    cursor: pointer;
  }

  .pg_page:hover a {
    border-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.blue400};
  }

  .pg_page.disabled a {
    cursor: auto;
  }

  .pg_page.disabled:hover a {
    border-color: ${(props) => props.theme.colors.gray200};
    color: ${(props) => props.theme.colors.black};
  }

  .pg_current a {
    border-color: ${(props) => props.theme.colors.blue400};
    background-color: ${(props) => props.theme.colors.blue400};
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
  }

  .pg_current:hover a {
    background-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.white} !important;
  }

  .pg_prev {
    margin-right: 10px;
  }

  .pg_next {
    margin-left: 10px;
  }
`;
