import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S2 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/Language.style";

export const LanguageButton = ({ langInfo, setLangInfo }) => {
  const { t } = useTranslation(["translation"]);

  const [LanguageModalOpen, setLanguageModalOpen] = useState(false);
  const toggleLanguageModal = () => setLanguageModalOpen((prev) => !prev);

  const onChangeLang = (lang) => {
    const newLang = lang.toLowerCase();
    setLangInfo(newLang);
    localStorage.setItem("langInfo", newLang);
    toggleLanguageModal();
  };

  return (
    <S.LanguageWrap>
      <S.LanguageBtn type="button" onClick={toggleLanguageModal}>
        <S2.CountBadge>{LangCode[langInfo?.toUpperCase()]}</S2.CountBadge>
        {t(IK[`language_${langInfo}`])}
      </S.LanguageBtn>
      {LanguageModalOpen && <LanguagePopup $LanguageModalOpen={LanguageModalOpen} onChangeLang={onChangeLang} />}
    </S.LanguageWrap>
  );
};

export const LanguagePopup = ({ onChangeLang }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <S.LanguagePopup>
      {langList?.map((item, index) => (
        <S.LanguageBtn type="button" key={index} onClick={() => onChangeLang(item.langInfo)}>
          <S2.CountBadge>{LangCode[item.langInfo]}</S2.CountBadge>
          {t(IK[item.langTxt])}
        </S.LanguageBtn>
      ))}
    </S.LanguagePopup>
  );
};

export const langList = [
  {
    langInfo: "KO",
    langTxt: "language_ko",
  },
  {
    langInfo: "EN",
    langTxt: "language_en",
  },
  {
    langInfo: "VI",
    langTxt: "language_vi",
  },
];

export const LangCode = {
  KO: "KR",
  EN: "US",
  VI: "VN",
};
