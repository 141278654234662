import Notiflix from "notiflix";
import { IK } from "./i18n/keyStore";

export const eachUploader = (event, setFieldValue, fileName, t) => {
  const file = event.target.files[0];
  const maxSizeInBytes = 500 * 1024 * 1024; // 500MB

  if (file.size > maxSizeInBytes) {
    Notiflix.Report.failure(t(IK.limit_size), t(IK.compress_file), t(IK.confirm));
    event.target.value = null; // 파일 선택을 취소하기 위해 input의 값을 초기화
    return;
  } else {
    setFieldValue(fileName, file);
  }
};
