import axios from "axios";

/**OTT 코드 전송*/
export const postAdminOtt = async (ott) => {
  const response = await axios.post(`/api/auth/admin-login`, { ott });
  return response;
};

/**세라핀 ott 코드 전송 */
export const postSeraquickOtt = async (ott) => {
  const response = await axios.post(`/api/auth/sera-quick-login`, { ott });
  return response;
};
