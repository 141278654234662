import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonFlex } from "../../../../components/element/Button/style/ButtonLayout.style";

// 정보 수정 버튼
export const ProfileEditBtn = styled(Link)`
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  box-shadow: 0px 0 40px rgb(76 87 125 / 2%);
  &:hover {
    background-color: ${(props) => props.theme.colors.blue400};
    border-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ProfileEditBox = styled.dt`
  display: flex;
  align-items: baseline;
  gap: 8px;
  ${ProfileEditBtn} {
    flex: 0 0 30px;
  }
`;

// 재주문, 추가교정장치, 대체교정장치 버튼
export const ProfileBtnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  a {
    position: relative;
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      border-color: ${(props) => props.theme.colors.blue400};
      color: ${(props) => props.theme.colors.blue400};
      p {
        color: ${(props) => props.theme.colors.blue400};
      }
    }
    h3 {
      font-size: 15px;
    }
    p {
      margin-top: 5px;
      transition: all 0.3s;
      color: ${(props) => props.theme.colors.gray600};
    }
    i {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -18px;
      font-size: 24px;
    }
  }
`;

// 처방전 탭
export const ProfileHistoryTab = styled.div`
  margin-bottom: 15px;
  .swiper {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 10px;
    .swiper-slide {
      width: auto;
      padding: 17px 25px;
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
      background-color: ${(props) => props.theme.colors.white};
      cursor: pointer;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 1px solid ${(props) => props.theme.colors.gray200};
      }
      &.active {
        background-color: ${(props) => props.theme.colors.blue400};
        color: ${(props) => props.theme.colors.white};
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 16px;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

// 처방전 내역 팝업
export const ProfileHistoryModalList = styled.ul`
  li {
    position: relative;
    display: flex;
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 28px;
      left: 11px;
      width: 2px;
      height: calc(100% - 33px);
      background-color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

export const ProfileHistoryCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-right: 15px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.blue400};
  &:before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.blue400};
  }
`;

export const ProfileHistoryModalItem = styled.div`
  flex: 1;
`;

export const ProfileDepth1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-size: 15px;
  }
  span {
    display: block;
    font-size: 15px;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const ProfileDepth2 = styled.div`
  padding: 15px;
  margin-top: 15px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.gray300};
  ${ProfileHistoryModalList} li:before {
    background-color: ${(props) => props.theme.colors.gray400};
  }
`;

// 처방전 이력 프로그래스
export const ProfileHistoryList = styled.ul`
  display: flex;
`;

export const ProfileHistoryItem = styled.li`
  flex: 1;
`;

export const ProfileHistoryStep = styled.div`
  position: relative;
  margin-bottom: 17px;
  &:before {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 1px;
    right: -1px;
    height: 7px;
    //첫번째 블록에서 무조건  true로 반영
    display: ${(props) => (props.$hidebar ? "none" : "block")};
    background-color: ${(props) => (props.$complete ? props.theme.colors.blue400 : props.theme.colors.gray400)};
  }
  div {
    h3 {
      margin-bottom: 7px;
      font-size: 18px;
    }
    span {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      border: 4px solid ${(props) => (props.$complete ? props.theme.colors.blue400 : props.$progress ? props.theme.colors.blue400 : props.theme.colors.gray400)};
      background-color: ${(props) => (props.$complete ? props.theme.colors.blue400 : props.theme.colors.white)};
      border-radius: 50%;
    }
  }
`;

export const ProfileHistoryBox = styled.div`
  padding-right: 15px;
`;

export const ProfileHistoryDesc = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  &:not(:last-child) {
    padding-bottom: 24px;
    &:before {
      content: "";
      position: absolute;
      top: 24px;
      left: 9px;
      width: 2px;
      height: calc(100% - 24px);
      background-color: ${(props) => props.theme.colors.gray200};
    }
  }
  dl {
    flex: 1;
    dt {
      font-size: 15px;
    }

    dd {
      margin-top: 5px;
      span {
        display: block;
        color: ${(props) => props.theme.colors.gray600};
      }
      ${ButtonFlex} {
        margin-top: 8px;
      }
    }
  }
`;

export const ProfileHistoryDescDot = styled.span`
  width: 16px;
  height: 16px;
  margin: 3px 0 0 2px;
  border-radius: 50%;
  background-color: ${(props) => (props.$complete ? props.theme.colors.blue400 : props.$progress ? props.theme.colors.blue400 : props.theme.colors.gray400)};
`;

export const ProfileHistoryDescBox = styled.div`
  margin-top: 8px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  h4 {
    margin-bottom: 5px;
  }
`;

// 재주문 목록
export const ProfilePrevOrderList = styled.div`
  margin-top: 20px;
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    max-height: 155px;
    overflow: auto;
    padding: 15px;
    background-color: ${(props) => props.theme.colors.gray300};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    li button {
      position: relative;
      padding-left: 10px;
      text-align: left;
      font-size: 15px;
      &:hover {
        color: ${(props) => props.theme.colors.blue400};
      }
      &:before {
        content: "";
        position: absolute;
        top: 9px;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: ${(props) => props.theme.colors.blue400};
        border-radius: 50%;
      }
    }
  }
`;
