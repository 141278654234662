import { useTranslation } from "react-i18next";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../utils/i18n/keyStore";
import { Previews } from "../../preview/Preview";
import * as S from "../../../../components/element/Drop/style/Drop.style";
import { CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";

const Radiation = ({ setFieldValue, getFieldProps, errors }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <CardTitle
        message={t(IK.image_required_message_1)}
        icon={<i className="ri-fullscreen-line"></i>}
      />

      <S.DropListWrap $radiation>
        <S.DropList className="required_img panorama">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="panorama"
            radiation
          />
          {errors.panorama && <FieldMessageError text={errors.panorama} />}
        </S.DropList>
        <S.DropList className="cephalo">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="cephalo"
            radiation
          />
          {errors.cephalo && <FieldMessageError text={errors.cephalo} />}
        </S.DropList>
      </S.DropListWrap>

      <div className="noti_box">
        <i className="ri-upload-cloud-fill"></i>
        {t(IK.file_drag_message)}
      </div>
    </>
  );
};

export default Radiation;
