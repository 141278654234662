import { Field } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkEmail, checkLicenseNumber, checkLoginId } from "../../apis/joinApi";
import { FieldMessageError, FieldMessageErrorClick, FieldMessageSuccess } from "../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import { IK } from "../../utils/i18n/keyStore";
import { TitleV1 } from "../../components/element/Title/TitleText";
import * as S from "../../components/element/Form/style/Form.style";
import * as S2 from "../../components/Layout/CardLayout/style/CardLayout.style";

const JoinTerms = ({ values, setFieldValue, setFieldError, errors }) => {
  const { t } = useTranslation(["translation"]);
  const [idCheckFlag, setIdCheckFlag] = useState(false);
  const [emailCheckFlag, setEmailCheckFlag] = useState(false);
  const [licenseFlag, setLicenseCheckFlag] = useState(false);

  /**면허증 중복 검사 */
  const handleOnClickLicense = (e) => {
    checkLicenseNumber({
      licenseNumber: values.license,
      countryCode: values.countryCode,
    })
      .then((response) => {
        setLicenseCheckFlag(true);
        setFieldValue("licenseCheck", true);
      })
      .catch((err) => {
        setFieldError("license", t(IK.duplicate_license));
      });
  };

  /**아이디 중복체크 */
  const handleClickLoginIdCheck = () => {
    if (values.loginId) {
      checkLoginId({ loginId: values.loginId })
        .then((response) => {
          setIdCheckFlag(true);
          setFieldValue("loginIdCheck", true);
        })
        .catch((err) => {
          setFieldError("loginIdCheck", t(IK.duplicate_id));
        });
    }
  };

  /**이메일 중복체크 */
  const handleClickEmailCheck = () => {
    if (values.email) {
      checkEmail({ email: values.email })
        .then((response) => {
          setEmailCheckFlag(true);
          setFieldValue("emailCheck", true);
        })
        .catch((err) => {
          setFieldError("emailCheck", t(IK.duplicate_email));
        });
    }
  };

  /**중복검사 후 다시 기입할 경우 감지 */
  const handleKeyDown = (event) => {
    setFieldValue(event.target.id + "Check", false);
    if (event.target.id === "loginId") {
      setIdCheckFlag(false);
    } else if (event.target.id === "license") {
      setLicenseCheckFlag(false);
    } else {
      setEmailCheckFlag(false);
    }
  };

  return (
    <>
      <TitleV1 title={t(IK.doctor_info_message)} />

      <S.FormControlsColumn $large>
        {/* 면허번호 */}
        <FormControlsBox required regular title={t(IK.license_number)}>
          <S.FormControlsFlex>
            <Field as={S.FormControls} $full type="text" id="license" name="license" maxLength="20" onKeyDown={handleKeyDown} />
            <S.FormControlsBtn as="label" type="button" onClick={handleOnClickLicense}>
              {t(IK.duplicate_btn)}
            </S.FormControlsBtn>
          </S.FormControlsFlex>
          <FieldMessageErrorClick name="license" />
          {errors.licenseCheck && <FieldMessageError text={errors.licenseCheck} />}
          {licenseFlag && <FieldMessageSuccess text={t(IK.available_license_message)} />}
        </FormControlsBox>

        {/* 이름(한글) */}
        {values.countryCode === "01" && (
          <FormControlsBox required regular title={t(IK.korea_name)}>
            <Field as={S.FormControls} $full type="text" name="koreaName" maxLength="10" />
            <FieldMessageErrorClick name="koreaName" />
          </FormControlsBox>
        )}

        {/* 이름(영문) */}
        <FormControlsBox required regular title={t(IK.english_name)}>
          <S.FormControlsFlex>
            <S2.CardColWd $full>
              <Field as={S.FormControls} $full type="text" name="firstName" placeholder={t(IK.first_name)} maxLength="20" />
              <FieldMessageErrorClick name="firstName" />
            </S2.CardColWd>
            <S2.CardColWd $full>
              <Field as={S.FormControls} $full type="text" name="lastName" placeholder={t(IK.last_name)} maxLength="20" />
              <FieldMessageErrorClick name="lastName" />
            </S2.CardColWd>
          </S.FormControlsFlex>
        </FormControlsBox>

        {/* 아이디 */}
        <FormControlsBox required regular title={t(IK.id)}>
          <S.FormControlsFlex>
            <Field as={S.FormControls} $full type="text" id="loginId" name="loginId" maxLength="15" onKeyDown={handleKeyDown} autoComplete="new-password" />
            <S.FormControlsBtn as="label" type="button" onClick={handleClickLoginIdCheck}>
              {t(IK.duplicate_btn)}
            </S.FormControlsBtn>
          </S.FormControlsFlex>
          <FieldMessageErrorClick name="loginId" />
          {errors.loginIdCheck && <FieldMessageError text={errors.loginIdCheck} />}
          {idCheckFlag && <FieldMessageSuccess text={t(IK.available_id_message)} />}
        </FormControlsBox>

        {/* 비밀번호 */}
        <FormControlsBox required regular title={t(IK.password)} example={`ex) serafin2021!`}>
          <Field as={S.FormControls} $full type="password" name="loginPw" maxLength="16" autoComplete="new-password" />
          <FieldMessageErrorClick name="loginPw" />
        </FormControlsBox>

        {/* 비밀번호 확인 */}
        <FormControlsBox required regular title={t(IK.password_check)}>
          <Field as={S.FormControls} $full type="password" name="reLoginPw" maxLength="16" autoComplete="new-password" />
          <FieldMessageErrorClick name="reLoginPw" />
        </FormControlsBox>

        {/* 연락처/핸드폰 */}
        <FormControlsBox required regular title={t(IK.contact)}>
          <Field as={S.FormControls} $full type="text" name="phone" maxLength="13" />
          <FieldMessageErrorClick name="phone" />
        </FormControlsBox>

        {/* 이메일 */}
        <FormControlsBox required regular title={t(IK.email)} example={values.countryCode === "01" ? t(IK.tax_bill_use_mail) : ""}>
          <S.FormControlsFlex>
            <Field as={S.FormControls} $full type="email" id="email" name="email" onKeyDown={handleKeyDown} />
            <S.FormControlsBtn as="label" type="button" onClick={handleClickEmailCheck}>
              {t(IK.duplicate_btn)}
            </S.FormControlsBtn>
          </S.FormControlsFlex>
          <FieldMessageErrorClick name="email" />
          {errors.emailCheck && <FieldMessageError text={errors.emailCheck} />}
          {emailCheckFlag && <FieldMessageSuccess text={t(IK.available_email_message)} />}
        </FormControlsBox>
      </S.FormControlsColumn>
    </>
  );
};

export default JoinTerms;
