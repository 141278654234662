import { useTranslation } from "react-i18next";
import * as S from "../../../components/element/Tab/style/Tab.style";
import { IK } from "../../../utils/i18n/keyStore";

const EventTabType = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);

  const tabList = [
    { name: t(IK.event_progress), isEnd: "N" },
    { name: t(IK.event_over), isEnd: "Y" },
  ];

  const handleOnClickTab = (isEnd) => {
    setSearchCondition((prev) => ({ ...prev, page: 1, endEvent: isEnd }));
  };

  return (
    <S.TabType1>
      {tabList.map((tab) => (
        <S.TabType1Item $active={searchCondition?.endEvent === tab.isEnd} key={tab.name} onClick={() => handleOnClickTab(tab.isEnd)}>
          <button type="button">{tab.name}</button>
        </S.TabType1Item>
      ))}
    </S.TabType1>
  );
};

export default EventTabType;
