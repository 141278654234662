import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useDoctorAuth } from "../../../Hooks/useDoctorAuth";
import { useReplacementSummary } from "../../../apis/replacementApi";
import {
  CardInnerLayout,
  CardTitle,
  CardTail,
} from "../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../utils/i18n/keyStore";
import ReplaceSummaryTable from "./ReplaceSummaryTable";

const ReplacementSummary = () => {
  const { patientId, replacementId } = useParams();

  /**본인 환자인지 판단 */
  const access = useDoctorAuth(patientId);

  const { t } = useTranslation(["translation"]);

  const { data: replacementData, isLoading: isReplaceLoading } =
    useReplacementSummary(replacementId, patientId);
  const replacementInfo = replacementData?.data;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!access) {
    return null;
    //권한 없을시 컴포넌트 렌더링 안함
  }

  return (
    <>
      {isReplaceLoading ? (
        <LoadingIndicator />
      ) : (
        <CardInnerLayout>
          <CardTitle
            title={
              <>
                {`${t(IK.alternative_correction_devices)} ${t(
                  IK.prescription_summary
                )}`}
                <span>{`${replacementInfo?.patientName} ${
                  replacementInfo?.korName
                    ? ` (${replacementInfo?.korName})`
                    : ""
                }`}</span>
              </>
            }
          />
          <S.PrintWrapper ref={componentRef}>
            <ReplaceSummaryTable replacementInfo={replacementInfo} />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton to={`/patient/${patientId}/profile`}>
                {t(IK.patient_profile)}
              </S2.StyledButton>
              <S2.StyledButton
                as="button"
                $primary
                type="button"
                onClick={handlePrint}
              >
                {t(IK.print)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      )}
    </>
  );
};

export default ReplacementSummary;
