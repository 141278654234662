import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../utils/i18n/keyStore";
import { handleOnClickMeditLink } from "../../../utils/meditUtils";
import * as S from "./style/ScanPreview.style";

export const ScanPreview = ({ files, setFieldValue, setDeleteStudyFileIds, meditFiles, setMeditFiles, fieldName = "scan" }) => {
  const { t } = useTranslation(["translation"]);
  const [scanFiles, setScanFiles] = useState(files ? files : []);

  useEffect(() => {
    if (files) {
      setScanFiles(files);
    }
  }, [files]);

  /**메딧 파일 받기 */ //윈도우 객체에 함수 추가
  window.getFileUuid = (meditScan, name) => {
    return new Promise((resolve, reject) => {
      if (meditFiles.some((file) => file.uuid[0] === meditScan[0].uuid)) {
        Notiflix.Notify.failure(t(IK.file_already_message));
        reject("파일이 이미 존재합니다");
      } else {
        const uuidArray = meditScan.map((file) => file.uuid);
        setMeditFiles((prev) => {
          const newFiles = [...prev, { name: name, uuid: uuidArray }];
          resolve("파일이 성공적으로 추가되었습니다");
          return newFiles;
        });
      }
    });
  };

  /**메딧스캔파일 지우기 */
  const handleMeditDelete = (id) => {
    const meditScan = meditFiles.filter((item) => item.uuid[0] !== id);
    setMeditFiles(meditScan);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/zip": [".zip"],
      "text/stl": [".stl"],
    },
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFieldValue(fieldName, scanFiles.concat(previewFiles));
      setScanFiles(scanFiles.concat(previewFiles));
    },
  });

  /**등록 스캔 파일 지우기 */
  const handleOnClickClose = (id, state) => {
    let filteredFiles = "";
    if (isNaN(id)) {
      //기존 파일인 경우
      filteredFiles = scanFiles.filter((file) => file.preview !== id);
    } else {
      filteredFiles = scanFiles.filter((file) => file[state + "FileId"] !== id);

      //처방전에서 사용
      if (setDeleteStudyFileIds) setDeleteStudyFileIds((prevIds) => [...prevIds, id]);
      //환자등록에서 온 세라퀵의 경우
      if (state === "seraQuick") {
        setFieldValue("seraQuickDeleteFileIds", id);
      }
    }
    setScanFiles(filteredFiles);
    setFieldValue(fieldName, filteredFiles);
  };

  return (
    <S.ScanBox>
      <S.ScanDrop {...getRootProps()}>
        <S.ScanIcon>
          <i className="ri-upload-cloud-2-line"></i>
        </S.ScanIcon>
        <h3>{t(IK.scan_file_message)}</h3>
        <p>{t(IK.zip_stl_file)}</p>
        <input {...getInputProps()} />

        <S2.ButtonCtBox>
          <S2.StyledButton as="button" $primary type="button" onClick={handleOnClickMeditLink}>
            {t(IK.medlink)}
          </S2.StyledButton>
          <S2.StyledButton as="button" $primary type="button">
            {t(IK.upload_file)}
          </S2.StyledButton>
        </S2.ButtonCtBox>
      </S.ScanDrop>
      <S.ScanList>
        <ul>
          {scanFiles.length > 0 &&
            scanFiles.map((files) => (
              <DropFilePreview
                key={files.preview || files.studyFileId || files.patientFileId || files.additionalFileId || files.seraQuickFileId}
                files={files}
                handleOnClickClose={handleOnClickClose}
              />
            ))}
          {meditFiles.length > 0 && meditFiles?.map((medit) => <MeditFilePreview key={medit.uuid[0]} medit={medit} handleMeditDelete={handleMeditDelete} />)}
        </ul>
      </S.ScanList>
    </S.ScanBox>
  );
};

export const MeditFilePreview = ({ medit, handleMeditDelete }) => {
  return (
    <li>
      <span>{medit.name}</span>
      <button type="button" onClick={() => handleMeditDelete(medit.uuid[0])}>
        <i className="ri-close-line" />
      </button>
    </li>
  );
};

export const DropFilePreview = ({ files, handleOnClickClose }) => {
  const id = files.preview || files.studyFileId || files.patientFileId || files.additionalFileId || files.seraQuickFileId;

  function determineFileState(files) {
    if (files.patientFileId) return "patient";
    if (files.additionalFileId) return "additional";
    if (files.seraQuickFileId) return "seraQuick";
    return "study";
  }

  return (
    <li>
      <span>{files.name || files.orgFileName}</span>
      <button type="button" onClick={() => handleOnClickClose(id, determineFileState(files))}>
        <i className="ri-close-line"></i>
      </button>
    </li>
  );
};
