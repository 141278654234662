import { css, styled } from "styled-components";

export const SelectionItemList = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: ${(props) => (props.$small ? "10px" : "13px 20px")};
  ${(props) =>
    props.$gridColumns3fr &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    `}
`;

export const SelectionItem = styled.div`
  ${(props) =>
    props.$Small &&
    css`
      ${SelectionItemInput} {
        top: 2px;
        width: 18px;
        height: 18px;
        &:before {
          top: 1px;
        }
      }
      ${SelectionItemSpan} {
        padding-left: 27px;
        font-size: 14px;
      }
    `}
`;

export const SelectionItemLabel = styled.label`
  position: relative;
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;

  ${(props) =>
    props.$btn &&
    css`
      padding: 7px 13px;
      background-color: ${(props) => props.theme.colors.gray300};
      border: 1px solid ${(props) => props.theme.colors.gray200};
      border-radius: 5px;
      color: ${(props) => props.theme.colors.gray600};
      &:has(input:checked) {
        background-color: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.blue400};
        color: ${(props) => props.theme.colors.blue400};
        font-weight: 600;
      }
    `}

  p {
    position: relative;
    margin-top: 3px;
    padding-left: 40px;
    color: ${(props) => props.theme.colors.gray600};
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 29px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.blue400};
    }
  }
`;

export const SelectionItemInput = styled.input`
  position: absolute;
  top: 1px;
  width: 19px;
  height: 19px;
  appearance: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  cursor: pointer;
  ${(props) =>
    props.$btn &&
    css`
      top: 1px;
      left: 1px;
      width: 1px;
      height: 1px;
      opacity: 0;
    `}

  ${(props) =>
    props.$checkbox &&
    css`
      border-radius: 3px;
      &:checked {
        background-color: ${(props) => props.theme.colors.blue400};
        border-color: ${(props) => props.theme.colors.blue400};
        &:before {
          color: ${(props) => props.theme.colors.white};
        }
      }
      &:before {
        content: "\\f00c";
        font-family: FontAwesome;
        font-size: 10px;
        color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        top: 1px;
        text-align: center;
      }
    `}

  ${(props) =>
    props.$radio &&
    css`
      border-radius: 50%;
      &:checked {
        border: 4px solid ${(props) => props.theme.colors.blue400};
      }
    `}
    &:checked + span {
    font-weight: 500;
    color: ${(props) => props.theme.colors.blue400};
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.gray200};
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
`;

export const SelectionItemSpan = styled.span`
  display: inline-block;
  padding-left: 29px;
  text-align: left;
  font-size: 15px;

  //임상환경 디폴트 값 표시
  ${(props) =>
    props.$default &&
    css`
      text-decoration: underline;
      font-style: italic;
    `}
`;
