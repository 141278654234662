import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as S from "../../../components/element/Drop/style/Drop.style";
import imageRotate from "../../../utils/imageRotate";

export function Previews({ setFieldValue, name, getFieldProps, radiation }) {
  const [files, setFiles] = useState(getFieldProps(name).value || getFieldProps(name).length ? [getFieldProps(name).value] : []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFieldValue(name, acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const handleOnClickClose = (e) => {
    setFiles([]);
    setFieldValue(name, "");
  };
  const generateHandler = (orientation) => (e) => {
    imageRotate(files, setFiles, orientation, setFieldValue, name);
  };

  const handleOnClickRefresh = generateHandler(6);
  const handleOnClickArrow1 = generateHandler(2);
  const handleOnClickArrow2 = generateHandler(4);
  const thumbs = files.map((file) => {
    return (
      <S.DropItemImg key={file.name}>
        <img src={URL.createObjectURL(file)} alt="thumb" />
        <S.DropItemControlBtn type="button" onClick={handleOnClickRefresh} $refresh>
          <i className="ri-refresh-line"></i>
        </S.DropItemControlBtn>
        <S.DropItemControlBtn type="button" onClick={handleOnClickArrow1} $arrowbtn1>
          <i className="ri-arrow-left-right-line"></i>
        </S.DropItemControlBtn>
        <S.DropItemControlBtn type="button" onClick={handleOnClickArrow2} $arrowbtn2>
          <i className="ri-arrow-up-down-line"></i>
        </S.DropItemControlBtn>
        <S.DropItemControlBtn type="button" onClick={handleOnClickClose} $close>
          <i className="ri-close-line"></i>
        </S.DropItemControlBtn>
      </S.DropItemImg>
    );
  });

  return (
    <S.DropItem $radiation={radiation}>
      <S.DropItemZone {...getRootProps()}>
        <input id={name} name={name} {...getInputProps()} />
      </S.DropItemZone>
      {thumbs}
    </S.DropItem>
  );
}
