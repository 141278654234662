import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "../element/Table/style/table.style";
import * as S2 from "../../styles/Common";
import { TitleV3 } from "../element/Title/TitleText";

function Terms() {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content1_title)} />
        <p>{t(IK.terms_of_use_content1_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content2_title)} />
        <p>{t(IK.terms_of_use_content2_txt1)}</p>
        <S2.ContentBox as="ol" $small>
          <li>{t(IK.terms_of_use_content2_txt2)}</li>
          <li>{t(IK.terms_of_use_content2_txt3)}</li>
          <li>{t(IK.terms_of_use_content2_txt4)}</li>
          <li>{t(IK.terms_of_use_content2_txt5)}</li>
          <li>{t(IK.terms_of_use_content2_txt6)}</li>
          <li>{t(IK.terms_of_use_content2_txt7)}</li>
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content3_title)} />
        <p>{t(IK.terms_of_use_content3_txt1)}</p>
        <ol>
          <li>{t(IK.terms_of_use_content3_txt2)}</li>
          <li>{t(IK.terms_of_use_content3_txt3)}</li>
          <li>{t(IK.terms_of_use_content3_txt4)}</li>
          <li>{t(IK.terms_of_use_content3_txt5)}</li>
          <li>{t(IK.terms_of_use_content3_txt6)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content4_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content4_txt1)}</li>
          <li>{t(IK.terms_of_use_content4_txt2)}</li>
          <li>{t(IK.terms_of_use_content4_txt3)}</li>
          <li>{t(IK.terms_of_use_content4_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content5_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content5_txt1)}</li>
          <li>
            {t(IK.terms_of_use_content5_txt2)}
            <ol>
              <li>{t(IK.terms_of_use_content5_txt2_1)}</li>
              <li>{t(IK.terms_of_use_content5_txt2_2)}</li>
              <li>{t(IK.terms_of_use_content5_txt2_3)}</li>
              <li>{t(IK.terms_of_use_content5_txt2_4)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content5_txt3)}</li>
          <li>{t(IK.terms_of_use_content5_txt4)}</li>
          <li>{t(IK.terms_of_use_content5_txt5)}</li>
          <li>{t(IK.terms_of_use_content5_txt6)}</li>
          <li>{t(IK.terms_of_use_content5_txt7)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content6_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content6_txt1)}</li>
          <li>
            {t(IK.terms_of_use_content6_txt2)}
            <ol>
              <li>{t(IK.terms_of_use_content6_txt2_1)}</li>
              <li>{t(IK.terms_of_use_content6_txt2_2)}</li>
              <li>{t(IK.terms_of_use_content6_txt2_3)}</li>
              <li>{t(IK.terms_of_use_content6_txt2_4)}</li>
              <li>{t(IK.terms_of_use_content6_txt2_5)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content6_txt3)}</li>
          <li>{t(IK.terms_of_use_content6_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content7_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content7_txt1)}</li>
          <li>{t(IK.terms_of_use_content7_txt2)}</li>
          <li>{t(IK.terms_of_use_content7_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content8_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content8_txt1)}</li>
          <li>{t(IK.terms_of_use_content8_txt2)}</li>
          <li>{t(IK.terms_of_use_content8_txt3)}</li>
          <li>{t(IK.terms_of_use_content8_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content9_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content9_txt1)}</li>
          <li>{t(IK.terms_of_use_content9_txt2)}</li>
          <li>{t(IK.terms_of_use_content9_txt3)}</li>
          <li>{t(IK.terms_of_use_content9_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content10_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content10_txt1)}</li>
          <li>{t(IK.terms_of_use_content10_txt2)}</li>
          <li>{t(IK.terms_of_use_content10_txt3)}</li>
          <li>{t(IK.terms_of_use_content10_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content11_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content11_txt1)}</li>
          <li>{t(IK.terms_of_use_content11_txt2)}</li>
          <li>{t(IK.terms_of_use_content11_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content12_title)} />
        <ol>
          <li>
            {t(IK.terms_of_use_content12_txt1)}
            <ol>
              <li>{t(IK.terms_of_use_content12_txt1_1)}</li>
              <li>{t(IK.terms_of_use_content12_txt1_2)}</li>
              <li>{t(IK.terms_of_use_content12_txt1_3)}</li>
              <li>{t(IK.terms_of_use_content12_txt1_4)}</li>
              <li>{t(IK.terms_of_use_content12_txt1_5)}</li>
              <li>{t(IK.terms_of_use_content12_txt1_6)}</li>
              <li>{t(IK.terms_of_use_content12_txt1_7)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content12_txt2)}</li>
          <li>
            {t(IK.terms_of_use_content12_txt3)}
            <ol>
              <li>{t(IK.terms_of_use_content12_txt3_1)}</li>
              <li>{t(IK.terms_of_use_content12_txt3_2)}</li>
              <li>{t(IK.terms_of_use_content12_txt3_3)}</li>
              <li>{t(IK.terms_of_use_content12_txt3_4)}</li>
              <li>{t(IK.terms_of_use_content12_txt3_5)}</li>
              <li>{t(IK.terms_of_use_content12_txt3_6)}</li>
              <li>{t(IK.terms_of_use_content12_txt3_7)}</li>
            </ol>
          </li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content13_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content13_txt1)}</li>
          <li>{t(IK.terms_of_use_content13_txt2)}</li>
          <li>{t(IK.terms_of_use_content13_txt3)}</li>
          <li>{t(IK.terms_of_use_content13_txt4)}</li>
          <li>{t(IK.terms_of_use_content13_txt5)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content14_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content14_txt1)}</li>
          <li>{t(IK.terms_of_use_content14_txt2)}</li>
          <li>{t(IK.terms_of_use_content14_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_big_title1)} />
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content15_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content15_txt1)}</li>
          <li>{t(IK.terms_of_use_content15_txt2)}</li>
          <li>{t(IK.terms_of_use_content15_txt3)}</li>
          <li>{t(IK.terms_of_use_content15_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content16_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content16_txt1)}</li>
          <li>{t(IK.terms_of_use_content16_txt2)}</li>
          <li>{t(IK.terms_of_use_content16_txt3)}</li>
          <li>{t(IK.terms_of_use_content16_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content17_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content17_txt1)}</li>
          <li>{t(IK.terms_of_use_content17_txt2)}</li>
          <li>{t(IK.terms_of_use_content17_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content18_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content18_txt1)}</li>
          <li>{t(IK.terms_of_use_content18_txt2)}</li>
          <li>{t(IK.terms_of_use_content18_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_big_title2)} />
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content19_title)} />
        <p>{t(IK.terms_of_use_content19_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content20_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content20_txt1)}</li>
          <li>{t(IK.terms_of_use_content20_txt2)}</li>
          <li>{t(IK.terms_of_use_content20_txt3)}</li>
          <li>{t(IK.terms_of_use_content20_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content21_title)} />
        <ol>
          <li>
            {t(IK.terms_of_use_content21_txt1)}
            <ol>
              <li>{t(IK.terms_of_use_content21_txt1_1)}</li>
              <li>{t(IK.terms_of_use_content21_txt1_2)}</li>
              <li>{t(IK.terms_of_use_content21_txt1_3)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content21_txt2)}</li>
          <li>{t(IK.terms_of_use_content21_txt3)}</li>
          <li>{t(IK.terms_of_use_content21_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content22_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content22_txt1)}</li>
          <li>{t(IK.terms_of_use_content22_txt2)}</li>
          <li>{t(IK.terms_of_use_content22_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content23_title)} />
        <ol>
          <li>
            {t(IK.terms_of_use_content23_txt1)}
            <ol>
              <li>{t(IK.terms_of_use_content23_txt1_1)}</li>
              <li>{t(IK.terms_of_use_content23_txt1_2)}</li>
              <li>{t(IK.terms_of_use_content23_txt1_3)}</li>
              <li>{t(IK.terms_of_use_content23_txt1_4)}</li>
              <li>{t(IK.terms_of_use_content23_txt1_5)}</li>
              <li>{t(IK.terms_of_use_content23_txt1_6)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content23_txt2)}</li>
          <li>{t(IK.terms_of_use_content23_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content24_title)} />
        <ol>
          <li>
            {t(IK.terms_of_use_content24_txt1)}
            <ol>
              <li>{t(IK.terms_of_use_content24_txt1_1)}</li>
              <li>{t(IK.terms_of_use_content24_txt1_2)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content24_txt2)}</li>
          <li>{t(IK.terms_of_use_content24_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content25_title)} />
        <p>{t(IK.terms_of_use_content25_txt1)}</p>
        <S2.ContentBox as="ol" $small>
          <li>{t(IK.terms_of_use_content25_txt2)}</li>
          <li>{t(IK.terms_of_use_content25_txt3)}</li>
          <li>{t(IK.terms_of_use_content25_txt4)}</li>
          <li>{t(IK.terms_of_use_content25_txt5)}</li>
          <li>{t(IK.terms_of_use_content25_txt6)}</li>
          <li>{t(IK.terms_of_use_content25_txt7)}</li>
          <li>{t(IK.terms_of_use_content25_txt8)}</li>
          <li>{t(IK.terms_of_use_content25_txt9)}</li>
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content26_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content26_txt1)}</li>
          <li>{t(IK.terms_of_use_content26_txt2)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content27_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content27_txt1)}</li>
          <li>{t(IK.terms_of_use_content27_txt2)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content28_title)} />
        <p>{t(IK.terms_of_use_content28_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content29_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content29_txt1)}</li>
          <li>{t(IK.terms_of_use_content29_txt2)}</li>
          <li>{t(IK.terms_of_use_content29_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content30_title)} />
        <ol>
          <li>
            <p>{t(IK.terms_of_use_content30_txt1)}</p>
            <S2.ContentBox as="div" $small>
              <S.TableType1>
                <caption>
                  제30조(청약철회 등의 효과) ㅣ 취소시점, 지급 비용으로 구성
                </caption>
                <thead>
                  <tr>
                    <th scope="col">{t(IK.terms_of_use_content30_txt1_th1)}</th>
                    <th scope="col">{t(IK.terms_of_use_content30_txt1_th2)}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t(IK.terms_of_use_content30_txt1_td1)}</td>
                    <td>{t(IK.terms_of_use_content30_txt1_td2)}</td>
                  </tr>

                  <tr>
                    <td>{t(IK.terms_of_use_content30_txt1_td3)}</td>
                    <td>{t(IK.terms_of_use_content30_txt1_td4)}</td>
                  </tr>

                  <tr>
                    <td>{t(IK.terms_of_use_content30_txt1_td5)}</td>
                    <td>{t(IK.terms_of_use_content30_txt1_td6)}</td>
                  </tr>
                </tbody>
              </S.TableType1>
            </S2.ContentBox>
          </li>
          <S2.ContentBox as="li" $small>
            {t(IK.terms_of_use_content30_txt2)}
          </S2.ContentBox>
          <li>{t(IK.terms_of_use_content30_txt3)}</li>
          <li>{t(IK.terms_of_use_content30_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content31_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content31_txt1)}</li>
          <li>{t(IK.terms_of_use_content31_txt2)}</li>
          <li>{t(IK.terms_of_use_content31_txt3)}</li>
          <li>{t(IK.terms_of_use_content31_txt4)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_big_title3)} />
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content32_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content32_txt1)}</li>
          <li>{t(IK.terms_of_use_content32_txt2)}</li>
          <li>{t(IK.terms_of_use_content32_txt3)}</li>
          <li>{t(IK.terms_of_use_content32_txt4)}</li>
          <li>
            {t(IK.terms_of_use_content32_txt5)}
            <ol>
              <li>{t(IK.terms_of_use_content32_txt5_1)}</li>
              <li>{t(IK.terms_of_use_content32_txt5_2)}</li>
            </ol>
          </li>
          <li>{t(IK.terms_of_use_content32_txt6)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content33_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content33_txt1)}</li>
          <li>{t(IK.terms_of_use_content33_txt2)}</li>
          <li>{t(IK.terms_of_use_content33_txt3)}</li>
          <li>{t(IK.terms_of_use_content33_txt4)}</li>
          <li>{t(IK.terms_of_use_content33_txt5)}</li>
          <li>{t(IK.terms_of_use_content33_txt6)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content34_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content34_txt1)}</li>
          <li>{t(IK.terms_of_use_content34_txt2)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content35_title)} />
        <ol>
          <li>{t(IK.terms_of_use_content35_txt1)}</li>
          <li>{t(IK.terms_of_use_content35_txt2)}</li>
          <li>{t(IK.terms_of_use_content35_txt3)}</li>
          <li>{t(IK.terms_of_use_content35_txt4)}</li>
          <li>{t(IK.terms_of_use_content35_txt5)}</li>
          <li>{t(IK.terms_of_use_content35_txt6)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content36_title)} />
        <p>{t(IK.terms_of_use_content36_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content37_title)} />
        <p>{t(IK.terms_of_use_content37_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content38_title)} />
        <p>{t(IK.terms_of_use_content38_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.terms_of_use_content39_title)} />
        <p>{t(IK.terms_of_use_content39_txt1)}</p>
      </S2.ContentBox>
    </>
  );
}

export default Terms;
