import * as S from "./style/Title.style";

export const TitleV1 = ({ title, titleSub }) => {
  return (
    <S.TitleV1>
      <h2>{title}</h2>
      {titleSub && <p>{titleSub}</p>}
    </S.TitleV1>
  );
};

export const TitleV3 = ({ title, titleSub, required, children }) => {
  return (
    <S.TitleV3 $required={required}>
      <h2>{title}</h2>
      {titleSub && <span>{titleSub}</span>}
      {children}
    </S.TitleV3>
  );
};

export const TitleV4 = ({ title }) => {
  return (
    <S.TitleV4>
      <h2>{title}</h2>
    </S.TitleV4>
  );
};
