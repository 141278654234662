import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSaveAdditionalFileStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";
import { Previews } from "../Preview/Preview";

function Extraoral({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, setCurrentStepIndex, additionalInfo, setAdditionalInfo, isFinish, additionalId, studyId, patientId }) {
  const { t } = useTranslation(["translation"]);

  const additionalStudyFileMutation = useSaveAdditionalFileStep();

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  /**삭제할 파일 아이디 */
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  const handleSubmit = (values) => {
    //빈 값 제거
    const data = deleteEmptyKey(values);

    const formData = new FormData();

    let formDataLength = 0;
    Object.entries(data).forEach(([key, value]) => {
      if (!value.additionalFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      //서버 전송시 로딩
      Notiflix.Loading.standard("");

      //삭제할 아이디 추가
      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteAdditionalFileIds", deleteStudyFileIds.join(","));
      }
      //패키지 코드 추가
      formData.append("studyPackagesCode", additionalInfo.packages);

      additionalStudyFileMutation.mutate(
        {
          step: "extraoral",
          formData,
          params: {
            studyId,
            patientId,
            additionalId,
          },
        },
        {
          onSuccess: (data) => {
            const resData = {};
            data.data.forEach((item) => {
              resData[item.position] = item;
            });
            setDeleteStudyFileIds([]);
            setAdditionalInfo({ ...additionalInfo, ...values, ...resData });
            Notiflix.Loading.remove();
            navigateNext();
          },
          onError: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(t(IK.again_message));
          },
        }
      );
    } else {
      navigateNext();
    }
  };

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(currentStepList.length - 1);
    } else {
      next();
    }
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />

      <Formik
        initialValues={{
          extraoral_front: additionalInfo.extraoral_front || undefined,
          front_rest: additionalInfo.front_rest || undefined,
          front_smile: additionalInfo.front_smile || undefined,
          side45: additionalInfo.side45 || undefined,
          side90: additionalInfo.side90 || undefined,
          side90_smile: additionalInfo.side90_smile || undefined,
        }}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S.DropListWrap>
              <S.DropList className="extraoral_front">
                <Previews
                  setFieldValue={setFieldValue}
                  files={additionalInfo["extraoral_front"]}
                  name="extraoral_front"
                  setDeleteStudyFileIds={setDeleteStudyFileIds}
                  deleteStudyFileIds={deleteStudyFileIds}
                />
                <FieldMessageErrorClick name="extraoral_front" />
              </S.DropList>
              <S.DropList className="front_rest">
                <Previews setFieldValue={setFieldValue} files={additionalInfo["front_rest"]} name="front_rest" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="front_rest" />
              </S.DropList>
              <S.DropList className="front_smile">
                <Previews
                  setFieldValue={setFieldValue}
                  files={additionalInfo["front_smile"]}
                  name="front_smile"
                  setDeleteStudyFileIds={setDeleteStudyFileIds}
                  deleteStudyFileIds={deleteStudyFileIds}
                />
                <FieldMessageErrorClick name="front_smile" />
              </S.DropList>
              <S.DropList className="side45">
                <Previews setFieldValue={setFieldValue} files={additionalInfo["side45"]} name="side45" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="side45" />
              </S.DropList>
              <S.DropList className="side90">
                <Previews setFieldValue={setFieldValue} files={additionalInfo["side90"]} name="side90" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="side90" />
              </S.DropList>
              <S.DropList className="side90_smile">
                <Previews
                  setFieldValue={setFieldValue}
                  files={additionalInfo["side90_smile"]}
                  name="side90_smile"
                  setDeleteStudyFileIds={setDeleteStudyFileIds}
                  deleteStudyFileIds={deleteStudyFileIds}
                />
                <FieldMessageErrorClick name="side90_smile" />
              </S.DropList>
            </S.DropListWrap>

            <div className="noti_box">
              <i className="ri-upload-cloud-fill"></i>
              {t(IK.file_drag_message)}
            </div>

            <CardTail error={t(IK.image_required_message_2)} line>
              <S2.ButtonLtBox>
                <S2.StyledButton as="button" type="button" onClick={() => prev()}>
                  {t(IK.prev)}
                </S2.StyledButton>
                <S2.StyledButton as="button" $primary type="submit">
                  {t(IK.next)}
                </S2.StyledButton>
                {isFinish && (
                  <S2.StyledButton as="button" $primary type="submit" onClick={() => setSkip(true)}>
                    {t(IK.Skip_to_prescription_summary)}
                  </S2.StyledButton>
                )}
              </S2.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Extraoral;
