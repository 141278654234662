import { useRecoilState } from "recoil";
import { meditScan } from "../../../../atoms/meditAtom";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import { ScanPreview } from "../../../../components/element/ScanPreview/ScanPreview";

const Scan = ({ getFieldProps, setFieldValue, errors }) => {
  const [meditFiles, setMeditFiles] = useRecoilState(meditScan);
  return (
    <>
      <ScanPreview
        setFieldValue={setFieldValue}
        files={getFieldProps("scan").value}
        meditFiles={meditFiles}
        setMeditFiles={setMeditFiles}
      />
      {errors.scan && <FieldMessageError text={errors.scan} />}
    </>
  );
};

export default Scan;
