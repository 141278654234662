import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FormControlsStudyBox } from "../../../../components/element/Form/FormLayout";
import { IK } from "../../../../utils/i18n/keyStore";
import * as S from "../../../../components/element/Form/style/Form.style";

const ReplaceInstructions = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <FormControlsStudyBox title={t(IK.special_instructions)}>
      <Field name="SPECIAL_INSTRUCTIONS">
        {({ field }) => (
          <S.FormControls as="textarea" $textarea $full {...field} />
        )}
      </Field>
    </FormControlsStudyBox>
  );
};

export default ReplaceInstructions;
