import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { IK } from "../../../utils/i18n/keyStore";
import * as S3 from "../../element/Button/style/ButtonLayout.style";
import { TimePicker } from "../../element/DatePicker/DatePicker";
import * as S2 from "../../element/Form/style/Form.style";
import * as S from "./style/AlarmPopup.style";

const AlarmFilter = ({ t, setAlarmParams, alarmParams, scrollRef, alarmCount }) => {
  /**알람 필터 */
  /**
   * VIP 알람 필터
   * REJECT_FILTER = "처방전 반려"
   * RECEIPT_FILTER = "처방전 접수"
   * REVIEW_FILTER = "세라뷰 검토 요청"
   * DESIGN_CONFIRM_FILTER = "세라뷰 승인"
   * DELIVERY_PROGRESS_FILTER = "장치 배송 중"
   * ORDER_EXPIRATION_DATE_FILTER = "주문만료일 1달 전"
   */
  const alarmfilter = [
    { text: t(IK.total), value: "", count: "allCount" },
    { text: t(IK.AL11), value: "REJECT_FILTER", count: "rejectFilter" },
    { text: t(IK.AL14), value: "RECEIPT_FILTER", count: "receiptFilter" },
    { text: t(IK.AL16), value: "REVIEW_FILTER", count: "reviewFilter" },
    { text: t(IK.AL17), value: "DESIGN_CONFIRM_FILTER", count: "designConfirmFilter" },
    { text: t(IK.AL19), value: "DELIVERY_PROGRESS_FILTER", count: "deliveryProgressFilter" },
    { text: t(IK.AL32), value: "ORDER_EXPIRATION_DATE_FILTER", count: "orderExpirationDateFilter" },
  ];

  /**검색버튼 클릭 */
  const handleAlarmSearch = (data) => {
    if (data.from > data.to) {
      //종료일이 시작일 보다 빠른 경우 경고
      return Notiflix.Report.failure(t(IK.event_date_invaild), t(IK.again_message), t(IK.confirm), () => {});
    }

    const filteredData = Object.fromEntries(Object.entries({ alarmFilterCode: alarmParams.alarmFilterCode, ...data }).filter(([key, value]) => value !== ""));
    setAlarmParams({ alarmCode: "VIP", ...filteredData });
  };

  /**검색 필터 설정 */
  const alramSearch = (value) => {
    /**필터버튼 클릭시 스크롤 상단으로 이동 */
    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    setAlarmParams((prev) => ({ ...prev, alarmFilterCode: value }));
  };

  /**검색 초기화 */
  const onReset = (resetForm) => {
    /**초기화 클릭시 스크롤 상단으로 이동 */
    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    setAlarmParams({
      alarmCode: "VIP",
      size: 20,
      alarmFilterCode: "",
    });
    resetForm({
      values: {
        from: "",
        to: "",
        patientName: "",
      },
    });
  };

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date, setFieldValue) => {
    if (date) {
      setFieldValue("from", dayjs(date).format("YYYY-MM-DD HH:mm"));
    } else {
      setFieldValue("from", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handleEndDate = (date, setFieldValue) => {
    if (date) {
      setFieldValue("to", dayjs(date).format("YYYY-MM-DD HH:mm"));
    } else {
      setFieldValue("to", "");
    }
  };

  return (
    <Formik
      initialValues={{
        from: "",
        to: "",
        patientName: "",
      }}
      onSubmit={handleAlarmSearch}
    >
      {({ values, setFieldValue, resetForm }) => (
        <>
          <Form>
            <S2.FormControlsColumn>
              <S2.FormControlsFlex>
                <TimePicker
                  handleDataChange={(date) => handleStartDate(date, setFieldValue)}
                  placeholderText={t(IK.from_time)}
                  selected={values?.from && new Date(values?.from)}
                  formTimeControls
                  full
                />
                <TimePicker handleDataChange={(date) => handleEndDate(date, setFieldValue)} placeholderText={t(IK.to_time)} selected={values?.to && new Date(values?.to)} formTimeControls full />
              </S2.FormControlsFlex>
              <S2.FormControlsFlex>
                <Field as={S2.FormControls} $full type="text" name="patientName" placeholder={t(IK.patient_name)} maxLength="30" />
                <S3.StyledButton type="submit" as="button" $primary>
                  <i className="ri-search-2-line"></i> {t(IK.search)}
                </S3.StyledButton>
                <S3.StyledButton type="button" as="button" onClick={() => onReset(resetForm)}>
                  <i className="ri-refresh-line"></i> {t(IK.reset)}
                </S3.StyledButton>
              </S2.FormControlsFlex>
            </S2.FormControlsColumn>
          </Form>

          <S.AlarmPopTab>
            {alarmfilter.map((item) => (
              <li key={item.value}>
                <S.AlarmPopTabBtn $active={item.value === alarmParams.alarmFilterCode} onClick={() => alramSearch(item.value)}>
                  {item.text} {<strong>({alarmCount ? alarmCount[item.count] : "0"})</strong>}
                </S.AlarmPopTabBtn>
              </li>
            ))}
          </S.AlarmPopTab>
        </>
      )}
    </Formik>
  );
};

export default AlarmFilter;
