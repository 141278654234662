import { useEffect, useState } from "react";

function useLocalStorageState(key, defaultValue) {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
    return () => {
      localStorage.removeItem(key);
    };
  }, [key, state]);

  return [state, setState];
}

export default useLocalStorageState;
