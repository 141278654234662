import styled from "styled-components";

export const JoinTerms = styled.div`
  padding: 25px;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 10px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    li .frm_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .checkbox {
        flex: 1;
        padding-right: 40px;
      }
    }
    li a {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.blue400};
    }
  }
`;
