import { useTranslation } from "react-i18next";
import { SkeletonBoardTable } from "../../../../components/element/BoardList/SkeletonBoardTable";
import * as S from "../../../../components/element/BoardList/style/BoardList.style";
import { IK } from "../../../../utils/i18n/keyStore";
import AddressManageTr from "./Tr";

function AddressManageTable({ addressData, setAddressModalOpen, setEditAddressData, isLoading, defaultAddressData }) {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      {isLoading ? (
        <SkeletonBoardTable />
      ) : (
        <S.BoardTable>
          <thead>
            <tr>
              <th scope="col">{t(IK.address_classification)}</th>
              <th scope="col">{t(IK.clinic_name)}</th>
              <th scope="col">{t(IK.postcode)}</th>
              <th scope="col">{t(IK.clinic_address)}</th>
              <th scope="col">
                {t(IK.modify)}/{t(IK.delete)}
              </th>
            </tr>
          </thead>
          <tbody>
            {addressData.map((data) => (
              <AddressManageTr
                key={data.addressId}
                data={data}
                setEditAddressData={setEditAddressData}
                setAddressModalOpen={setAddressModalOpen}
                defaultAddressData={defaultAddressData}
              />
            ))}
          </tbody>
        </S.BoardTable>
      )}
    </>
  );
}

export default AddressManageTable;
