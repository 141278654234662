import { useTranslation } from "react-i18next";
import { FormConfirm } from "../../../../components/element/Form/FormLayout";
import { IK } from "../../../../utils/i18n/keyStore";

function ReplaceConfirm() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <FormConfirm title={t(IK.alternative_correction_devices_confirm_txt1)}>
        <p>
          {t(IK.alternative_correction_devices_confirm_txt2)}
          <br />
          {t(IK.alternative_correction_devices_confirm_txt3)}
        </p>
      </FormConfirm>
    </>
  );
}

export default ReplaceConfirm;
