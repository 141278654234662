import { Link } from "react-router-dom";
import LogoType1Image from "../../../assets/images/common/logo.png";
import LogoType2Image from "../../../assets/images/common/logo2.png";
import * as S from "./style/Logo.style";

export const LogoType1 = () => {
  return (
    <S.Logo>
      <Link to="/">
        <img src={LogoType1Image} alt="SERAFIN" />
      </Link>
    </S.Logo>
  );
};

const handleOnClickClearSearchCondition = (e) => {
  const resetElem = document.getElementById("serachConditionResetBtn");
  if (resetElem) {
    resetElem.click();
  }
};

export const LogoType2 = () => {
  return (
    <S.Logo>
      <Link to="/patient/list" onClick={handleOnClickClearSearchCondition}>
        <img src={LogoType2Image} alt="SERAFIN" />
      </Link>
    </S.Logo>
  );
};
