import cx from "classnames";
import { useTranslation } from "react-i18next";
import * as S from "../../components/element/Step/style/Step.style";
import { IK } from "../../utils/i18n/keyStore";

const Steps = ({ currentStepIndex, values }) => {
  const { t } = useTranslation(["translation"]);

  /**국가코드 선택후 약관동의 처리 여부 판단을 위해 국가코드는 스텝에서 예외처리(ui적으로만) */ // t(IK.country_code),
  const stepsList = [t(IK.agree_form_title), t(IK.clinic_form_title), t(IK.doctor_form_title), t(IK.etc_form_title), t(IK.waiting_for_approval)];
  const startIndex = values.countryCode === "01" ? 0 : 1;

  if (currentStepIndex <= 0) return null;

  return (
    <S.StepType1>
      <ol>
        {stepsList.slice(startIndex).map((step, idx) => {
          const isActive = currentStepIndex >= idx + startIndex + 1;

          return (
            <li key={idx} className={cx("", isActive && "active")}>
              <span>{idx + 1}</span>
              <h3>{step}</h3>
            </li>
          );
        })}
      </ol>
    </S.StepType1>
  );
};

export default Steps;
