import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import { useGetPaymentDetail } from "../../../apis/paymentApi";
import Modal from "../../../components/Modal/Modal";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import * as S2 from "../../../styles/Common";
import * as S3 from "../../../components/element/Table/style/table.style";
import LoadingSpinner from "../../../components/loading/LoadingSpinner/LoadingSpinner";
import { formatCurrency, payMethodStatus, paymentStatus } from "../../../utils/paymentUtils";
import { TitleV3 } from "../../../components/element/Title/TitleText";

const PaymentDetailModal = ({ paymentDetailModal, closePaymentDetailModal }) => {
  const { data, isLoading } = useGetPaymentDetail(paymentDetailModal.paymentId);
  const { t } = useTranslation(["translation"]);

  return (
    <Modal open={paymentDetailModal.isOpen} close={closePaymentDetailModal} header={`${t(IK.detail_billing)} (#${paymentDetailModal.paymentId || 0})`}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {data?.map((bill) => (
            <S2.ContentLine key={bill.paymentId}>
              <TitleV3 title={t(IK.history_billing)} />
              <S.BoardTable>
                <colgroup>
                  <col width="150px"></col>
                  <col width="150px"></col>
                  <col width="150px"></col>
                  <col width="150px"></col>
                  <col width="150px"></col>
                  <col width="150px"></col>
                  <col width="150px"></col>
                  <col width="*"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">{t(IK.pay_products)}</th>
                    <th scope="col">{t(IK.patient_name)} </th>
                    <th scope="col">{`${t(IK.pay_products)} ${t(IK.pay_amount)}`}</th>
                    <th scope="col">{`${t(IK.discount)} ${t(IK.pay_amount)}`}</th>
                    <th scope="col">{`${t(IK.discount)} ${t(IK.coupon)} ${t(IK.pay_amount)}`}</th>
                    <th scope="col">{t(IK.pay_charged)}</th>
                    <th scope="col">
                      {t(IK.create_date)} ({t(IK.delivery_date)})
                    </th>
                    <th scope="col">{t(IK.pay_notes)}</th>
                  </tr>
                </thead>
                <tbody>
                  {bill.paymentDetailDTOList.map((item) => (
                    <tr key={item.paymentDetailId}>
                      <td>{t(IK[item.packages])}</td>
                      <td>
                        {`${item.patientFirstName} ${item.patientLastName}`} {item?.patientKoreaName && `(${item.patientKoreaName})`}
                      </td>
                      <td>
                        {formatCurrency(item.salePrice)}
                        {t(IK.krw)}
                      </td>
                      <td>
                        <S2.TextDiv $textColorRed600>
                          -{formatCurrency(item.discountPrice)}
                          {t(IK.krw)}
                        </S2.TextDiv>
                      </td>
                      <td>
                        <S2.TextDiv $textColorRed600>
                          -{formatCurrency(item.couponAmount)}
                          {t(IK.krw)}
                        </S2.TextDiv>
                      </td>
                      <td>
                        {formatCurrency(item.salePrice - (item.discountPrice + item.couponAmount))}
                        {t(IK.krw)}
                      </td>
                      <td>{item.startDate || "-"}</td>
                      <td style={{ whiteSpace: "pre-line" }}>{item.memo}</td>
                    </tr>
                  ))}
                </tbody>
              </S.BoardTable>

              <S2.ContentBox $large>
                <TitleV3 title={t(IK.info_billing)} />
                <S3.TableType2>
                  <colgroup>
                    <col width="200px"></col>
                    <col width="*"></col>
                    <col width="200px"></col>
                    <col width="*"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">{t(IK.payment_status)}</th>
                      <td>{paymentStatus[bill?.paymentStatus]}</td>
                      <th scope="row">{t(IK.date_billing)}</th>
                      <td>{bill?.paidAt}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.method_billing)}</th>
                      <td colSpan={bill.payMethod === "vbank" ? 1 : 3}>
                        {t(payMethodStatus[bill?.payMethod || "prepaid"])} {bill?.escrow && `(${t(IK.ascrow)})`}
                      </td>
                      {bill.payMethod === "vbank" && (
                        <>
                          <th scope="row">{t(IK.deposit_account)}</th>
                          <td>
                            {bill?.vbankName} {bill?.vbankNum}
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      {bill.payMethod === "vbank" && (
                        <>
                          <th scope="row">{t(IK.deposit_deadline)}</th>
                          <td colSpan={3}>{bill?.vBankDate}</td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </S3.TableType2>
              </S2.ContentBox>

              <S2.ContentBox $large>
                <TitleV3 title={t(IK.price_billing)} />
                <S3.TableType2>
                  <colgroup>
                    <col width="200px"></col>
                    <col width="*"></col>
                    <col width="200px"></col>
                    <col width="*"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">{t(IK.total_price)}</th>
                      <td>
                        {formatCurrency(bill?.paymentTotalAmount)}
                        {t(IK.krw)}
                      </td>
                      <th scope="row">{t(IK.total_discount)}</th>
                      <td>
                        <S2.TextDiv $textColorRed600>
                          -{formatCurrency(bill?.discountAmount)}
                          {t(IK.krw)}
                        </S2.TextDiv>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.total_coupon)}</th>
                      <td colSpan={3}>
                        <S2.TextDiv $textColorRed600>
                          -{formatCurrency(bill?.couponAmount)}
                          {t(IK.krw)}
                        </S2.TextDiv>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.final_price)}</th>
                      <td colSpan={3}>
                        <S2.TextDiv $textColorBlue400 $textSize24 $textBold800>
                          {formatCurrency(bill?.payAmount)}
                          {t(IK.krw)}
                        </S2.TextDiv>
                      </td>
                    </tr>
                  </tbody>
                </S3.TableType2>
              </S2.ContentBox>
            </S2.ContentLine>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default PaymentDetailModal;
