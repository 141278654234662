import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { useGetBoardList } from "../../../apis/boardApi";
import CommonImage from "../../../components/common/CommonImage";
import { BoardSkeleton } from "../../../components/element/BoardList/BoardSkeleton";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";
import { CardInnerLayout, CardTail } from "../../../components/Layout/CardLayout/CardLayout";
import { useSearchParam } from "../../../Hooks/useSearchParam";
import { changeBoardParam } from "../../../utils/searchUtils";

function LibraryList() {
  /**검색 조건 설정 */
  const { searchCondition, setSearchCondition } = useSearchParam({ page: 1, sort: "MARKETING" });

  /**리스트 사이즈 */
  const boardSize = 10;

  /**자료실 게시판 조회 */
  const { data, isLoading, isError } = useGetBoardList(changeBoardParam(searchCondition));
  const libraryList = data?.data;

  /**페이지 설정 */
  const handleChangePage = (page) => {
    setSearchCondition((prev) => ({ ...prev, page }));
  };

  return (
    <CardInnerLayout>
      {isLoading || isError ? (
        <BoardSkeleton />
      ) : (
        <>
          {libraryList.totalElements > 0 ? (
            <S.BoardCardList>
              {libraryList.content.map((item, index) => (
                <li key={index}>
                  <Link to={`/education/library-list/${item.boardId}`} state={{ page: searchCondition.page }}>
                    <dl>
                      <dt>
                        <CommonImage filePath={item?.distFileName} />
                      </dt>
                      <dd>
                        <h3>{item.title}</h3>
                        <span>{dayjs(item.createdDate).format("YYYY.MM.DD")}</span>
                      </dd>
                    </dl>
                  </Link>
                </li>
              ))}
            </S.BoardCardList>
          ) : (
            <EmptyCard />
          )}
          <CardTail line>
            <Paging totalCount={libraryList.totalElements} currentPage={searchCondition.page} size={boardSize} handleChangePage={handleChangePage} />
          </CardTail>
        </>
      )}
    </CardInnerLayout>
  );
}

export default LibraryList;
