import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 환자 목록 탭 카운트 조회 */
export const useGetPatientListTapCount = () => {
  return useQuery(["TapCount"], async () => {
    const { data } = await axios.get(`/api/patient/list/tab-count`);
    return data;
  });
};

/** 환자 목록 조회 */
export const useGetPatientList = (dataToSubmit) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PatientList", dataToSubmit],
    async () => {
      const { data } = await axios.get("/api/patient/list", { params: dataToSubmit });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 환자 목록 아이콘 즐겨찾기 기능 */
export const registCliassification = async (patientId, code) => {
  return await axios.post(`/api/patient/${patientId}/classification?code=${code}`);
};

/** 환자 등록 */
export const createPatient = async (patient) => {
  return await axios.post("/api/patient", patient, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/** 환자 삭제 */
export const deletePatient = async (patientId) => {
  return await axios.delete(`/api/patient/${patientId}`);
};

/** 환자의 스터디 버튼 접근권한을 조회 */
export const getStudyPermission = async (patientId) => {
  return await axios.get(`/api/patient/${patientId}/info/study-btn`);
};

/** 환자 수정,삭제 가능 여부 조회 */
export const checkDeleteModifyBtn = async (patientId) => {
  return await axios.get(`/api/patient/${patientId}/info/modify-button`);
};

/** 임상증례 여부 조회  */
export const getClinicCaseList = async (patientId) => {
  return await axios.get(`/api/patient/${patientId}/clinical-case`);
};

/** 환자 프로필 정보 조회 */
export const useGetPatientInfo = (patientId, studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PatientInfo", patientId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info`);
      return data;
    },
    {
      enabled: !studyId, //*처방전에서 스터디 있을때는 조회x
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 환자 보관함 등록  */
export const useRestorePatientFromLocker = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (patientId) => {
      await axios.put(`/api/patient/${patientId}/locker-out`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PatientInfo");
        Notiflix.Report.info(t(IK.info), t(IK.storagebox_message1));
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/** 환자 보관함 복원  */
export const useStorePatientInLocker = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (patientId) => {
      await axios.put(`/api/patient/${patientId}/locker`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PatientInfo");
        Notiflix.Report.info(t(IK.info), t(IK.storagebox_message2));
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/** 환자 정보 업데이트 */
export const useUpdatePatientProfile = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async ({ patientId, data }) => {
      await axios.put(`/api/patient/${patientId}/info`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PatientInfo");
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/** 환자 메모 업데이트 */
export const updateMemo = async (patientId, value) => {
  return await axios.put(`/api/patient/${patientId}/memo`, value);
};

/** 환자 정보제공 동의서 업데이트 */
export const useInsertAgreementFile = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  return useMutation(
    async ({ patientId, data }) => {
      await axios.post(`/api/patient/${patientId}/agreement`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PatientInfo");
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/**해당 환자의 처방전 내역을 조회 */
export const useGetPatientStudyList = (patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PatientStudyList", patientId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/study-list`);
      return data;
    },
    {
      enabled: !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰, 세라뷰 웹, 배송 링크 받아오기*/
export const getLink = async (patientId, type, statusId, studyId, designId) => {
  const queryParams = {
    type,
    statusId,
    studyId,
    ...(designId && { designId }),
  };

  return await axios.get(`/api/patient/${patientId}/link`, {
    params: {
      ...queryParams,
      web: "vip",
    },
  });
};

/**세라뷰 디자인 가져오기 */
export const useGetStudyDesignList = (patientId, studyId, statusId) => {
  return useQuery(
    ["StudyDesignList", patientId, studyId, statusId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/${studyId}/design-list?statusId=${statusId}`);
      return data;
    },
    {
      enabled: !!patientId && !!studyId && !!statusId,
    }
  );
};

/**환자 정보 수정 */
export const updatePatientInfo = async (patientId, data) => {
  return await axios.put(`/api/patient/${patientId}/information`, data);
};

/**환자 임상상태 수정 */
export const updatePatientCondition = async (patientId, data) => {
  return await axios.put(`/api/patient/${patientId}/condition`, data);
};

/**환자 구외사진 가져오기 */
export const useGetPatientExtraoral = (patientId) => {
  return useQuery(["GetPatientExtraoral", patientId], async () => {
    const { data } = await axios.get(`/api/patient/${patientId}/extraoral`);
    return data;
  });
};

/**환자 구외사진 수정 */
export const useUpdatePatientExtraoral = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ patientId, formData }) => {
      await axios.put(`/api/patient/${patientId}/extraoral`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetPatientExtraoral");
      },
    }
  );
};

/**환자 구내사진 가져오기 */
export const useGetPatientIntraoral = (patientId) => {
  return useQuery(["GetPatientIntraoral", patientId], async () => {
    const { data } = await axios.get(`/api/patient/${patientId}/intraoral`);
    return data;
  });
};

/**환자 구내사진 수정 */
export const useUpdatePatientIntraoral = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ patientId, formData }) => {
      await axios.put(`/api/patient/${patientId}/intraoral`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetPatientIntraoral");
      },
    }
  );
};

/**환자 방사선사진 가져오기 */
export const useGetPatientRadiograph = (patientId) => {
  return useQuery(["GetPatientRadiograph", patientId], async () => {
    const { data } = await axios.get(`/api/patient/${patientId}/radiograph`);
    return data;
  });
};

/**환자 구내사진 수정 */
export const useUpdatePatientRadiograph = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ patientId, formData }) => {
      await axios.put(`/api/patient/${patientId}/radiograph`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetPatientRadiograph");
      },
    }
  );
};

/** 환자 스캔파일 가져오기 */
export const useGetPatientScan = (patientId) => {
  return useQuery(["GetScan", patientId], async () => {
    const { data } = await axios.get(`/api/patient/${patientId}/scan`);
    return data;
  });
};

/** 환자 스캔파일 수정 */
export const useUpdatePatientScan = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ patientId, formData }) => {
      await axios.put(`/api/patient/${patientId}/scan`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetScan");
      },
    }
  );
};

/**환자 디폴트값 가져오기 */
export const getStudyDefaultData = async (studyId, patientId) => {
  return await axios.get(`/api/study/${studyId}/file-list?patientId=${patientId}`);
};
