import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 주소지 조회 */
export const useGetAddress = () => {
  const { t } = useTranslation();
  return useQuery(
    ["GetAddress"],
    async () => {
      const { data } = await axios.get(`/api/address/list`);
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 새로운 주소지 추가 */
export const useAddAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (value) => {
      await axios.post("/api/address", value);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetAddress");
      },
    }
  );
};

/** 주소지 삭제 */
export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      await axios.delete(`/api/address/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetAddress");
      },
    }
  );
};

/** 기존 주소지 수정 */
export const useEditAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ value, id }) => {
      await axios.put(`/api/address/${id}`, value);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetAddress");
      },
    }
  );
};

/**대표 주소 조회 */
export const useGetDefaultAddress = () => {
  return useQuery(["GetDefaultAddress"], async () => {
    const { data } = await axios.get(`/api/address/representative`);
    return data;
  });
};
