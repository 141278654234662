import styled from "styled-components";

// 임상증례 리스트
export const CaseList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  > li {
    > a {
      display: flex;
      flex-direction: column;
      min-height: 190px;
      border: 1px solid ${(props) => props.theme.colors.gray200};
      border-radius: 10px;
      overflow: hidden;
      dt {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 215px;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .keyword {
          position: absolute;
          top: 8px;
          right: 8px;
          padding: 5px 10px;
          border-radius: 5px;
          background-color: ${(props) => props.theme.colors.blue400};
          color: ${(props) => props.theme.colors.white};
        }
      }
      dd {
        padding: 20px;
        ul {
          display: flex;
          li {
            position: relative;
            padding-right: 13px;
            margin-right: 13px;
            font-size: 15px;
            &:last-child {
              padding-right: 0;
              margin-right: 0;
            }
            &:not(:last-child):after {
              content: "";
              position: absolute;
              top: 5px;
              right: -1px;
              width: 1px;
              height: 13px;
              background-color: ${(props) => props.theme.colors.gray400};
            }
          }
        }
      }
    }
  }
  > li:hover > a {
    border-color: ${(props) => props.theme.colors.blue400};
  }
`;

// 임상증례 상세
export const CaseHead = styled.div`
  padding: 30px;
  border-radius: 10px;
  border: 3px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.gray300};
`;

export const CaseHeadBox = styled.div`
  display: flex;
`;

export const CaseHeadImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 275px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const CaseInfo = styled.div`
  flex: 1;
  padding-right: 30px;
  h2 {
    margin-bottom: 20px;
    font-size: 28px;
  }
  dl {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    font-size: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    dt {
      flex: 0 0 115px;
      padding-right: 15px;
      font-weight: 600;
    }
    dd {
      flex: 1;
      p {
        position: relative;
        padding-left: 13px;
        margin-top: 5px;
        &:before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          width: 3px;
          height: 3px;
          background-color: ${(props) => props.theme.colors.black};
          border-radius: 50%;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`;

export const CaseTextList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  dl {
    padding: 20px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.gray300};
    dt {
      margin-bottom: 7px;
      font-size: 15px;
      color: ${(props) => props.theme.colors.blue400};
      font-weight: 700;
    }
    dd {
      p {
        position: relative;
        padding-left: 13px;
        margin-top: 5px;
        font-size: 15px;
        &:before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          width: 3px;
          height: 3px;
          background-color: ${(props) => props.theme.colors.black};
          border-radius: 50%;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`;

export const CaseImgList = styled.ul`
  display: flex;
  gap: 20px;
`;

export const CaseImgItem = styled.li`
  flex: 1;
  h3 {
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => (props.$initial ? props.theme.colors.black : props.$final ? props.theme.colors.blue400 : props.$progress ? props.theme.colors.green200 : props.theme.colors.white)};
    &.initial_title {
      background-color: ${(props) => props.theme.colors.black};
    }
    &.final_title {
      background-color: ${(props) => props.theme.colors.blue400};
    }
    &.progress_title {
      background-color: ${(props) => props.theme.colors.green200};
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const CaseImgItemBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 102px;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 4px solid ${(props) => props.theme.colors.blue400};
    opacity: ${(props) => (props.$active ? "1" : "0")};
    transition: all 0.3s;
  }
`;

export const CaseVideo = styled.div`
  video {
    width: 100%;
  }
`;

export const CaseTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const CaseTabBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  min-height: 65px;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  font-weight: ${(props) => (props.$active ? "600" : "400")};
  font-size: 15px;
  color: ${(props) => (props.$active ? props.theme.colors.blue400 : props.theme.colors.black)};
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 4px solid ${(props) => props.theme.colors.blue400};
    opacity: ${(props) => (props.$active ? "1" : "0")};
    transition: all 0.3s;
  }
  img {
    max-width: 150px;
  }
`;
