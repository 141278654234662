/* TODO 기본 이미지 변경 필요 */
import defaultImage from '../assets/images/common/no_profile.png';
/**
 * handleProfileImageError - 프로필 이미지 로딩 오류 처리 함수
 * @param {Event} e - 이미지 로딩 에러 이벤트 객체
 * @returns {void}
 */
export function handleProfileImageError(e){
  e.target.src = defaultImage; // 이미지 로딩에 실패한 경우 기본 이미지로 대체
}

