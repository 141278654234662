import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const DevelopeStudyPlan = ({ studyInfo, progress, lastDetail }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      {progress ? (
        <S.ProfileHistoryDesc>
          <S.ProfileHistoryDescDot $complete={lastDetail >= "15"} />
          <dl>
            <dt>{t(IK.develope_study_plan)}</dt>
            {studyInfo?.createdDate && lastDetail >= "15" && (
              <dd>
                <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>
              </dd>
            )}
          </dl>
        </S.ProfileHistoryDesc>
      ) : (
        <>
          <S.ProfileHistoryCircle />
          <S.ProfileHistoryModalItem>
            <S.ProfileDepth1>
              <h3>{t(IK.develope_study_plan)}</h3>
              {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
            </S.ProfileDepth1>
          </S.ProfileHistoryModalItem>
        </>
      )}
    </>
  );
};

export default DevelopeStudyPlan;
