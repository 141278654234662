import styled from "styled-components";

export const AdditionalBox = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.gray300};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  dl:not(:first-child) {
    margin-top: 25px;
  }
  dt {
    margin-bottom: 7px;
    font-size: 15px;
    font-weight: 600;
  }
  dd p {
    position: relative;
    padding-left: 10px;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.black};
    }
  }
`;
