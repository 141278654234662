import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteAlarm, useReadAlarm } from "../../../apis/alarmApi";
import { IK } from "../../../utils/i18n/keyStore";
import { EmptyCard } from "../../element/EmptyCard/EmptyCard";
import * as S from "./style/AlarmPopup.style";

const AlarmList = ({ alarmList, handleCloseAlarmPopup, scrollRef, viewRef }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  const deleteAlarmMutation = useDeleteAlarm();
  const readAlarmMutation = useReadAlarm();
  const [deletedAlarms, setDeletedAlarms] = useState([]);

  /**해당 알림으로 이동 */
  const handleOnClickAlarm = (data) => {
    const { alarmId, patientId, alarmCode } = data;
    readAlarmMutation.mutate(alarmId, {
      onSuccess: () => {
        handleCloseAlarmPopup();
        if (alarmCode === "AL31") return navigate("/account/billing-list");

        return navigate(`/patient/${patientId}/profile`);
      },
    });
  };

  /**알림 삭제 */
  const handleClickDelete = (alarmId) => {
    //화면에서 삭제와 동시에 알림 제거
    setDeletedAlarms((prevDeletedAlarms) => [...prevDeletedAlarms, alarmId]);
    deleteAlarmMutation.mutate(alarmId);
  };

  function renderNotificationsByDate(notifications) {
    const notificationsByDate = notifications.reduce((acc, notification) => {
      const createdDate = new Date(notification.createdDate);
      const dateKey = createdDate.toDateString();
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(notification);
      return acc;
    }, {});

    const renderedNotifications = Object.entries(notificationsByDate).map(([date, notifications]) => {
      const formattedDate = new Date(date).toLocaleString("ko-KR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });

      const notificationItems = notifications.map((notification) => {
        const createdTime = dayjs(notification.createdDate).format("A h:mm:ss");
        if (deletedAlarms.includes(notification.alarmId)) {
          return null;
        }

        return (
          <S.AlarmPopListItem key={notification.alarmId}>
            <S.AlarmPopListItemIco $active={notification.readYn === "Y"}>
              <i className="ri-notification-3-fill"></i>
            </S.AlarmPopListItemIco>

            <S.AlarmPopListItemBox>
              <S.AlarmPopListItemTxt onClick={() => handleOnClickAlarm(notification)}>
                <p>{createdTime}</p>
                {notification.alarmCode === "AL31" ? (
                  <p>{t(IK.AL31)}</p>
                ) : (
                  <>
                    <p>
                      <strong>{t(notification.alarmCode)}</strong>
                    </p>
                    <p>
                      {notification.patientName} {notification?.packages && `[${t(notification.packages)}]`}
                    </p>
                  </>
                )}
              </S.AlarmPopListItemTxt>

              <S.AlarmPopListItemControl>
                <S.AlarmPopListItemClose type="button" onClick={() => handleClickDelete(notification.alarmId)}>
                  <i className="ri-delete-bin-fill"></i>
                </S.AlarmPopListItemClose>
              </S.AlarmPopListItemControl>
            </S.AlarmPopListItemBox>
          </S.AlarmPopListItem>
        );
      });

      return (
        <dl key={date}>
          <dt>{formattedDate}</dt>
          <dd>
            <S.AlarmPopList>{notificationItems}</S.AlarmPopList>
          </dd>
        </dl>
      );
    });

    return (
      <S.AlarmPopBox ref={scrollRef}>
        {renderedNotifications}
        <S.AlarmScrollDetection ref={viewRef} />
      </S.AlarmPopBox>
    );
  }

  return <>{alarmList.length === 0 ? <EmptyCard /> : renderNotificationsByDate(alarmList)}</>;
};

export default AlarmList;
