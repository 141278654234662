import { styled, css } from "styled-components";

export const TitleV1 = styled.div`
  margin-bottom: 30px;
  h2 {
    line-height: 1;
    font-size: 24px;
  }
  p {
    margin-top: 13px;
    font-size: 15px;
  }
`;

export const TitleV3 = styled.div`
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.blue400};
  }
  h2 {
    display: inline-block;
    font-size: 18px;
  }
  ${(props) =>
    props.$required &&
    css`
      h2 {
        position: relative;
        &:after {
          content: "*";
          margin-left: 3px;
          color: rgb(233, 30, 99);
        }
      }
    `};
  span {
    display: inline-block;
    margin-left: 5px;
    color: ${(props) => props.theme.colors.gray600};
  }
  p {
    margin-top: 5px;
  }
  button {
    margin-left: 10px;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.blue400};
    font-size: 15px;
    font-weight: 600;
  }
`;

export const TitleV4 = styled.div`
  margin-bottom: 10px;
  h2 {
    font-size: 15px;
  }
`;

export const TitleIco = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
  i {
    font-size: 18px;
    color: ${(props) => props.theme.colors.blue400};
  }
  h2 {
    font-size: 18px;
  }
`;
