import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import { IK } from "../../../utils/i18n/keyStore";

const VideoPlayer = ({ distFileName }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return <>{!hasError ? <video src={distFileName} controls onError={handleError} controlsList="nodownload" /> : <VideoError />}</>;
};

const VideoError = () => {
  const { t } = useTranslation(["translation"]);
  return <EmptyCard text={t(IK.video_error_message)} />;
};

export default VideoPlayer;
