import { useEffect, useState } from "react";
import defaultImage from "../../assets/images/common/no_profile.png";

const CommonImage = ({ filePath }) => {
  const [imageSrc, setImageSrc] = useState(filePath);

  const handleError = () => {
    setImageSrc(defaultImage);
  };

  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);

  return <img src={imageSrc || defaultImage} onError={handleError} alt="common" />;
};

export default CommonImage;
