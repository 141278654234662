import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S4 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import * as S3 from "../../../../styles/Common";
import { IK } from "../../../../utils/i18n/keyStore";

const SearchFilter = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);

  /**성별 선택 */
  const handleOnChangeGender = (e) => {
    const checkboxes = Array.from(document.getElementsByName("genderArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      gender: values.join(";"),
      page: 1,
    }));
  };

  /**치료 옵션 선택 */
  const handleOnClickCheckBox = (e) => {
    const checkboxes = Array.from(document.getElementsByName("packagesArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      packages_list: values.join(";"),
      page: 1,
    }));
  };

  /**임상상태 선택 */
  const handleOnClickClinicalCondition = (e) => {
    const checkboxes = Array.from(document.getElementsByName("conditionsArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      conditions_list: values.join(";"),
      page: 1,
    }));
  };

  /**초기화*/
  const handleOnClickReset = () => {
    setSearchCondition({
      size: 8,
      page: 1,
    });
  };

  const packages = [
    // { value: "RP", label: t(IK.serafin) + " R Passive" },
    { value: "R", label: t(IK.serafin) + " R Active" },
    { value: "S10", label: t(IK.serafin) + " 10" },
    { value: "S20", label: t(IK.serafin) + " 20" },
    { value: "SR", label: t(IK.SR) },
    { value: "SAP", label: t(IK.SAP) },
    { value: "Si", label: t(IK.Si) },
    { value: "SiAP", label: t(IK.SiAP) },
  ];

  const conditions = [
    { value: "class1", label: "Class I" },
    { value: "class2", label: "Class II div 1" },
    { value: "class3", label: "Class II div 2" },
    { value: "class4", label: "Class III" },
    { value: "crowding", label: t(IK.crowding) },
    { value: "spacing", label: t(IK.Interdental_space) },
    { value: "narrowArch", label: t(IK.narrow_dental_arch) },
    { value: "deepBite", label: t(IK.deepbite) },
    { value: "openbiteVerticality", label: t(IK.open_bite_vertical) },
    { value: "openbiteOverjet", label: t(IK.open_bite_horizontal) },
    { value: "abteriorCrossbite", label: t(IK.anterior_crossbite) },
    { value: "posteriorCrossbite", label: t(IK.posterior_crossbite) },
    { value: "flaredTeeth", label: t(IK.prolapse_of_anterior_teeth) },
    { value: "unevenSmile", label: t(IK.smile_line_needs_improvement) },
    { value: "asymmetric", label: t(IK.asymmetry) },
    { value: "occlusalPlaneRight", label: t(IK.Inclined_occlusal_plane_right) },
    { value: "occlusalPlaneLeft", label: t(IK.Inclined_occlusal_plane_left) },
    { value: "misshapenTeeth", label: t(IK.missing) },
    { value: "occlusalPlane", label: t(IK.abnormally_shaped_teeth) },
    { value: "gingivalRecession", label: t(IK.gingival_recession) },
    { value: "scissorsBite", label: t(IK.scissors_bite) },
  ];

  const genderList = [
    { value: "male", label: t(IK.male) },
    { value: "female", label: t(IK.female) },
  ];

  /**치료옵션 아코디언 */
  const [isPackageOpen, setIsPackageOpen] = useState(true);
  /**치료옵션 열기/닫기 */
  const handleOnClickPackage = () => {
    setIsPackageOpen((prev) => !prev);
  };

  /**임상상태 아코디언 */
  const [isConditionOpen, setIsConditionOpen] = useState(false);
  /**임상상태 열기/닫기 */
  const handleOnClickCondition = () => {
    setIsConditionOpen((prev) => !prev);
  };

  return (
    <>
      <S3.FilterAccordionList>
        {/* 성별 */}
        <S3.FilterAccordionItem>
          <S3.FilterAccordionItemTitle $cursornone>
            <strong>{t(IK.gender)}</strong>
          </S3.FilterAccordionItemTitle>
          <dd>
            <S4.SelectionItemList>
              {genderList.map((genders) => (
                <S4.SelectionItem key={genders.value}>
                  <S4.SelectionItemLabel>
                    <S4.SelectionItemInput
                      $checkbox
                      type="checkbox"
                      name="genderArray"
                      onChange={handleOnChangeGender}
                      value={genders.value}
                      checked={searchCondition?.gender?.split(";").includes(genders.value) || false}
                    />
                    <S4.SelectionItemSpan>{genders.label}</S4.SelectionItemSpan>
                  </S4.SelectionItemLabel>
                </S4.SelectionItem>
              ))}
            </S4.SelectionItemList>
          </dd>
        </S3.FilterAccordionItem>

        {/* 치료 옵션 */}
        <S3.FilterAccordionItem>
          <S3.FilterAccordionItemTitle onClick={handleOnClickPackage} $isActive={isPackageOpen}>
            <strong>{t(IK.treatment_option)}</strong>
            <span>
              <i className="ri-arrow-drop-right-line" />
            </span>
          </S3.FilterAccordionItemTitle>

          {isPackageOpen && (
            <dd>
              <S.FormControlsColumn>
                {packages.map((pkg) => (
                  <S4.SelectionItem key={pkg.value}>
                    <S4.SelectionItemLabel>
                      <S4.SelectionItemInput
                        $checkbox
                        type="checkbox"
                        name="packagesArray"
                        onChange={handleOnClickCheckBox}
                        value={pkg.value}
                        checked={searchCondition?.packages_list?.split(";").includes(pkg.value) || false}
                      />
                      <S4.SelectionItemSpan>{pkg.label}</S4.SelectionItemSpan>
                    </S4.SelectionItemLabel>
                  </S4.SelectionItem>
                ))}
              </S.FormControlsColumn>
            </dd>
          )}
        </S3.FilterAccordionItem>

        {/* 임상상태 */}
        <S3.FilterAccordionItem>
          <S3.FilterAccordionItemTitle onClick={handleOnClickCondition} $isActive={isConditionOpen}>
            <strong>{t(IK.clinical_condition)}</strong>
            <span>
              <i className="ri-arrow-drop-right-line" />
            </span>
          </S3.FilterAccordionItemTitle>

          {isConditionOpen && (
            <dd>
              <S.FormControlsColumn>
                {conditions.map((condition) => (
                  <S4.SelectionItem key={condition.value}>
                    <S4.SelectionItemLabel>
                      <S4.SelectionItemInput
                        $checkbox
                        type="checkbox"
                        name="conditionsArray"
                        onChange={handleOnClickClinicalCondition}
                        value={condition.value}
                        checked={searchCondition?.conditions_list?.split(";").includes(condition.value) || false}
                      />
                      <S4.SelectionItemSpan>{condition.label}</S4.SelectionItemSpan>
                    </S4.SelectionItemLabel>
                  </S4.SelectionItem>
                ))}
              </S.FormControlsColumn>
            </dd>
          )}
        </S3.FilterAccordionItem>
      </S3.FilterAccordionList>

      <S3.ContentBox $regular>
        <S2.StyledButton as="button" $primary $full type="button" onClick={handleOnClickReset}>
          <i className="ri-restart-line"></i> {t(IK.reset)}
        </S2.StyledButton>
      </S3.ContentBox>
    </>
  );
};

export default SearchFilter;
