import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useGetClinicItem, useSaveDoctorClinicItem, useSavePatientClinicItem } from "../../../../apis/clinicItemApi";
import { userInfo } from "../../../../atoms/userAtom";
import { CardTail, CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { SkeletonFormCardList } from "../../../../components/element/Form/SkeletonFormCardList";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S4 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import { TitleV3, TitleV4 } from "../../../../components/element/Title/TitleText";
import * as S3 from "../../../../styles/Common";
import { IK } from "../../../../utils/i18n/keyStore";
import { convertKeysToLowerCase } from "../../../../utils/objectUtils";
import ElasticCutInterfaceModal from "../ElasticCutInterfaceModal";

function ClinicalConfigurationsForm({ showCloseBtn, close, patientId, isModal }) {
  const { t } = useTranslation(["translation"]);

  /**국가코드 조회 */
  const { countryCode } = useRecoilValue(userInfo);

  const { data, isLoading } = useGetClinicItem(patientId ? "patient" : "doctor", patientId);
  const itemInfo = data?.data;

  /**Elastic cut interface 모달 설정 */
  const [isElasticCutInterfaceModal, setIsElasticCutInterfaceModal] = useState(false);

  /**Elastic cut interface 모달 열기 */
  const openElasticCutInterfaceModal = () => {
    setIsElasticCutInterfaceModal(true);
  };

  /**Elastic cut interface 모달 닫기 */
  const closeElasticCutInterfaceModal = () => {
    setIsElasticCutInterfaceModal(false);
  };

  const validationSchema = Yup.object().shape({
    CI19: Yup.string().required(t(IK.clinical_configurations_item1_required)),
    CI05: Yup.string().required(t(IK.clinical_configurations_item2_1_required)),
    CI04: Yup.string().required(t(IK.clinical_configurations_item2_2_required)),
    CI06: Yup.string().required(t(IK.clinical_configurations_item2_3_required)),
    CI08: Yup.string().required(t(IK.clinical_configurations_item4_2_required)),
    CI09: Yup.string().required(t(IK.clinical_configurations_item4_1_required)),
    CI16: Yup.string().required(t(IK.clinical_configurations_item7_required)),
    CI11: Yup.string().required(t(IK.clinical_configurations_item5_required)),
    CI10: Yup.string().required(t(IK.clinical_configurations_item9_required)),
    CI13: Yup.string().required(t(IK.clinical_configurations_item3_2_required)),
    CI07: Yup.string().required(t(IK.clinical_configurations_item3_1_required)),
    CI14: Yup.string().required(t(IK.clinical_configurations_item12_required)),
    CI20: Yup.string().required(t(IK.clinical_configurations_item13_required)),
    CI21: Yup.string().required(t(IK.clinical_configurations_item13_required)),
    CI22: Yup.string().required(t(IK.clinical_configurations_item14_1_required)),
    CI23: Yup.string().required(t(IK.clinical_configurations_item14_2_required)),
    CI24: Yup.string().required(t(IK.clinical_configurations_item15_required)),
    CI25: Yup.string().required(t(IK.clinical_configurations_item25_required)),
    CI26: Yup.string().required(t(IK.clinical_configurations_item26_required)),
    CI27: Yup.string().required(t(IK.clinical_configurations_item1_required)),
    CI04_2: Yup.string().when("CI04", {
      is: (val) => val === "2",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    }),
    CI05_2: Yup.string().when("CI05", {
      is: (val) => val === "2",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    }),
    CI06_2: Yup.string().when("CI06", {
      is: (val) => val === "2",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    }),
    CI11_1: Yup.string().when("CI11", {
      is: (val) => val === "1",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    }),
  });

  /**의사 임상환경 저장 */
  const saveDoctorClinicItemMutation = useSaveDoctorClinicItem();

  /**환자 임상환경 저장 */
  const savePatientClinicItemMutation = useSavePatientClinicItem();

  const handleSubmit = (values) => {
    values.CI04_2 = values.CI04 === "2" ? values.CI04_2 : "";
    values.CI05_2 = values.CI05 === "2" ? values.CI05_2 : "";
    values.CI06_2 = values.CI06 === "2" ? values.CI06_2 : "";
    values.CI11_1 = values.CI11 === "1" ? values.CI11_1 : "";

    const data = convertKeysToLowerCase(values);
    const mutation = patientId ? savePatientClinicItemMutation : saveDoctorClinicItemMutation;

    mutation.mutate(patientId ? { data, patientId } : data, {
      onSuccess: (res) => {
        Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
        if (isModal) close();
      },
      onError: (err) => {
        Notiflix.Report.failure(t(IK.fail_save), "", t(IK.confirm));
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonFormCardList />
      ) : (
        <Formik
          initialValues={{
            CI19: itemInfo.CI19 || "",
            CI05: itemInfo.CI05 || "",
            CI05_2: itemInfo.CI05_2 || "",
            CI04: itemInfo.CI04 || "",
            CI04_2: itemInfo.CI04_2 || "",
            CI06: itemInfo.CI06 || "",
            CI06_2: itemInfo.CI06_2 || "",
            CI08: itemInfo.CI08 || "",
            CI09: itemInfo.CI09 || "",
            CI16: itemInfo.CI16 || "",
            CI11: itemInfo.CI11 || "",
            CI11_1: itemInfo.CI11_1 || "",
            CI10: itemInfo.CI10 || "",
            CI13: itemInfo.CI13 || "",
            CI07: itemInfo.CI07 || "",
            CI14: itemInfo.CI14 || "",
            CI20: itemInfo.CI20 || "",
            CI21: itemInfo.CI21 || "",
            CI22: itemInfo.CI22 || "",
            CI23: itemInfo.CI23 || "",
            CI24: itemInfo.CI24 || "",
            CI25: itemInfo.CI25 || "", //교정 장치 트리밍
            CI26: itemInfo.CI26 || "", //어린이 교정장치 트리밍
            CI27: itemInfo.CI27 || "", //어린이 장치배송의 기본 단위
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount={true}
          validateOnBlur={true}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <S.FormCardList>
                <li>
                  <S.FormControlsColumn $large>
                    {countryCode === "01" && (
                      <div>
                        {/**장치 배송의 기본 단위 */}
                        <TitleV3 title={t(IK.clinical_configurations_item1_title)} required />

                        <S.FormControlsColumn>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI19" value="1" />
                              <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item1_1)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>

                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI19" value="2" />
                              <S4.SelectionItemSpan>{t(IK.clinical_configurations_item1_2)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S.FormControlsColumn>
                        <FieldMessageErrorClick name="CI19" />
                      </div>
                    )}
                    <div>
                      {/**교정장치 트리밍 */}
                      <TitleV3 title={t(IK.clinical_configurations_item25_title)} required />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI25" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item25_1)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>

                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI25" value="2" />
                            <S4.SelectionItemSpan>{t(IK.clinical_configurations_item25_2)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI25" />
                    </div>
                  </S.FormControlsColumn>
                </li>

                <li>
                  {/**치아크기 부조화*/}
                  <TitleV3 title={t(IK.clinical_configurations_item9_title)} required />

                  <S.FormControlsColumn>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI10" value="1" />
                        <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item9_1)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>

                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI10" value="2" />
                        <S4.SelectionItemSpan>{t(IK.clinical_configurations_item9_2)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>

                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI10" value="3" />
                        <S4.SelectionItemSpan>{t(IK.clinical_configurations_item9_3)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>

                    <FieldMessageErrorClick name="CI10" />
                  </S.FormControlsColumn>
                </li>

                <li>
                  {/**시행단계의 지연여부*/}
                  <TitleV3 title={t(IK.clinical_configurations_item2_title)} titleSub={t(IK.clinical_configurations_item2_title_ex)} required />

                  <S.FormControlsColumn $large>
                    {/**어태치먼트 부여*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item2_1)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI05" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.not_delayed)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>

                        <S.FormControlsFlex>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI05" value="2" />
                              <S4.SelectionItemSpan>{t(IK.delays_step)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>

                          {values.CI05 === "2" && (
                            <Field name="CI05_2">
                              {({ field }) => (
                                <S.FormLineControls $select as="select" {...field}>
                                  <option value="">{t(IK.select)}</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </S.FormLineControls>
                              )}
                            </Field>
                          )}
                        </S.FormControlsFlex>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI05" />
                      <FieldMessageErrorClick name="CI05_2" />
                    </div>

                    {/**IPR (치간삭제)*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item2_2)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI04" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.not_delayed)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                        <S.FormControlsFlex>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI04" value="2" />
                              <S4.SelectionItemSpan>{t(IK.delays_step)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>

                          {values.CI04 === "2" && (
                            <Field name="CI04_2">
                              {({ field }) => (
                                <S.FormLineControls $select as="select" {...field}>
                                  <option value="">{t(IK.select)}</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </S.FormLineControls>
                              )}
                            </Field>
                          )}
                        </S.FormControlsFlex>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI04" />
                      <FieldMessageErrorClick name="CI04_2" />
                    </div>

                    {/**발치*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item2_3)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI06" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.not_delayed)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                        <S.FormControlsFlex>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI06" value="2" />
                              <S4.SelectionItemSpan>{t(IK.delays_step)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>

                          {values.CI06 === "2" && (
                            <Field name="CI06_2">
                              {({ field }) => (
                                <S.FormLineControls $select as="select" {...field}>
                                  <option value="">{t(IK.select)}</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </S.FormLineControls>
                              )}
                            </Field>
                          )}
                        </S.FormControlsFlex>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI06" />
                      <FieldMessageErrorClick name="CI06_2" />
                    </div>
                  </S.FormControlsColumn>
                </li>

                <li>
                  {/**Spacing*/}
                  <TitleV3 title={t(IK.clinical_configurations_item3_title)} required />

                  <S.FormControlsColumn $large>
                    {/**Pontic (가공치) 적용*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item3_1)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI07" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item3_1_1)}</S4.SelectionItemSpan>
                            <p>{t(IK.clinical_configurations_item3_1_1_1)}</p>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI07" />
                    </div>

                    {/**공간 폐쇄 시 overcorrection (과수정)*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item3_2)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI13" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.not_applied)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI13" value="2" />
                            <S4.SelectionItemSpan>{t(IK.applied)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI13" />
                    </div>
                  </S.FormControlsColumn>
                </li>

                <li>
                  {/**악궁확장*/}
                  <TitleV3 title={t(IK.dental_arch_extension)} required />

                  <S.FormControlsColumn $large>
                    {/**기본 확장량*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item4_1)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI09" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item4_1_1)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>

                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI09" value="2" />
                            <S4.SelectionItemSpan>{t(IK.clinical_configurations_item4_1_2)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI09" />
                    </div>

                    {/**기본 확장위치*/}
                    <div>
                      <TitleV4 title={t(IK.clinical_configurations_item4_2)} />

                      <S.FormControlsColumn>
                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI08" value="1" />
                            <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item4_2_1)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>

                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI08" value="2" />
                            <S4.SelectionItemSpan>{t(IK.clinical_configurations_item4_2_2)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>

                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI08" value="3" />
                            <S4.SelectionItemSpan>{t(IK.clinical_configurations_item4_2_3)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>

                        <S4.SelectionItem>
                          <S4.SelectionItemLabel>
                            <Field as={S4.SelectionItemInput} $radio type="radio" name="CI08" value="4" />
                            <S4.SelectionItemSpan>{t(IK.clinical_configurations_item4_2_4)}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        </S4.SelectionItem>
                      </S.FormControlsColumn>
                      <FieldMessageErrorClick name="CI08" />
                    </div>
                  </S.FormControlsColumn>
                </li>

                <li>
                  {/**상악 전치부 배열*/}
                  <TitleV3 title={t(IK.clinical_configurations_item5_title)} required />

                  <S.FormControlsColumn>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI11" value="1" />
                        <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item5_1)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>

                    {values.CI11 === "1" && (
                      <S.FormIndent>
                        <S.FormControlsColumn>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI11_1" value="1" />
                              <S4.SelectionItemSpan>{t(IK.clinical_configurations_item5_1_1)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI11_1" value="2" />
                              <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item5_1_2)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S.FormControlsColumn>
                        <FieldMessageErrorClick name="CI11_1" />
                      </S.FormIndent>
                    )}

                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI11" value="2" />
                        <S4.SelectionItemSpan>{t(IK.clinical_configurations_item5_2)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S.FormControlsColumn>
                  <FieldMessageErrorClick name="CI11" />
                </li>

                <li>
                  {/**A-P 교정*/}
                  <div>
                    <TitleV3 title={t(IK.clinical_configurations_item7_title)} required>
                      {/**Elastic cut interface*/}
                      <button type="button" onClick={openElasticCutInterfaceModal}>
                        Elastic cut interface
                      </button>
                    </TitleV3>
                    <S.FormControlsColumn>
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <Field as={S4.SelectionItemInput} $radio type="radio" name="CI16" value="1" />
                          <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item7_1)}</S4.SelectionItemSpan>
                          <p>{t(IK.clinical_configurations_item7_1_1)}</p>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <Field as={S4.SelectionItemInput} $radio type="radio" name="CI16" value="2" />
                          <S4.SelectionItemSpan>{t(IK.clinical_configurations_item7_2)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                    </S.FormControlsColumn>
                    <FieldMessageErrorClick name="CI16" />
                  </div>
                </li>

                <li>
                  {/**최후방 대구치의 왜곡*/}
                  <TitleV3 title={t(IK.clinical_configurations_item12_title)} titleSub={t(IK.clinical_configurations_item12_title_ex)} required />

                  <S.FormControlsColumn>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI14" value="1" />
                        <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item12_1)}</S4.SelectionItemSpan>
                        <p>{t(IK.clinical_configurations_item12_1_1)}</p>
                        <p>{t(IK.clinical_configurations_item12_1_2)}</p>
                        <p>{t(IK.clinical_configurations_item12_1_3)}</p>
                        <p>{t(IK.clinical_configurations_item12_1_4)}</p>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>

                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI14" value="2" />
                        <S4.SelectionItemSpan>{t(IK.clinical_configurations_item12_2)}</S4.SelectionItemSpan>
                        <p>{t(IK.clinical_configurations_item12_2_1)}</p>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>

                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <Field as={S4.SelectionItemInput} $radio type="radio" name="CI14" value="3" />
                        <S4.SelectionItemSpan>{t(IK.clinical_configurations_item12_3)}</S4.SelectionItemSpan>
                        <p>{t(IK.clinical_configurations_item12_3_1)}</p>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S.FormControlsColumn>
                  <FieldMessageErrorClick name="CI14" />
                </li>
              </S.FormCardList>

              {/*------------------------어린이 임상환경 설정-------------------- */}
              <S3.ContentBox $large>
                <CardTitle title={t(IK.clinical_configurations_child)} />
                <S.FormCardList>
                  <li>
                    <S.FormControlsColumn $large>
                      {countryCode === "01" && (
                        <div>
                          {/**장치 배송의 기본 단위 */}
                          <TitleV3 title={t(IK.clinical_configurations_item1_title)} required />

                          <S.FormControlsColumn>
                            <S4.SelectionItem>
                              <S4.SelectionItemLabel>
                                <Field as={S4.SelectionItemInput} $radio type="radio" name="CI27" value="1" />
                                <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item1_1)}</S4.SelectionItemSpan>
                              </S4.SelectionItemLabel>
                            </S4.SelectionItem>

                            <S4.SelectionItem>
                              <S4.SelectionItemLabel>
                                <Field as={S4.SelectionItemInput} $radio type="radio" name="CI27" value="2" />
                                <S4.SelectionItemSpan>{t(IK.clinical_configurations_item1_2)}</S4.SelectionItemSpan>
                              </S4.SelectionItemLabel>
                            </S4.SelectionItem>
                          </S.FormControlsColumn>
                          <FieldMessageErrorClick name="CI27" />
                        </div>
                      )}
                      <div>
                        {/**교정장치 트리밍 */}
                        <TitleV3 title={t(IK.clinical_configurations_item25_title)} required />

                        <S.FormControlsColumn>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI26" value="1" />
                              <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item25_1)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>

                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI26" value="2" />
                              <S4.SelectionItemSpan>{t(IK.clinical_configurations_item25_2)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S.FormControlsColumn>
                        <FieldMessageErrorClick name="CI26" />
                      </div>
                    </S.FormControlsColumn>
                  </li>
                  <li>
                    {/*악궁 확장 */}
                    <TitleV3 title={t(IK.dental_arch_extension)} required />

                    <S.FormControlsColumn $large>
                      {/**치아 이동단계 설정*/}
                      <div>
                        <TitleV4 title={t(IK.clinical_configurations_item15_title)} />

                        <S4.SelectionItemList>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI24" value="1" />
                              <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item15_1)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI24" value="2" />
                              <S4.SelectionItemSpan>{t(IK.clinical_configurations_item15_2)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S4.SelectionItemList>
                        <FieldMessageErrorClick name="CI24" />
                      </div>

                      {/*확장할 치아 */}
                      <div>
                        <TitleV4 title={t(IK.clinical_configurations_item14_1_title)} />

                        <S4.SelectionItemList>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI22" value="2" />
                              <S4.SelectionItemSpan $default>{t(IK.clinical_configurations_item14_1_2)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI22" value="1" />
                              <S4.SelectionItemSpan>{t(IK.clinical_configurations_item14_1_1)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S4.SelectionItemList>
                        <FieldMessageErrorClick name="CI22" />
                      </div>

                      {/*기본 확장량 */}
                      <div>
                        <TitleV4 title={t(IK.clinical_configurations_item14_2_title)} />

                        <S4.SelectionItemList>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI23" value="1" />
                              <S4.SelectionItemSpan>&lt; 2 mm</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI23" value="2" />
                              <S4.SelectionItemSpan>2-4 mm</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI23" value="3" />
                              <S4.SelectionItemSpan $default>4-6 mm</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI23" value="4" />
                              <S4.SelectionItemSpan>6-8 mm</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI23" value="5" />
                              <S4.SelectionItemSpan>8 &lt; mm</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S4.SelectionItemList>
                        <FieldMessageErrorClick name="CI23" />
                      </div>
                    </S.FormControlsColumn>
                  </li>
                  <li>
                    {/*IPR */}
                    <TitleV3 title="IPR" required />

                    <S.FormControlsColumn $large>
                      <div>
                        <TitleV4 title={t(IK.permanent_teeth)} />

                        <S4.SelectionItemList>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI20" value="1" />
                              <S4.SelectionItemSpan $default>{t(IK.implemented)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI20" value="2" />
                              <S4.SelectionItemSpan>{t(IK.not_implemented)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S4.SelectionItemList>
                        <FieldMessageErrorClick name="CI20" />
                      </div>

                      <div>
                        <TitleV4 title={t(IK.deciduous_teeth)} />

                        <S4.SelectionItemList>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI21" value="1" />
                              <S4.SelectionItemSpan>{t(IK.implemented)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <Field as={S4.SelectionItemInput} $radio type="radio" name="CI21" value="2" />
                              <S4.SelectionItemSpan $default>{t(IK.not_implemented)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>
                        </S4.SelectionItemList>
                        <FieldMessageErrorClick name="CI21" />
                      </div>
                    </S.FormControlsColumn>
                  </li>
                </S.FormCardList>

                <CardTail line>
                  <S2.ButtonLtBox>
                    {showCloseBtn && (
                      <S2.StyledButton as="button" onClick={close}>
                        {t(IK.cancel)}
                      </S2.StyledButton>
                    )}
                    <S2.StyledButton as="button" $primary type="submit">
                      {t(IK.save)}
                    </S2.StyledButton>
                  </S2.ButtonLtBox>
                </CardTail>
              </S3.ContentBox>
            </Form>
          )}
        </Formik>
      )}
      {isElasticCutInterfaceModal && <ElasticCutInterfaceModal patientId={patientId} open={isElasticCutInterfaceModal} close={closeElasticCutInterfaceModal} />}
    </>
  );
}

export default ClinicalConfigurationsForm;
