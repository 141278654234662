import Notiflix from "notiflix";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import SetupInterceptors from "../../apis/SetupInterceptors";
import * as S from "../../styles/Common";
import i18n from "../../utils/i18n";
import { IK } from "../../utils/i18n/keyStore";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";

function Layout() {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);

  /**리코일에 유저정보 없으면 토큰 초기화 시키기 */
  useEffect(() => {
    //인터셉터 등록
    SetupInterceptors(navigate, t);

    const userRecoil = localStorage.getItem("recoil-persist");
    if (!userRecoil || !userRecoil?.includes("userInfo")) {
      localStorage.removeItem("accessToken");
      navigate("/");
      Notiflix.Report.info("", t(IK.login_expiration_message1), t(IK.confirm), () => {});
    }
  }, [navigate, t]);

  const langInfo = localStorage.getItem("langInfo");
  const token = localStorage.getItem("accessToken");

  if (!token) {
    i18n.changeLanguage(langInfo);
    return <Navigate to="/" {...Notiflix.Report.failure(t(IK.Invalid_approach), "", t(IK.confirm))} />;
  }

  return (
    <>
      <Sidebar />
      <S.Container id="container">
        <Outlet />
      </S.Container>
      <Footer />
    </>
  );
}

export default Layout;
