import { FieldMessageError } from "../../element/FieldMessage/FieldMessage";
import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/CardLayout.style";

/**제목+ 좁은 카드 */
export const CardInnerLayout = ({ wd1440, children }) => {
  return (
    <S.CardRow $wd1440={wd1440}>
      <S.CardCol>
        <S.CardInner>{children}</S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export const CardTitle = ({
  title,
  subTitle,
  required,
  message,
  icon,
  children,
}) => {
  const { t } = useTranslation(["translation"]);
  return (
    <S.CardTitle>
      <h2>
        {title}
        {subTitle && <span>{subTitle}</span>}
      </h2>
      {required && (
        <p>
          <span>*</span>
          {t(IK.marking_required_message)}
        </p>
      )}
      {message && (
        <p>
          <span>{icon}</span>
          {message}
        </p>
      )}
      {children}
    </S.CardTitle>
  );
};

/**카드 꼬리 */
export const CardTail = ({ line, study, error, children }) => {
  return (
    <S.CardTail $line={line} $study={study}>
      {error && <FieldMessageError text={error} />}
      {children}
    </S.CardTail>
  );
};
