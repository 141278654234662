import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRememberPage } from "../../../Hooks/useRememberPage";
import { useGetBillsList } from "../../../apis/paymentApi";
import { BoardBottom } from "../../../components/Layout/BoardLayout/BoardLayout";
import { CardInnerLayout, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import { SkeletonBoardTable } from "../../../components/element/BoardList/SkeletonBoardTable";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { formatCurrency } from "../../../utils/paymentUtils";

function BillingList() {
  const navigation = useNavigate();
  const { t } = useTranslation(["translation"]);

  /**페이지 기억하기 */
  const [currentPage, setCurrentPage] = useRememberPage();

  /**페이지 변경 */
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const { data: billingData, isLoading, isError } = useGetBillsList(currentPage - 1);
  const billingList = billingData?.data?.content;

  const tableSub = [t(IK.pay_number), t(IK.pay_title), t(IK.pay_sale), t(IK.pay_discount), t(IK.pay_charged), t(IK.pay_remaining), t(IK.order_status)];

  /**결제 리스트 상태코드 */
  const payStatus = {
    0: t(IK.upcoming_billing), // 결제 예정, 어드민에서만 쓰는 상태값
    10: t(IK.request_billing), // 결제 요청
    20: t(IK.partial_orders), // 일부 주문
    30: t(IK.partial_pay), // 일부 결제
    40: t(IK.total_pay_complete), // 전체 결제 완료
  };

  return (
    <CardInnerLayout>
      <CardTitle title={`${t(IK.pay_order)} ${t(IK.check_details)}`} />
      {isLoading || isError ? (
        <SkeletonBoardTable isBill />
      ) : (
        <>
          <S.BoardTable>
            <thead>
              <tr>
                {tableSub.map((sub, index) => (
                  <th key={index} scope="col">
                    <span>{sub}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {billingList.length > 0 ? (
                <>
                  {billingList.map((bill) => (
                    <tr key={bill.billingId}>
                      <td>#{bill.billingId}</td>
                      <td>
                        {bill.billingMonth}
                        {t(IK.pay_month)} {t(IK.pay_order)}
                      </td>
                      <td>
                        {formatCurrency(bill.saleTotalPrice)}
                        {t(IK.krw)}
                      </td>
                      <td>
                        <S3.TextDiv $textColorRed600>
                          -{formatCurrency(bill.discountTotalPrice)}
                          {t(IK.krw)}
                        </S3.TextDiv>
                      </td>
                      <td>
                        <S3.TextDiv>
                          {formatCurrency(bill.paymentTotalPrice)}
                          {t(IK.krw)}
                        </S3.TextDiv>
                      </td>
                      <td>
                        {formatCurrency(bill.prepaidTotalPrice)}
                        {t(IK.krw)}
                      </td>
                      <td>
                        <S2.StyledSmallButton
                          as="button"
                          type="button"
                          onClick={() =>
                            navigation(`/account/billing-list/payment/${bill.billingId}`, {
                              state: {
                                page: currentPage,
                                billMonth: bill.billingMonth,
                              },
                            })
                          }
                        >
                          {payStatus[bill.billingStatus]}
                        </S2.StyledSmallButton>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="7">
                    <EmptyCard />
                  </td>
                </tr>
              )}
            </tbody>
          </S.BoardTable>

          <BoardBottom>
            <Paging totalCount={billingData.data.totalElements} currentPage={currentPage} size={billingData.data.size} handleChangePage={handleChangePage} />
            <S2.ButtonLtBox>
              <S2.StyledButton $primary to="/account/grade">
                {t(IK.pay_price_discount)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </BoardBottom>
        </>
      )}
    </CardInnerLayout>
  );
}

export default BillingList;
