import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { submitGallery, useGetGallerySubmitInfo } from "../../../../../apis/galleryApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import CommonImage from "../../../../../components/common/CommonImage";
import * as S4 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../../../components/element/Form/FormLayout";
import * as S2 from "../../../../../components/element/Form/style/Form.style";
import * as S6 from "../../../../../components/element/SelectionControls/style/SelectionControls.style";
import * as S3 from "../../../../../styles/Common";
import { achievedList, fDropImages, iDropImages, nonSerafinDeviceList, pDropImages, serafinDeviceList } from "../../../../../utils/clinicalgalleryUtils";
import { clinicConditionArray } from "../../../../../utils/conditionItemUtils";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";
import { SkeletonView } from "../../../../Education/ClinicalCases/SkeletonView";
import * as S5 from "../../../../Education/ClinicalCases/style/ClinicalCases.style";
import { Previews } from "../../../Study/Sections/Preview/Preview";

function ClinicalCasesRegister() {
  const { t } = useTranslation(["translation"]);
  const { patientId, studyId } = useParams();

  /**환자 데이터 받아오기 */
  const { data: patientData, isLoading: isPatientLoading } = useGetGallerySubmitInfo(studyId);
  const patientInfo = patientData?.data;

  /**인풋 수 관리 */
  const [inputCount, setInputCount] = useState(1);

  /**인풋 증가 최대 5개 */
  const handleAddInput = () => {
    setInputCount((prevCount) => prevCount + 1);
  };

  /**인풋 감소 최소 1개*/
  const handleRemoveInput = (values, setFieldValue) => {
    setInputCount((prevCount) => prevCount - 1);
    if (values.clinicalPlan.length === inputCount) {
      //마지막 요소 값이 있을때 배열의 제일 마지막 요소 삭제
      setFieldValue("clinicalPlan", values.clinicalPlan.slice(0, -1));
    }
  };

  const navigate = useNavigate();

  /**form 초기값 */
  const formInitialValues = {
    clinicalPlan: [],
    clinicalMonth: "",
    clinicalDay: "",
    clinicalTime: "",
    nonSerafinDevice: [],
    nonSerafinDeviceOther: false,
    nonSerafinDeviceText: "",
    serafinDevice: [],
    serafinDeviceOther: false,
    serafinDeviceText: "",
    clinicalAchieved: [],
    clinicalAchievedOther: false,
    clinicalAchievedText: "",
    i_occlusal_upper: patientInfo?.occlusal_upper || "",
    i_intraoral_front: patientInfo?.intraoral_front || "",
    i_occlusal_lower: patientInfo?.occlusal_lower || "",
    i_buccal_right: patientInfo?.buccal_right || "",
    i_overjet: patientInfo?.overjet || "",
    i_buccal_left: patientInfo?.buccal_left || "",
    i_panorama: patientInfo?.panorama || "",
    i_cephalo: patientInfo?.cephalo || "",
    p_occlusal_upper: "",
    p_intraoral_front: "",
    p_occlusal_lower: "",
    p_buccal_right: "",
    p_overjet: "",
    p_buccal_left: "",
    f_occlusal_upper: "",
    f_intraoral_front: "",
    f_occlusal_lower: "",
    f_buccal_right: "",
    f_overjet: "",
    f_buccal_left: "",
    f_panorama: "",
    f_cephalo: "",
  };

  const validationSchema = Yup.object().shape({
    clinicalPlan: Yup.array().test("treatment-plan", t(IK.treatment_plan_required), (value) => value && value.length >= 1 && value.every((v) => !!v && v?.trim() !== "")),
    clinicalMonth: Yup.string().required(t(IK.total_treatment_time_required)),
    clinicalTime: Yup.string().required(t(IK.aligner_wear_time_required)),
    clinicalDay: Yup.string().required(t(IK.aligner_wear_day_required)),
    nonSerafinDevice: Yup.array().when("nonSerafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.gallery_other_device_required)),
    }),
    nonSerafinDeviceText: Yup.string().when("nonSerafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    serafinDevice: Yup.array().when("serafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.gallery_device_check_required)),
    }),
    serafinDeviceText: Yup.string().when("serafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    clinicalAchieved: Yup.array().when("clinicalAchievedOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.gallery_achieved_check_required)),
    }),
    clinicalAchievedText: Yup.string().when("clinicalAchievedOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    i_occlusal_upper: Yup.mixed().required(t(IK.image_required)),
    i_occlusal_lower: Yup.mixed().required(t(IK.image_required)),
    i_buccal_right: Yup.mixed().required(t(IK.image_required)),
    i_overjet: Yup.mixed().required(t(IK.image_required)),
    i_buccal_left: Yup.mixed().required(t(IK.image_required)),
    f_occlusal_upper: Yup.mixed().required(t(IK.image_required)),
    f_occlusal_lower: Yup.mixed().required(t(IK.image_required)),
    f_buccal_right: Yup.mixed().required(t(IK.image_required)),
    f_overjet: Yup.mixed().required(t(IK.image_required)),
    f_buccal_left: Yup.mixed().required(t(IK.image_required)),
    i_panorama: Yup.mixed().required(t(IK.image_required)),
  });

  const handleSubmit = (values) => {
    Notiflix.Loading.standard("");
    const { clinicalAchievedOther, nonSerafinDeviceOther, serafinDeviceOther, clinicalPlan, ...rest } = values;

    const data = deleteEmptyKey({
      ...rest,
      clinicalPlan: clinicalPlan.join(";"),
    });
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key.includes("i_") && !(value instanceof File)) {
        //이미 등록된 초기 이미지의 경우 건너뛰기
        return;
      }
      formData.append(key, value);
    });
    formData.append("patientId", patientId);
    formData.append("studyId", studyId);

    submitGallery(formData)
      .then((res) => {
        navigate(`/patient/${patientId}/profile`);
      })
      .catch((err) => {
        Notiflix.Notify.failure(t(IK.again_message));
      })
      .finally(() => {
        Notiflix.Loading.remove();
      });
  };

  return (
    <>
      <Beforeunload onBeforeunload={() => ""}>
        <CardInnerLayout>
          <CardTitle title={t(IK.clinical_cases_submission)} required />
          {isPatientLoading ? (
            <SkeletonView />
          ) : (
            <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnMount={true}>
              {({ setFieldValue, values, errors, setFieldError }) => (
                <Form>
                  <S5.CaseHead>
                    <S5.CaseHeadBox>
                      <S5.CaseInfo>
                        <h2>{patientInfo.patientName}</h2>
                        <dl>
                          <dt>{t(IK.treatment_option)}</dt>
                          <dd>{t(patientInfo.packages)}</dd>
                        </dl>
                        <dl>
                          <dt>{t(IK.clinical_condition)}</dt>
                          <dd>{clinicConditionArray(patientInfo?.conditionItemList, t, patientInfo?.etc)}</dd>
                        </dl>
                        <dl>
                          <dt>{t(IK.birthday)}</dt>
                          <dd>{patientInfo.birthday}</dd>
                        </dl>
                        <dl>
                          <dt>{t(IK.gender)}</dt>
                          <dd>{patientInfo.gender === "male" ? t(IK.male) : t(IK.female)}</dd>
                        </dl>
                        <dl>
                          <dt>{t(IK.race)}</dt>
                          <dd>{patientInfo.race}</dd>
                        </dl>
                      </S5.CaseInfo>
                      <S5.CaseHeadImg>
                        <CommonImage filePath={patientInfo.profile} />
                      </S5.CaseHeadImg>
                    </S5.CaseHeadBox>
                  </S5.CaseHead>

                  <S3.ContentBox $regular>
                    <S2.FormControlsContainer>
                      {/**치료 계획 */}
                      <FormControlsBox required title={t(IK.treatment_plan)}>
                        <S2.FormControlsColumn>
                          {Array.from({ length: inputCount }, (_, index) => (
                            <S2.FormControlsFlex $rowCenter key={`clinicalPlan${index}`}>
                              <Field as={S2.FormControls} name={`clinicalPlan[${index}]`} value={values.clinicalPlan[index] || ""} />
                              {index + 1 === inputCount && (
                                <>
                                  {index !== 4 && (
                                    <S4.StlyedSquareButton type="button" $add onClick={handleAddInput}>
                                      <i className="ri-add-line"></i>
                                    </S4.StlyedSquareButton>
                                  )}
                                  {index !== 0 && (
                                    <S4.StlyedSquareButton
                                      type="button"
                                      $delete
                                      onClick={() => {
                                        handleRemoveInput(values, setFieldValue);
                                      }}
                                    >
                                      <i className="ri-subtract-line"></i>
                                    </S4.StlyedSquareButton>
                                  )}
                                </>
                              )}
                            </S2.FormControlsFlex>
                          ))}
                        </S2.FormControlsColumn>
                        <FieldMessageErrorClick name="clinicalPlan" />
                      </FormControlsBox>

                      {/**요약 */}
                      <FormControlsBox required title={t(IK.treatment_summary)}>
                        <S2.FormControlsColumn $large>
                          {/**총 치료 기간 */}
                          <FormControlsBox title={`1) ${t(IK.total_treatment_time)}`}>
                            <S2.FormControlsFlex>
                              <Field as={S2.FormLineControls} type="text" name="clinicalMonth" />
                              {t(IK.months)}
                            </S2.FormControlsFlex>
                            <FieldMessageErrorClick name="clinicalMonth" />
                          </FormControlsBox>

                          {/**장치 착용 시간 */}
                          <FormControlsBox title={`2) ${t(IK.aligner_wear_time)}`}>
                            <S2.FormControlsFlex>
                              <div>
                                <S2.FormControlsFlex>
                                  {t(IK.day)}
                                  <Field as={S2.FormLineControls} type="text" name="clinicalTime" />
                                  {t(IK.hour_by_hour)}
                                </S2.FormControlsFlex>
                                <FieldMessageErrorClick name="clinicalTime" />
                              </div>
                              <div>
                                <S2.FormControlsFlex>
                                  {t(IK.per_device)}
                                  <Field as={S2.FormLineControls} type="text" name="clinicalDay" />
                                  {t(IK.day_use)}
                                </S2.FormControlsFlex>
                                <FieldMessageErrorClick name="clinicalDay" />
                              </div>
                            </S2.FormControlsFlex>
                          </FormControlsBox>

                          {/**세라핀 외 장치 */}
                          <FormControlsBox title={`3) ${t(IK.gallery_other_device)}`}>
                            <S6.SelectionItemList>
                              {Object.entries(nonSerafinDeviceList).map(([key, label]) => (
                                <S6.SelectionItem key={key}>
                                  <S6.SelectionItemLabel>
                                    <Field as={S6.SelectionItemInput} $checkbox type="checkbox" value={key} name="nonSerafinDevice" />
                                    <S6.SelectionItemSpan>{label}</S6.SelectionItemSpan>
                                  </S6.SelectionItemLabel>
                                </S6.SelectionItem>
                              ))}
                              <S2.FormControlsFlex>
                                <S6.SelectionItem>
                                  <S6.SelectionItemLabel>
                                    <Field as={S6.SelectionItemInput} $checkbox type="checkbox" name="nonSerafinDeviceOther" />
                                    <S6.SelectionItemSpan>{t(IK.other)}</S6.SelectionItemSpan>
                                  </S6.SelectionItemLabel>
                                </S6.SelectionItem>
                                {values.nonSerafinDeviceOther && <Field as={S2.FormLineControls} $large type="text" name="nonSerafinDeviceText" />}
                              </S2.FormControlsFlex>
                            </S6.SelectionItemList>
                            <FieldMessageErrorClick name="nonSerafinDevice" />
                            <FieldMessageErrorClick name="nonSerafinDeviceText" />
                          </FormControlsBox>

                          {/**유지장치 */}
                          <FormControlsBox title={`4) ${t(IK.gallery_devide_check)}`}>
                            <S6.SelectionItemList>
                              {Object.entries(serafinDeviceList).map(([key, label]) => (
                                <S6.SelectionItem key={key}>
                                  <S6.SelectionItemLabel>
                                    <Field as={S6.SelectionItemInput} $checkbox type="checkbox" name="serafinDevice" value={key} />
                                    <S6.SelectionItemSpan>{label}</S6.SelectionItemSpan>
                                  </S6.SelectionItemLabel>
                                </S6.SelectionItem>
                              ))}
                              <S2.FormControlsFlex>
                                <S6.SelectionItem>
                                  <S6.SelectionItemLabel>
                                    <Field as={S6.SelectionItemInput} $checkbox type="checkbox" name="serafinDeviceOther" />
                                    <S6.SelectionItemSpan>{t(IK.other)}</S6.SelectionItemSpan>
                                  </S6.SelectionItemLabel>
                                </S6.SelectionItem>
                                {values.serafinDeviceOther && <Field as={S2.FormLineControls} $large type="text" name="serafinDeviceText" />}
                              </S2.FormControlsFlex>
                            </S6.SelectionItemList>
                            <FieldMessageErrorClick name="serafinDevice" />
                            <FieldMessageErrorClick name="serafinDeviceText" />
                          </FormControlsBox>
                        </S2.FormControlsColumn>
                      </FormControlsBox>

                      {/**Results achieved */}
                      <FormControlsBox required title="Results achieved">
                        <S6.SelectionItemList $gridColumns3fr>
                          {Object.entries(achievedList).map(([key, label]) => (
                            <S6.SelectionItem key={key}>
                              <S6.SelectionItemLabel>
                                <Field as={S6.SelectionItemInput} $checkbox type="checkbox" name="clinicalAchieved" value={key} />
                                <S6.SelectionItemSpan>{label}</S6.SelectionItemSpan>
                              </S6.SelectionItemLabel>
                            </S6.SelectionItem>
                          ))}
                          <S2.FormControlsFlex>
                            <S6.SelectionItem>
                              <S6.SelectionItemLabel>
                                <Field as={S6.SelectionItemInput} $checkbox type="checkbox" name="clinicalAchievedOther" />
                                <S6.SelectionItemSpan>{t(IK.other)}</S6.SelectionItemSpan>
                              </S6.SelectionItemLabel>
                            </S6.SelectionItem>
                            {values.clinicalAchievedOther && <Field as={S2.FormLineControls} $large type="text" name="clinicalAchievedText" />}
                          </S2.FormControlsFlex>
                        </S6.SelectionItemList>
                        <FieldMessageErrorClick name="clinicalAchieved" />
                        <FieldMessageErrorClick name="clinicalAchievedText" />
                      </FormControlsBox>

                      {/**구내 사진 */}
                      <FormControlsBox required title={t(IK.intraoral)}>
                        <S3.ContentBox $large>
                          <S3.ListGroup>
                            <dt>{t(IK.treatment_before)}</dt>
                            <dd>
                              <S.DropListWrap>
                                {iDropImages.map((img, index) => (
                                  <PrevImage key={img.name} styleImg={img.style} name={img.name} setFieldValue={setFieldValue} files={patientInfo[img.style]} required index={index} />
                                ))}
                              </S.DropListWrap>
                            </dd>
                          </S3.ListGroup>
                        </S3.ContentBox>

                        <S3.ContentBox $large>
                          <S3.ListGroup>
                            <dt>{t(IK.in_treatment_aligner_wear_photo)}</dt>
                            <dd>
                              <S.DropListWrap>
                                {pDropImages.map((img) => (
                                  <PrevImage key={img.name} styleImg={img.style} name={img.name} setFieldValue={setFieldValue} />
                                ))}
                              </S.DropListWrap>
                            </dd>
                          </S3.ListGroup>
                        </S3.ContentBox>

                        <S3.ContentBox $large>
                          <S3.ListGroup>
                            <dt>{t(IK.treatment_after_premises_photo)}</dt>
                            <dd>
                              <S.DropListWrap>
                                {fDropImages.map((img, index) => {
                                  return <PrevImage key={img.name} styleImg={img.style} name={img.name} setFieldValue={setFieldValue} required index={index} />;
                                })}
                              </S.DropListWrap>
                            </dd>
                          </S3.ListGroup>
                        </S3.ContentBox>
                      </FormControlsBox>

                      {/**방사선 사진 */}
                      <FormControlsBox title={t(IK.radiograph)}>
                        <S3.ContentBox $large>
                          <S3.ListGroup>
                            <dt>{t(IK.treatment_before)}</dt>
                            <dd>
                              <S.DropListWrap $radiation>
                                <PrevImage styleImg="panorama" name="i_panorama" setFieldValue={setFieldValue} required radiation files={patientInfo?.panorama} />
                                <PrevImage styleImg="cephalo" name="i_cephalo" setFieldValue={setFieldValue} radiation files={patientInfo?.cephalo} />
                              </S.DropListWrap>
                            </dd>
                          </S3.ListGroup>
                        </S3.ContentBox>

                        <S3.ContentBox $large>
                          <S3.ListGroup>
                            <dt>{t(IK.treatment_after)}</dt>
                            <dd>
                              <S.DropListWrap $radiation>
                                <PrevImage styleImg="panorama" name="f_panorama" setFieldValue={setFieldValue} radiation />
                                <PrevImage styleImg="cephalo" name="f_cephalo" setFieldValue={setFieldValue} radiation />
                              </S.DropListWrap>
                            </dd>
                          </S3.ListGroup>
                        </S3.ContentBox>
                      </FormControlsBox>
                    </S2.FormControlsContainer>
                  </S3.ContentBox>

                  <CardTail line>
                    <S4.ButtonLtBox>
                      <S4.StyledButton to={`/patient/${patientId}/profile`}>{t(IK.prev)}</S4.StyledButton>
                      <S4.StyledButton as="button" $primary type="submit">
                        {t(IK.submitted)}
                      </S4.StyledButton>
                    </S4.ButtonLtBox>
                  </CardTail>
                </Form>
              )}
            </Formik>
          )}
        </CardInnerLayout>
      </Beforeunload>
    </>
  );
}

export default ClinicalCasesRegister;

export const PrevImage = ({ styleImg, name, setFieldValue, required, radiation, index, files }) => {
  return (
    <S.DropList className={index !== 1 && required ? `required_img ${styleImg}` : `${styleImg}`}>
      <Previews setFieldValue={setFieldValue} name={name} radiation={radiation} files={files} isGallery />
      {required && <FieldMessageErrorClick name={name} />}
    </S.DropList>
  );
};
