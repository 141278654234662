import { useTranslation } from "react-i18next";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { ErrorCard } from "../../components/element/Error/ErrorCard";
import * as S from "../../components/element/Error/style/Error.style";
import { IK } from "../../utils/i18n/keyStore";

const NotFound = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <ErrorCard title={"404 ERROR"} subTitle={t(IK.error_subtitle)} contnetTxt={t(IK.error_content_txt2)}>
      <S.ErrorBtn>
        <S2.StyledButton $primary to="/">
          {t(IK.home)}
        </S2.StyledButton>
      </S.ErrorBtn>
    </ErrorCard>
  );
};

export default NotFound;
