import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveFileStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";
import { studyFormConfig } from "../../../../../utils/studyUtils";
import { Previews } from "../Preview/Preview";

function Extraoral({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, studyInfo, setStudyInfo, patientId, isFinish, setCurrentStepIndex }) {
  const { t } = useTranslation(["translation"]);
  const saveFileMutation = useSaveFileStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  const config = studyFormConfig[currentStepType][stepLabel];
  const validationSchema = Yup.object().shape(
    Object.entries(config).reduce((acc, [key, isRequired]) => {
      if (isRequired) {
        acc[key] = Yup.mixed().required(t(IK.image_required));
      } else {
        acc[key] = Yup.mixed().nullable();
      }
      return acc;
    }, {})
  );

  const handleSubmit = (values) => {
    // 빈 값 제거
    const cleanData = deleteEmptyKey(values);

    const formData = new FormData();
    let formDataLength = 0;
    Object.entries(cleanData).forEach(([key, value]) => {
      if (!value.studyFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    //등록할 파일이 있음
    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      //삭제할 아이디 추가
      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteStudyFileId", deleteStudyFileIds.join(","));
      }
      //패키지 코드 추가
      formData.append("studyPackagesCode", studyInfo.packages);

      //서버 전송시 로딩
      Notiflix.Loading.standard("");
      saveFileMutation.mutate(
        {
          step: "extraoral",
          formData,
          params: {
            studyId: studyInfo.studyId,
            patientId: patientId,
          },
        },
        {
          onSuccess: (data) => {
            const resData = {};

            data.data.data.forEach((item) => {
              resData[item.position] = item;
            });

            setDeleteStudyFileIds([]);
            setStudyInfo({ ...studyInfo, ...values, ...resData });
            Notiflix.Loading.remove();
            navigateNext();
          },
          onError: () => {
            Notiflix.Notify.failure(t(IK.again_message));
            Notiflix.Loading.remove();
          },
        }
      );
    } else {
      navigateNext();
    }
  };

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(currentStepList.length - 2);
    } else {
      next();
    }
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} message={t(IK.image_required_message_1)} icon={<i className="ri-fullscreen-line"></i>} />
      <Formik
        initialValues={{
          extraoral_front: studyInfo.extraoral_front || undefined,
          front_rest: studyInfo.front_rest || undefined,
          front_smile: studyInfo.front_smile || undefined,
          side45: studyInfo.side45 || undefined,
          side90: studyInfo.side90 || undefined,
          side90_smile: studyInfo.side90_smile || undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S.DropListWrap>
              <S.DropList className={config.extraoral_front ? "required_img extraoral_front" : "extraoral_front"}>
                <Previews
                  setFieldValue={setFieldValue}
                  files={studyInfo["extraoral_front"]}
                  name="extraoral_front"
                  setDeleteStudyFileIds={setDeleteStudyFileIds}
                  deleteStudyFileIds={deleteStudyFileIds}
                />
                <FieldMessageErrorClick name="extraoral_front" />
              </S.DropList>
              <S.DropList className={config.front_rest ? "required_img front_rest" : "front_rest"}>
                <Previews setFieldValue={setFieldValue} files={studyInfo["front_rest"]} name="front_rest" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="front_rest" />
              </S.DropList>
              <S.DropList className={config.front_smile ? "required_img front_smile" : "front_smile"}>
                <Previews setFieldValue={setFieldValue} files={studyInfo["front_smile"]} name="front_smile" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="front_smile" />
              </S.DropList>
              <S.DropList className={config.side45 ? "required_img side45" : "side45"}>
                <Previews setFieldValue={setFieldValue} files={studyInfo["side45"]} name="side45" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="side45" />
              </S.DropList>
              <S.DropList className={config.side90 ? "required_img side90" : "side90"}>
                <Previews setFieldValue={setFieldValue} files={studyInfo["side90"]} name="side90" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="side90" />
              </S.DropList>
              <S.DropList className={config.side90_smile ? "required_img side90_smile" : "side90_smile"}>
                <Previews setFieldValue={setFieldValue} files={studyInfo["side90_smile"]} name="side90_smile" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="side90_smile" />
              </S.DropList>
            </S.DropListWrap>

            <div className="noti_box">
              <i className="ri-upload-cloud-fill"></i>
              {t(IK.file_drag_message)}
            </div>

            <CardTail error={t(IK.image_required_message_2)} line study>
              <S3.CardFlex>
                <S2.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S2.StyledButton>
                <S2.ButtonLtBox>
                  <S2.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S2.StyledButton>
                  <S2.StyledButton as="button" $primary type="submit">
                    {t(IK.next)}
                  </S2.StyledButton>
                  {isFinish && (
                    <S2.StyledButton as="button" $primary type="submit" onClick={() => setSkip(true)}>
                      {t(IK.Skip_to_prescription_summary)}
                    </S2.StyledButton>
                  )}
                </S2.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Extraoral;
