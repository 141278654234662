import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import {
  FormControlsBox,
  FormControlsStudyBox,
} from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";

function DentalArchExtension({
  next,
  prev,
  currentStepType,
  stepLabel,
  currentStepList,
  currentStepIndex,
  studyInfo,
  setStudyInfo,
  patientId,
  isFinish,
  setCurrentStepIndex,
}) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const handleInputChange = (e, id) => {
    const index = e.currentTarget.value;
    const subElement = document.getElementById(id);
    if (index === "1") {
      subElement.classList.remove("hide");
    } else {
      subElement.classList.add("hide");
    }
  };

  const validationSchema = Yup.object().shape({
    SI04_01:
      studyInfo.SI01 !== "3"
        ? Yup.string().required(t(IK.dental_arch_extension_required1))
        : Yup.string(),
    SI04_02:
      studyInfo.SI01 !== "2"
        ? Yup.string().required(t(IK.dental_arch_extension_required2))
        : Yup.string(),
    SI04_01_01: Yup.number().when(["SI04_01"], {
      is: (val) => val === "1" && studyInfo.SI01 !== "3",
      then: (val) =>
        Yup.number()
          .moreThan(0, t(IK.enter_figure))
          .required(t(IK.enter_figure)),
    }),
    SI04_02_01: Yup.number().when(["SI04_02"], {
      is: (val) => val === "1" && studyInfo.SI01 !== "2",
      then: (val) =>
        Yup.number()
          .moreThan(0, t(IK.enter_figure))
          .required(t(IK.enter_figure)),
    }),
  });

  const code = currentStepList[currentStepIndex].code;
  const config = studyFormConfig[currentStepType][stepLabel];

  const handleSubmit = (values) => {
    const data = {
      SI04_01: values.SI04_01,
      SI04_02: values.SI04_02,
      SI04_01_01: values.SI04_01 === "1" ? values.SI04_01_01 : "",
      SI04_02_01: values.SI04_02 === "1" ? values.SI04_02_01 : "",
    };

    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip
            ? setCurrentStepIndex(currentStepList.length - 2)
            : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
        required
      />

      <Formik
        initialValues={{
          SI04_01: studyInfo.SI04_01 || "",
          SI04_01_01: (studyInfo.SI04_01 === "1" && studyInfo.SI04_01_01) || "",
          SI04_02: studyInfo.SI04_02 || "",
          SI04_02_01: (studyInfo.SI04_02 === "1" && studyInfo.SI04_02_01) || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormControlsStudyBox
              title={t(IK.dental_arch_extension)}
              required
              openModal={openModal}
            >
              <div className="frm_column v2">
                {(!studyInfo.SI01 ||
                  studyInfo.SI01 === "1" ||
                  studyInfo.SI01 === "2") && (
                  <FormControlsBox
                    regular
                    title={`${t(IK.maxilla)} ${t(IK.dental_arch_extension)}`}
                  >
                    <div className="frm_column">
                      <div className="chk_area">
                        {config.maxilla.not_necessary && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI04_01"
                                className="input_radio"
                                onChange={(e) => {
                                  handleInputChange(e, "sub_4_1");
                                  setFieldValue("SI04_01", "3");
                                }}
                                value="3"
                              />
                              <span>{t(IK.not_necessary)}</span>
                            </label>
                          </div>
                        )}

                        {config.maxilla.implementation_necessary && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI04_01"
                                className="input_radio"
                                onChange={(e) => {
                                  handleInputChange(e, "sub_4_1");
                                  setFieldValue("SI04_01", "2");
                                }}
                                value="2"
                              />
                              <span>{t(IK.implementation_necessary)}</span>
                            </label>
                          </div>
                        )}
                        <div className="frm_flex v2">
                          {config.maxilla.riority_implementation && (
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI04_01"
                                  onChange={(e) => {
                                    handleInputChange(e, "sub_4_1");
                                    setFieldValue("SI04_01", "1");
                                  }}
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                          )}
                          {config.maxilla.dental_arch_extension && (
                            <div
                              id="sub_4_1"
                              className={
                                studyInfo.SI04_01 !== "1" ? "hide" : ""
                              }
                            >
                              <div className="frm_line_field">
                                {t(IK.dental_arch_extension_item)}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Field
                                  name="SI04_01_01"
                                  type="number"
                                  maxLength={2}
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                  }}
                                />
                                mm
                              </div>
                              <FieldMessageErrorClick name="SI04_01_01" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <FieldMessageErrorClick name="SI04_01" />
                  </FormControlsBox>
                )}

                {(!studyInfo.SI01 ||
                  studyInfo.SI01 === "1" ||
                  studyInfo.SI01 === "3") && (
                  <FormControlsBox
                    regular
                    title={`${t(IK.mandible)} ${t(IK.dental_arch_extension)}`}
                  >
                    <div className="frm_column">
                      <div className="chk_area">
                        {config.mandible.not_necessary && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI04_02"
                                className="input_radio"
                                onChange={(e) => {
                                  handleInputChange(e, "sub_4_2");
                                  setFieldValue("SI04_02", "3");
                                }}
                                value="3"
                              />
                              <span>{t(IK.not_necessary)}</span>
                            </label>
                          </div>
                        )}

                        {config.mandible.implementation_necessary && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI04_02"
                                className="input_radio"
                                onChange={(e) => {
                                  handleInputChange(e, "sub_4_2");
                                  setFieldValue("SI04_02", "2");
                                }}
                                value="2"
                              />
                              <span>{t(IK.implementation_necessary)}</span>
                            </label>
                          </div>
                        )}

                        <div className="frm_flex v2">
                          {config.mandible.riority_implementation && (
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI04_02"
                                  onChange={(e) => {
                                    handleInputChange(e, "sub_4_2");
                                    setFieldValue("SI04_02", "1");
                                  }}
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                          )}
                          {config.mandible.dental_arch_extension && (
                            <div
                              id="sub_4_2"
                              className={
                                studyInfo.SI04_02 !== "1" ? "hide" : ""
                              }
                            >
                              <div className="frm_line_field">
                                {t(IK.dental_arch_extension_item)}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Field
                                  name="SI04_02_01"
                                  type="number"
                                  maxLength={2}
                                  onInput={(e) => {
                                    if (
                                      e.target.value.length > e.target.maxLength
                                    )
                                      e.target.value = e.target.value.slice(
                                        0,
                                        e.target.maxLength
                                      );
                                  }}
                                />
                                mm
                              </div>
                              <FieldMessageErrorClick name="SI04_02_01" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <FieldMessageErrorClick name="SI04_02" />
                  </FormControlsBox>
                )}
              </div>
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton
                  $secondary
                  as="button"
                  type="button"
                  onClick={() => setCurrentStepIndex(1)}
                >
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton
                      as="button"
                      type="submit"
                      onClick={() => setSkip(true)}
                      $primary
                    >
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.dental_arch_extension_pop_message1)}</li>
          <li>{t(IK.dental_arch_extension_pop_message2)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default DentalArchExtension;
