/**
 * 객체의 Boolean 속성을 'Y'로 변환하여 반환한다.
 * @param {Object} obj 변환할 객체
 * @returns {Object} 변환된 객체
 */
export function convertToY(obj) {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value === true) {
      result[key] = "Y";
    } else if (value !== false && value !== null && value !== undefined && value !== "") {
      result[key] = value;
    }
  }
  return result;
}

/**
 * 객체에서 값이 비어있는 키(`null`, `undefined`, 빈 문자열)를 삭제합니다.
 *
 * @param {Object} obj - 입력 객체입니다.
 * @returns {Object} - 입력 객체와 동일한 키를 가진 객체지만, 비어있는 것들이 제거된 객체입니다.
 */
export function deleteEmptyKey(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      return ![null, undefined, ""].includes(value) && (!(value instanceof Object) || value instanceof File || Object.keys(value).length > 0);
    })
  );
}

/**
 * 주어진 JSON 배열을 ID 값을 키로 갖는 JSON 객체로 변환합니다.
 * @param {Array} jsonArray - 변환할 JSON 배열
 * @param {string} key - JSON 객체의 키로 사용할 속성 이름
 * @returns {Object} - ID 값을 키로 갖는 JSON 객체를 반환합니다.
 */
export function jsonArrayToObject(jsonArray, key) {
  let result = {};
  jsonArray.forEach((item) => {
    result[item[key]] = item;
  });
  return result;
}

export function jsonArrayToObjectBoolean(jsonArray, key, value) {
  let result = {};
  jsonArray.forEach((item) => {
    if (item[key] !== "otherText") {
      result[item[key]] = item[value] === "Y" ? true : false;
    } else {
      result[item[key]] = item[value];
    }
  });
  return result;
}

export function convertKeysToLowerCase(obj) {
  let key,
    keys = Object.keys(obj);
  let n = keys.length;
  let newobj = {};
  while (n--) {
    key = keys[n];
    if (obj[key]) {
      if (typeof obj[key] === "object") {
        newobj[key.toLowerCase()] = obj[key].join(",");
      } else {
        newobj[key.toLowerCase()] = obj[key];
      }
    }
  }
  return newobj;
}

/**본처방전 초기화 */
export function removeSIKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (!key.startsWith("SI")) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
