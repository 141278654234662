import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGetPatientListTapCount } from "../../../../apis/patientApi";
import { IK } from "../../../../utils/i18n/keyStore";
import * as S from "../style/PatientsList.style";
import ToolTip from "../../../../components/element/ToolTip/ToolTip";

const TabType = ({ setSearchCondition, searchCondition }) => {
  const { t } = useTranslation(["translation"]);

  /**승인된 치료계획 수 */
  const { data } = useGetPatientListTapCount();
  const tabCount = data?.data;

  const handleOnClick = useCallback(
    (condition) => {
      setSearchCondition((prevState) => {
        return { ...prevState, status: condition, page: 1 };
      });
    },
    [setSearchCondition]
  );

  const tabList = [
    {
      title: "total_patient",
      icon: "ri-empathize-line",
      condition: "PS01", //전체환자
      count: tabCount?.totlaPatientCount,
      rgbacolor: "rgba(233, 113, 166, 0.1)",
      color: "#e971a6",
    },
    {
      title: "need_check",
      icon: "ri-check-fill",
      condition: "PS05", //확인필요
      count: tabCount?.needToBeConfirmedCount,
      rgbacolor: "rgba(227, 85, 81, 0.1)",
      color: "#e35551",
    },
    {
      title: "review_request",
      icon: "ri-edit-line",
      condition: "PS02", //검토요청
      count: tabCount?.needCheckCount,
      rgbacolor: "rgba(253, 126, 20, 0.1)",
      color: "#fd7e14",
    },
    {
      title: "production",
      icon: "ri-microscope-line",
      condition: "PS03", //제작중
      count: tabCount?.excutionCount,
      rgbacolor: "rgba(76, 175, 80, 0.1)",
      color: "#4caf50",
    },
    {
      title: "delivery_completed",
      icon: "ri-truck-line",
      condition: "PS04", //배송완료
      count: tabCount?.completedCount,
      rgbacolor: "rgba(33, 150, 243, 0.1)",
      color: "#2196f3",
    },
    {
      title: "locker",
      icon: "ri-first-aid-kit-line",
      condition: "PS99", //보관함
      count: tabCount?.storageCount,
      rgbacolor: "rgba(111, 66, 193, 0.1)",
      color: "#6f42c1",
    },
  ];

  return (
    <S.PatientTab>
      {tabList.map((tab, i) => (
        <S.PatientTabItem key={tab.title} className={searchCondition.status === tab.condition ? "active" : ""} $index={i + 1} $rgbacolor={tab.rgbacolor} $color={tab.color}>
          <button type="button" onClick={() => handleOnClick(tab.condition)}>
            <S.PatientTabIco>
              <i className={tab.icon}></i>
            </S.PatientTabIco>
            <S.PatientTabTxt>{t(IK[tab.title])}</S.PatientTabTxt>
            <S.PatientTabCount>
              {tab.count}
              <ToolTip content={tab.count} />
            </S.PatientTabCount>
          </button>
        </S.PatientTabItem>
      ))}
    </S.PatientTab>
  );
};

export default TabType;
