import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import {
  FormControlsBox,
  FormControlsStudyBox,
} from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";
import { TitleV4 } from "../../../../../components/element/Title/TitleText";

function Crowding({
  next,
  prev,
  currentStepType,
  stepLabel,
  currentStepList,
  currentStepIndex,
  setStudyInfo,
  studyInfo,
  patientId,
  isFinish,
  setCurrentStepIndex,
}) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const schema = {
    SI09_02_01_01: Yup.string().required(t(IK.crowding_required1)),
    SI09_02_01_02: Yup.string().required(t(IK.crowding_required2)),
    SI09_02_01_03: Yup.string().required(t(IK.crowding_required3)),
    SI09_02_01_04: Yup.string().required(t(IK.crowding_required4)),
    SI09_02_02_01: Yup.string().required(t(IK.crowding_required5)),
    SI09_02_02_02: Yup.string().required(t(IK.crowding_required6)),
    SI09_02_02_03: Yup.string().required(t(IK.crowding_required7)),
    SI09_02_02_04: Yup.string().required(t(IK.crowding_required8)),
  };
  if (studyInfo.SI01 === "2") {
    delete schema.SI09_02_02_01;
    delete schema.SI09_02_02_02;
    delete schema.SI09_02_02_03;
    delete schema.SI09_02_02_04;
  }
  if (studyInfo.SI01 === "3") {
    delete schema.SI09_02_01_01;
    delete schema.SI09_02_01_02;
    delete schema.SI09_02_01_03;
    delete schema.SI09_02_01_04;
  }
  const validationSchema = Yup.object().shape(schema);

  const code = currentStepList[currentStepIndex].code;
  const handleSubmit = (data) => {
    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip
            ? setCurrentStepIndex(currentStepList.length - 2)
            : next();
        },
      }
    );
  };
  const config = studyFormConfig[currentStepType][stepLabel];

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
        required
      />

      <Formik
        initialValues={{
          SI09_02_01_01: studyInfo.SI09_02_01_01 || "",
          SI09_02_01_02: studyInfo.SI09_02_01_02 || "",
          SI09_02_01_03: studyInfo.SI09_02_01_03 || "",
          SI09_02_01_04: studyInfo.SI09_02_01_04 || "",
          SI09_02_02_01: studyInfo.SI09_02_02_01 || "",
          SI09_02_02_02: studyInfo.SI09_02_02_02 || "",
          SI09_02_02_03: studyInfo.SI09_02_02_03 || "",
          SI09_02_02_04: studyInfo.SI09_02_02_04 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        <Form>
          <FormControlsStudyBox title="Crowding" required openModal={openModal}>
            <div className="card_flex">
              {studyInfo.SI01 === "1" || studyInfo.SI01 === "2" ? (
                <div className="col_wd_pre">
                  <TitleV4 title={t(IK.maxilla)} />
                  <div className="frm_column v2 frm_radio_indent">
                    {config.crowding_item1_title && (
                      <FormControlsBox
                        medium
                        title={t(IK.crowding_item1_title)}
                      >
                        <div className="chk_area">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI09_02_01_01"
                                className="input_radio"
                                value="1"
                              />
                              <span>{t(IK.increase_incline)}</span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI09_02_01_01"
                                className="input_radio"
                                value="2"
                              />
                              <span>{t(IK.maintain_incline)}</span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI09_02_01_01"
                                className="input_radio"
                                value="3"
                              />
                              <span>{t(IK.decrease_incline)}</span>
                            </label>
                          </div>
                        </div>
                        <FieldMessageErrorClick name="SI09_02_01_01" />
                      </FormControlsBox>
                    )}

                    {config.crowding_item2_title && (
                      <div className="frm_group">
                        <div className="frm_title v1">
                          <h3
                            style={{ paddingLeft: "60px", textIndent: "-60px" }}
                          >
                            IPR&emsp;&ensp;{t(IK.crowding_item2_title)}
                          </h3>
                        </div>
                        <div className="frm_area">
                          <div className="chk_area">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_02"
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_02"
                                  className="input_radio"
                                  value="2"
                                />
                                <span>{t(IK.implementation_necessary)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_02"
                                  className="input_radio"
                                  value="3"
                                />
                                <span>{t(IK.not_necessary)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI09_02_01_02" />
                        </div>
                      </div>
                    )}
                    {config.crowding_item3_title && (
                      <div className="frm_group">
                        <div className="frm_title v1">
                          <h3
                            style={{ paddingLeft: "60px", textIndent: "-60px" }}
                          >
                            &emsp;&emsp;&emsp;&nbsp;{t(IK.crowding_item3_title)}
                          </h3>
                        </div>
                        <div className="frm_area">
                          <div className="chk_area">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_03"
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_03"
                                  className="input_radio"
                                  value="2"
                                />
                                <span>{t(IK.implementation_necessary)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_03"
                                  className="input_radio"
                                  value="3"
                                />
                                <span>{t(IK.not_necessary)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI09_02_01_03" />
                        </div>
                      </div>
                    )}
                    {config.crowding_item4_title && (
                      <div className="frm_group">
                        <div className="frm_title v1">
                          <h3
                            style={{ paddingLeft: "60px", textIndent: "-60px" }}
                          >
                            &emsp;&emsp;&emsp;&nbsp;{t(IK.crowding_item4_title)}
                          </h3>
                        </div>
                        <div className="frm_area">
                          <div className="chk_area">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_04"
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_04"
                                  className="input_radio"
                                  value="2"
                                />
                                <span>{t(IK.implementation_necessary)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_01_04"
                                  className="input_radio"
                                  value="3"
                                />
                                <span>{t(IK.not_necessary)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI09_02_01_04" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {studyInfo.SI01 === "1" || studyInfo.SI01 === "3" ? (
                <div className="col_wd_pre">
                  <TitleV4 title={t(IK.mandible)} />
                  <div className="frm_column v2 frm_radio_indent">
                    {config.crowding_item5_title && (
                      <FormControlsBox
                        medium
                        title={t(IK.crowding_item5_title)}
                      >
                        <div className="chk_area">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI09_02_02_01"
                                className="input_radio"
                                value="1"
                              />
                              <span>{t(IK.increase_incline)}</span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI09_02_02_01"
                                className="input_radio"
                                value="2"
                              />
                              <span>{t(IK.maintain_incline)}</span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI09_02_02_01"
                                className="input_radio"
                                value="3"
                              />
                              <span>{t(IK.decrease_incline)}</span>
                            </label>
                          </div>
                        </div>
                        <FieldMessageErrorClick name="SI09_02_02_01" />
                      </FormControlsBox>
                    )}
                    {config.crowding_item6_title && (
                      <div className="frm_group">
                        <div className="frm_title v1">
                          <h3
                            style={{ paddingLeft: "60px", textIndent: "-60px" }}
                          >
                            IPR&emsp;&ensp;{t(IK.crowding_item6_title)}
                          </h3>
                        </div>
                        <div className="frm_area">
                          <div className="chk_area">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_02"
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_02"
                                  className="input_radio"
                                  value="2"
                                />
                                <span>{t(IK.implementation_necessary)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_02"
                                  className="input_radio"
                                  value="3"
                                />
                                <span>{t(IK.not_necessary)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI09_02_02_02" />
                        </div>
                      </div>
                    )}
                    {config.crowding_item7_title && (
                      <div className="frm_group">
                        <div className="frm_title v1">
                          <h3
                            style={{ paddingLeft: "60px", textIndent: "-60px" }}
                          >
                            &emsp;&emsp;&emsp;&nbsp;{t(IK.crowding_item7_title)}
                          </h3>
                        </div>
                        <div className="frm_area">
                          <div className="chk_area">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_03"
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_03"
                                  className="input_radio"
                                  value="2"
                                />
                                <span>{t(IK.implementation_necessary)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_03"
                                  className="input_radio"
                                  value="3"
                                />
                                <span>{t(IK.not_necessary)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI09_02_02_03" />
                        </div>
                      </div>
                    )}
                    {config.crowding_item8_title && (
                      <div className="frm_group">
                        <div className="frm_title v1">
                          <h3
                            style={{ paddingLeft: "60px", textIndent: "-60px" }}
                          >
                            &emsp;&emsp;&emsp;&nbsp;{t(IK.crowding_item8_title)}
                          </h3>
                        </div>
                        <div className="frm_area">
                          <div className="chk_area">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_04"
                                  className="input_radio"
                                  value="1"
                                />
                                <span>{t(IK.riority_implementation)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_04"
                                  className="input_radio"
                                  value="2"
                                />
                                <span>{t(IK.implementation_necessary)}</span>
                              </label>
                            </div>
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI09_02_02_04"
                                  className="input_radio"
                                  value="3"
                                />
                                <span>{t(IK.not_necessary)}</span>
                              </label>
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI09_02_02_04" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </FormControlsStudyBox>

          <CardTail line study>
            <S3.CardFlex>
              <S.StyledButton
                $secondary
                as="button"
                type="button"
                onClick={() => setCurrentStepIndex(1)}
              >
                {t(IK.select_treatment_options)}
              </S.StyledButton>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" type="submit" $primary>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton
                    as="button"
                    type="submit"
                    onClick={() => setSkip(true)}
                    $primary
                  >
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </S3.CardFlex>
          </CardTail>
        </Form>
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.crowding_pop_message1)}</li>
          <li>{t(IK.crowding_pop_message2)}</li>
          <li>{t(IK.crowding_pop_message3)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Crowding;
