import { useTranslation } from "react-i18next";
import { studyStatusLabel } from "../../../../../json/studyStatusObject";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";
import ApprovedSeraview from "../statusItem/ApprovedSeraview";
import ConfirmSeraview from "../statusItem/ConfirmSeraview";
import DevelopeStudyPlan from "../statusItem/DevelopeStudyPlan";
import ProgressAligner from "../statusItem/ProgressAligner";
import ReceiptStudy from "../statusItem/ReceiptStudy";
import RejectStudy from "../statusItem/RejectStudy";
import ShippedDevice from "../statusItem/ShippedDevice";
import SubmitStudy from "../statusItem/SubmitStudy";
import TransitDevice from "../statusItem/TransitDevice";
import WritingStudy from "../statusItem/WritingStudy";
const StudyHistoryList = ({ studyStatusList, lastDetail, selectedId, selectedStudy, isAdditionalEdit }) => {
  const { t } = useTranslation(["translation"]);

  /**세라뷰 단계를 거치지 않는 케이스 */
  const skipSeraview = selectedStudy?.packages === "RP" || Object.keys(selectedId)[0] === "replacement" || isAdditionalEdit === "none";

  return (
    <S.ProfileHistoryList>
      {/**처방전 상태 */}
      <S.ProfileHistoryItem>
        <S.ProfileHistoryStep $progress={lastDetail <= "14"} $complete={lastDetail >= "14"}>
          <div>
            <h3>{lastDetail <= "14" ? studyStatusLabel(lastDetail, t) : t(IK.receipt_study)}</h3>
            <span></span>
          </div>
        </S.ProfileHistoryStep>
        <S.ProfileHistoryBox>
          {lastDetail === "11" ? (
            <RejectStudy progress studyInfo={studyStatusList?.["11"]} lastDetail={lastDetail} selectedId={selectedId} recentStatusId={studyStatusList?.["11"]?.statusId} />
          ) : (
            <WritingStudy progress studyInfo={studyStatusList?.["01"] || studyStatusList?.["12"]} lastDetail={lastDetail} selectedId={selectedId} />
          )}
          <SubmitStudy progress studyInfo={studyStatusList?.["13"]} lastDetail={lastDetail} selectedId={selectedId} />
          <ReceiptStudy progress studyInfo={studyStatusList?.["14"]} lastDetail={lastDetail} />
        </S.ProfileHistoryBox>
      </S.ProfileHistoryItem>

      {/**세라뷰 상태 */}
      {!skipSeraview && (
        <S.ProfileHistoryItem>
          <S.ProfileHistoryStep $progress={lastDetail <= "17" && lastDetail > "14"} $complete={lastDetail >= "17"}>
            <div>
              <h3>{lastDetail <= "17" && lastDetail > "14" ? studyStatusLabel(lastDetail, t) : t(IK.approved_seraview)}</h3>
              <span></span>
            </div>
          </S.ProfileHistoryStep>
          <S.ProfileHistoryBox>
            <DevelopeStudyPlan progress studyInfo={studyStatusList?.["15"]} lastDetail={lastDetail} />
            <ConfirmSeraview progress studyInfo={{ ...studyStatusList?.["16"], patientId: selectedStudy.patientId }} lastDetail={lastDetail} />
            <ApprovedSeraview progress studyInfo={studyStatusList?.["17"]} lastDetail={lastDetail} />
          </S.ProfileHistoryBox>
        </S.ProfileHistoryItem>
      )}

      {/**장치 상태 */}
      <S.ProfileHistoryItem>
        <S.ProfileHistoryStep $hidebar $progress={lastDetail >= "18" && lastDetail !== "21"} $complete={lastDetail === "20" || lastDetail === "22"}>
          <div>
            <h3>{lastDetail > "17" && lastDetail < "21" ? studyStatusLabel(lastDetail, t) : t(IK.shipped_device)}</h3>
            <span></span>
          </div>
        </S.ProfileHistoryStep>
        <S.ProfileHistoryBox>
          <ProgressAligner progress studyInfo={studyStatusList?.["18"]} lastDetail={lastDetail} />
          <TransitDevice progress studyInfo={studyStatusList?.["19"]} lastDetail={lastDetail} />
          <ShippedDevice progress studyInfo={studyStatusList?.["20"]} lastDetail={lastDetail} />
        </S.ProfileHistoryBox>
      </S.ProfileHistoryItem>
    </S.ProfileHistoryList>
  );
};

export default StudyHistoryList;
