import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetPatientIntraoral, useUpdatePatientIntraoral } from "../../../../apis/patientApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../components/element/Drop/style/Drop.style";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import LoadingIndicator from "../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../utils/objectUtils";
import { Previews } from "../Preview/Preview";

const IntraoralEdit = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  /**구내 사진 가져오기 */
  const { data, isLoading } = useGetPatientIntraoral(patientId);
  const intraoralInfo = data?.data;

  const { t } = useTranslation(["translation"]);

  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  const validationSchema = Yup.object().shape({
    occlusal_upper: Yup.mixed().required(t(IK.image_required)),
    occlusal_lower: Yup.mixed().required(t(IK.image_required)),
    buccal_right: Yup.mixed().required(t(IK.image_required)),
    overjet: Yup.mixed().required(t(IK.image_required)),
    buccal_left: Yup.mixed().required(t(IK.image_required)),
  });

  const intraoralMutation = useUpdatePatientIntraoral();

  /**구내 사진 수정 */
  const handleSubmit = (values) => {
    const data = deleteEmptyKey(values);
    const formData = new FormData();

    let formDataLength = 0;
    Object.entries(data).forEach(([key, value]) => {
      if (!value.patientFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      Notiflix.Loading.standard("");
      //삭제할 파일이 있을 경우
      if (deleteStudyFileIds.length > 0) {
        formData.append("deletePatientFileIds", deleteStudyFileIds.join(","));
      }

      intraoralMutation.mutate(
        { patientId, formData },
        {
          onSuccess: () => {
            setDeleteStudyFileIds([]);
            Notiflix.Loading.remove();
            Notiflix.Notify.success(t(IK.update_success));
          },
          onError: (err) => {
            Notiflix.Loading.remove();
            if (err?.response?.status !== 403) {
              Notiflix.Notify.failure(t(IK.again_message));
            }
          },
        }
      );
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Formik
          initialValues={{
            occlusal_upper: intraoralInfo.occlusal_upper || undefined,
            intraoral_front: intraoralInfo.intraoral_front || undefined,
            occlusal_lower: intraoralInfo.occlusal_lower || undefined,
            buccal_right: intraoralInfo.buccal_right || undefined,
            overjet: intraoralInfo.overjet || undefined,
            buccal_left: intraoralInfo.buccal_left || undefined,
          }}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <CardInnerLayout>
                <CardTitle title={t(IK.intraoral)} icon={<i className="ri-fullscreen-line" />} message={t(IK.image_required_message_1)} />
                <S.DropListWrap>
                  <S.DropList className="required_img occlusal_upper">
                    <Previews
                      setFieldValue={setFieldValue}
                      setDeleteStudyFileIds={setDeleteStudyFileIds}
                      deleteStudyFileIds={deleteStudyFileIds}
                      filesData={values.occlusal_upper}
                      name="occlusal_upper"
                    />
                    {errors.occlusal_upper && <FieldMessageError text={errors.occlusal_upper} />}
                  </S.DropList>
                  <S.DropList className="intraoral_front">
                    <Previews
                      setFieldValue={setFieldValue}
                      setDeleteStudyFileIds={setDeleteStudyFileIds}
                      deleteStudyFileIds={deleteStudyFileIds}
                      filesData={values.intraoral_front}
                      name="intraoral_front"
                    />
                  </S.DropList>
                  <S.DropList className="required_img occlusal_lower">
                    <Previews
                      setFieldValue={setFieldValue}
                      setDeleteStudyFileIds={setDeleteStudyFileIds}
                      deleteStudyFileIds={deleteStudyFileIds}
                      filesData={values.occlusal_lower}
                      name="occlusal_lower"
                    />
                    {errors.occlusal_lower && <FieldMessageError text={errors.occlusal_lower} />}
                  </S.DropList>
                  <S.DropList className="required_img buccal_right">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.buccal_right} name="buccal_right" />
                    {errors.buccal_right && <FieldMessageError text={errors.buccal_right} />}
                  </S.DropList>
                  <S.DropList className="required_img overjet">
                    <Previews setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} filesData={values.overjet} name="overjet" />
                    {errors.overjet && <FieldMessageError text={errors.overjet} />}
                  </S.DropList>
                  <S.DropList className="required_img buccal_left">
                    <Previews setFieldValue={setFieldValue} filesData={values.buccal_left} setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} name="buccal_left" />
                    {errors.buccal_left && <FieldMessageError text={errors.buccal_left} />}
                  </S.DropList>
                </S.DropListWrap>

                <div className="noti_box">
                  <i className="ri-upload-cloud-fill"></i>
                  {t(IK.file_drag_message)}
                </div>

                <CardTail line>
                  <S2.ButtonLtBox>
                    <S2.StyledButton as="button" type="button" onClick={() => navigate(`/patient/${patientId}/profile`)}>
                      {t(IK.list)}
                    </S2.StyledButton>
                    <S2.StyledButton
                      as="button"
                      $primary
                      type="submit"
                      onClick={() => {
                        validationSchema
                          .validate(values)
                          .then(() => console.log("Validation succeeded"))
                          .catch((err) => {
                            console.log(err.errors);
                          });
                      }}
                    >
                      {t(IK.save)}
                    </S2.StyledButton>
                  </S2.ButtonLtBox>
                </CardTail>
              </CardInnerLayout>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default IntraoralEdit;
