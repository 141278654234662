import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/Summary.style";

export const SummaryTableLi = ({ endline, children, title, dataId, handleClickForUpdate, printshow }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <S.SummaryListItem $endline={endline} $printshow={printshow}>
      <dl>
        <dt>
          <h2>{title}</h2>
          {handleClickForUpdate && (
            <button type="button" data-location={dataId} onClick={handleClickForUpdate}>
              <i className="ri-pencil-fill" />
              {t(IK.modify)}
            </button>
          )}
        </dt>
        <dd>{children}</dd>
      </dl>
    </S.SummaryListItem>
  );
};
