import { useCallback, useEffect, useMemo } from "react";

/**
 * 총합 금액 계산
 * @param {Array} billsInfo - 청구 정보 배열
 * @param {boolean} isLoading - 로딩 상태
 * @param {Function} setTotals - 총액을 설정하는 함수
 */
const useCalculateTotal = (billsInfo, isLoading, setTotals) => {
  const calculateTotal = useCallback(() => {
    return billsInfo.reduce(
      (result, item) => {
        const { discountPrice = 0, paymentPrice = 0, salePrice = 0, discountCoupon = 0, check } = item;

        if (check) {
          result.discountPrice += discountPrice;
          result.paymentPrice += paymentPrice;
          result.salePrice += salePrice;
          result.discountCoupon += discountCoupon;
        }

        // 총합계산
        result.totalSalelPrice += salePrice;
        result.totalDiscountPrice += discountPrice;
        result.totalPaymentPrice += paymentPrice;

        return result;
      },
      {
        discountPrice: 0,
        paymentPrice: 0,
        salePrice: 0,
        discountCoupon: 0,
        totalSalelPrice: 0,
        totalDiscountPrice: 0,
        totalPaymentPrice: 0,
      }
    );
  }, [billsInfo]);

  useEffect(() => {
    if (!isLoading) {
      setTotals(calculateTotal());
    }
  }, [isLoading, calculateTotal, setTotals]);

  return useMemo(() => calculateTotal(), [calculateTotal]);
};

export default useCalculateTotal;
