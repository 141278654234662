import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useGetAddress } from "../../../../apis/addressApi";
import { updatePatientInfo, useGetPatientInfo } from "../../../../apis/patientApi";
import { userInfo } from "../../../../atoms/userAtom";
import { CardInnerLayout, CardTail, CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../../components/element/Form/FormLayout";
import * as S2 from "../../../../components/element/Form/style/Form.style";
import * as S3 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import LoadingIndicator from "../../../../components/loading/LoadingIndicator/LoadingIndicator";
import AddressPopupBtn from "../../../../components/popup/AddressPopup";
import { formatDate } from "../../../../utils/dateUtils";
import { enterKeyDown } from "../../../../utils/enterKeyDown";
import { IK } from "../../../../utils/i18n/keyStore";
import AddressModal from "../../../Account/AddressManage/AddressModal";

const RegisterFormEdit = () => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const { patientId } = useParams();

  /**국가코드 조회 */
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  /**환자 정보 조회 */
  const { data: PatientData, isLoading } = useGetPatientInfo(patientId);
  const patientInfo = PatientData?.data;

  /**주소지 조회 */
  const { data: addressData } = useGetAddress();
  const addressList = [{ addressId: 0, addressName: t(IK.new_address_add), isDefault: true }, ...(addressData?.data || [])];

  /**선택된 주소로 변경 */
  const handleOnClickAddress = (item, setFieldValue, addressType) => {
    const defaultAddress = item.addressId === 0;
    const patientAddressDTO = patientInfo.patientAddressDTO;

    setFieldValue(`${addressType}AddressName`, defaultAddress ? patientAddressDTO[`${addressType}AddressName`] : item.addressName || "");
    setFieldValue(`${addressType}Address`, defaultAddress ? patientAddressDTO[`${addressType}Address`] : item.address || "");
    setFieldValue(`${addressType}PostalCode`, defaultAddress ? patientAddressDTO[`${addressType}PostalCode`] : item.postalCode || "");
    setFieldValue(`${addressType}AddressDetail`, defaultAddress ? patientAddressDTO[`${addressType}AddressDetail`] : item.addressDetail || "");
  };

  /**주소지 선택 */
  const handleOnClickQuickShipAddress = (item, setFieldValue) => {
    handleOnClickAddress(item, setFieldValue, "ship");
  };

  /**청구지 선택 */
  const handleOnClickQuickBillAddress = (item, setFieldValue) => {
    handleOnClickAddress(item, setFieldValue, "bill");
  };

  /**생년월일 입력 */
  const onBlurDate = (e, setFieldValue, setFieldError) => {
    const formatDated = formatDate(e.target.value);
    if (formatDated) {
      setFieldValue("birthday", formatDated);
    } else {
      setFieldValue("birthday", "");
      setFieldError("birthday", t(IK.date_format_valid));
    }
  };

  /**주소지 등록 모달 상태관리 */
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  /**주소지 등록 모달 닫기*/
  const closeAddressModal = () => {
    setAddressModalOpen(false);
  };

  /**환자 정보 업데이트*/
  const handleSubmit = (values) => {
    updatePatientInfo(patientId, values)
      .then((response) => {
        Notiflix.Notify.success(t(IK.update_success));
      })
      .catch((err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      });
  };

  const validationSchema = Yup.object().shape({
    koreaName:
      countryCode === "01"
        ? Yup.string()
            .matches(/^[가-힣0-9]+$/, t(IK.only_korean_number))
            .required(t(IK.korea_name_required))
            .max(10, t(IK.name_length_limit_10))
        : Yup.string(),
    lastName: Yup.string()
      .required(t(IK.english_name_required))
      .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
      .max(20, t(IK.name_length_limit)),
    firstName: Yup.string()
      .required(t(IK.english_name_required))
      .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
      .max(20, t(IK.name_length_limit)),
    gender: Yup.string().required(t(IK.gender_required)),
    birthday: Yup.string()
      .required(t(IK.birthday_required))
      .matches(/^\d{4}-\d{2}-\d{2}$/, t(IK.date_format_valid)),
    race: Yup.string().required(t(IK.race_required)),
    shipAddressName: Yup.string().required(t(IK.clinic_hospital_required)),
    shipPostalCode: Yup.string().required(t(IK.postcode_required)),
    shipAddress: Yup.string().required(t(IK.address_required)),
    billAddressName: Yup.string().required(t(IK.clinic_hospital_required)),
    billPostalCode: Yup.string().required(t(IK.postcode_required)),
    billAddress: Yup.string().required(t(IK.address_required)),
  });

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Formik
          initialValues={{
            koreaName: patientInfo.koreaName || "",
            lastName: patientInfo.lastName || "",
            firstName: patientInfo.firstName || "",
            gender: patientInfo.gender || "",
            birthday: patientInfo.birthday || "",
            race: patientInfo.race || "",
            shipAddressName: patientInfo.patientAddressDTO?.shipAddressName || "",
            shipAddress: patientInfo.patientAddressDTO?.shipAddress || "",
            shipPostalCode: patientInfo.patientAddressDTO?.shipPostalCode || "",
            shipAddressDetail: patientInfo.patientAddressDTO?.shipAddressDetail || "",
            billAddressName: patientInfo.patientAddressDTO?.billAddressName || "",
            billAddress: patientInfo.patientAddressDTO?.billAddress || "",
            billPostalCode: patientInfo.patientAddressDTO?.billPostalCode || "",
            billAddressDetail: patientInfo.patientAddressDTO?.billAddressDetail || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({ setFieldValue, setFieldError, values }) => (
            <Form onKeyDown={enterKeyDown}>
              <CardInnerLayout>
                <CardTitle title={t(IK.enter_patient_information)} required />

                <S2.FormControlsColumn $large>
                  {/* 이름(한글) */}
                  {countryCode === "01" && (
                    <FormControlsBox required title={t(IK.korea_name)}>
                      <Field as={S2.FormControls} type="text" name="koreaName" maxLength="10" />
                      <FieldMessageErrorClick name="koreaName" />
                    </FormControlsBox>
                  )}

                  {/* 이름(영문) */}
                  <FormControlsBox required title={t(IK.english_name)}>
                    <S2.FormControlsFlex>
                      <div>
                        <Field as={S2.FormControls} type="text" name="firstName" placeholder={t(IK.first_name)} maxLength="20" />
                        <FieldMessageErrorClick name="firstName" />
                      </div>
                      <div>
                        <Field as={S2.FormControls} type="text" name="lastName" placeholder={t(IK.last_name)} maxLength="20" />
                        <FieldMessageErrorClick name="lastName" />
                      </div>
                    </S2.FormControlsFlex>
                  </FormControlsBox>

                  {/* 성별 */}
                  <FormControlsBox required title={t(IK.gender)}>
                    <S3.SelectionItemList>
                      <S3.SelectionItem>
                        <S3.SelectionItemLabel>
                          <Field as={S3.SelectionItemInput} $radio type="radio" name="gender" value="male" />
                          <S3.SelectionItemSpan>{t(IK.male)}</S3.SelectionItemSpan>
                        </S3.SelectionItemLabel>
                      </S3.SelectionItem>
                      <S3.SelectionItem>
                        <S3.SelectionItemLabel>
                          <Field as={S3.SelectionItemInput} $radio type="radio" name="gender" value="female" />
                          <S3.SelectionItemSpan>{t(IK.female)}</S3.SelectionItemSpan>
                        </S3.SelectionItemLabel>
                      </S3.SelectionItem>
                    </S3.SelectionItemList>
                    <FieldMessageErrorClick name="gender" />
                  </FormControlsBox>

                  {/* 생년월일 */}
                  <FormControlsBox required title={t(IK.birthday)}>
                    <Field as={S2.FormControls} type="text" name="birthday" onBlur={(e) => onBlurDate(e, setFieldValue, setFieldError)} placeholder="ex)20230425" className="frm_control" />
                    <FieldMessageErrorClick name="birthday" />
                  </FormControlsBox>

                  {/* 인종 */}
                  <FormControlsBox required title={t(IK.race)}>
                    <S3.SelectionItemList>
                      <S3.SelectionItem>
                        <S3.SelectionItemLabel>
                          <Field as={S3.SelectionItemInput} $radio type="radio" name="race" value="Caucasian" />
                          <S3.SelectionItemSpan>{t(IK.caucasian)}</S3.SelectionItemSpan>
                        </S3.SelectionItemLabel>
                      </S3.SelectionItem>
                      <S3.SelectionItem>
                        <S3.SelectionItemLabel>
                          <Field as={S3.SelectionItemInput} $radio type="radio" name="race" value="Asian" />
                          <S3.SelectionItemSpan>{t(IK.asian)}</S3.SelectionItemSpan>
                        </S3.SelectionItemLabel>
                      </S3.SelectionItem>
                      <S3.SelectionItem>
                        <S3.SelectionItemLabel>
                          <Field as={S3.SelectionItemInput} $radio type="radio" name="race" value="African" />
                          <S3.SelectionItemSpan>{t(IK.african)}</S3.SelectionItemSpan>
                        </S3.SelectionItemLabel>
                      </S3.SelectionItem>
                    </S3.SelectionItemList>
                    <FieldMessageErrorClick name="race" />
                  </FormControlsBox>

                  <FormControlsBox>
                    <S2.FormControlsBtn type="button" onClick={() => setAddressModalOpen(true)}>
                      {t(IK.registration_address)}
                    </S2.FormControlsBtn>
                  </FormControlsBox>

                  {/* 배송지 선택 */}
                  <FormControlsBox required title={`${t(IK.shipping_address)} ${t(IK.select)}`}>
                    <S3.SelectionItemList $small>
                      {addressList.map((item) => {
                        return (
                          <S3.SelectionItem key={item.addressId}>
                            <S3.SelectionItemLabel $btn>
                              <S3.SelectionItemInput
                                $btn
                                type="radio"
                                onClick={() => handleOnClickQuickShipAddress(item, setFieldValue)}
                                name="shippingAddressQuickSelect"
                                value={item.addressId}
                                defaultChecked={0 === item.addressId}
                              />
                              {item.addressName}
                            </S3.SelectionItemLabel>
                          </S3.SelectionItem>
                        );
                      })}
                    </S3.SelectionItemList>
                  </FormControlsBox>

                  {/* 배송지 */}
                  <FormControlsBox required title={t(IK.shipping_address)}>
                    <S2.FormControlsColumn>
                      {/* 배송지명 */}
                      <div>
                        <Field as={S2.FormControls} type="text" id="shipAddressName" name="shipAddressName" placeholder={t(IK.clinic_hospital)} maxLength="30" />
                        <FieldMessageErrorClick name="shipAddressName" />
                      </div>

                      {/* 우편번호 */}
                      <div>
                        <S2.FormControlsFlex>
                          <Field as={S2.FormControls} type="text" id="shipPostalCode" name="shipPostalCode" placeholder={t(IK.postcode)} maxLength="8" />
                          <AddressPopupBtn countryCode={countryCode} setFieldValue={setFieldValue} basicAddressId={"shipAddress"} zoneCodeId={"shipPostalCode"} />
                        </S2.FormControlsFlex>
                        <FieldMessageErrorClick name="shipPostalCode" />
                      </div>

                      {/* 기본주소 */}
                      <div>
                        <Field as={S2.FormControls} type="text" id="shipAddress" name="shipAddress" placeholder={t(IK.address)} maxLength="100" />
                        <FieldMessageErrorClick name="shipAddress" />
                      </div>

                      {/* 상세주소 */}
                      <div>
                        <Field as={S2.FormControls} type="text" id="shipAddressDetail" name="shipAddressDetail" placeholder={t(IK.detailed_address)} maxLength="100" />
                      </div>
                    </S2.FormControlsColumn>
                  </FormControlsBox>

                  {/* 청구지 선택 */}
                  <FormControlsBox required title={`${t(IK.billing_address)} ${t(IK.select)}`}>
                    <S3.SelectionItemList $small>
                      {addressList.map((item) => {
                        return (
                          <S3.SelectionItem key={"bill_" + item.addressId}>
                            <S3.SelectionItemLabel $btn>
                              <S3.SelectionItemInput
                                $btn
                                type="radio"
                                onClick={() => handleOnClickQuickBillAddress(item, setFieldValue)}
                                name="billAddressQuickSelect"
                                value={item.addressId}
                                defaultChecked={0 === item.addressId}
                              />
                              {item.addressName}
                            </S3.SelectionItemLabel>
                          </S3.SelectionItem>
                        );
                      })}
                    </S3.SelectionItemList>
                  </FormControlsBox>

                  {/* 청구지 */}
                  <FormControlsBox required title={t(IK.billing_address)}>
                    <S2.FormControlsColumn>
                      {/* 청구지명 */}
                      <div>
                        <Field as={S2.FormControls} type="text" id="billAddressName" name="billAddressName" placeholder={t(IK.bill_address_name)} maxLength="30" />
                        <FieldMessageErrorClick name="billAddressName" />
                      </div>

                      {/* 우편번호 */}
                      <div>
                        <S2.FormControlsFlex>
                          <Field as={S2.FormControls} type="text" id="billPostalCode" name="billPostalCode" placeholder={t(IK.postcode)} maxLength="8" />
                          <AddressPopupBtn countryCode={countryCode} setFieldValue={setFieldValue} basicAddressId={"billAddress"} zoneCodeId={"billPostalCode"} />
                        </S2.FormControlsFlex>
                        <FieldMessageErrorClick name="billPostalCode" />
                      </div>

                      {/* 기본주소 */}
                      <div>
                        <Field as={S2.FormControls} type="text" id="billAddress" name="billAddress" placeholder={t(IK.address)} maxLength="100" />
                        <FieldMessageErrorClick name="billAddress" />
                      </div>

                      {/* 상세주소 */}
                      <div>
                        <Field as={S2.FormControls} type="text" id="billAddressDetail" name="billAddressDetail" placeholder={t(IK.detailed_address)} maxLength="100" />
                      </div>
                    </S2.FormControlsColumn>
                  </FormControlsBox>
                </S2.FormControlsColumn>

                <CardTail line>
                  <S.ButtonLtBox>
                    <S.StyledButton as="button" type="button" onClick={() => navigate(`/patient/${patientId}/profile`)}>
                      {t(IK.list)}
                    </S.StyledButton>
                    <S.StyledButton as="button" $primary type="submit">
                      {t(IK.save)}
                    </S.StyledButton>
                  </S.ButtonLtBox>
                </CardTail>
              </CardInnerLayout>
            </Form>
          )}
        </Formik>
      )}

      {/**주소지 등록 모달 */}
      <AddressModal addressModalOpen={addressModalOpen} closeAddressModal={closeAddressModal} />
    </>
  );
};

export default RegisterFormEdit;
