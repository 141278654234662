import Skeleton from "react-loading-skeleton";
import * as S from "./style/BoardView.style";
import { CardInnerLayout, CardTitle } from "../../Layout/CardLayout/CardLayout";

export const SkeletonBoardView = () => {
  return (
    <CardInnerLayout>
      <CardTitle title={<Skeleton width={120} height={30} />} />
      <S.BoardViewTop>
        <Skeleton width={120} height={30} />
      </S.BoardViewTop>
      <S.BoardViewCont>
        <Skeleton height={25} width={500} count={2} />
      </S.BoardViewCont>
    </CardInnerLayout>
  );
};
