import * as S from "./style/Option.style";

export const OptionBtn = ({ BtnId, onHandle, children, adult, child, packages, type1, type2, type3 }) => {
  return (
    <S.OptionListItem $adult={adult} $child={child} $packages={packages} $type1={type1} $type2={type2} $type3={type3}>
      <button type="button" id={BtnId} onClick={onHandle}>
        <dl>{children}</dl>
      </button>
    </S.OptionListItem>
  );
};
