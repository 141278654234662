import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getStudyDefaultData } from "../../../../../apis/patientApi";
import { useCreateStudy, useUpdateStudy } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { OptionBtn } from "./OptionBtn";
import { OptionModal } from "./OptionModal";
import * as S from "./style/Option.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";

function SelectPackage({
  prev,
  next,
  setCurrentStepType,
  studyInfo,
  setStudyInfo,
  patientId,
}) {
  const { t } = useTranslation(["translation"]);
  const { studyId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const createStudyMutation = useCreateStudy();
  const updateStudyMutation = useUpdateStudy();

  const handleClick = (e) => {
    /**패키지 정보, 연령, 아이디값 추출 */
    const packages = e.currentTarget.id;
    const newStudyInfo = {
      age: studyInfo?.age.toUpperCase(),
      packages,
      patientId,
    };
    setCurrentStepType(e.currentTarget.id);
    setStudyInfo((prev) => ({ ...prev, packages }));

    /**이미 스터디 아이디가 있는 경우(업데이트) */
    //studyInfo.studyId 는 스터디 처방전 처음 작성 후 돌아올 경우 필요
    if (studyId || studyInfo.studyId) {
      updateStudyMutation.mutate(
        {
          ...newStudyInfo,
          studyId: studyId || studyInfo.studyId,
        },
        {
          onSuccess: (data) => {
            const studyId = data.data;
            //**패키지 업데이트 한 경우 studyInfo 초기화  */
            getStudyDefaultData(studyId, patientId).then((res) => {
              setStudyInfo({
                ...res.data.data,
                patientName: studyInfo.patientName,
                age: studyInfo.age,
                studyId: studyId,
                packages: res.data.data.study.packages,
              });
              next();
            });
          },
        }
      );
    } else {
      createStudyMutation.mutate(newStudyInfo, {
        onSuccess: (data) => {
          const studyId = data.data;
          /**스터디 아이디값 url 추가 */
          window.history.pushState(
            "",
            null,
            `/patient/${patientId}/study/${studyId}`
          );
          /**스터디 아이디값으로 기본 설정값 저장 */
          getStudyDefaultData(studyId, patientId).then((res) => {
            setStudyInfo({
              patientName: studyInfo.patientName,
              age: studyInfo.age,
              packages: newStudyInfo.packages,
              studyId: studyId,
              ...res.data.data,
            });
          });
          next();
        },
      });
    }
  };

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
      >
        <S2.StyledButton as="button" $primary type="button" onClick={openModal}>
          {t(IK.option_guide)}
        </S2.StyledButton>
      </CardTitle>

      <S.OptionList>
        {studyInfo.age === "adult" ? (
          <>
            <OptionBtn BtnId="RP" onHandle={handleClick} type1>
              <dt>
                <h3>R</h3>
                <span>Passive</span>
              </dt>
              <dd>
                <p>Retainer</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="R" onHandle={handleClick} type2>
              <dt>
                <h3>R</h3>
                <span>Active</span>
              </dt>
              <dd>
                <p>{t(IK.up_to_level4)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="S10" onHandle={handleClick} type2>
              <dt>
                <h3>10</h3>
              </dt>
              <dd>
                <p>{t(IK.up_to_level10)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="S20" onHandle={handleClick} type2>
              <dt>
                <h3>20</h3>
              </dt>
              <dd>
                <p>{t(IK.up_to_level20)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="SR" onHandle={handleClick} type2>
              <dt>
                <h3 style={{ fontSize: "24px" }}>{t(IK.regular)}</h3>
              </dt>
              <dd>
                <p>{t(IK.limit_step)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="SAP" onHandle={handleClick} type2>
              <dt>
                <h3 style={{ marginTop: "9px", fontSize: "24px" }}>
                  {t(IK.regular)}
                </h3>
                <span>AP</span>
              </dt>
              <dd>
                <p>{t(IK.limit_step)}</p>
                <span>
                  <i className="ri-information-fill"></i>
                  {t(IK.serafin_option_message)}
                </span>
              </dd>
            </OptionBtn>
          </>
        ) : (
          <>
            <OptionBtn BtnId="Si" onHandle={handleClick} type3>
              <dt>
                <h3>i</h3>
              </dt>
              <dd>
                <p>{t(IK.serafin_i_message)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="SiAP" onHandle={handleClick} type3>
              <dt>
                <h3>iAP</h3>
              </dt>
              <dd>
                <p>{t(IK.serafin_iap_message1)}</p>
                <span>
                  <i className="ri-information-fill"></i>
                  {t(IK.serafin_option_message)}
                </span>
              </dd>
            </OptionBtn>
          </>
        )}
      </S.OptionList>

      <CardTail
        error={
          studyInfo?.age === "child" && (
            <>
              {t(IK.bimaxillay)} - {t(IK.device_option_children_txt1)}
              <br />
              {t(IK.maxilla2)} - {t(IK.device_option_children_txt2)}
            </>
          )
        }
        line
      >
        <S2.ButtonLtBox>
          <S2.StyledButton as="button" type="button" onClick={prev}>
            {t(IK.prev)}
          </S2.StyledButton>
        </S2.ButtonLtBox>
      </CardTail>

      <OptionModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        adult={studyInfo?.age === "adult"}
        child={studyInfo?.age === "child"}
      />
    </>
  );
}

export default SelectPackage;
