import styled from "styled-components";
import { StyledButton } from "../../../element/Button/style/ButtonLayout.style";

export const SingleLayout = styled.div`
  width: 750px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px 0;
`;

export const SingleLayoutHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const SingleLayoutBox = styled.div`
  padding: 45px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
`;

export const SingleLayoutBtn = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 30px;
  ${StyledButton} {
    flex: 1;
  }
`;
