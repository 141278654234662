import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userRole } from "../../atoms/userAtom";
import * as S from "../../components/element/Tab/style/Tab.style";
import { IK } from "../../utils/i18n/keyStore";

/**계정 상단 탭 */
export const AccountTabType = ({ now }) => {
  const { t } = useTranslation(["translation"]);
  const role = useRecoilValue(userRole);

  const tabList = [
    { nav: "/account/mypage", name: t(IK.clinic_info), id: "치과정보" },
    { nav: "/account/mypage/doctor-info", name: t(IK.doctor_info), id: "의사정보" },
    { nav: "/account/mypage/etc-info", name: t(IK.etc_info), id: "기타정보" },
    { nav: "/account/mypage/address-manage", name: t(IK.address_management), id: "주소지 관리" },
  ];

  const staffTabList = [
    { nav: "/account/mypage", name: t(IK.clinic_info), id: "치과정보" },
    { nav: "/account/mypage/etc-info", name: t(IK.etc_info), id: "기타정보" },
    { nav: "/account/mypage/address-manage", name: t(IK.address_management), id: "주소지 관리" },
  ];

  return (
    <S.TabType1>
      {role?.STAFF
        ? staffTabList.map((tab) => (
            <S.TabType1Item $active={now === tab.id} key={tab.id}>
              <Link to={tab.nav}>{tab.name}</Link>
            </S.TabType1Item>
          ))
        : tabList.map((tab) => (
            <S.TabType1Item $active={now === tab.id} key={tab.id}>
              <Link to={tab.nav}>{tab.name}</Link>
            </S.TabType1Item>
          ))}
    </S.TabType1>
  );
};
