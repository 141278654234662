import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import { IK } from "../../../utils/i18n/keyStore";

function Faq() {
  const { t } = useTranslation(["translation"]);

  const [accordionItems, setAccordionItems] = useState([]);

  const ItemList = useMemo(() => {
    return [
      {
        id: 1,
        title: t(IK.faq_question1),
        content: t(IK.faq_answer1),
      },
      {
        id: 2,
        title: t(IK.faq_question2),
        content: t(IK.faq_answer2),
      },
      {
        id: 3,
        title: t(IK.faq_question3),
        content: t(IK.faq_answer3),
      },
      {
        id: 4,
        title: t(IK.faq_question4),
        content: t(IK.faq_answer4),
      },
      {
        id: 5,
        title: t(IK.faq_question5),
        content: t(IK.faq_answer5),
      },
      {
        id: 6,
        title: t(IK.faq_question6),
        content: t(IK.faq_answer6),
      },
      {
        id: 7,
        title: t(IK.faq_question7),
        content: t(IK.faq_answer7),
      },
      {
        id: 8,
        title: t(IK.faq_question8),
        content: t(IK.faq_answer8),
      },
      {
        id: 9,
        title: t(IK.faq_question9),
        content: t(IK.faq_answer9),
      },
    ];
  }, [t]);

  //state에 바로 넣으면 언어팩 적용 안되는 문제 해결용
  useEffect(() => {
    setAccordionItems(ItemList);
  }, [ItemList]);

  /** 토글 열기 */
  const toggleAccordion = (itemId) => {
    setAccordionItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, isOpen: !item.isOpen };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <CardInnerLayout>
      <S.FaqList>
        {accordionItems.map((item, index) => (
          <li key={index}>
            <button onClick={() => toggleAccordion(item.id)}>
              {item.title}
            </button>
            {item.isOpen && <div>{item.content}</div>}
          </li>
        ))}
      </S.FaqList>
    </CardInnerLayout>
  );
}

export default Faq;
