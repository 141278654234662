import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { registCliassification } from "../../../../apis/patientApi";
import ProfileImage from "../../../../components/common/ProfileImg";
import * as S from "../../../../components/element/BoardList/style/BoardList.style";
import { studyStatusListLabel } from "../../../../json/studyStatusObject";
import * as S2 from "../../../../styles/Common";
import { IK } from "../../../../utils/i18n/keyStore";
import { getSortIcon, sortIconList } from "../../../../utils/statusUtils";
import { getArchLabel, seraphinOption } from "../../../../utils/studyUtils";

const Tr = ({ item }) => {
  const { t } = useTranslation(["translation"]);

  /**아이콘 즐겨찾기 기능 */
  const handleOnClickClassification = (e) => {
    const target = e.currentTarget;
    registCliassification(item.patientId, target.dataset.code)
      .then((res) => {
        const parentElement = target.parentNode.parentNode;
        const firstChildButton = parentElement.querySelector("button");
        const firstChildImg = firstChildButton.querySelector("img");
        const targetImg = target.querySelector("img");
        const targetSrc = targetImg.src;
        firstChildImg.src = targetSrc;
        Notiflix.Notify.success(t(IK.success_save));
      })
      .catch((err) => {
        Notiflix.Notify.failure(t(IK.fail_save));
      });
  };

  /**세라뷰 스킵단계인지 표기 */
  const seraviewSkip = item.packages === "RP" || item.replacementYn === "Y" || item?.additionalProcess === "none";

  return (
    <tr>
      <td>
        <S.BoardTableSortIcon>
          <button type="button">
            <img src={getSortIcon(item.classification)} alt="icon" />
          </button>
          <div>
            {sortIconList.map((con) => (
              <button type="button" onClick={handleOnClickClassification} data-code={con.code} key={con.code}>
                <img src={con.icon} alt="icon" />
              </button>
            ))}
          </div>
        </S.BoardTableSortIcon>
      </td>
      <td>
        <S.BoardTableProfile to={`/patient/${item.patientId}/profile`} state={""}>
          <S.BoardTableThumbnail>
            <ProfileImage profile={item.profile} />
          </S.BoardTableThumbnail>
          <S.BoardTableName>
            <strong>{`${item.firstName} ${item.lastName}${item.korName ? ` (${item.korName})` : ""}`}</strong>
            <p>({item.patientNumber})</p>
          </S.BoardTableName>
        </S.BoardTableProfile>
      </td>
      <td>
        {seraphinOption[item.packages]}
        <br />
        {item?.SI01 && `(${getArchLabel(item?.SI01, t)})`}
      </td>
      <td>
        {(item.detail === "01" || item.detail > 10) && (
          <S.BoardTableState>
            <S.BoardTableStateList>
              <S.BoardTableStateItem $progress={item.detail <= "14"} $complete={item.detail >= "14"}>
                <span></span>
              </S.BoardTableStateItem>
              {!seraviewSkip && (
                <S.BoardTableStateItem $progress={item.detail <= "17" && item.detail > "14"} $complete={item.detail >= "17"}>
                  <span></span>
                </S.BoardTableStateItem>
              )}
              <S.BoardTableStateItem $last $progress={item.detail >= "18" && item.detail !== "21"} $complete={item.detail === "20" || item.detail === "22"}>
                <span></span>
              </S.BoardTableStateItem>
            </S.BoardTableStateList>
            <h4>
              {item?.additionalAmountYn === "Y" && `${t(IK.pay_need)}) `}
              {item.additionalYn === "Y" && `${t(IK.additional_device)} `}
              {item.replacementYn === "Y" && `${t(IK.alternative_correction_devices)} `}
              {studyStatusListLabel(item, t)}
            </h4>
            {item.detail >= "16" && (
              <p>
                {item?.lastCommentByDoctor === "Y" && <S2.TextDiv $textColorRed600>{t(IK.comment_writed_doctor)}</S2.TextDiv>}
                {item?.lastCommentByDoctor === "N" && <S2.TextDiv>{t(IK.comment_writed_center)}</S2.TextDiv>}
              </p>
            )}
          </S.BoardTableState>
        )}
      </td>
      <td>{item.startDate ? dayjs(item.startDate).format("YYYY-MM-DD") : ""}</td>
      <td>{item.endDate ? dayjs(item.endDate).format("YYYY-MM-DD") : "TBD"}</td>
      <td>{item.agreementYn === "Y" ? t(IK.submitted) : t(IK.not_submitted)}</td>
    </tr>
  );
};
export default Tr;
