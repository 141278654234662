import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import SerafinLogo from "../../../../assets/images/common/symbol.png";

// 일반 테이블 게시판
export const BoardTable = styled.table`
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  thead th {
    height: 70px;
    padding: 10px;
    background-color: ${(props) => props.theme.colors.gray300};
  }
  tbody td {
    height: 70px;
    padding: 10px;
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.white};
  }

  ${(props) =>
    props.$BoardTableType2 &&
    css`
      tbody td {
        height: 92px;
      }
    `};
`;

export const BoardTableProfile = styled(Link)`
  display: flex;
  align-items: center;
  gap: 17px;
  text-align: left;
`;

export const BoardTableThumbnailTitle = styled.span`
  display: inline-block;
  width: 55px;
  margin-left: -5px;
  text-align: center;
`;

export const BoardTableThumbnail = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  overflow: hidden;
  padding: 5px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const BoardTableName = styled.div`
  flex: 1;
  text-align: left;
  word-break: break-all;
`;

export const BoardTableChk = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  .label_checkbox {
    width: 20px;
    height: 20px;
  }
  .input_checkbox {
    position: static;
    vertical-align: baseline;
  }
`;

export const BoardTableLink = styled(Link)`
  &:hover {
    color: ${(props) => props.theme.colors.blue400};
    text-decoration: underline;
  }
`;

export const BoardTableSortBox = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  color: ${(props) => (props.$Primary ? props.theme.colors.blue400 : props.theme.colors.black)};
  font-weight: bold;
`;

export const BoardTableSortBoxSpan = styled.span`
  display: flex;
  flex-direction: column;
  i {
    line-height: 10px;
    font-size: 15px;
    color: ${(props) => props.theme.colors.gray400};
  }
  ${(props) =>
    props.$SortAsc &&
    css`
      i.ri-arrow-up-s-fill {
        color: ${(props) => props.theme.colors.blue400};
      }
    `};
  ${(props) =>
    props.$SortDesc &&
    css`
      i.ri-arrow-down-s-fill {
        color: ${(props) => props.theme.colors.blue400};
      }
    `};
`;

export const BoardTableSortIcon = styled.div`
  position: relative;
  div {
    position: absolute;
    left: 11px;
    z-index: 1;
    display: none;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0 40px rgba(46, 55, 164, 0.2);
  }
  &:hover div {
    display: flex;
  }
`;

// 프로그래스바 상태
export const BoardTableState = styled.div`
  display: inline-block;
  width: 360px;
  text-align: left;
`;

export const BoardTableStateList = styled.ul`
  display: flex;
`;

export const BoardTableStateItem = styled.li`
  width: ${(props) => (props.$last ? "0" : "180px")};
  position: relative;
  margin-bottom: 8px;
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    display: ${(props) => (props.$last ? "none" : "block")};
    height: 3px;
    background-color: ${(props) => (props.$complete ? props.theme.colors.blue400 : props.theme.colors.gray400)};
  }
  span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    border: 3px solid ${(props) => (props.$complete ? props.theme.colors.blue400 : props.$progress ? props.theme.colors.blue400 : props.theme.colors.gray400)};
    background-color: ${(props) => (props.$complete ? props.theme.colors.blue400 : props.theme.colors.white)};
    border-radius: 50%;
  }
`;

// 갤러리 게시판
export const BoardCardList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
  li {
    a,
    button {
      display: block;
      width: 100%;
      border: 1px solid ${(props) => props.theme.colors.gray200};
      border-radius: 10px;
      overflow: hidden;
      dt {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
        img {
          max-width: 100%;
          max-height: 100%;
        }
        div {
          position: relative;
          z-index: 1;
          color: ${(props) => props.theme.colors.blue300};
          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background: url(${SerafinLogo}) center/72px no-repeat;
            opacity: 0.3;
            filter: grayscale(80%);
          }
          h1 {
            margin-top: 5px;
            line-height: 1.1;
            font-size: 40px;
            font-weight: 800;
          }
          h3 {
            font-size: 22px;
            font-weight: 700;
          }
        }
      }
      dd {
        padding: 20px;
        h3 {
          min-height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          font-size: 18px;
          transition: all 0.3s;
        }
        span {
          display: block;
          margin-top: 5px;
          color: ${(props) => props.theme.colors.gray600};
        }
      }
      &:hover {
        border-color: ${(props) => props.theme.colors.blue400};
        dd {
          h3 {
            color: ${(props) => props.theme.colors.blue400};
          }
        }
      }
    }
  }
`;

// 아코디언 게시판
export const FaqList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 15px;
  li {
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 5px;
    button {
      text-align: left;
      padding: 20px;
      font-size: 15px;
    }
    div {
      padding: 20px;
      background-color: ${(props) => props.theme.colors.gray300};
      font-size: 15px;
    }
  }
`;
