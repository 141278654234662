import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { updatePatientCondition, useGetPatientInfo } from "../../../../apis/patientApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S3 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import LoadingIndicator from "../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../utils/i18n/keyStore";
import { convertToY, jsonArrayToObjectBoolean } from "../../../../utils/objectUtils";
import { ClinicalCheckBox } from "../../Register/ClinicalCondition/ClinicalCheckBox";

const ClinicalConditionEdit = () => {
  const { patientId } = useParams();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const [patientInfo, setPatientInfo] = useState({});

  /**환자 정보 조회 */
  const { data: PatientData, isLoading } = useGetPatientInfo(patientId);

  useEffect(() => {
    if (!isLoading) {
      const processedData = {
        ...jsonArrayToObjectBoolean(PatientData?.data.conditionItemList, "itemKey", "itemValue"),
      };
      setPatientInfo(processedData);
    }
  }, [isLoading, PatientData]);

  /**기타 선택여부에 따른 처리 */
  const handleOnClickOther = (e, setFieldValue) => {
    if (!e.currentTarget.checked) {
      setFieldValue("conditionItemMap.otherText", "");
    }
  };

  const handleSubmit = (values) => {
    const data = {};
    data.conditionItemMap = convertToY(values.conditionItemMap);

    updatePatientCondition(patientId, data)
      .then((response) => {
        Notiflix.Notify.success(t(IK.update_success));
      })
      .catch((err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Formik
          enableReinitialize="false"
          initialValues={{
            conditionItemMap: {
              crowding: patientInfo.crowding || false,
              openbiteVerticality: patientInfo?.openbiteVerticality || false,
              unevenSmile: patientInfo.unevenSmile || false,
              spacing: patientInfo.spacing || false,
              occlusalPlaneRight: patientInfo.occlusalPlaneRight || false,
              class1: patientInfo.class1 || false,
              class2: patientInfo.class2 || false,
              class3: patientInfo.class3 || false,
              class4: patientInfo.class3 || false,
              openbiteOverjet: patientInfo.openbiteOverjet || false,
              deepBite: patientInfo.deepBite || false,
              occlusalPlaneLeft: patientInfo.occlusalPlaneLeft || false,
              abteriorCrossbite: patientInfo.abteriorCrossbite || false,
              misshapenTeeth: patientInfo.misshapenTeeth || false,
              posteriorCrossbite: patientInfo.posteriorCrossbite || false,
              missing: patientInfo.missing || false,
              narrowArch: patientInfo.narrowArch || false,
              flaredTeeth: patientInfo.flaredTeeth || false,
              asymmetric: patientInfo.asymmetric || false,
              gingivalRecession: patientInfo.gingivalRecession || false,
              scissorsBite: patientInfo.scissorsBite || false,
              other: patientInfo?.other || false,
              otherText: patientInfo.otherText || "",
            },
          }}
          validationSchema={Yup.object().shape({
            conditionItemMap: Yup.object()
              .shape({
                crowding: Yup.boolean(),
                openbiteVerticality: Yup.boolean(),
                overjet: Yup.boolean(),
                spacing: Yup.boolean(),
                other: Yup.boolean(),
              })
              .test("has-true-value", t(IK.check_one_over), function (value) {
                const hasTrueValue = Object.keys(value).some((key) => value[key] === true);
                return hasTrueValue;
              })
              .test("has-other-text", t(IK.othertext_required), function (value) {
                if (value.other && !value.otherText) {
                  return false; // 오류 반환
                }
                return true; // 유효성 통과
              })
              .test("has-other-text-length", t(IK.otherText_length_limit), function (value) {
                if (!!value.otherText === "" && value.otherText?.length > 80) {
                  return false;
                }
                return true;
              }),
          })}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <CardInnerLayout>
                <CardTitle title={t(IK.clinical_condition)} />
                <S.FormControlsFlex>
                  <ClinicalCheckBox />
                  <S.FormControlsColumn as="li">
                    <S3.SelectionItem>
                      <S3.SelectionItemLabel>
                        <Field type="checkbox" name="conditionItemMap.other">
                          {({ field }) => <S3.SelectionItemInput $checkbox type="checkbox" {...field} onClick={(e) => handleOnClickOther(e, setFieldValue)} />}
                        </Field>
                        <S3.SelectionItemSpan>{t(IK.other)}</S3.SelectionItemSpan>
                      </S3.SelectionItemLabel>
                    </S3.SelectionItem>

                    <Field name="conditionItemMap.otherText">
                      {({ field }) => <S.FormControls as="textarea" placeholder={t(IK.otherText_ex)} maxLength={80} $textarea {...field} id="otherText" disabled={!values.conditionItemMap.other} />}
                    </Field>
                  </S.FormControlsColumn>
                </S.FormControlsFlex>

                <CardTail error={errors.conditionItemMap} line>
                  <S2.ButtonLtBox>
                    <S2.StyledButton as="button" type="button" onClick={() => navigate(`/patient/${patientId}/profile`)}>
                      {t(IK.list)}
                    </S2.StyledButton>
                    <S2.StyledButton as="button" $primary type="submit">
                      {t(IK.save)}
                    </S2.StyledButton>
                  </S2.ButtonLtBox>
                </CardTail>
              </CardInnerLayout>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ClinicalConditionEdit;
