import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveAdditionStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { TitleV3 } from "../../../../../components/element/Title/TitleText";
import { IK } from "../../../../../utils/i18n/keyStore";

const ApAttachment = ({
  next,
  prev,
  currentStepType,
  stepLabel,
  additionalId,
  patientId,
  studyId,
  currentStepList,
  currentStepIndex,
  setCurrentStepIndex,
  additionalInfo,
  setAdditionalInfo,
  isFinish,
}) => {
  const { t } = useTranslation(["translation"]);

  const additionalStudyMutation = useSaveAdditionStep();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape({
    AI07: Yup.string().required(t(IK.ap_attachment_required)),
  });

  const code = currentStepList[currentStepIndex].code;

  const handleSubmit = (data) => {
    additionalStudyMutation.mutate(
      {
        step: code,
        data,
        params: {
          additionalId,
          patientId,
          studyId,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo({ ...additionalInfo, ...data });
          isFinish && skip ? setCurrentStepIndex(currentStepList.length - 1) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} subTitle={`${t(IK[additionalInfo.packages])}`} required />
      <Formik
        initialValues={{
          AI07: additionalInfo.SI16 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.ap_attachment)} required openModal={openModal}>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI07" className="input_radio" value="1" />
                    <span>{t(IK.ap_attachment_text_1)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI07" className="input_radio" value="2" />
                    <span>{t(IK.ap_attachment_text_2)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI07" className="input_radio" value="3" />
                    <span>{t(IK.ap_attachment_text_3)}</span>
                  </label>
                </div>
              </div>
              <FieldMessageErrorClick name="AI07" />
            </FormControlsStudyBox>

            <CardTail line>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" type="submit" $primary>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} $primary>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <TitleV3 title={t(IK.ap_attachment_desc_1)} />
        <S2.ModalBoxBullet>
          <li>{t(IK.ap_attachment_desc_2)}</li>
          <li>{t(IK.ap_attachment_desc_3)}</li>
          <span>{t(IK.ap_attachment_desc_4)}</span>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
};

export default ApAttachment;
