import React, { useEffect, useState } from "react";
import { DndProvider, DragPreviewImage, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useGetPreCut, useSavePreCut } from "../../../apis/clinicItemApi";
import button_hole from "../../../assets/images/elastic/button_cutout.png";
import elastic_hooks from "../../../assets/images/elastic/elastic_hooks.png";
import * as S from "../../../components/Modal/style/Modal.style";
import * as S3 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S2 from "../../../components/element/Tab/style/Tab.style";
import { elasticTeeth, getelasticTeethPosition, getelasticTeethScale } from "../../../utils/elasticTeeth";
import { IK } from "../../../utils/i18n/keyStore";

const ElasticCutInterfaceModal = ({ open, close, patientId }) => {
  const { t } = useTranslation(["translation"]);

  const { data, isLoading } = useGetPreCut(patientId ? "patient" : "doctor", patientId);
  const precutData = data?.data;

  /**현재 탭 */
  const [elasticState, setElasticState] = useState({
    class: "class_2",
    side: "elastic_bs", //협측(bs), 설측(ts)
    data: {},
    clickItem: "", //클릭한 아이템
  });

  useEffect(() => {
    if (!isLoading && precutData?.elastic) {
      setElasticState((prev) => ({ ...prev, data: precutData?.elastic }));
    }
  }, [precutData?.elastic, isLoading]);

  //치아 박스
  const teethBox = {
    upperTeeth_1: ["18", "17", "16", "15", "14", "13"],
    upperTeeth_2: ["28", "27", "26", "25", "24", "23"],
    lowerTeeth_1: ["48", "47", "46", "45", "44", "43"],
    lowerTeeth_2: ["38", "37", "36", "35", "34", "33"],
  };

  /**탭 리스트 */
  const tabList = {
    class_2: "Class II",
    class_3: "Class III",
  };

  /**클래스 변경 */
  const changeTab = (tab) => {
    setElasticState((prev) => ({ ...prev, class: tab, side: "elastic_bs", clickItem: "" }));
  };

  /**협측, 설측 변경 */
  const changeSide = (side) => {
    setElasticState((prev) => ({ ...prev, side, clickItem: "" }));
  };

  /**프리시젼컷 드랍 이벤트 */
  const handleDrop = (item, id) => {
    setElasticState((prev) => {
      const data = { ...prev.data };
      //협측, 설측, 치아번호에 해당하는 타입을 저장
      data[prev.class][prev.side][`teeth_${id}`] = item.type;
      return { ...prev, data, clickItem: "" };
    });
  };

  /**클릭된 아이템 삭제 */
  const handleDeleteItem = () => {
    setElasticState((prev) => {
      const item = prev.clickItem;
      const data = { ...prev.data };
      //협측, 설측, 치아번호에 해당하는 타입을 삭제
      data[prev.class][prev.side][`teeth_${item}`] = "";

      return { ...prev, data, clickItem: "" };
    });
  };

  /**치아 클릭 */
  const handleClick = (id) => {
    //클릭한 아이템이 빈값이면 선택 x
    if (elasticState.data[elasticState.class][elasticState.side][`teeth_${id}`] === "") return;
    setElasticState((prev) => ({ ...prev, clickItem: id }));
  };

  /**프리시젼 초기화 */
  const handleReset = () => {
    setElasticState((prev) => ({ ...prev, data: JSON.parse(JSON.stringify(elasticTeeth)), clickItem: "" }));
  };

  /**프리시젼 저장 */
  const precutSaveMutation = useSavePreCut();

  const handleSave = () => {
    precutSaveMutation.mutate(
      { type: patientId ? "patient" : "doctor", data: elasticState.data, patientId },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  /**해당 치아에 맞는 컷 render */
  const renderElasticType = (elasticState, id) => {
    const { class: elasticClass, side: elasticSide, data } = elasticState;
    const teethValue = data[elasticClass][elasticSide][`teeth_${id}`];

    //치아에 해당하는 값이 없으면 null
    if (teethValue === "") return null;

    return <img src={teethValue === "BUTTON_HOLE" ? button_hole : elastic_hooks} alt={teethValue} />;
  };

  return (
    <S.ModalBox $openModal={open}>
      <section>
        <S.ModalBoxHeader>
          <h3>Elastic cut interface</h3>
          <button className="close" onClick={close}>
            <i className="ri-close-line"></i>
          </button>
        </S.ModalBoxHeader>

        <S.ModalBoxCont>
          <S2.TabType1>
            {Object.keys(tabList).map((tab) => (
              <S2.TabType1Item onClick={() => changeTab(tab)} $active={elasticState.class === tab} key={tab}>
                {tabList[tab]}
              </S2.TabType1Item>
            ))}
          </S2.TabType1>

          <S2.TabType2>
            {["elastic_bs", "elastic_ts"].map((side) => (
              <S2.TabType2Item onClick={() => changeSide(side)} $active={elasticState.side === side} key={side}>
                {t(IK[side])}
              </S2.TabType2Item>
            ))}
          </S2.TabType2>

          <DndProvider backend={HTML5Backend}>
            <S.ElasticCutList>
              <ElasticCutItem type="BUTTON_HOLE" img={button_hole} />
              <ElasticCutItem type="ELASTIC_HOOKS" img={elastic_hooks} />
            </S.ElasticCutList>

            {isLoading || Object.keys(elasticState.data).length === 0 ? (
              <Skeleton width={740} height={180} />
            ) : (
              <S.ElasticCutTeethBox>
                {Object.entries(teethBox).map(([key, teethLabel], index) => (
                  <li key={index}>
                    {teethLabel.map((id, i) => (
                      <ElasticCutDrop handleClick={handleClick} key={id} id={id} onDrop={handleDrop} teethType={key} index={i} elasticState={elasticState}>
                        {renderElasticType(elasticState, id)}
                      </ElasticCutDrop>
                    ))}
                  </li>
                ))}
              </S.ElasticCutTeethBox>
            )}
          </DndProvider>

          <S.ElasticCutDelete>
            <p>
              <i className="ri-information-fill" />
              {t(IK.delete_interface)}
            </p>
            <S3.StyledSmallButton onClick={handleDeleteItem}>{t(IK.delete)}</S3.StyledSmallButton>
          </S.ElasticCutDelete>
          <S.ModalBoxBtn>
            <S3.ButtonFlex>
              <S3.StyledButton onClick={handleReset}>{t(IK.reset)}</S3.StyledButton>
              <S3.StyledButton $primary onClick={handleSave}>
                {t(IK.save)}
              </S3.StyledButton>
            </S3.ButtonFlex>
          </S.ModalBoxBtn>
        </S.ModalBoxCont>
      </section>
    </S.ModalBox>
  );
};

export default ElasticCutInterfaceModal;

/**드래그 아이템 */
export const ElasticCutItem = ({ type, img }) => {
  const { t } = useTranslation(["translation"]);

  const [{ isDragging }, drag, preview] = useDrag({
    type, // 드래그 아이템의 타입
    item: { type }, // 드래그 아이템
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const typeText = {
    BUTTON_HOLE: `${t(IK.button_hole)}`,
    ELASTIC_HOOKS: `${t(IK.elastic_cut)}`,
  };

  return (
    <li>
      <DragPreviewImage connect={preview} src={img} />
      <img
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "pointer",
        }}
        src={img}
        alt={type}
      />
      <h4>{typeText[type]}</h4>
    </li>
  );
};

/**드랍존 */
export const ElasticCutDrop = ({ id, onDrop, children, teethType, index, elasticState, handleClick }) => {
  const [{ canDrop }, drop] = useDrop({
    accept: ["BUTTON_HOLE", "ELASTIC_HOOKS"],
    drop: (item) => onDrop(item, id),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  const { class: elasticClass, side: elasticSide, data, clickItem } = elasticState;
  //협측, 설측, 치아번호에 해당하는 스타일 적용
  const teethValue = data[elasticClass][elasticSide][`teeth_${id}`];

  //클릭한 아이템이 현재 치아번호와 같은지 판단
  const isClicked = clickItem === id;

  const positionStyle = getelasticTeethPosition(teethType, index);
  const scaleStyle = getelasticTeethScale(teethType, teethValue);

  return (
    <S.ElasticCutTeethItem
      ref={drop}
      $active={canDrop || isClicked}
      style={{
        ...positionStyle,
      }}
      onClick={() => handleClick(id)}
    >
      <span
        style={{
          ...scaleStyle,
        }}
      >
        {children}
      </span>
    </S.ElasticCutTeethItem>
  );
};
