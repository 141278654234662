import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfo } from "../atoms/userAtom";

const isKoreaAccount = (Component) => (props) => {
  const navigate = useNavigate();
  const user = useRecoilValue(userInfo);
  const { countryCode, doctorType } = user;

  useEffect(() => {
    if (countryCode !== "01" || doctorType === "DENTIS") {
      navigate("/patient/list");
    }
  }, [countryCode, doctorType, navigate]);

  // Always return a component
  return <Component {...props} />;
};

export default isKoreaAccount;
