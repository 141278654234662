import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/Form.style";
import { TitleV3 } from "../Title/TitleText";

/**폼 레이아웃 */
export const FormControlsStudyBox = ({ title, titleSub, children, required, openModal }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <S.FormControlsStudyBox>
      <TitleV3 title={title} required={required} titleSub={titleSub}>
        {openModal && (
          <button type="button" onClick={openModal}>
            {t(IK.educational_materials)}
          </button>
        )}
      </TitleV3>
      <S.FormControlsStudyItem>{children}</S.FormControlsStudyItem>
    </S.FormControlsStudyBox>
  );
};

export const FormControlsBox = ({ title, children, required, regular, medium, large, example }) => {
  return (
    <S.FormControlsBox>
      <S.FormControlsTitle $required={required} $regular={regular} $medium={medium} $large={large}>
        <h3>{title}</h3>
        {example && <span>{example}</span>}
      </S.FormControlsTitle>
      <S.FormControlsDiv>{children}</S.FormControlsDiv>
    </S.FormControlsBox>
  );
};

export const FormConfirm = ({ title, children }) => {
  return (
    <S.FormConfirmBox>
      <S.FormConfirmBoxIcon>
        <i className="ri-check-fill"></i>
      </S.FormConfirmBoxIcon>
      <h2>{title}</h2>
      {children}
    </S.FormConfirmBox>
  );
};
