import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as S from "../../../../components/element/BoardSearch/style/BoardSearch.style";
import * as S3 from "../../../../components/element/Button/style/ButtonLayout.style";
import { DatePicker } from "../../../../components/element/DatePicker/DatePicker";
import * as S2 from "../../../../components/element/Form/style/Form.style";
import { IK } from "../../../../utils/i18n/keyStore";
import { replaceInput } from "../../../../utils/searchUtils";

const SearchFilter = ({ searchCondition, setSearchCondition, resetSorting }) => {
  // const [reset, setReset] = useState(false);

  const { t } = useTranslation(["translation"]);

  /**검색어 전처리(한글, 영어만 입력 가능) */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const sanitizedValue = replaceInput(event.target.value);
      if (sanitizedValue) {
        setSearchCondition((prev) => ({
          ...prev,
          name: sanitizedValue,
          page: 1,
        }));
      } else {
        setSearchCondition((prev) => {
          const { name, ...rest } = prev;
          return { ...rest, page: 1 };
        });
      }
    }
  };

  /**환자 이름 검색(클릭) */
  const handleOnClick = (event) => {
    const inputElement = document.getElementById("patientName");
    const sanitizedName = replaceInput(inputElement.value);
    inputElement.value = sanitizedName;
    setSearchCondition((prev) => ({ ...prev, name: sanitizedName, page: 1 }));
  };

  /** 치료 옵션 선택*/
  const handleChangePackages = (event) => {
    setSearchCondition((prev) => ({
      ...prev,
      page: 1,
      packages: event.target.value ? event.target.value : "",
    }));
  };

  /** 기간 검색*/
  const handleDateChange = (event) => {
    const gap = event.target.value;
    if (gap) {
      const startDate = new Date(dayjs().subtract(gap, "month").format("YYYY-MM-DD"));
      const endDate = new Date();
      setSearchCondition((prev) => ({
        ...prev,
        page: 1,
        gap: gap,
        startDate: startDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
      }));
    } else {
      setSearchCondition((prev) => {
        const { startDate, endDate, page, gap, ...rest } = prev;
        return { ...rest, page: 1 };
      });
    }
  };

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date) => {
    setSearchCondition((prev) => {
      if (date) {
        return {
          ...prev,
          startDate: dayjs(date).format("YYYY-MM-DD"),
          page: 1,
        };
      } else {
        const { startDate, page, ...rest } = prev;
        return { ...rest, page: 1 };
      }
    });
  };

  /**치료 만료일 날짜 설정 */
  const handleEndDate = (date) => {
    setSearchCondition((prev) => {
      if (date) {
        return { ...prev, endDate: dayjs(date).format("YYYY-MM-DD"), page: 1 };
      } else {
        const { endDate, page, ...rest } = prev;
        return { ...rest, page: 1 };
      }
    });
  };

  /** 리셋버튼 */
  const handleOnClickReset = (event) => {
    // form을 찾음
    const form = document.getElementById("patientSearchFilter");

    // form 내의 모든 input 요소들을 찾아서 값을 초기화
    const inputs = form.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = "";
    }

    // select 요소들도 초기화
    const selects = form.getElementsByTagName("select");
    for (let i = 0; i < selects.length; i++) {
      selects[i].value = "";
    }

    setSearchCondition((prev) => {
      return { status: prev.status, page: 1, size: 8 };
    });
  };

  /**세라퀵 실행 모달 */
  // const [isSeraQuickModal, setIsSeraQuickModal] = useState(false);

  /**세라퀵 모달 열기 */
  // const handleOpenSeraQuickModal = useCallback(() => setIsSeraQuickModal(true), []);

  return (
    <>
      <form id="patientSearchFilter">
        <S.BoardSearchFilter>
          <S2.FormControlsFlex>
            <S3.StyledButton $primary to="/patient/register">
              <i className="ri-pencil-fill"></i> {t(IK.patient_register)}
            </S3.StyledButton>
            {/* <S3.StyledButton $primary as="button" type="button" onClick={handleOpenSeraQuickModal}>
              <i className="ri-pencil-ruler-2-fill"></i> {t(IK.seraquick_start)}
            </S3.StyledButton> */}
          </S2.FormControlsFlex>
          <S.BoardSearchFilterList>
            <div>
              <S2.FormControlsFlex $rowCenter>
                <S2.FormControls as="select" $select id="dateRangeHelper" name="dateRangeHelper" onChange={handleDateChange}>
                  <option value="">{t(IK.start_date_search)}</option>
                  <option value={1}>{t(IK.month1)}</option>
                  <option value={3}>{t(IK.month3)}</option>
                  <option value={6}>{t(IK.month6)}</option>
                  <option value={12}>{t(IK.year1)}</option>
                </S2.FormControls>

                <DatePicker placeholderText={t(IK.date_example)} selected={searchCondition?.startDate && new Date(searchCondition?.startDate)} handleDataChange={handleStartDate} formControls />

                <DatePicker placeholderText={t(IK.date_example)} selected={searchCondition?.endDate && new Date(searchCondition?.endDate)} handleDataChange={handleEndDate} formControls />
              </S2.FormControlsFlex>
            </div>

            <div>
              <S2.FormControls as="select" $select id="packages" name="packages" onChange={handleChangePackages} defaultValue={searchCondition?.packages}>
                <option value="">{t(IK.treatment_option)}</option>
                <option value="R">R-active</option>
                <option value="RP">R-passive</option>
                <option value="S10">10</option>
                <option value="S20">20</option>
                <option value="SR">Regular</option>
                <option value="SAP">Regular AP</option>
                <option value="Si">i</option>
                <option value="SiAP">iAP</option>
              </S2.FormControls>
            </div>

            <div>
              <S.BoardSearchForm>
                <S2.FormControls type="text" id="patientName" name="patientName" placeholder={t(IK.patient_search_placehold)} onKeyDown={handleKeyDown} defaultValue={searchCondition?.name} />
                <button type="button" onClick={handleOnClick}>
                  <i className="ri-search-line"></i>
                </button>
              </S.BoardSearchForm>
            </div>

            <S3.StyledButton as="button" $hide $primary type="button" id="serachConditionResetBtn" onClick={handleOnClickReset}>
              <i className="ri-restart-line"></i> {t(IK.reset)}
            </S3.StyledButton>
          </S.BoardSearchFilterList>
        </S.BoardSearchFilter>
      </form>
      {/**세라퀵 모달 */}
      {/* <SeraquickModal isSeraQuickModal={isSeraQuickModal} setIsSeraQuickModal={setIsSeraQuickModal} /> */}
    </>
  );
};
export default SearchFilter;
