import styled from "styled-components";

export const MeditBox = styled.div`
  padding: 20px;
`;

export const MeditError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  h2 {
    margin-top: 10px;
    font-size: 24px;
    word-break: break-all;
  }
  i {
    line-height: 1;
    font-size: 70px;
    color: ${(props) => props.theme.colors.blue400};
  }
`;

export const MeditList = styled.ol`
  max-height: 375px;
  overflow: auto;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-bottom: 0;
  li {
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    counter-increment: list-number;
    &:after {
      position: absolute;
      top: 15px;
      left: 15px;
      content: counter(list-number);
      min-width: 12px;
      line-height: 20px;
      padding: 0 5px;
      text-align: center;
      background-color: ${(props) => props.theme.colors.blue400};
      border-radius: 5px;
      color: ${(props) => props.theme.colors.white};
      font-size: 12px;
    }
    button {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      padding: 20px 20px 20px 55px;
      text-align: left;
      background-color: ${(props) => props.theme.colors.white};
      &:hover {
        background-color: ${(props) => props.theme.colors.gray300};
      }
      dl {
        display: flex;
        font-size: 15px;
        dt {
          min-width: 80px;
          font-weight: 600;
        }
        dd {
          flex: 1;
        }
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        list-style-type: disc;
      }
    }
  }
`;

export const MeditInfo = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 20px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.gray300};
  li {
    position: relative;
    padding-left: 15px;
    font-size: 15px;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.black};
    }
  }
`;
