import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { resetAdditionalStudy, useUpdateAdditionalStudy } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { stepTypes } from "../../../../../utils/studyUtils";

function UpdateStudy({ next, prev, currentStepList, setCurrentStepIndex, setIsFinish, setCurrentStepType, additionalInfo, setAdditionalInfo, additionalId, patientId, studyId }) {
  const { t } = useTranslation(["translation"]);
  const additionalMutation = useUpdateAdditionalStudy();

  /**중복 요청 방지 */
  const [submitting, setSubmitting] = useState(false);

  /**데이터 저장 후 다음 스텝 */
  const saveAndContinue = (values) => {
    additionalMutation.mutate(
      { studyId, patientId, additionalId, process: values.process },
      {
        onSuccess: () => {
          if (values.process === "NONE") {
            setCurrentStepType(stepTypes.NOT_EDIT);
          } else {
            setCurrentStepType(additionalInfo.packages);
          }

          //처방전 신규 업데이트 (기존 내역 전부 초기화)
          resetAdditionalStudy(additionalId, patientId, studyId).then((res) => {
            const additionalNewData = res?.data;

            setAdditionalInfo((prev) => ({
              ...additionalNewData,
              patientName: prev.patientName,
              process: values.process,
              packages: prev.packages,
              age: prev.age,
            }));
          });

          setSubmitting(false);
          next();
        },
        onError: (error) => {
          setSubmitting(false);
          Notiflix.Notify.failure(t(IK.again_message));
        },
      }
    );
  };

  /**수정여부 확인 팝업 */
  const showConfirm = (title, message, onOk) => {
    Notiflix.Confirm.show(t(title), t(message), t(IK.confirm), t(IK.cancel), onOk, () => {
      setSubmitting(false);
    });
  };

  const handleSubmit = (values) => {
    if (submitting) {
      return false;
    }
    setSubmitting(true);

    /**처음 작성하는 경우, 데이터 전송 */
    if (!additionalInfo.process || additionalInfo.process === null) {
      /**처방전을 수정하지 않고 유지할 경우 팝업 설정 */
      if (values.process === "NONE") {
        showConfirm(IK.none_update_study_1, IK.none_update_study_2, () => saveAndContinue(values));
      } else {
        saveAndContinue(values);
      }
    } else if (additionalInfo.process === values.process) {
      /**값의 변화가 없는 경우, 데이터 미전송(통과) */
      setSubmitting(false);
      next();
    } else if (additionalInfo.process && additionalInfo.process !== values.process) {
      /**값의 변화가 있는 경우, 경고문과 함께 데이터 전송 */
      if (values.process === "NONE") {
        showConfirm(IK.none_update_study_1, IK.none_update_study_2, () => saveAndContinue(values));
      } else {
        showConfirm(IK.is_edit, IK.edit_update_seraview_study, () => saveAndContinue(values));
      }
    }
  };

  useEffect(() => {
    setIsFinish(false);
  }, [setIsFinish]);

  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />

      <Formik
        initialValues={{
          process: additionalInfo?.process || "NONE",
        }}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={`${t(IK.update_seraview_study)}`} required>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="process" className="input_radio" value="EDIT" />
                    <span>{t(IK.yes)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="process" className="input_radio" value="NONE" />
                    <span>{t(IK.no)}</span>
                  </label>
                </div>
              </div>
            </FormControlsStudyBox>
            <CardTail line>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" $primary type="submit">
                  {t(IK.next)}
                </S.StyledButton>
              </S.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UpdateStudy;
