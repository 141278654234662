import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveAdditionalFileStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";
import { studyFormConfig } from "../../../../../utils/studyUtils";
import { Previews } from "../Preview/Preview";

function Intraoral({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, setCurrentStepIndex, additionalInfo, setAdditionalInfo, isFinish, additionalId, studyId, patientId }) {
  const { t } = useTranslation(["translation"]);

  const additionalStudyFileMutation = useSaveAdditionalFileStep();

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const config = studyFormConfig[currentStepType][stepLabel];
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  const validationSchema = Yup.object().shape(
    Object.entries(config).reduce((acc, [key, isRequired]) => {
      if (isRequired) {
        acc[key] = Yup.mixed().required(t(IK.image_required));
      } else {
        acc[key] = Yup.mixed().nullable();
      }
      return acc;
    }, {})
  );

  const handleSubmit = (values) => {
    const data = deleteEmptyKey(values);

    const formData = new FormData();
    let formDataLength = 0;

    Object.entries(data).forEach(([key, value]) => {
      if (!value.additionalFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      //서버 전송시 로딩
      Notiflix.Loading.standard("");

      //삭제할 아이디 추가
      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteAdditionalFileIds", deleteStudyFileIds.join(","));
      }
      //패키지 코드 추가
      formData.append("studyPackagesCode", additionalInfo.packages);

      additionalStudyFileMutation.mutate(
        {
          step: "intraoral",
          formData,
          params: {
            studyId,
            patientId,
            additionalId,
          },
        },
        {
          onSuccess: (data) => {
            const resData = {};
            data.data.forEach((item) => {
              resData[item.position] = item;
            });
            setDeleteStudyFileIds([]);
            setAdditionalInfo({ ...additionalInfo, ...values, ...resData });
            Notiflix.Loading.remove();
            navigateNext();
          },
          onError: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(t(IK.again_message));
          },
        }
      );
    } else {
      navigateNext();
    }
  };

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(currentStepList.length - 1);
    } else {
      next();
    }
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />

      <Formik
        initialValues={{
          occlusal_upper: additionalInfo.occlusal_upper || undefined,
          intraoral_front: additionalInfo.intraoral_front || undefined,
          occlusal_lower: additionalInfo.occlusal_lower || undefined,
          buccal_right: additionalInfo.buccal_right || undefined,
          overjet: additionalInfo.overjet || undefined,
          buccal_left: additionalInfo.buccal_left || undefined,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S.DropListWrap>
              <S.DropList className={config.occlusal_upper ? "required_img occlusal_upper" : "occlusal_upper"}>
                <Previews setFieldValue={setFieldValue} files={values.occlusal_upper} name="occlusal_upper" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="occlusal_upper" />
              </S.DropList>
              <S.DropList className={config.intraoral_front ? "required_img intraoral_front" : "intraoral_front"}>
                <Previews setFieldValue={setFieldValue} files={values.intraoral_front} name="intraoral_front" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
              </S.DropList>
              <S.DropList className={config.occlusal_lower ? "required_img occlusal_lower" : "occlusal_lower"}>
                <Previews setFieldValue={setFieldValue} files={values.occlusal_lower} name="occlusal_lower" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="occlusal_lower" />
              </S.DropList>
              <S.DropList className={config.buccal_right ? "required_img buccal_right" : "buccal_right"}>
                <Previews setFieldValue={setFieldValue} files={values.buccal_right} name="buccal_right" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="buccal_right" />
              </S.DropList>
              <S.DropList className={config.overjet ? "required_img overjet" : "overjet"}>
                <Previews setFieldValue={setFieldValue} files={values.overjet} name="overjet" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="overjet" />
              </S.DropList>
              <S.DropList className={config.buccal_left ? "required_img buccal_left" : "buccal_left"}>
                <Previews setFieldValue={setFieldValue} files={values.buccal_left} name="buccal_left" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="buccal_left" />
              </S.DropList>
            </S.DropListWrap>

            <div className="noti_box">
              <i className="ri-upload-cloud-fill"></i>
              {t(IK.file_drag_message)}
            </div>

            <CardTail error={t(IK.image_required_message_2)} line>
              <S2.ButtonLtBox>
                <S2.StyledButton as="button" type="button" onClick={() => prev()}>
                  {t(IK.prev)}
                </S2.StyledButton>
                <S2.StyledButton as="button" $primary type="submit">
                  {t(IK.next)}
                </S2.StyledButton>
                {isFinish && (
                  <S2.StyledButton as="button" $primary type="submit" onClick={() => setSkip(true)}>
                    {t(IK.Skip_to_prescription_summary)}
                  </S2.StyledButton>
                )}
              </S2.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Intraoral;
