import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetRejectReason } from "../../../../../apis/studyApi";
import Modal from "../../../../../components/Modal/Modal";
import * as S3 from "../../../../../components/Modal/style/Modal.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import LoadingSpinner from "../../../../../components/loading/LoadingSpinner/LoadingSpinner";
import "../../../../../styles/Editor.css";
import { IK } from "../../../../../utils/i18n/keyStore";
import { rejectReasonList } from "../../../../../utils/statusUtils";
import * as S from "../../style/Profile.style";

const RejectStudy = ({ studyInfo, progress, lastDetail, selectedId, recentStatusId }) => {
  const { patientId } = useParams();
  const { t } = useTranslation(["translation"]);

  const [openRejetModal, setOpenRejetModal] = useState(false);

  const { data, isLoading } = useGetRejectReason(patientId, studyInfo?.statusId, openRejetModal);
  const contents = data?.data;

  const linkList = {
    study: `/patient/${patientId}/study/${studyInfo?.studyId}`,
    additional: `/patient/${patientId}/study/${studyInfo?.studyId}/additional/${studyInfo?.additionalId}`,
    replacement: `/patient/${patientId}/study/${studyInfo?.studyId}/replace/${studyInfo?.replacementId}`,
  };

  //현재 진행중인 상태가 반려일 경우만 버튼 표출
  const isNowReject = recentStatusId === studyInfo?.statusId;

  return (
    <>
      {progress ? (
        <S.ProfileHistoryDesc>
          <S.ProfileHistoryDescDot $complete={lastDetail === "11"} />
          <dl>
            <dt>{t(IK.reject_study)}</dt>
            {studyInfo?.createdDate && (
              <dd>
                <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>
                <S2.ButtonFlex>
                  <S2.StyledSmallButton as="button" onClick={() => setOpenRejetModal(true)}>
                    {t(IK.reject_study_btn)}
                  </S2.StyledSmallButton>
                </S2.ButtonFlex>
              </dd>
            )}
          </dl>
        </S.ProfileHistoryDesc>
      ) : (
        <>
          <S.ProfileHistoryCircle />
          <S.ProfileHistoryModalItem>
            <S.ProfileDepth1>
              <h3>
                {t(IK.reject_study)} {studyInfo?.rejectReason && ` [${t(rejectReasonList[studyInfo.rejectReason])}]`}
              </h3>
              <S2.ButtonFlex>
                <S2.StyledSmallButton as="button" onClick={() => setOpenRejetModal(true)}>
                  {t(IK.reject_study_btn)}
                </S2.StyledSmallButton>
              </S2.ButtonFlex>
              {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
            </S.ProfileDepth1>
          </S.ProfileHistoryModalItem>
        </>
      )}
      <RejectStudyModal
        openRejetModal={openRejetModal}
        setOpenRejetModal={setOpenRejetModal}
        contents={contents}
        link={linkList[Object.keys(selectedId)[0]]}
        isLoading={isLoading}
        isNowReject={isNowReject}
      />
    </>
  );
};

export default RejectStudy;

export const RejectStudyModal = ({ openRejetModal, setOpenRejetModal, contents, link, isLoading, isNowReject }) => {
  const { t } = useTranslation(["translation"]);

  const closeModal = () => {
    setOpenRejetModal(false);
  };

  return (
    <Modal open={openRejetModal} header={t(IK.reject_study_btn)} close={closeModal}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="ck-content" dangerouslySetInnerHTML={{ __html: contents }} />
          <S3.ModalBoxBtn>
            <S2.ButtonFlex>
              <S2.StyledButton as="button" onClick={closeModal}>
                {t(IK.cancel)}
              </S2.StyledButton>
              {isNowReject && (
                <S2.StyledButton to={link} $primary>
                  {t(IK.reject_data_new)}
                </S2.StyledButton>
              )}
            </S2.ButtonFlex>
          </S3.ModalBoxBtn>
        </>
      )}
    </Modal>
  );
};
