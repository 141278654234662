import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/**세라퀵 프로그램 실행
 * @param {string} seraQuickId
 * @description 세라퀵 실행 링크 사용자 검증 후 반환
 */
export const getRunningSeraquick = async (seraQuickId) => {
  const { data } = await axios.get(`/api/sera-quick/${seraQuickId}/run`);
  return data;
};

/**세라퀵 환자 저장 */
export const useSaveSeraquick = () => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async (formData) => {
      Notiflix.Loading.standard("");
      const response = axios.post(`/api/sera-quick`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        Notiflix.Loading.remove();
      },
      onError: (error) => {
        Notiflix.Loading.remove();
        if (error.response.status === 400) {
          Notiflix.Report.warning(t(IK.coin_lack), "", t(IK.confirm), () => {});
        } else {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/**세라퀵 아이디로 프로그램 실행
 * 세라퀵 사용자 검증
 */
export const runSeraquick = async (seraQuickId) => {
  const response = await axios.get(`/api/sera-quick/${seraQuickId}/run`);
  return response;
};

/**세라퀵 저장된 리스트 조회 */
export const useGetSeraquickList = () => {
  const { t } = useTranslation();
  return useQuery(
    ["SeraquickList"],
    async () => {
      const { data } = await axios.get(`/api/sera-quick`);
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라퀵 아이디로 환자 정보 조회 */
export const useGetSeraquickPatientInfo = (seraQuickId) => {
  const { t } = useTranslation();
  return useQuery(
    ["SeraquickPatient", seraQuickId],
    async () => {
      const { data } = await axios.get(`/api/sera-quick/${seraQuickId}`);
      return data;
    },
    {
      enabled: !!seraQuickId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};
