import axios from "axios";

/**세라뷰 파일 아이디 조회 */
export const getSeraviewFileId = async () => {
  const { data } = await axios.get("/api/boards/support/seraview");
  return data;
};

/**세라퀵 파일 아이디 조회 */
export const getSeraquickFileId = async () => {
  const { data } = await axios.get("/api/boards/support/sera-quick");
  return data;
};
