import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useGeEtcInfo, useUpdateEtcInfo } from "../../../apis/doctorApi";
import { checkLoginId } from "../../../apis/joinApi";
import { userInfo } from "../../../atoms/userAtom";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageError, FieldMessageErrorClick, FieldMessageSuccess } from "../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../components/element/Form/FormLayout";
import * as S2 from "../../../components/element/Form/style/Form.style";
import { countryLabel } from "../../../utils/countryUtils";
import { IK } from "../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../utils/objectUtils";
import { AccountTabType } from "../AccountTabType";
import SkeletonAccount from "../SkeletonAccount";

function EtcInfo() {
  const { t } = useTranslation(["translation"]);

  /**계정 기타정보 가져오기 */
  const { data, isLoading } = useGeEtcInfo();
  const etcInfo = data?.data;
  const etcInfoMutation = useUpdateEtcInfo();

  /**사용자 정보 가져오기 */
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  /**스태프 아이디 중복 체크 */
  const [idCheckFlag, setIdCheckFlag] = useState(false);

  const validationSchema = Yup.object().shape({
    staffId: !!etcInfo?.staffId
      ? Yup.string()
      : Yup.string()
          .matches(/^[A-Za-z0-9]+$/, t(IK.id_validate))
          .min(4, t(IK.id_min_validate)),
    loginPw: Yup.string().when(["staffId"], {
      is: (val, val2) => !!val && !etcInfo.staffId,
      then: (val, val2) =>
        Yup.string()
          .required(t(IK.password_required))
          .max(16, t(IK.password_max_validate))
          .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/, t(IK.password_validate)),
      otherwise: (val, val2) => Yup.string(),
    }),
    reLoginPw: Yup.string()
      .oneOf([Yup.ref("loginPw"), null], t(IK.password_not_match))
      .test("required", t(IK.password_not_match), function (value) {
        const staffLoginPw = this.resolve(Yup.ref("loginPw"));
        const staffReLoginPw = this.resolve(Yup.ref("reLoginPw"));
        return staffLoginPw === staffReLoginPw ? true : false;
      }),
    staffIdCheck: Yup.bool().when("staffId", {
      is: (val) => val && val.trim() !== "",
      then: (val) => Yup.bool().isTrue().oneOf([true], t(IK.id_duplicate_check_required)),
      otherwise: (val) => Yup.bool(),
    }),
    staffContact1: Yup.string().when("countryCode", {
      is: (val) => val === "01",
      then: (val) => Yup.string(),
      otherwise: (val) =>
        Yup.string()
          .email(t(IK.email_valid))
          .test("email", t(IK.email_valid), (value) => {
            if (value) {
              const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
              return regex.test(value);
            }
            return true;
          }),
    }),
    staffContact2: Yup.string().when("countryCode", {
      is: (val) => val === "01",
      then: (val) => Yup.string(),
      otherwise: (val) =>
        Yup.string()
          .email(t(IK.email_valid))
          .test("email", t(IK.email_valid), (value) => {
            if (value) {
              const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
              return regex.test(value);
            }
            return true;
          }),
    }),
  });

  /**아이디 검사 */
  const handleOnClickCheckLoginId = (e, setFieldValue, values, setFieldError) => {
    checkLoginId({ loginId: values.staffId })
      .then((res) => {
        if (res.data.code === 200) {
          setIdCheckFlag(true);
          setFieldValue("staffIdCheck", true);
        }
      })
      .catch((e) => {
        setFieldError("staffIdCheck", t(IK.duplicate_id));
      });
  };

  /**업데이트 내역 제출 */
  const handleSubmit = (values) => {
    try {
      Notiflix.Loading.standard("");
      const { staffContact1, staffContact2, countryCode, staffContactId1, staffContactId2, staffIdCheck, ...rest } = values;
      const staffContact = [];

      //스태프 contact 추가
      const addStaffContact = (contact, contactId) => {
        if (contact || contactId) {
          staffContact.push({ contact, ...(contactId && { contactId }) });
        }
      };

      addStaffContact(staffContact1, staffContactId1);
      addStaffContact(staffContact2, staffContactId2);

      const data = deleteEmptyKey({ ...rest, staffContact });
      etcInfoMutation.mutate(data, {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
        },
        onError: () => {
          Notiflix.Report.failure(t(IK.fail_save), "", t(IK.confirm));
        },
      });
    } finally {
      Notiflix.Loading.remove();
    }
  };

  return (
    <Beforeunload onBeforeunload={() => ""}>
      <CardInnerLayout>
        <AccountTabType now="기타정보" />
        <CardTitle required />
        {isLoading ? (
          <SkeletonAccount />
        ) : (
          <>
            <Formik
              initialValues={{
                staffId: etcInfo?.staffId || "",
                staffIdCheck: true,
                loginPw: "",
                reLoginPw: "",
                staffContact1: etcInfo?.staffContact[0]?.contact || "",
                staffContactId1: etcInfo?.staffContact[0]?.contactId || "",
                staffContact2: etcInfo?.staffContact[1]?.contact || "",
                staffContactId2: etcInfo?.staffContact[1]?.contactId || "",
                countryCode: etcInfo.countryCode || "", //유효성 검사용
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount={true}
              enableReinitialize={true}
            >
              {({ setFieldValue, values, setFieldError, errors }) => (
                <Form>
                  <S2.FormControlsColumn $large>
                    {/* Staff 아이디 */}
                    {etcInfo?.staffId ? (
                      <FormControlsBox title={t(IK.staff_id)}>{etcInfo.staffId}</FormControlsBox>
                    ) : (
                      <FormControlsBox title={t(IK.staff_id)}>
                        <S2.FormControlsFlex>
                          <Field
                            as={S2.FormControls}
                            type="staffId"
                            id="staffId"
                            name="staffId"
                            onChange={(e) => {
                              setFieldValue("staffId", e.target.value);
                              if (e.target.value !== etcInfo.staffId) {
                                setFieldValue("staffIdCheck", false);
                                setIdCheckFlag(false);
                              } else {
                                setFieldValue("staffIdCheck", true);
                              }
                            }}
                            autoComplete="new-password"
                          />
                          <S2.FormControlsBtn
                            type="button"
                            onClick={(e) => {
                              handleOnClickCheckLoginId(e, setFieldValue, values, setFieldError);
                            }}
                          >
                            {t(IK.duplicate_btn)}
                          </S2.FormControlsBtn>
                        </S2.FormControlsFlex>
                        <FieldMessageErrorClick name="staffId" />
                        {errors.staffIdCheck && <FieldMessageError text={errors.staffIdCheck} />}
                        {idCheckFlag && <FieldMessageSuccess text={t(IK.available_id_message)} />}
                      </FormControlsBox>
                    )}

                    {/* Staff 새 비밀번호 */}
                    <FormControlsBox title={`Staff ${t(IK.new_password)}`} example="ex) serafin2021!">
                      <Field as={S2.FormControls} type="password" id="loginPw" name="loginPw" maxLength="16" autoComplete="new-password" />
                      <FieldMessageErrorClick name="loginPw" />
                    </FormControlsBox>

                    {/* Staff 새 비밀번호 확인 */}
                    <FormControlsBox title={`Staff ${t(IK.confirm_new_password)}`}>
                      <Field as={S2.FormControls} type="password" id="reLoginPw" name="reLoginPw" maxLength="16" autoComplete="new-password" />
                      <FieldMessageErrorClick name="reLoginPw" />
                    </FormControlsBox>

                    {/* Staff 연락처 1 */}
                    <FormControlsBox title={countryCode === "01" ? `${t(IK.staff_contact)} 1` : `${t(IK.staff_email)} 1`}>
                      <Field as={S2.FormControls} type="text" name="staffContact1" />
                      <FieldMessageErrorClick name="staffContact1" />
                    </FormControlsBox>

                    {/* Staff 연락처 2 */}
                    <FormControlsBox title={countryCode === "01" ? `${t(IK.staff_contact)} 2` : `${t(IK.staff_email)} 2`}>
                      <Field as={S2.FormControls} type="text" name="staffContact2" />
                      <FieldMessageErrorClick name="staffContact2" />
                    </FormControlsBox>

                    {/* 국가 */}
                    <FormControlsBox title={t(IK.country_code)}>{t(countryLabel[etcInfo.countryCode])}</FormControlsBox>

                    {countryCode === "01" && (
                      // 담당자
                      <FormControlsBox title={t(IK.recommendation)}>{etcInfo.partnerName}</FormControlsBox>
                    )}
                  </S2.FormControlsColumn>

                  <CardTail line>
                    <S.ButtonLtBox>
                      <S.StyledButton as="button" $primary type="submit">
                        {t(IK.save)}
                      </S.StyledButton>
                    </S.ButtonLtBox>
                  </CardTail>
                </Form>
              )}
            </Formik>
          </>
        )}
      </CardInnerLayout>
    </Beforeunload>
  );
}

export default EtcInfo;
