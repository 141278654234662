import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import * as Yup from "yup";
import { useSavePreferredPlan } from "../../../../../apis/preferredTreatmentApi";
import { useGetStudySummary, useSaveStep } from "../../../../../apis/studyApi";
import { userIsPreferedPlan } from "../../../../../atoms/userAtom";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S4 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import * as S3 from "../../../../../components/element/Form/style/Form.style";
import { TitleV3 } from "../../../../../components/element/Title/TitleText";
import { enterKeyDown } from "../../../../../utils/enterKeyDown";
import { IK } from "../../../../../utils/i18n/keyStore";

function Instructions({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, studyInfo, setStudyInfo, patientId, isFinish, setCurrentStepIndex }) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();
  const [studyPlanId, setStudyPlanId] = useRecoilState(userIsPreferedPlan);

  /**처방전 정보 가져오기 */
  const { data: studyData, isLoading: isStudyLoading } = useGetStudySummary(patientId, studyInfo.studyId, studyPlanId, true);
  const studyPatientData = studyData?.data;

  useEffect(() => {
    /**선호 치료계획으로 온 경우 데이터 없을시 넣어주기 */
    if (!isStudyLoading && studyPatientData && !!studyPlanId) {
      const { firstName, lastName, korName } = studyPatientData.patient;
      const { studyId, age, packages } = studyPatientData.study;
      setStudyInfo({
        ...studyPatientData,
        studyId,
        age,
        packages,
        patientName: `${firstName} ${lastName}${!!korName ? ` (${korName})` : ""}`,
      });
      setStudyPlanId(0);
    }
  }, [isStudyLoading, studyPatientData, setStudyInfo, studyPlanId, setStudyPlanId]);

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**선호 치료계획 모달 */
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const openPlanModal = () => setPlanModalOpen(true);
  const closePlanModal = () => setPlanModalOpen(false);

  /**선호 치료계획 유효성 */
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(IK.pick_study_save_placeholder)),
  });

  const saveStudyPlanMutation = useSavePreferredPlan();
  /**선호 치료계획 저장 */
  const handleStudyPlanSubmit = (data) => {
    saveStudyPlanMutation.mutate(
      {
        studyId: studyInfo.studyId,
        data,
      },
      {
        onSuccess: () => {
          closePlanModal();
        },
      }
    );
  };

  const code = currentStepList[currentStepIndex].code;
  const handleSubmit = (data) => {
    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip ? setCurrentStepIndex(currentStepList.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`}>
        <S.StyledButton as="button" type="submit" $primary onClick={openPlanModal}>
          {t(IK.pick_study_save)}
        </S.StyledButton>
      </CardTitle>

      <Formik
        initialValues={{
          SI13: studyInfo.SI13 || "",
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormControlsStudyBox title={t(IK.special_instructions)} openModal={openModal}>
            <Field name="SI13">{({ field }) => <S3.FormControls as="textarea" $textarea $full {...field} />}</Field>
          </FormControlsStudyBox>

          <CardTail
            error={
              studyInfo.age === "adult" && (
                <>
                  {t(IK.special_instructions_noti1)}
                  <br />
                  {t(IK.special_instructions_noti2)}
                </>
              )
            }
            line
            study
          >
            <S4.CardFlex>
              <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                {t(IK.select_treatment_options)}
              </S.StyledButton>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" type="submit" $primary>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} $primary>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </S4.CardFlex>
          </CardTail>
        </Form>
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.special_instructions_pop_message)}</li>
        </S2.ModalBoxBullet>
      </Modal>

      <Modal open={planModalOpen} close={closePlanModal} header={t(IK.pick_study_save)}>
        <TitleV3 title={t(IK.pick_study_desc1)}>
          <p>{t(IK.pick_study_desc2)}</p>
        </TitleV3>

        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleStudyPlanSubmit}
        >
          <Form onKeyDown={enterKeyDown}>
            <S3.FormControlsFlex>
              <Field name="name">{({ field }) => <S3.FormControls $full placeholder={t(IK.pick_study_save_placeholder)} {...field} />}</Field>
              <S.StyledButton as="button" type="submit" $primary>
                {t(IK.save)}
              </S.StyledButton>
            </S3.FormControlsFlex>
            <FieldMessageErrorClick name="name" />
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

export default Instructions;
