import { styled } from "styled-components";

export const ToolTipBox = styled.div`
  position: absolute;
  z-index: 1;
  margin-top: 16px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  line-height: 1;
  font-size: 41px;
  font-weight: 900;
  display: none;
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    left: 14px;
    width: 12px;
    height: 12px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.white};
    transform: rotate(135deg);
  }
`;
