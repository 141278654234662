import Skeleton from "react-loading-skeleton";
import * as S from "./style/ClinicalCases.style";
import * as S2 from "../../../styles/Common";

export const SkeletonView = () => {
  return (
    <>
      <S.CaseHead>
        <S.CaseHeadBox>
          <SkeletonInfo />
          <Skeleton width={450} height={275} />
        </S.CaseHeadBox>
      </S.CaseHead>

      <ExSkeletonView />
      <ExSkeletonView />
      <ExSkeletonView />
      <ExSkeletonView />
    </>
  );
};

export const SkeletonInfo = () => {
  return (
    <S.CaseInfo>
      <h2>
        <Skeleton height={20} width={700} />
      </h2>
      <dl>
        <dt>
          <Skeleton height={20} width={100} />
        </dt>
        <dd>
          <Skeleton height={20} width={200} />
        </dd>
      </dl>
      <dl>
        <dt>
          <Skeleton height={20} width={100} />
        </dt>
        <dd>
          <Skeleton height={20} width={200} />
        </dd>
      </dl>
      <dl>
        <dt>
          <Skeleton height={20} width={100} />
        </dt>
        <dd>
          <Skeleton height={20} width={200} />
        </dd>
      </dl>
      <dl>
        <dt>
          <Skeleton height={20} width={100} />
        </dt>
        <dd>
          <Skeleton height={20} width={200} />
        </dd>
      </dl>
    </S.CaseInfo>
  );
};

export const ExSkeletonView = () => {
  return (
    <S2.ContentBox $large>
      <Skeleton height={40} width={"100%"} />
      <Skeleton height={30} width={400} />
      <Skeleton height={30} width={500} />
      <Skeleton height={30} width={370} />
    </S2.ContentBox>
  );
};
