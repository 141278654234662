import { useTranslation } from "react-i18next";
import ClinicalConfigurationsForm from "../../pages/Account/ClinicalConfigurations/Sections/Form";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "../Modal/style/Modal.style";

const ClinicalConfigurationsPopup = ({ open, close, showCloseBtn, patientId }) => {
  const { t } = useTranslation(["translation"]);
  // 임상환경 설정 팝업
  return (
    <S.ModalBox $openModal={open}>
      {open && (
        <section>
          <S.ModalBoxHeader>
            <h3>{t(IK.clinical_configurations)}</h3>
            {showCloseBtn && (
              <button className="close" onClick={close}>
                <i className="ri-close-line"></i>
              </button>
            )}
          </S.ModalBoxHeader>
          <S.ModalBoxCont>
            <ClinicalConfigurationsForm patientId={patientId} isModal showCloseBtn={showCloseBtn} close={close} />
          </S.ModalBoxCont>
        </section>
      )}
    </S.ModalBox>
  );
};

export default ClinicalConfigurationsPopup;
