import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const StudySwiper = ({ studySwiperList, setSelectedId, selectedId, setLastDetail, setIsAdditionalEdit }) => {
  const { t } = useTranslation(["translation"]);

  /**처방전 선택하기 */
  const handleChangeStudy = (id, item) => {
    setSelectedId(id);
    setLastDetail(item.lastDetail);
    setIsAdditionalEdit(item.additionalProcess);
  };

  const nameList = {
    additional: "additional_device",
    replacement: "alternative_correction_devices",
    study: "main_study",
  };

  return (
    <S.ProfileHistoryTab>
      <Swiper slidesPerView={"auto"} navigation={true} modules={[Navigation]}>
        {studySwiperList?.map((item, index) => (
          <SwiperSlide
            key={`${index}_${item?.createdDate}`}
            className={item[`${item.type}Id`] === selectedId?.[item.type] ? "active" : ""}
            onClick={() => handleChangeStudy({ [item.type]: item[`${item.type}Id`] }, item)}
          >
            {t(IK[nameList[item.type]])}({dayjs(item.createdDate).format("YY-MM-DD")})
          </SwiperSlide>
        ))}
      </Swiper>
    </S.ProfileHistoryTab>
  );
};

export default StudySwiper;
