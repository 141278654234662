import { css, styled } from "styled-components";

// 스터디 폼
export const FormControlsStudyBox = styled.div`
  padding: 30px;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const FormControlsStudyItem = styled.div`
  padding: 0 15px;
`;

// 입력 폼
export const FormControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const FormControlsBox = styled.div`
  display: flex;
  align-items: baseline;
  .css-13cymwt-control {
    min-height: 50px;
    border-color: ${(props) => props.theme.colors.gray200};
    &:hover {
      border-color: ${(props) => props.theme.colors.gray200};
    }
  }
  .css-1u9des2-indicatorSeparator {
    background-color: ${(props) => props.theme.colors.gray200};
  }
`;

export const FormControlsTitle = styled.div`
  width: ${(props) => (props.$regular ? "125px" : props.$medium ? "155px" : props.$large ? "260px" : "200px")};
  h3 {
    font-size: 15px;
  }
  ${(props) =>
    props.$required &&
    css`
      h3 {
        position: relative;
        &:after {
          content: "*";
          margin-left: 3px;
          color: rgb(233, 30, 99);
        }
      }
    `};
  span {
    font-size: 13px;
    font-style: italic;
    color: ${(props) => props.theme.colors.gray500};
  }
`;

export const FormControlsDiv = styled.div`
  flex: 1;
`;

export const FormControlsFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: ${(props) => (props.$large ? "30px" : "10px")};
  ${(props) =>
    props.$colCenter &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.$rowCenter &&
    css`
      align-items: center;
    `}
`;

export const FormControlsLine = styled.div`
  margin-top: 15px;
  padding-top: 16px;
  border-top: 1px dashed ${(props) => props.theme.colors.gray400};
`;

export const FormControlsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$large ? "25px" : "10px")};
`;

export const FormIndent = styled.div`
  margin-left: 29px;
`;

export const FormControls = styled.input`
  width: 370px;
  height: 46px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
  &:disabled {
    background-color: ${(props) => props.theme.colors.gray300};
  }
  &:read-only {
    background-color: ${(props) => props.theme.colors.gray300};
  }
  &:focus-visible {
    outline: 1px solid ${(props) => props.theme.colors.blue400};
    outline-offset: -1px;
  }
  &::file-selector-button {
    display: none;
  }
  &[type="file"] {
    padding: 12px 10px;
    background-color: ${(props) => props.theme.colors.white};
  }

  ${(props) =>
    props.$large &&
    css`
      width: 200px;
    `};

  ${(props) =>
    props.$regular &&
    css`
      width: 160px;
    `};

  ${(props) =>
    props.$small &&
    css`
      width: 100px;
    `};

  ${(props) =>
    props.$full &&
    css`
      width: 100%;
      flex: 1;
    `};

  ${(props) =>
    props.$select &&
    css`
      width: auto;
      padding: 12px 30px 12px 10px;
      cursor: pointer;
      &:read-only {
        background-color: ${(props) => props.theme.colors.white};
      }
    `};

  ${(props) =>
    props.$textarea &&
    css`
      flex: auto;
      display: block;
      min-height: 100px;
      resize: ${(props) => (props.$resizenone ? "none" : "vertical")};
    `};
`;

export const FormLineControls = styled.input`
  width: 50px;
  padding: 5px;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
  background-color: transparent;
  &:disabled {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.gray600};
  }

  ${(props) =>
    props.$large &&
    css`
      width: 200px;
    `};

  ${(props) =>
    props.$regular &&
    css`
      width: 160px;
    `};

  ${(props) =>
    props.$small &&
    css`
      width: 100px;
    `};

  ${(props) =>
    props.$select &&
    css`
      width: auto;
      padding: 5px 23px 5px 5px;
      cursor: pointer;
      background-position: center right 5px;
    `};
`;

export const FormControlsBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  min-width: 103px;
  height: 46px;
  line-height: 44px;
  padding: 0px 20px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.blue400};
  color: ${(props) => props.theme.colors.blue400};
  font-weight: 600;
  cursor: pointer;
`;

export const FormFileList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
  li {
    display: flex;
    align-items: center;
  }
  button {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    color: ${(props) => props.theme.colors.blue400};
  }
`;

export const FormSelectListBox = styled.div`
  position: relative;
  display: inline-block;
`;

export const FormSelectList = styled.ul`
  position: absolute;
  top: 50px;
  width: 100%;
  max-height: 211px;
  overflow: auto;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 5px;
  li {
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.white};
    &:hover {
      background-color: ${(props) => props.theme.colors.gray300};
    }
    &:last-child {
      border-bottom: 0;
    }
  }
`;

export const FormCardList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  > li {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => (props.$bgwhite ? "125px" : props.theme.colors.gray300)};
  }
`;

export const FormConfirmBox = styled.div`
  padding: 40px;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 10px;
  text-align: center;
  h2 {
    margin: 20px 0 15px;
    font-size: 24px;
  }
  p {
    font-size: 18px;
  }
`;

export const FormConfirmBoxIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.colors.blue400};
  color: ${(props) => props.theme.colors.blue400};
  font-size: 28px;
`;
