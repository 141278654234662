import styled from "styled-components";

export const LanguageWrap = styled.div`
  position: relative;
`;

export const LanguageBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 7px;
  color: ${(props) => props.theme.colors.gray600};
`;

export const LanguagePopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: 30px;
  padding: 10px;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.18) 0px 1px 2px;
`;
