import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S2 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../../../../components/element/SelectionControls/style/SelectionControls.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const StudyPickList = ({ studyOrderHistorty, selectedStudy, setSelectedStudy }) => {
  const { t } = useTranslation(["translation"]);

  /**스터디 선택하기 */
  const onChangeStudy = (study) => {
    setSelectedStudy(study);
  };

  useEffect(() => {
    const radioInput = document.getElementById(selectedStudy.studyId);
    if (radioInput) {
      radioInput.checked = true;
    }
  }, [selectedStudy]);

  return (
    <S.ProfilePrevOrderList>
      <S2.CardInner>
        <S2.CardTitle>
          <h2>{t(IK.previous_order_list)}</h2>
        </S2.CardTitle>
        <ul>
          {studyOrderHistorty.map((item) => (
            <li key={item.studyId}>
              <S3.SelectionItem>
                <S3.SelectionItemLabel onClick={() => onChangeStudy(item)}>
                  <S3.SelectionItemInput $radio id={item.studyId} type="radio" name="study" />
                  <S3.SelectionItemSpan>
                    {t(IK[item.packages])} ({dayjs(item?.createdDate).format("YYYY-MM-DD")})
                  </S3.SelectionItemSpan>
                </S3.SelectionItemLabel>
              </S3.SelectionItem>
            </li>
          ))}
        </ul>
      </S2.CardInner>
    </S.ProfilePrevOrderList>
  );
};

export default StudyPickList;
