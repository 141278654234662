import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import { useRecoilState } from "recoil";
import { converterTimeZone } from "../../apis/userApi";
import { userTimezone } from "../../atoms/userAtom";
import { formatTimezone } from "../../utils/dateUtils";

const callBackApiList = {
  "/patient": ["PatientList"],
  "/patient/list": ["PatientList"],
  "/patient/:patientId/profile": ["PatientInfo", "PatientStudyList", "StudySwiper", "StudyProgress"],
  "/education/library-list": ["BoardList"],
  "/education/library-list/:boardId": ["BoardInfo"],
  "/education/educational-materials-list": ["BoardList"],
  "/education/educational-materials-list/:boardId": ["BoardInfo"],
  "/education/correction-image-list": ["BoardList"],
  "/education/correction-image-list/:boardId": ["BoardInfo"],
  "/support/event-list": ["EventList"],
  "/support/event-list/:eventBoardId": ["EventInfo"],
  "/support/notice-list": ["BoardList"],
  "/support/notice-list/:boardId": ["BoardInfo"],
};

/** URL 패턴 일치 여부 확인 함수 */
const isUrlMatch = (urlPattern, urlToCheck) => {
  const regexPattern = new RegExp("^" + urlPattern.replace(/:\w+/g, "\\d+") + "$");
  return regexPattern.test(urlToCheck);
};

const TimeZone = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [selectedTimezone, setSelectedTimezone] = useRecoilState(userTimezone);

  /** 현재 pathname에 해당하는 콜백 API 찾기 */
  const matchedComponent = useMemo(() => {
    for (const [path, queryKey] of Object.entries(callBackApiList)) {
      if (isUrlMatch(path, pathname)) return queryKey;
    }
    return [];
  }, [pathname]);

  /** 타임존 변경 핸들러 */
  const handleTimezone = useCallback(
    (timezone) => {
      const formattedTimezone = formatTimezone(timezone); // 타임존 포맷
      converterTimeZone(formattedTimezone)
        .then(() => {
          setSelectedTimezone(timezone.value);
          if (matchedComponent?.length > 0) {
            // 쿼리 캐시 무효화
            queryClient.invalidateQueries({
              predicate: (query) => [...(matchedComponent || []), "AlarmList"].includes(query.queryKey[0]),
            });
          }
        })
        .catch(console.error);
    },
    [matchedComponent, queryClient, setSelectedTimezone]
  );

  return <TimezoneSelect value={selectedTimezone} onChange={handleTimezone} menuPlacement="top" menuPosition="auto" />;
};

export default TimeZone;
