/**프리시젼 치아 기본 값 */
export const elasticTeeth = {
  class_2: {
    elastic_bs: {
      teeth_18: "",
      teeth_17: "",
      teeth_16: "",
      teeth_15: "",
      teeth_14: "",
      teeth_13: "ELASTIC_HOOKS",
      teeth_23: "ELASTIC_HOOKS",
      teeth_24: "",
      teeth_25: "",
      teeth_26: "",
      teeth_27: "",
      teeth_28: "",
      teeth_48: "",
      teeth_47: "",
      teeth_46: "BUTTON_HOLE",
      teeth_45: "",
      teeth_44: "",
      teeth_43: "",
      teeth_33: "",
      teeth_34: "",
      teeth_35: "",
      teeth_36: "BUTTON_HOLE",
      teeth_37: "",
      teeth_38: "",
    },
    elastic_ts: {
      teeth_18: "",
      teeth_17: "",
      teeth_16: "",
      teeth_15: "",
      teeth_14: "",
      teeth_13: "",
      teeth_23: "",
      teeth_24: "",
      teeth_25: "",
      teeth_26: "",
      teeth_27: "",
      teeth_28: "",
      teeth_48: "",
      teeth_47: "",
      teeth_46: "",
      teeth_45: "",
      teeth_44: "",
      teeth_43: "",
      teeth_33: "",
      teeth_34: "",
      teeth_35: "",
      teeth_36: "",
      teeth_37: "",
      teeth_38: "",
    },
  },
  class_3: {
    elastic_bs: {
      teeth_18: "",
      teeth_17: "",
      teeth_16: "BUTTON_HOLE",
      teeth_15: "",
      teeth_14: "",
      teeth_13: "",
      teeth_23: "",
      teeth_24: "",
      teeth_25: "",
      teeth_26: "BUTTON_HOLE",
      teeth_27: "",
      teeth_28: "",
      teeth_48: "",
      teeth_47: "",
      teeth_46: "",
      teeth_45: "",
      teeth_44: "",
      teeth_43: "ELASTIC_HOOKS",
      teeth_33: "ELASTIC_HOOKS",
      teeth_34: "",
      teeth_35: "",
      teeth_36: "",
      teeth_37: "",
      teeth_38: "",
    },
    elastic_ts: {
      teeth_18: "",
      teeth_17: "",
      teeth_16: "",
      teeth_15: "",
      teeth_14: "",
      teeth_13: "",
      teeth_23: "",
      teeth_24: "",
      teeth_25: "",
      teeth_26: "",
      teeth_27: "",
      teeth_28: "",
      teeth_48: "",
      teeth_47: "",
      teeth_46: "",
      teeth_45: "",
      teeth_44: "",
      teeth_43: "",
      teeth_33: "",
      teeth_34: "",
      teeth_35: "",
      teeth_36: "",
      teeth_37: "",
      teeth_38: "",
    },
  },
};

/**프리시젼 치아 포지션 */
export const getelasticTeethPosition = (teethLabel, index) => {
  //치아 타입
  const teethType = {
    upperTeeth_1: ["top", "left", "width", "height", "paddingTop", "paddingRight"],
    upperTeeth_2: ["top", "right", "width", "height", "paddingTop", "paddingLeft"],
    lowerTeeth_1: ["top", "left", "width", "height", "paddingBottom", "paddingRight"],
    lowerTeeth_2: ["top", "right", "width", "height", "paddingBottom", "paddingLeft"],
  };

  //상악인지 하악인지 판단
  const isUpper = teethLabel.includes("upper");

  //상악, 하악에 따른 치아 위치
  const teethPositions = isUpper
    ? [
        [17, 13, 10, 8, 6, -1],
        [0, 46, 99, 156, 201, 244],
      ]
    : [
        [34, 34, 34, 34, 34, 34],
        [0, 50, 101, 158, 203, 246],
      ];

  //상악, 하악에 따른 치아 크기
  const teethSize = isUpper
    ? [
        [41, 48, 52, 40, 38, 34],
        [41, 45, 47, 50, 52, 59],
      ]
    : [
        [45, 46, 52, 40, 38, 39],
        [39, 39, 42, 46, 49, 54],
      ];

  //상악, 하악에 따른 치아 간격
  const teethPadding = isUpper
    ? [
        [4, 3, 3, 6, 7, 12],
        [5, 1, 4, 4, 3, 6],
      ]
    : [
        [4, 4, 3, 6, 6, 7],
        [1, 0, 0, 2, 0, 0],
      ];

  //해당 위치에 맞는 치아 포지션을 반환
  const [upDown, leftRight, width, height, paddingUpDown, paddingLeftRight] = teethType[teethLabel];

  return {
    [upDown]: teethPositions[0][index] + "px",
    [leftRight]: teethPositions[1][index] + "px",
    [width]: teethSize[0][index] + "px",
    [height]: teethSize[1][index] + "px",
    [paddingUpDown]: teethPadding[0][index] + "px",
    [paddingLeftRight]: teethPadding[1][index] + "px",
    alignItems: isUpper ? "flex-start" : "flex-end",
  };
};

/**프리시젼 치아 좌우반전 */
export const getelasticTeethScale = (teethLabel, teethValue) => {
  //상악인지 하악인지 판단
  const isUpper = teethLabel.includes("upper");
  //왼쪽 치아인지 오른쪽 치아인지 판단
  const isLeft = teethLabel.includes("1");

  const teethAngle = () => {
    const scaleY = isUpper ? 1 : -1;
    const scaleX = isLeft ? -1 : 1;
    return {
      transform: teethValue === "BUTTON_HOLE" ? `scaleY(${scaleY})` : `scaleX(${scaleX})`,
    };
  };

  return {
    ...teethAngle(),
  };
};
