import { css, styled } from "styled-components";

export const TabType1 = styled.ul`
  display: flex;
  margin-bottom: 30px;
`;

export const TabType1Item = styled.li`
  position: relative;
  flex: 1;
  height: 60px;
  line-height: 58px;
  text-align: center;
  background-color: ${(props) => (props.$active ? props.theme.colors.white : props.theme.colors.gray300)};
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  border-bottom: 1px solid ${(props) => (props.$active ? "transparent" : props.theme.colors.gray200)};
  border-left: 1px solid ${(props) => (props.$active ? props.theme.colors.gray200 : "transparent")};
  border-right: 1px solid ${(props) => (props.$active ? props.theme.colors.gray200 : "transparent")};
  cursor: pointer;
  font-size: 15px;
  font-weight: ${(props) => (props.$active ? "600" : "")};
  &:first-child {
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
  }
  &:last-child {
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
  }
  &:nth-child(n + 2):before {
    content: "";
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 0;
    width: 1px;
    background-color: ${(props) => props.theme.colors.gray200};
  }
  ${(props) =>
    props.$active &&
    css`
      &:before {
        display: none;
      }
      + li:before {
        display: none;
      }
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 2px;
        background: ${(props) => props.theme.colors.blue400};
      }
    `};
  a {
    display: block;
  }
  button {
    font-size: 15px;
    font-weight: ${(props) => (props.$active ? "600" : "")};
  }
`;

export const TabType2 = styled.ul`
  display: flex;
`;

export const TabType2Item = styled.li`
  padding: 0 50px 8px;
  border-bottom: 3px solid ${(props) => (props.$active ? props.theme.colors.blue400 : props.theme.colors.gray200)};
  color: ${(props) => (props.$active ? props.theme.colors.blue400 : "")};
  font-weight: ${(props) => (props.$active ? "600" : "")};
  font-size: 15px;
  cursor: pointer;
`;
