import * as S from "./style/Modal.style";

const Modal = ({ open, close, header, headerSub, children, headernone }) => {
  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <S.ModalBox $openModal={open}>
      {open && (
        <section>
          <S.ModalBoxHeader $headernone={headernone}>
            <h3>
              {header} {headerSub && <span>{headerSub}</span>}
            </h3>
            {close && (
              <button className="close" onClick={close}>
                <i className="ri-close-line"></i>
              </button>
            )}
          </S.ModalBoxHeader>
          <S.ModalBoxCont $headernone={headernone}>{children}</S.ModalBoxCont>
        </section>
      )}
    </S.ModalBox>
  );
};

export default Modal;
