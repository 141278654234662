import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useInsertAgreementFile } from "../../../../../apis/patientApi";
import Modal from "../../../../../components/Modal/Modal";
import * as S from "../../../../../components/Modal/style/Modal.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import * as S3 from "../../../../../components/element/Form/style/Form.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import { eachUploader } from "../../../../../utils/limitUpload";

export const PatientAgreementModal = ({ agreementModalOpen, setAgreementModalOpen, patientId }) => {
  const { t } = useTranslation(["translation"]);
  const agreeFileMutation = useInsertAgreementFile();

  const closeAgreementModal = () => setAgreementModalOpen(false);

  /**의무정보 제공 동의서 제출 */
  const handleSubmit = (value) => {
    const data = new FormData();
    data.append("agreementFile", value.patientAgreement);
    agreeFileMutation.mutate(
      { patientId, data },
      {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {
            closeAgreementModal();
          });
        },
        onError: () => {
          Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
        },
      }
    );
  };

  return (
    <Modal open={agreementModalOpen} close={closeAgreementModal} header={t(IK.patient_consent_form)}>
      <Formik
        initialValues={{
          patientAgreement: "",
        }}
        validationSchema={Yup.object().shape({
          patientAgreement: Yup.mixed().nullable().required(t(IK.patient_consent_form_required)),
        })}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <S3.FormControlsFlex>
              <S3.FormControls $full type="file" id="patientAgreement" name="patientAgreement" onChange={(event) => eachUploader(event, setFieldValue, "patientAgreement", t)} />
              <S3.FormControlsBtn as="label" htmlFor="patientAgreement" type="button">
                {t(IK.file_btn)}
              </S3.FormControlsBtn>
            </S3.FormControlsFlex>
            <FieldMessageErrorClick name="patientAgreement" />

            <S.ModalBoxBtn>
              <S2.ButtonFlex>
                <S2.StyledButton as="button" type="button" onClick={closeAgreementModal}>
                  {t(IK.cancel)}
                </S2.StyledButton>
                <S2.StyledButton as="button" type="submit" $primary>
                  {t(IK.compelete)}
                </S2.StyledButton>
              </S2.ButtonFlex>
            </S.ModalBoxBtn>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
