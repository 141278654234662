import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";

function Midline({
  next,
  prev,
  currentStepType,
  stepLabel,
  currentStepList,
  currentStepIndex,
  setStudyInfo,
  studyInfo,
  patientId,
  isFinish,
  setCurrentStepIndex,
}) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleOnChangeSI08 = (e, setFieldValue) => {
    const currentTarget = e.currentTarget;
    if (currentTarget.value !== "3") {
      setFieldValue("SI08_3_01", []);
      setFieldValue("SI08_3_02", "");
      setFieldValue("SI08_3_01_1_01", 0);
      setFieldValue("SI08_3_01_2_01", 0);
      setFieldValue("SI08_3_01_3_01", 0);
      setFieldValue("SI08_3_01_4_01", 0);
    }
  };

  const validationSchema = Yup.object().shape({
    SI08: Yup.string().required(t(IK.midline_required)),
    SI08_3_01: Yup.array()
      .of(Yup.string())
      .when("SI08_3_02", {
        is: "3",
        then: (val) => Yup.array().min(1, t(IK.two_jaw_movement_required)),
        otherwise: (val) => Yup.array(),
      }),
    SI08_3_02: Yup.string().when("SI08", {
      is: (val) => studyInfo.SI01 === "1" && val === "3",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
      otherwise: (val) => Yup.string(),
    }),
    SI08_3_01_1_01: Yup.number().when(["SI08_3_01", "SI08"], {
      is: (SI08_3_01, SI08) =>
        SI08_3_01 && SI08_3_01.includes("1") && SI08 === "3",
      then: (val) =>
        Yup.number()
          .required(t(IK.enter_figure))
          .moreThan(0, t(IK.enter_figure)),
      otherwise: (val) => Yup.number(),
    }),
    SI08_3_01_2_01: Yup.number().when(["SI08_3_01", "SI08"], {
      is: (SI08_3_01, SI08) =>
        SI08_3_01 && SI08_3_01.includes("2") && SI08 === "3",
      then: (val) =>
        Yup.number()
          .required(t(IK.enter_figure))
          .moreThan(0, t(IK.enter_figure)),
      otherwise: (val) => Yup.number(),
    }),
    SI08_3_01_3_01: Yup.number().when(["SI08_3_01", "SI08"], {
      is: (SI08_3_01, SI08) =>
        SI08_3_01 && SI08_3_01.includes("3") && SI08 === "3",
      then: (val) =>
        Yup.number()
          .required(t(IK.enter_figure))
          .moreThan(0, t(IK.enter_figure)),
      otherwise: (val) => Yup.number(),
    }),
    SI08_3_01_4_01: Yup.number().when(["SI08_3_01", "SI08"], {
      is: (SI08_3_01, SI08) =>
        SI08_3_01 && SI08_3_01.includes("4") && SI08 === "3",
      then: (val) =>
        Yup.number()
          .required(t(IK.enter_figure))
          .moreThan(0, t(IK.enter_figure)),
      otherwise: (val) => Yup.number(),
    }),
  });

  const code = currentStepList[currentStepIndex].code;
  const config = studyFormConfig[currentStepType][stepLabel];

  const handleSubmit = (values) => {
    const data = {
      SI08: values.SI08,
    };

    if (studyInfo.SI01 === "1" && values.SI08 === "3") {
      const { SI08_3_02, SI08_3_01 } = values;
      data.SI08_3_02 = SI08_3_02;

      if (SI08_3_02 === "3") {
        data.SI08_3_01 = SI08_3_01.join(",");

        SI08_3_01.forEach((item) => {
          const key = `SI08_3_01_${item}_01`;
          data[key] = values[key];
        });
      }
    }

    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip
            ? setCurrentStepIndex(currentStepList.length - 2)
            : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
        required
      />

      <Formik
        initialValues={{
          SI08: studyInfo.SI08 || "",
          SI08_3_01: studyInfo.SI08_3_01 ? studyInfo.SI08_3_01.split(",") : [],
          SI08_3_02: studyInfo.SI08_3_02 || "",
          SI08_3_01_1_01: studyInfo.SI08_3_01_1_01 || 0,
          SI08_3_01_2_01: studyInfo.SI08_3_01_2_01 || 0,
          SI08_3_01_3_01: studyInfo.SI08_3_01_3_01 || 0,
          SI08_3_01_4_01: studyInfo.SI08_3_01_4_01 || 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormControlsStudyBox
              title={t(IK.midline)}
              required
              openModal={openModal}
            >
              <div className="frm_column">
                {config.midline_item1 && (
                  <div className="frm_div">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI08"
                          className="input_radio"
                          onChange={(e) => {
                            setFieldValue("SI08", e.currentTarget.value);
                            handleOnChangeSI08(e, setFieldValue);
                          }}
                          value="1"
                        />
                        <span>{t(IK.midline_item1)}</span>
                      </label>
                    </div>
                  </div>
                )}

                {config.midline_item2 && (
                  <div className="frm_div">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI08"
                          onChange={(e) => {
                            setFieldValue("SI08", e.currentTarget.value);
                            handleOnChangeSI08(e, setFieldValue);
                          }}
                          className="input_radio"
                          value="2"
                        />
                        <span>{t(IK.midline_item2)}</span>
                      </label>
                    </div>
                  </div>
                )}

                {config.midline_item3 && (
                  <div className="frm_div">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI08"
                          onChange={(e) => {
                            setFieldValue("SI08", e.currentTarget.value);
                            handleOnChangeSI08(e, setFieldValue);
                          }}
                          className="input_radio"
                          value="3"
                        />
                        <span>{t(IK.midline_item3)}</span>
                      </label>
                    </div>
                  </div>
                )}
                {/**양악을 선택했을때만 하위옵션 존재 */}
                {studyInfo.SI01 === "1" && (
                  <div id="sub_3" className={values.SI08 === "3" ? "" : "hide"}>
                    <div className="frm_radio_indent frm_column">
                      {config.midline_item3_1 && (
                        <div className="radio">
                          <label className="label_radio">
                            <Field
                              type="radio"
                              name="SI08_3_02"
                              onChange={(e) => {
                                setFieldValue(
                                  "SI08_3_02",
                                  e.currentTarget.value
                                );
                              }}
                              className="input_radio"
                              value="1"
                            />
                            <span>{t(IK.midline_item3_1)}</span>
                          </label>
                        </div>
                      )}
                      {config.midline_item3_2 && (
                        <div className="radio">
                          <label className="label_radio">
                            <Field
                              type="radio"
                              name="SI08_3_02"
                              onChange={(e) => {
                                setFieldValue(
                                  "SI08_3_02",
                                  e.currentTarget.value
                                );
                              }}
                              className="input_radio"
                              value="2"
                            />
                            <span>{t(IK.midline_item3_2)}</span>
                          </label>
                        </div>
                      )}
                      {config.midline_item3_3 && (
                        <div className="frm_div">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI08_3_02"
                                onChange={(e) => {
                                  setFieldValue(
                                    "SI08_3_02",
                                    e.currentTarget.value
                                  );
                                }}
                                className="input_radio"
                                value="3"
                              />
                              <span>{t(IK.midline_item3_3)}</span>
                            </label>
                          </div>
                          <FieldMessageErrorClick name="SI08_3_02" />
                        </div>
                      )}

                      {config.midline_item3_3 && config.midline_item3_3_1 && (
                        <div
                          id="sub_3_3"
                          className={values.SI08_3_02 === "3" ? "" : "hide"}
                        >
                          <div className="frm_radio_indent frm_column">
                            <div className="chk_area">
                              <div className="frm_div">
                                <div className="frm_flex">
                                  <div className="checkbox">
                                    <label className="label_checkbox">
                                      <Field
                                        type="checkbox"
                                        name="SI08_3_01"
                                        className="input_checkbox"
                                        value="1"
                                      />
                                      <span>
                                        {t(IK.maxilla)} {t(IK.move_right)}
                                      </span>
                                    </label>
                                  </div>
                                  <div className="frm_line_field">
                                    <Field
                                      name="SI08_3_01_1_01"
                                      type="number"
                                    />
                                    mm
                                  </div>
                                </div>
                                <FieldMessageErrorClick name="SI08_3_01_1_01" />
                              </div>
                              <div className="frm_div">
                                <div className="frm_flex">
                                  <div className="checkbox">
                                    <label className="label_checkbox">
                                      <Field
                                        type="checkbox"
                                        name="SI08_3_01"
                                        className="input_checkbox"
                                        value="2"
                                      />
                                      <span>
                                        {t(IK.maxilla)} {t(IK.move_left)}
                                      </span>
                                    </label>
                                  </div>
                                  <div className="frm_line_field">
                                    <Field
                                      name="SI08_3_01_2_01"
                                      type="number"
                                    />
                                    mm
                                  </div>
                                </div>
                                <FieldMessageErrorClick name="SI08_3_01_2_01" />
                              </div>
                            </div>

                            <div className="frm_div">
                              <div className="chk_area">
                                <div className="frm_div">
                                  <div className="frm_flex">
                                    <div className="checkbox">
                                      <label className="label_checkbox">
                                        <Field
                                          type="checkbox"
                                          name="SI08_3_01"
                                          className="input_checkbox"
                                          value="3"
                                        />
                                        <span>
                                          {t(IK.mandible)} {t(IK.move_right)}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="frm_line_field">
                                      <Field
                                        name="SI08_3_01_3_01"
                                        type="number"
                                      />
                                      mm
                                    </div>
                                  </div>
                                  <FieldMessageErrorClick name="SI08_3_01_3_01" />
                                </div>
                                <div className="frm_div">
                                  <div className="frm_flex">
                                    <div className="checkbox">
                                      <label className="label_checkbox">
                                        <Field
                                          type="checkbox"
                                          name="SI08_3_01"
                                          className="input_checkbox"
                                          value="4"
                                        />
                                        <span>
                                          {t(IK.mandible)} {t(IK.move_left)}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="frm_line_field">
                                      <Field
                                        name="SI08_3_01_4_01"
                                        type="number"
                                      />
                                      mm
                                    </div>
                                  </div>
                                  <FieldMessageErrorClick name="SI08_3_01_4_01" />
                                </div>
                              </div>
                              <FieldMessageErrorClick name="SI08_3_01" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <FieldMessageErrorClick name="SI08" />
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton
                  $secondary
                  as="button"
                  type="button"
                  onClick={() => setCurrentStepIndex(1)}
                >
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton
                      as="button"
                      type="submit"
                      onClick={() => setSkip(true)}
                      $primary
                    >
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.midline_pop_message1)}</li>
          <li>{t(IK.midline_pop_message2)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Midline;
