import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";

function Overjet({
  next,
  prev,
  currentStepType,
  stepLabel,
  currentStepList,
  currentStepIndex,
  studyInfo,
  setStudyInfo,
  patientId,
  isFinish,
  setCurrentStepIndex,
}) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  /**중복 요청 방지 */
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    SI06: Yup.string().required(t(IK.overjet_required)),
    SI06_3_01: Yup.string().when(["SI06"], {
      is: (value) => value === "3",
      then: (value) =>
        Yup.number()
          .moreThan(0, t(IK.enter_figure))
          .required(t(IK.enter_figure)),
    }),
  });

  const code = currentStepList[currentStepIndex].code;
  const config = studyFormConfig[currentStepType][stepLabel];

  const handleOnChangeSI06 = (e, setFieldValue) => {
    const index = e.currentTarget.value;
    if (index !== 3) {
      setFieldValue("SI06_3_01", "");
    }
  };

  const handleSubmit = (values) => {
    const data = {
      SI06: values.SI06,
      SI06_3_01: values.SI06 === "3" ? values.SI06_3_01 : "",
    };

    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip
            ? setCurrentStepIndex(currentStepList.length - 2)
            : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
        required
      />

      <Formik
        initialValues={{
          SI06: studyInfo.SI06 || "",
          SI06_3_01: studyInfo.SI06_3_01 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values, setFieldError, setErrors }) => (
          <Form>
            <FormControlsStudyBox
              title={t(IK.overjet)}
              required
              openModal={openModal}
            >
              <div className="frm_column">
                {config.overjet_item1 && (
                  <div className="frm_div">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI06"
                          className="input_radio"
                          value="1"
                          onChange={(e) => {
                            handleOnChangeSI06(e, setFieldValue);
                            setFieldValue("SI06", e.currentTarget.value);
                          }}
                        />
                        <span>{t(IK.overjet_item1)}</span>
                      </label>
                    </div>
                  </div>
                )}

                {config.maintain_initial_state && (
                  <div className="frm_div">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="SI06"
                          className="input_radio"
                          value="2"
                          onChange={(e) => {
                            handleOnChangeSI06(e, setFieldValue);
                            setFieldValue("SI06", e.currentTarget.value);
                          }}
                        />
                        <span>{t(IK.maintain_initial_state)}</span>
                      </label>
                    </div>
                  </div>
                )}

                {config.overjet_item3 && (
                  <div className="frm_div">
                    <div className="frm_flex">
                      <div className="radio">
                        <label className="label_radio">
                          <Field
                            type="radio"
                            name="SI06"
                            className="input_radio"
                            value="3"
                            onChange={(e) => {
                              handleOnChangeSI06(e, setFieldValue);
                              setFieldValue("SI06", e.currentTarget.value);
                            }}
                          />
                          <span>{t(IK.overjet_item3)}</span>
                        </label>
                      </div>
                      <div
                        className={
                          values.SI06 === "3"
                            ? "frm_line_field"
                            : "frm_line_field hide"
                        }
                      >
                        <Field name="SI06_3_01" type="number" />
                        mm ({t(IK.necessary)} IPR {t(IK.implementation)})
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <FieldMessageErrorClick name="SI06" />
              <FieldMessageErrorClick name="SI06_3_01" />
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton
                  $secondary
                  as="button"
                  type="button"
                  onClick={() => setCurrentStepIndex(1)}
                >
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton
                      as="button"
                      type="submit"
                      onClick={() => setSkip(true)}
                      $primary
                    >
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.overjet_pop_message1)}</li>
          <li>{t(IK.overjet_pop_message2)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Overjet;
