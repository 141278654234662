import styled from "styled-components";

export const BoardViewTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.gray300};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  h3 {
    margin-right: 10px;
    font-size: 20px;
  }
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const BoardViewCont = styled.div`
  overflow: hidden;
  padding: 20px;
  font-size: 15px;
  font-family: "Pretendard";
  img {
    max-width: 100%;
    max-height: 100%;
  }
  li {
    list-style: inherit;
  }
`;

export const BoardViewVideo = styled.div`
  padding: 20px;
  margin: 0 20px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.gray300};
`;

export const BoardViewFile = styled.div`
  padding: 20px;
  margin-top: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  dl {
    display: flex;
    align-items: center;
    dt {
      position: relative;
      margin-right: 20px;
      padding-right: 20px;
      font-weight: 600;
      font-size: 15px;
      &:after {
        content: "";
        position: absolute;
        top: 4px;
        right: 0;
        width: 1px;
        height: 14px;
        background-color: ${(props) => props.theme.colors.gray200};
      }
    }
    dd {
      flex: 1;
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        li button {
          font-size: 15px;
          &:hover {
            color: ${(props) => props.theme.colors.blue400};
          }
        }
      }
    }
  }
`;
