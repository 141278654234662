import { useTranslation } from "react-i18next";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../utils/i18n/keyStore";
import { Previews } from "../../preview/Preview";
import * as S from "../../../../components/element/Drop/style/Drop.style";
import { CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";

const Intraoral = ({ setFieldValue, getFieldProps, errors }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <CardTitle
        message={t(IK.image_required_message_1)}
        icon={<i className="ri-fullscreen-line"></i>}
      />

      <S.DropListWrap>
        <S.DropList className="required_img occlusal_upper">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="occlusal_upper"
          />
          {errors.occlusal_upper && (
            <FieldMessageError text={errors.occlusal_upper} />
          )}
        </S.DropList>
        <S.DropList className="intraoral_front">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="intraoral_front"
          />
        </S.DropList>
        <S.DropList className="required_img occlusal_lower">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="occlusal_lower"
          />
          {errors.occlusal_lower && (
            <FieldMessageError text={errors.occlusal_lower} />
          )}
        </S.DropList>
        <S.DropList className="required_img buccal_right">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="buccal_right"
          />
          {errors.buccal_right && (
            <FieldMessageError text={errors.buccal_right} />
          )}
        </S.DropList>
        <S.DropList className="required_img overjet">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="overjet"
          />
          {errors.overjet && <FieldMessageError text={errors.overjet} />}
        </S.DropList>
        <S.DropList className="required_img buccal_left">
          <Previews
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name="buccal_left"
          />
          {errors.buccal_left && (
            <FieldMessageError text={errors.buccal_left} />
          )}
        </S.DropList>
      </S.DropListWrap>

      <div className="noti_box">
        <i className="ri-upload-cloud-fill"></i>
        {t(IK.file_drag_message)}
      </div>
    </>
  );
};

export default Intraoral;
