import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 임상증례 리스트 가져오기 */
export const useGetGalleryList = (params) => {
  const { t } = useTranslation();
  return useQuery(
    ["GalleryList", params],
    async () => {
      const { data } = await axios.get(`/api/clinical/list`, {
        params: params,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**임상증례 제출 정보 조회 */
export const useGetGallerySubmitInfo = (studyId) => {
  const { t } = useTranslation();
  return useQuery(
    ["GallerySubmitInfo", studyId],
    async () => {
      const { data } = await axios.get(`/api/clinical`, {
        params: {
          studyId,
        },
      });
      return data;
    },
    {
      enabled: !!studyId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**임상증례 상세 조회 */
export const useGetGalleryInfo = (galleryId) => {
  const { t } = useTranslation();
  return useQuery(
    ["GalleryInfo", galleryId],
    async () => {
      const { data } = await axios.get(`/api/clinical/${galleryId}`);
      return data;
    },
    {
      enabled: !!galleryId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 임상증례 등록하기  */
export const submitGallery = async (data) => {
  return await axios.post(`/api/clinical`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
