import { Formik } from "formik";
import React, { useMemo, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useDoctorAuth } from "../../../Hooks/useDoctorAuth";
import { useAuthAdminDoctor } from "../../../apis/doctorApi";
import { useReplacementStep, useReplacementSubmit, useReplacementSummary } from "../../../apis/replacementApi";
import { userInfo } from "../../../atoms/userAtom";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import LoadingIndicator from "../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../utils/i18n/keyStore";
import ReplaceSummaryTable from "./ReplaceSummaryTable";
import ReplaceConfirm from "./Section/Confirm";
import ReplaceForm from "./Section/Form";
import ReplaceInstructions from "./Section/Instructions";

function Replace() {
  const { t } = useTranslation(["translation"]);
  const { patientId, replacementId } = useParams();

  /**처방전 작성권한 조회 */
  useAuthAdminDoctor(patientId);

  /**담당환자 여부 조회 */
  const access = useDoctorAuth(patientId);

  /**요약 정보 가져오기 */
  const { data: replacementData, isLoading: isReplaceLoading } = useReplacementSummary(replacementId, patientId);
  const replacementInfo = replacementData?.data;

  const replacementList = useMemo(() => [<ReplaceForm />, <ReplaceInstructions />, <ReplaceSummaryTable />, <ReplaceConfirm />], []);
  const title_label = useMemo(() => [t(IK.alternative_correction_devices), t(IK.instructions), t(IK.alternative_correction_devices_summary), t(IK.checklist)], [t]);

  const navigate = useNavigate();

  /**사용자 정보 가져오기 */
  const user = useRecoilValue(userInfo);

  /**스텝 선택 */
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  /**다음으로 이동 */
  const onHandleNext = (validateForm, setTouched, values) => {
    let nextIndex = currentStepIndex + 1;
    //수동으로 유휴성 검사
    validateForm().then((errors) => {
      if (Object.entries(errors).length === 0 && errors.constructor === Object) {
        //데이터 전송 후 성공하면 이동
        handleStepSubmit(values, nextIndex);
      } else {
        setTouched(errors);
      }
    });
  };

  /**이전으로 이동 */
  const onHandlePrev = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const validationSchemas = [
    Yup.object().shape({
      UPPER: Yup.string().when(["UPPER_check"], {
        is: (UPPER_check) => UPPER_check,
        then: (UPPER_check) =>
          Yup.string()
            .required(t(IK.enter_description))
            .matches(/^[\d+, -]+$/, t(IK.only_number)),
        otherwise: (UPPER_check) => Yup.string(),
      }),
      LOWER: Yup.string().when(["LOWER_check"], {
        is: (LOWER_check) => LOWER_check,
        then: (LOWER_check) =>
          Yup.string()
            .required(t(IK.enter_description))
            .matches(/^[\d+, -]+$/, t(IK.only_number)),
        otherwise: (LOWER_check) => Yup.string(),
      }),
      UPPER_ATTACH: Yup.string().when(["UPPER_ATTACH_check"], {
        is: (UPPER_ATTACH_check) => UPPER_ATTACH_check,
        then: (UPPER_ATTACH_check) =>
          Yup.string()
            .required(t(IK.enter_description))
            .matches(/^[\d+, -]+$/, t(IK.only_number)),
        otherwise: (UPPER_ATTACH_check) => Yup.string(),
      }),
      LOWER_ATTACH: Yup.string().when(["LOWER_ATTACH_check"], {
        is: (LOWER_ATTACH_check) => LOWER_ATTACH_check,
        then: (LOWER_ATTACH_check) =>
          Yup.string()
            .required(t(IK.enter_description))
            .matches(/^[\d+, -]+$/, t(IK.only_number)),
        otherwise: (LOWER_ATTACH_check) => Yup.string(),
      }),
      replaceCheck: Yup.string().when(["UPPER_check", "LOWER_check", "LOWER_ATTACH_check", "UPPER_ATTACH_check"], {
        is: (UPPER_check, LOWER_check, LOWER_ATTACH_check, UPPER_ATTACH_check) => !UPPER_check && !LOWER_check && !LOWER_ATTACH_check && !UPPER_ATTACH_check,
        then: (UPPER_check, LOWER_check, LOWER_ATTACH_check, UPPER_ATTACH_check) => Yup.string().required(t(IK.replace_required)),
      }),
      upperCheck: Yup.boolean(),
      lowerCheck: Yup.boolean(),
      attachUpperCheck: Yup.boolean(),
      attachLowerCheck: Yup.boolean(),
    }),
    Yup.object().shape({}),
    Yup.object().shape({}),
    Yup.object().shape({}),
  ];

  const schema = Yup.lazy(() => validationSchemas[currentStepIndex]);

  const replaceMutation = useReplacementStep(replacementId, patientId);
  const replaceSubmitMutation = useReplacementSubmit(replacementId, patientId);

  /**대체교정장치 단계별로 전송 */
  const handleStepSubmit = (values, nextIndex) => {
    if (currentStepIndex === 0) {
      replaceMutation.mutate(
        {
          data: {
            UPPER: values.UPPER,
            UPPER_ATTACH: values.UPPER_ATTACH,
            LOWER: values.LOWER,
            LOWER_ATTACH: values.LOWER_ATTACH,
          },
          step: "ALIGNER",
        },
        {
          onSuccess: () => {
            setCurrentStepIndex(nextIndex);
          },
        }
      );
    }
    if (currentStepIndex === 1) {
      replaceMutation.mutate(
        {
          data: { SPECIAL_INSTRUCTIONS: values.SPECIAL_INSTRUCTIONS },
          step: "SPECIAL_INSTRUCTIONS",
        },
        {
          onSuccess: () => {
            setCurrentStepIndex(nextIndex);
          },
        }
      );
    }
    if (currentStepIndex === 2) {
      setCurrentStepIndex(nextIndex);
    }
    if (currentStepIndex === 3) {
      replaceSubmitMutation.mutate(null, {
        onSuccess: () => {
          navigate(`/patient/${patientId}/profile`);
        },
      });
    }
  };

  if (!access) {
    return null;
    //권한 없을시 컴포넌트 렌더링 안함
  }

  return (
    <Beforeunload onBeforeunload={() => ""}>
      {isReplaceLoading ? (
        <LoadingIndicator />
      ) : (
        <CardInnerLayout>
          <CardTitle
            title={
              <>
                {title_label[currentStepIndex]}&nbsp;
                {currentStepIndex !== title_label.length - 1 && <span>{`${replacementInfo?.patientName} ${replacementInfo?.patientKorName ? ` (${replacementInfo.patientKorName})` : ""}`}</span>}
              </>
            }
          />
          <Formik
            validationSchema={schema}
            initialValues={{
              UPPER: replacementInfo?.UPPER || "",
              LOWER: replacementInfo?.LOWER || "",
              UPPER_ATTACH: replacementInfo?.UPPER_ATTACH || "",
              LOWER_ATTACH: replacementInfo?.LOWER_ATTACH || "",
              SPECIAL_INSTRUCTIONS: replacementInfo?.SPECIAL_INSTRUCTIONS || "",
              UPPER_check: !!replacementInfo?.UPPER,
              LOWER_check: !!replacementInfo?.LOWER,
              UPPER_ATTACH_check: !!replacementInfo?.UPPER_ATTACH,
              LOWER_ATTACH_check: !!replacementInfo?.LOWER_ATTACH,
              replaceCheck: "",
            }}
            enableReinitialize
          >
            {({ values, validateForm, setTouched, setFieldValue }) => (
              <>
                {React.cloneElement(replacementList[currentStepIndex], {
                  values,
                  replacementInfo,
                  setFieldValue,
                  replacementId,
                })}
                {currentStepIndex === 0 ? (
                  <CardTail error={user.countryCode === "01" ? t(IK.alternative_correction_devices_item1_noti1) : t(IK.alternative_correction_devices_item1_noti1_global)} line>
                    <S.ButtonLtBox>
                      <S.StyledButton to={`/patient/${patientId}/profile`}>{t(IK.patient_profile)}</S.StyledButton>
                      <S.StyledButton as="button" $primary type="button" onClick={() => onHandleNext(validateForm, setTouched, values)}>
                        {t(IK.next)}
                      </S.StyledButton>
                    </S.ButtonLtBox>
                  </CardTail>
                ) : (
                  <CardTail line>
                    <S.ButtonLtBox>
                      <S.StyledButton as="button" type="button" onClick={onHandlePrev}>
                        {t(IK.prev)}
                      </S.StyledButton>
                      <S.StyledButton as="button" $primary type="button" onClick={() => onHandleNext(validateForm, setTouched, values)}>
                        {currentStepIndex === 3 ? t(IK.submitted) : t(IK.next)}
                      </S.StyledButton>
                    </S.ButtonLtBox>
                  </CardTail>
                )}
              </>
            )}
          </Formik>
        </CardInnerLayout>
      )}
    </Beforeunload>
  );
}
export default Replace;
