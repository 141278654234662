import Skeleton from "react-loading-skeleton";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/BoardList/style/BoardList.style";

const SkeletonBill = () => {
  return (
    <CardInnerLayout>
      <Skeleton height={40} width={200} />
      <br />
      <S.BoardTable>
        <Skeleton count={5} height={50} />
        <br />
        <Skeleton height={200} />
      </S.BoardTable>
    </CardInnerLayout>
  );
};

export default SkeletonBill;
