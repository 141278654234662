import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../components/element/Form/FormLayout";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S2 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import { IK } from "../../../../utils/i18n/keyStore";

function ReplaceForm({ values, replacementInfo, setFieldValue }) {
  const { t } = useTranslation(["translation"]);

  /**체크 해제시 필드 값 초기화 */
  const handleCheckReset = (e, name) => {
    if (!e.target.checked) {
      setFieldValue(name, "");
    }
  };

  return (
    <>
      <FormControlsStudyBox title={t(IK.alternative_correction_devices_item1)} titleSub={`${t(IK.alternative_correction_devices_item1_ex1)}`} required>
        <S.FormControlsFlex $large>
          {(replacementInfo?.SI01 === "1" || replacementInfo?.SI01 === "2") && (
            <S.FormControlsFlex>
              <S2.SelectionItem>
                <S2.SelectionItemLabel>
                  <Field as={S2.SelectionItemInput} type="checkbox" $checkbox name="UPPER_check" onClick={(e) => handleCheckReset(e, "UPPER")} />
                  <S2.SelectionItemSpan>{t(IK.maxilla)}</S2.SelectionItemSpan>
                </S2.SelectionItemLabel>
              </S2.SelectionItem>

              {values.UPPER_check && (
                <div>
                  <S.FormControlsFlex>
                    <Field name="UPPER">{({ field }) => <S.FormLineControls {...field} />}</Field>
                    {t(IK.level)}
                  </S.FormControlsFlex>
                  <FieldMessageErrorClick name="UPPER" />
                </div>
              )}
            </S.FormControlsFlex>
          )}

          {(replacementInfo?.SI01 === "1" || replacementInfo?.SI01 === "3") && (
            <S.FormControlsFlex>
              <S2.SelectionItem>
                <S2.SelectionItemLabel>
                  <Field as={S2.SelectionItemInput} type="checkbox" $checkbox name="LOWER_check" onClick={(e) => handleCheckReset(e, "LOWER")} />
                  <S2.SelectionItemSpan>{t(IK.mandible)}</S2.SelectionItemSpan>
                </S2.SelectionItemLabel>
              </S2.SelectionItem>

              {values.LOWER_check && (
                <div>
                  <S.FormControlsFlex>
                    <Field name="LOWER">{({ field }) => <S.FormLineControls {...field} />}</Field>
                    {t(IK.level)}
                  </S.FormControlsFlex>
                  <FieldMessageErrorClick name="LOWER" />
                </div>
              )}
            </S.FormControlsFlex>
          )}
        </S.FormControlsFlex>

        {replacementInfo.studyPackages !== "RP" && (
          <S.FormControlsLine>
            <S.FormControlsFlex $large>
              {(replacementInfo?.SI01 === "1" || replacementInfo?.SI01 === "2") && (
                <S.FormControlsFlex>
                  <S2.SelectionItem>
                    <S2.SelectionItemLabel>
                      <Field as={S2.SelectionItemInput} type="checkbox" $checkbox name="UPPER_ATTACH_check" onClick={(e) => handleCheckReset(e, "UPPER_ATTACH")} />
                      <S2.SelectionItemSpan>Attach template {t(IK.maxilla)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>

                  {values.UPPER_ATTACH_check && (
                    <div>
                      <S.FormControlsFlex>
                        <Field name="UPPER_ATTACH">{({ field }) => <S.FormLineControls {...field} />}</Field>
                        {t(IK.level)}
                      </S.FormControlsFlex>
                      <FieldMessageErrorClick name="UPPER_ATTACH" />
                    </div>
                  )}
                </S.FormControlsFlex>
              )}
              {(replacementInfo?.SI01 === "1" || replacementInfo?.SI01 === "3") && (
                <S.FormControlsFlex>
                  <S2.SelectionItem>
                    <S2.SelectionItemLabel>
                      <Field as={S2.SelectionItemInput} type="checkbox" $checkbox name="LOWER_ATTACH_check" onClick={(e) => handleCheckReset(e, "LOWER_ATTACH")} />
                      <S2.SelectionItemSpan>Attach template {t(IK.mandible)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>

                  {values.LOWER_ATTACH_check && (
                    <div>
                      <S.FormControlsFlex>
                        <Field name="LOWER_ATTACH">{({ field }) => <S.FormLineControls {...field} />}</Field>
                        {t(IK.level)}
                      </S.FormControlsFlex>
                      <FieldMessageErrorClick name="LOWER_ATTACH" />
                    </div>
                  )}
                </S.FormControlsFlex>
              )}
            </S.FormControlsFlex>
          </S.FormControlsLine>
        )}

        <FieldMessageErrorClick name="replaceCheck" />
      </FormControlsStudyBox>
    </>
  );
}
export default ReplaceForm;
