import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useUpdatePatientProfile } from "../../../../../apis/patientApi";
import Modal from "../../../../../components/Modal/Modal";
import * as S from "../../../../../components/Modal/style/Modal.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../../../components/element/Form/FormLayout";
import * as S3 from "../../../../../components/element/Form/style/Form.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import { eachUploader } from "../../../../../utils/limitUpload";

export const PatientInfoEditModal = ({ profileModalOpen, setProfileModalOpen, patientInfo, patientId }) => {
  const { t } = useTranslation(["translation"]);
  const closeProfileModal = () => setProfileModalOpen(false);

  const updateMutation = useUpdatePatientProfile();

  /**환자 프로필 수정 모달 */
  const handleSubmit = (value) => {
    const data = new FormData();
    for (let fieldName in value) {
      if (value[fieldName]) {
        data.append(fieldName, value[fieldName]);
      }
    }
    updateMutation.mutate(
      { patientId, data },
      {
        onSuccess: () => {
          closeProfileModal();
        },
      }
    );
  };

  return (
    <Modal open={profileModalOpen} close={closeProfileModal} header={t(IK.modification_patient_information)}>
      <Formik
        initialValues={{
          profile: "",
        }}
        validationSchema={Yup.object({
          profile: Yup.mixed().required(t(IK.image_required)),
        })}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S3.FormControlsColumn $large>
              {/* 대표 사진 */}
              <FormControlsBox required regular title={t(IK.patient_picture)}>
                <S3.FormControlsFlex>
                  <S3.FormControls $full type="file" id="profile" name="profile" accept="image/*" onChange={(event) => eachUploader(event, setFieldValue, "profile", t)} />
                  <S3.FormControlsBtn as="label" htmlFor="profile">
                    {t(IK.file_btn)}
                  </S3.FormControlsBtn>
                </S3.FormControlsFlex>
                <FieldMessageErrorClick name="profile" />
              </FormControlsBox>
            </S3.FormControlsColumn>

            <S.ModalBoxBtn>
              <S2.ButtonFlex>
                <S2.StyledButton as="button" type="button" onClick={closeProfileModal}>
                  {t(IK.cancel)}
                </S2.StyledButton>
                <S2.StyledButton $primary as="button" type="submit">
                  {t(IK.compelete)}
                </S2.StyledButton>
              </S2.ButtonFlex>
            </S.ModalBoxBtn>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
