import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import imageRotate from "../../../../../utils/imageRotate";

export function Previews({ files, setFieldValue, name, setDeleteStudyFileIds, deleteStudyFileIds, radiation, isGallery }) {
  const [filesData, setFilesData] = useState(files ? [files] : []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFieldValue(name, acceptedFiles[0]);
      setFilesData(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploaded: false,
          })
        )
      );
    },
  });
  const handleOnClickClose = (e) => {
    setFilesData([]);
    const fileId = e.currentTarget.dataset.fileId;

    if (fileId && setDeleteStudyFileIds) {
      //파일 삭제시 아이디 저장
      setDeleteStudyFileIds([...deleteStudyFileIds, fileId]);
    }

    if (fileId && isGallery) {
      //임상증례 파일 바꿔치기
      setFieldValue(`${name}_is_change`, true);
    }

    setFieldValue(name, "");
  };
  const generateHandler = (orientation) => (e) => {
    imageRotate(filesData, setFilesData, orientation, setFieldValue, name);
  };

  const handleOnClickRefresh = generateHandler(6);
  const handleOnClickArrow1 = generateHandler(2);
  const handleOnClickArrow2 = generateHandler(4);

  useEffect(() => {
    if (filesData.length > 0) {
      const file = filesData[0];
      if (file.distFileName) {
        setFilesData([
          Object.assign(
            {},
            {
              preview: file.distFileName,
              uploaded: true,
              studyFileId: file.studyFileId,
            }
          ),
        ]);
      }
    }
  }, [filesData]);

  const thumbs = filesData.map((file) => {
    return (
      <S.DropItemImg key={file.preview || file.distFileName}>
        <img src={file.preview} alt="preview" />
        {!file.uploaded && (
          <>
            <S.DropItemControlBtn type="button" onClick={handleOnClickRefresh} $refresh>
              <i className="ri-refresh-line"></i>
            </S.DropItemControlBtn>
            <S.DropItemControlBtn type="button" onClick={handleOnClickArrow1} $arrowbtn1>
              <i className="ri-arrow-left-right-line"></i>
            </S.DropItemControlBtn>
            <S.DropItemControlBtn type="button" onClick={handleOnClickArrow2} $arrowbtn2>
              <i className="ri-arrow-up-down-line"></i>
            </S.DropItemControlBtn>
          </>
        )}
        <S.DropItemControlBtn type="button" onClick={handleOnClickClose} data-file-id={file.studyFileId} $close>
          <i className="ri-close-line"></i>
        </S.DropItemControlBtn>
      </S.DropItemImg>
    );
  });

  return (
    <S.DropItem $radiation={radiation}>
      <S.DropItemZone {...getRootProps()}>
        <input id={name} name={name} {...getInputProps()} />
      </S.DropItemZone>
      {thumbs}
    </S.DropItem>
  );
}
