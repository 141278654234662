import Pagination from "react-js-pagination";
import * as S from "./style/Paging.style";

const Paging = ({ currentPage, totalCount, size, handleChangePage }) => {
  return (
    <S.PaginationContainer>
      <Pagination
        activePage={Number(currentPage)}
        itemsCountPerPage={Number(size)}
        totalItemsCount={totalCount}
        prevPageText={<i className="ri-arrow-left-s-line"></i>}
        nextPageText={<i className="ri-arrow-right-s-line"></i>}
        lastPageText={<i className="ri-arrow-right-double-line"></i>}
        firstPageText={<i className="ri-arrow-left-double-line"></i>}
        itemClass={"pg_num pg_page"}
        onChange={handleChangePage}
        activeClass={"pg_current"}
        itemClassFirst={"pg_page pg_start"}
        itemClassPrev={"pg_page pg_prev"}
        itemClassNext={"pg_page pg_next"}
        itemClassLast={"pg_page pg_end"}
      />
    </S.PaginationContainer>
  );
};

export default Paging;
