import { useEffect, useState } from "react";
import { getDoctorPatientAuth } from "../apis/doctorApi";

/**본인 환자인지 아닌지 판단*/
export const useDoctorAuth = (patientId) => {
  const [access, setAccess] = useState(true);

  useEffect(() => {
    getDoctorPatientAuth(patientId).catch((err) => {
      setAccess(false);
    });
  }, [access, patientId]);

  return access;
};
