import { Link } from "react-router-dom";
import { styled } from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const EventBanner = ({ bannerList }) => {
  return (
    <>
      <StyledSwiper
        loop={bannerList?.length > 1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
      >
        {bannerList.map((item) => (
          <SwiperSlide key={item.eventBoardId}>
            <Link to={`/support/event-list/${item.eventBoardId}`}>
              <img src={item.bannerFile} alt="" />
            </Link>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </>
  );
};

export default EventBanner;

const StyledSwiper = styled(Swiper)`
  margin-bottom: 20px;
  text-align: center;
  .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.colors.blue400};
  }
  img {
    max-width: 192px;
    max-height: 100px;
  }
`;
