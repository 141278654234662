import Skeleton from "react-loading-skeleton";
import * as S from "./style/ClinicalCases.style";

export const SkeletonCaseList = () => {
  return (
    <S.CaseList>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
        <SkeletonCaseListCard key={index} />
      ))}
    </S.CaseList>
  );
};

export const SkeletonCaseListCard = () => {
  return (
    <li>
      <dl>
        <dt>
          <Skeleton width="100%" height={190} />
        </dt>
        <dd>
          <Skeleton width="100%" height={45} />
        </dd>
      </dl>
    </li>
  );
};
