import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 추가 처방전 작성 이동(추가처방전 아이디 생성) */
export const createAdditionalStudy = async (data) => {
  const response = await axios.post("/api/additional", null, {
    params: data,
  });
  return response;
};

/** 해당 환자의 추가 처방전 작성 정보 가져오기 */
export const useGetAdditionalSummary = (additionalId, patientId, studyId) => {
  const { t } = useTranslation();
  return useQuery(
    ["StudySummary", additionalId, patientId, studyId],
    async () => {
      const { data } = await axios.get(`/api/additional/${additionalId}/summary`, {
        params: {
          patientId,
          studyId,
        },
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**추가처방전 조회 (초기화 후 업데이트 용) */
export const resetAdditionalStudy = async (additionalId, patientId, studyId) => {
  const { data } = await axios.get(`/api/additional/${additionalId}/summary`, {
    params: {
      patientId,
      studyId,
    },
  });
  return data;
};

/** 해당 환자의 추가 처방전 업데이트 (process 업데이트) */
export const useUpdateAdditionalStudy = () => {
  return useMutation(async (params) => {
    const response = await axios.put("/api/additional", null, {
      params: params,
    });
    return response.data;
  });
};

/** 해당 환자의 추가 처방전 단계 저장 */
export const useSaveAdditionStep = () => {
  const { t } = useTranslation();
  return useMutation(
    async ({ step, data, params }) => {
      const response = await axios.post(`/api/additional/${step}`, data, {
        params,
      });
      return response.data;
    },
    {
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/** 해당 환자의 추가 처방전 파일 저장 */
export const useSaveAdditionalFileStep = () => {
  return useMutation(async ({ step, formData, params }) => {
    const response = await axios.post(`/api/additional/${params.additionalId}/${step}`, formData, {
      params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  });
};

/** 해당 환자의 추가 처방전 스캔 저장 */
export const useSaveAdditionalScan = () => {
  return useMutation(async ({ formData, params }) => {
    const response = await axios.post(`/api/additional/${params.additionalId}/scan`, formData, {
      params: params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  });
};

/** 해당 환자의 추가 처방전 최종 제출 */
export const useAdditionalSubmit = () => {
  const { t } = useTranslation();
  return useMutation(
    async ({ params }) => {
      const response = await axios.post(`/api/additional/${params.additionalId}/submit`, null, {
        params: params,
      });
      return response.data;
    },
    {
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};
