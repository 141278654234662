import Notiflix from "notiflix";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import SetupInterceptors from "../apis/SetupInterceptors";
import { checkToken, deleteRefreshToken } from "../apis/joinApi";
import { IK } from "../utils/i18n/keyStore";
import LoadingIndicator from "./loading/LoadingIndicator/LoadingIndicator";

export const IsToken = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  const token = localStorage.getItem("accessToken");

  useQuery("tokenCheck", checkToken, {
    enabled: !!token, // 토큰이 있을 때만 쿼리 실행
    onSuccess: () => navigate("/patient/list"),
    onError: async (error) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("recoil-persist");
      await deleteRefreshToken();

      if (error?.code === "ECONNABORTED") {
        Notiflix.Report.failure(t(IK.server_err_1), t(IK.server_err_2), "확인", () => {
          window.location.reload(true);
        });
      }
    },
  });

  useEffect(() => {
    SetupInterceptors(navigate, t);
  }, [navigate, t]);

  if (token) return <LoadingIndicator />;

  return <Outlet />;
};
