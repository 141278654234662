import { Field } from "formik";
import { useTranslation } from "react-i18next";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S2 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import { IK } from "../../../../utils/i18n/keyStore";

export const ClinicalCheckBox = () => {
  const { t } = useTranslation(["translation"]);

  const checkList = [
    [
      { text: "Class I", name: "class1" },
      { text: "Class II div 1", name: "class2" },
      { text: "Class II div 2", name: "class3" },
      { text: "Class III", name: "class4" },
      { text: t(IK.crowding), name: "crowding" },
      { text: t(IK.Interdental_space), name: "spacing" },
      { text: t(IK.gingival_recession), name: "gingivalRecession" },
    ],
    [
      { text: t(IK.narrow_dental_arch), name: "narrowArch" },
      { text: t(IK.deepbite), name: "deepBite" },
      { text: t(IK.open_bite_vertical), name: "openbiteVerticality" },
      { text: t(IK.open_bite_horizontal), name: "openbiteOverjet" },
      { text: t(IK.anterior_crossbite), name: "abteriorCrossbite" },
      { text: t(IK.posterior_crossbite), name: "posteriorCrossbite" },
      { text: t(IK.scissors_bite), name: "scissorsBite" },
    ],
    [
      { text: t(IK.prolapse_of_anterior_teeth), name: "flaredTeeth" },
      { text: t(IK.smile_line_needs_improvement), name: "unevenSmile" },
      { text: t(IK.asymmetry), name: "asymmetric" },
      { text: t(IK.Inclined_occlusal_plane_right), name: "occlusalPlaneRight" },
      { text: t(IK.Inclined_occlusal_plane_left), name: "occlusalPlaneLeft" },
      { text: t(IK.missing), name: "missing" },
      { text: t(IK.abnormally_shaped_teeth), name: "misshapenTeeth" },
    ],
  ];

  return (
    <>
      <S.FormControlsDiv as="li">
        <S.FormControlsColumn $large>
          {checkList[0].map((box) => (
            <S2.SelectionItem key={box.name}>
              <S2.SelectionItemLabel>
                <Field type="checkbox" name={`conditionItemMap.${box.name}`}>
                  {({ field }) => <S2.SelectionItemInput $checkbox type="checkbox" {...field} />}
                </Field>
                <S2.SelectionItemSpan>{box.text}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
          ))}
        </S.FormControlsColumn>
      </S.FormControlsDiv>

      <S.FormControlsDiv as="li">
        <S.FormControlsColumn $large>
          {checkList[1].map((box) => (
            <S2.SelectionItem key={box.name}>
              <S2.SelectionItemLabel>
                <Field type="checkbox" name={`conditionItemMap.${box.name}`}>
                  {({ field }) => <S2.SelectionItemInput $checkbox type="checkbox" {...field} />}
                </Field>
                <S2.SelectionItemSpan>{box.text}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
          ))}
        </S.FormControlsColumn>
      </S.FormControlsDiv>

      <S.FormControlsDiv as="li">
        <S.FormControlsColumn $large>
          {checkList[2].map((box) => (
            <S2.SelectionItem key={box.name}>
              <S2.SelectionItemLabel>
                <Field type="checkbox" name={`conditionItemMap.${box.name}`}>
                  {({ field }) => <S2.SelectionItemInput $checkbox type="checkbox" {...field} />}
                </Field>
                <S2.SelectionItemSpan>{box.text}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
          ))}
        </S.FormControlsColumn>
      </S.FormControlsDiv>
    </>
  );
};
