import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useDeleteAddress } from "../../../../apis/addressApi";
import { IK } from "../../../../utils/i18n/keyStore";
import * as S from "../../../../components/element/Button/style/ButtonLayout.style";

function AddressManageTr({ data, setAddressModalOpen, setEditAddressData, defaultAddressData }) {
  const { t } = useTranslation(["translation"]);
  const deleteAddressMutation = useDeleteAddress();

  const onDeleteAddress = (id) => {
    Notiflix.Confirm.show(t(IK.delete_address), t(IK.delete_address_message1), t(IK.confirm), t(IK.cancel), () => {
      deleteAddressMutation.mutate(id, {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.delete_address), t(IK.delete_address_message2), t(IK.confirm));
        },
        onError: () => {
          Notiflix.Report.failure(t(IK.delete_address), t(IK.delete_address_message3), t(IK.confirm));
        },
      });
    });
  };

  const onEdit = (data) => {
    setEditAddressData(data);
    setAddressModalOpen(true);
  };

  const isDefault = data.addressId === defaultAddressData.billAddressId || data.addressId === defaultAddressData.shipAddressId;

  return (
    <>
      <tr>
        <td>{!isDefault ? t(IK.address_doctor) : t(IK.address_default)}</td>
        <td>{data.addressName}</td>
        <td>{data.postalCode}</td>
        <td>
          {data.address} {!!data?.addressDetail ? data?.addressDetail : ""}
        </td>
        <td>
          <S.ButtonFlex>
            <S.StyledSmallButton as="button" type="button" onClick={() => onEdit(data)}>
              {t(IK.modify)}
            </S.StyledSmallButton>
            {!isDefault && (
              <S.StyledSmallButton as="button" type="button" onClick={() => onDeleteAddress(data.addressId)}>
                {t(IK.delete)}
              </S.StyledSmallButton>
            )}
          </S.ButtonFlex>
        </td>
      </tr>
    </>
  );
}

export default AddressManageTr;
