import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { downloadFile, useGetBoardInfo } from "../../../apis/boardApi";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import VideoPlayer from "../../../pages/Education/CorrectionImage/VideoPlayer";
import "../../../styles/Editor.css";
import { IK } from "../../../utils/i18n/keyStore";
import { CardInnerLayout, CardTail, CardTitle } from "../../Layout/CardLayout/CardLayout";
import { SkeletonBoardView } from "./SkeletonBoardView";
import * as S from "./style/BoardView.style";

const BoardView = () => {
  const { state } = useLocation();
  const page = state?.page || 1;

  const { boardId } = useParams();
  const { t } = useTranslation(["translation"]);

  /**해당 페이지 탭제목 가져오기 */
  const boardTitle = {
    //세라핀 교정 더 잘하기 = etc, 교육 자료 = references, 자료실 = marketing, 공지사항 = notice
    etc: {
      title: t(IK.do_better),
      listLink: `/education/correction-image-list?page=${page}&sort=ETC`,
    },
    references: {
      title: t(IK.ebook),
      listLink: `/education/educational-materials-list?page=${page}&sort=REFERENCES`,
    },
    marketing: {
      title: t(IK.resources),
      listLink: `/education/library-list?page=${page}`,
    },
    notice: {
      title: t(IK.notice_board),
      listLink: `/support/notice-list?page=${page}&sort=NOTICE`,
    },
  };

  const { data, isLoading, isError } = useGetBoardInfo(boardId);
  const boardInfo = data?.data;

  /**파일 다운로드 */
  const onClickdownloadFile = (id, name) => {
    downloadFile(id, name, t);
  };

  return (
    <>
      {isLoading || isError ? (
        <SkeletonBoardView />
      ) : (
        <CardInnerLayout>
          <CardTitle title={boardTitle[boardInfo?.sort]["title"]} />
          <S.BoardViewTop>
            <h3>{boardInfo?.title}</h3>
            <span>{dayjs(boardInfo?.createdDate).format("YYYY.MM.DD")}</span>
          </S.BoardViewTop>
          {/* sun-editor 관련 className 을 추가해야 에디터 스타일이 작동합니다.*/}
          <S.BoardViewCont className="ck-content" dangerouslySetInnerHTML={{ __html: boardInfo?.content }} />
          {boardInfo?.sort === "etc" && boardInfo?.boardFiles?.attach?.[0] && (
            <S.BoardViewVideo>
              <VideoPlayer fileId={boardInfo?.boardFiles?.attach[0]?.fileId} distFileName={boardInfo?.boardFiles?.attach[0]?.distFileName} />
            </S.BoardViewVideo>
          )}
          {(boardInfo?.sort === "marketing" || boardInfo?.sort === "notice") && (
            <S.BoardViewFile>
              <dl>
                <dt>{t(IK.attached_file)}</dt>
                <dd>
                  <ul>
                    {boardInfo?.boardFiles?.attach?.length > 0
                      ? boardInfo?.boardFiles?.attach?.map((item, index) => (
                          <li key={index}>
                            <button type="button" onClick={() => onClickdownloadFile(item.fileId, item.orgFileName)}>
                              {item.orgFileName}
                            </button>
                          </li>
                        ))
                      : t(IK.empty)}
                  </ul>
                </dd>
              </dl>
            </S.BoardViewFile>
          )}

          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton to={boardTitle[boardInfo?.sort]["listLink"]}>{t(IK.list)}</S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      )}
    </>
  );
};

export default BoardView;
