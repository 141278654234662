import { styled } from "styled-components";
import { FormControls } from "../../Form/style/Form.style";

// 게시판 검색
export const BoardSearchFilter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const BoardSearchFilterList = styled.div`
  display: flex;
  gap: 15px;
`;

export const BoardSearchForm = styled.div`
  display: flex;
  button {
    width: 46px;
    height: 46px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  ${FormControls} {
    width: 210px;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
  }
`;
