import styled from "styled-components";

export const FooterBox = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 260px;
  padding: 24px;
`;

export const FooterInfo = styled.ul`
  display: flex;
  li {
    margin-left: 35px;
    a,
    button {
      color: ${(props) => props.theme.colors.gray600};
    }
  }
`;

export const Copyright = styled.p`
  color: ${(props) => props.theme.colors.gray600};
`;
