import { styled } from "styled-components";

export const FieldMessage = styled.div`
  display: flex;
  margin-top: 7px;
  color: ${(props) => (props.$errorMessage ? props.theme.colors.red600 : props.$successMessage ? "#21b497" : props.theme.colors.gray600)};
  i {
    margin-right: 5px;
  }
`;
