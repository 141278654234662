import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../utils/i18n/keyStore";
import { VideoPreview } from "./VideoPreview";

const IndicationVideo = ({ values, handleVideoCheck }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <VideoPreview youtubeLink={`https://www.youtube.com/embed/${values.video3}`}>
      <div className="checkbox">
        <label className="label_checkbox">
          <Field
            type="checkbox"
            name="certVideo3"
            className="input_checkbox"
            onClick={(e) => {
              const target = e.currentTarget;
              values.certVideo3 ? (target.checked = true) : handleVideoCheck("VIDEO3");
            }}
            checked={values.certVideo3}
          />
          <span>{t(IK.support_link_step3_txt)}</span>
        </label>
      </div>
      <FieldMessageErrorClick name="certVideo3" />
    </VideoPreview>
  );
};

export default IndicationVideo;
