import { css, styled } from "styled-components";
import ExtraoralFrontImage from "../../../../assets/images/patients/register/extraoral/extraoral_front.jpg";
import FrontRestImage from "../../../../assets/images/patients/register/extraoral/front_rest.jpg";
import FrontSmileImage from "../../../../assets/images/patients/register/extraoral/front_smile.jpg";
import Side45Image from "../../../../assets/images/patients/register/extraoral/side45.jpg";
import Side90Image from "../../../../assets/images/patients/register/extraoral/side90.jpg";
import Side90SmileImage from "../../../../assets/images/patients/register/extraoral/side90_smile.jpg";
import BuccalLeftImage from "../../../../assets/images/patients/register/intraoral/buccal_left.jpg";
import BuccalRightImage from "../../../../assets/images/patients/register/intraoral/buccal_right.jpg";
import OcclusalLowerImage from "../../../../assets/images/patients/register/intraoral/occlusal_lower.jpg";
import OcclusalUpper from "../../../../assets/images/patients/register/intraoral/occlusal_upper.jpg";
import OverjetImage from "../../../../assets/images/patients/register/intraoral/overjet_front.jpg";
import IntraoralFront from "../../../../assets/images/patients/register/intraoral/overjet_front2.jpg";
import OverjetBottomImage from "../../../../assets/images/patients/register/intraoral/overjet_front_bottom.jpg";
import OverjetTopImage from "../../../../assets/images/patients/register/intraoral/overjet_front_top.jpg";
import CephaloImage from "../../../../assets/images/patients/register/radiation/cephalo.jpg";
import PanoramaImage from "../../../../assets/images/patients/register/radiation/panorama.jpg";

export const DropListWrap = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.$radiation ? "1fr 1fr" : props.$overlap ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr")};
  gap: 25px;
  width: 1100px;
  margin: 0 auto;

  @media print {
    gap: 20px;
    width: 100%;
  }
`;

export const DropItem = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.$radiation ? "350px" : "190px")};
  border-radius: 10px;
  border: 3px solid ${(props) => props.theme.colors.gray200};
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  @media print {
    height: ${(props) => (props.$radiation ? "170px" : "100px")};
    background-size: cover;
  }
`;

export const DropList = styled.div`
  &.required_img ${DropItem} {
    border-color: ${(props) => props.theme.colors.red600};
  }

  &.extraoral_front ${DropItem} {
    background-image: url(${ExtraoralFrontImage});
    background-position: center bottom;
  }

  &.front_rest ${DropItem} {
    background-image: url(${FrontRestImage});
    background-position: center bottom;
  }

  &.front_smile ${DropItem} {
    background-image: url(${FrontSmileImage});
    background-position: center bottom;
  }

  &.side45 ${DropItem} {
    background-image: url(${Side45Image});
    background-position: center bottom;
  }

  &.side90 ${DropItem} {
    background-image: url(${Side90Image});
    background-position: center bottom;
  }

  &.side90_smile ${DropItem} {
    background-image: url(${Side90SmileImage});
    background-position: center bottom;
  }

  &.occlusal_upper ${DropItem} {
    background-image: url(${OcclusalUpper});
  }

  &.intraoral_front ${DropItem} {
    background-image: url(${IntraoralFront});
  }

  &.occlusal_lower ${DropItem} {
    background-image: url(${OcclusalLowerImage});
  }

  &.buccal_right ${DropItem}, &.upper_buccal_right ${DropItem}, &.lower_buccal_right ${DropItem} {
    background-image: url(${BuccalRightImage});
  }

  &.overjet ${DropItem} {
    background-image: url(${OverjetImage});
  }

  &.overjet_front_top ${DropItem} {
    background-image: url(${OverjetTopImage});
  }

  &.overjet_front_bottom ${DropItem} {
    background-image: url(${OverjetBottomImage});
  }

  &.buccal_left ${DropItem}, &.upper_buccal_left ${DropItem}, &.lower_buccal_left ${DropItem} {
    background-image: url(${BuccalLeftImage});
  }

  &.panorama ${DropItem} {
    background-image: url(${PanoramaImage});
  }

  &.cephalo ${DropItem} {
    background-image: url(${CephaloImage});
  }
`;

export const DropItemZone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;

export const DropItemImg = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DropItemControlBtn = styled.button`
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: ${(props) => props.theme.colors.blue400};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.white};

  ${(props) =>
    props.$refresh &&
    css`
      top: 10px;
      left: 10px;
    `};

  ${(props) =>
    props.$arrowbtn1 &&
    css`
      top: 45px;
      left: 10px;
    `};

  ${(props) =>
    props.$arrowbtn2 &&
    css`
      top: 80px;
      left: 10px;
    `};

  ${(props) =>
    props.$enlargebtn &&
    css`
      top: 10px;
      left: 10px;
    `};

  ${(props) =>
    props.$downloadbtn &&
    css`
      top: 45px;
      left: 10px;
    `};

  ${(props) =>
    props.$close &&
    css`
      top: 10px;
      right: 10px;
    `};
`;
