export function formatDate(inputDate) {
  if (inputDate.includes("-")) return inputDate;
  // 8자리숫자를 YYYY-MM-DD 형식의 문자열로 변환
  const year = inputDate.slice(0, 4);
  const month = inputDate.slice(-4, -2);
  const day = inputDate.slice(-2);

  try {
    // YYYY-MM-DD 형식의 문자열을 Date 객체로 변환
    const dateObj = new Date(`${year}-${month}-${day}`);
    // Date 객체를 YYYY-MM-DD 형식의 문자열로 변환
    const formattedDate = dateObj.toISOString().slice(0, 10);

    return formattedDate;
  } catch (e) {
    return null;
  }
}

export const formatTimezone = ({ value, offset }) => {
  const hours = String(Math.floor(Math.abs(offset))).padStart(2, "0");
  const minutes = String(Math.round((Math.abs(offset) % 1) * 60)).padStart(2, "0");

  return `${value}:GMT${offset >= 0 ? "+" : "-"}${hours}:${minutes}`;
};
