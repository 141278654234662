import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FieldMessageErrorClick } from "../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import AddressPopupBtn from "../../components/popup/AddressPopup";
import { IK } from "../../utils/i18n/keyStore";
import { eachUploader } from "../../utils/limitUpload";
import { TitleV1 } from "../../components/element/Title/TitleText";
import * as S from "../../components/element/Form/style/Form.style";

const DentistForm = ({ values, setFieldValue }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <TitleV1 title={t(IK.clinic_info_message)} />
      <S.FormControlsColumn $large>
        {/* 치과명 */}
        <FormControlsBox required regular title={t(IK.clinic_name)}>
          <Field as={S.FormControls} $full type="text" name="clinicName" maxLength="40" />
          <FieldMessageErrorClick name="clinicName" />
        </FormControlsBox>

        {/* 치과주소 */}
        <FormControlsBox required regular title={t(IK.clinic_address)}>
          <S.FormControlsColumn>
            {/* 우편번호 */}
            <div>
              <S.FormControlsFlex>
                <Field as={S.FormControls} $full type="text" name="postCode" placeholder={t(IK.postcode)} maxLength="8" readOnly={values.countryCode === "01"} />
                <AddressPopupBtn setFieldValue={setFieldValue} basicAddressId="address" zoneCodeId="postCode" countryCode={values.countryCode} />
              </S.FormControlsFlex>
              <FieldMessageErrorClick name="postCode" />
            </div>

            {/* 기본주소 */}
            <div>
              <Field as={S.FormControls} $full type="text" name="address" placeholder={t(IK.address)} maxLength="100" readOnly={values.countryCode === "01"} />
              <FieldMessageErrorClick name="address" />
            </div>

            {/* 상세주소 */}
            <div>
              <Field as={S.FormControls} $full type="text" name="detailAddress" placeholder={t(IK.detailed_address)} maxLength="100" />
            </div>
          </S.FormControlsColumn>
        </FormControlsBox>

        {/* 치과연락처 */}
        <FormControlsBox required regular title={t(IK.clinic_number)}>
          <Field as={S.FormControls} $full type="text" name="tel" maxLength="13" />
          <FieldMessageErrorClick name="tel" />
        </FormControlsBox>

        {/* 치과로고 */}
        <FormControlsBox regular title={t(IK.logo)}>
          <S.FormControlsFlex>
            <S.FormControls $full type="text" value={values.logo?.name || ""} readOnly placeholder={t(IK.file_placeholder)} />
            <S.FormControls $full hidden type="file" id="logo" name="logo" accept="image/*" onChange={(event) => eachUploader(event, setFieldValue, "logo", t)} />
            <S.FormControlsBtn as="label" htmlFor="logo">
              {t(IK.file_btn)}
            </S.FormControlsBtn>
          </S.FormControlsFlex>
        </FormControlsBox>

        {values.countryCode === "01" && (
          <>
            {/* 사업자 등록증 */}
            <FormControlsBox regular title={t(IK.business_license)}>
              <S.FormControlsFlex>
                <S.FormControls $full type="text" value={values.businessLicense?.name || ""} readOnly placeholder={t(IK.file_placeholder)} />
                <S.FormControls $full hidden type="file" id="businessLicense" name="businessLicense" onChange={(event) => eachUploader(event, setFieldValue, "businessLicense", t)} />
                <S.FormControlsBtn as="label" htmlFor="businessLicense">
                  {t(IK.file_btn)}
                </S.FormControlsBtn>
              </S.FormControlsFlex>
              <FieldMessageErrorClick name="businessLicense" />
            </FormControlsBox>

            {/* 사업자등록번호 */}
            <FormControlsBox regular title={t(IK.business_number)}>
              <Field as={S.FormControls} $full type="number" name="businessLicenseNumber" />
            </FormControlsBox>
          </>
        )}
      </S.FormControlsColumn>
    </>
  );
};

export default DentistForm;
