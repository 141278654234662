import { useParams } from "react-router-dom";
import { useDoctorAuth } from "../../../Hooks/useDoctorAuth";
import * as S from "../../../components/Layout/CardLayout/style/CardLayout.style";
import ProfileCard from "./section/ProfileCard";
import StudyList from "./section/StudyList";

function Profile() {
  const { patientId } = useParams();

  /**담당 의사 여부 확인 */
  const access = useDoctorAuth(patientId);

  if (!access) return null;

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardFlex>
          <ProfileCard patientId={patientId} />
          <StudyList patientId={patientId} />
        </S.CardFlex>
      </S.CardCol>
    </S.CardRow>
  );
}

export default Profile;
