import dayjs from "dayjs";
import Notiflix, { Confirm } from "notiflix";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { createAdditionalStudy } from "../../../../apis/additionalStudyApi";
import { downloadFile } from "../../../../apis/boardApi";
import { checkDeleteModifyBtn, deletePatient, getClinicCaseList, getStudyPermission, useGetPatientInfo, useRestorePatientFromLocker, useStorePatientInLocker } from "../../../../apis/patientApi";
import { createReplacementStudy } from "../../../../apis/replacementApi";
import patientAgreement from "../../../../assets/doc/pdf/Patient_obligation_information_consent_form.pdf";
import { userRole } from "../../../../atoms/userAtom";
import * as S2 from "../../../../components/Layout/CardLayout/style/CardLayout.style";
import ProfileImage from "../../../../components/common/ProfileImg";
import * as S3 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S4 from "../../../../styles/Common";
import { getConditionItem } from "../../../../utils/conditionItemUtils";
import { IK } from "../../../../utils/i18n/keyStore";
import * as S from "../style/Profile.style";
import { SkeletonProfileCard } from "./SkeletonProfileCard";
import { PatientAgreementModal } from "./profileModal/PatientAgreementModal";
import { PatientClinicalCasesModal } from "./profileModal/PatientClinicalCasesModal";
import { PatientEditModal } from "./profileModal/PatientEditModal";
import { PatientInfoEditModal } from "./profileModal/PatientInfoEditModal";
import { PatientMemoModal } from "./profileModal/PatientMemoModal";

function ProfileCard({ patientId }) {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const [btnInfo, setBtnInfo] = useState({});
  const [modifyBtn, setModifyBtn] = useState(false);
  const [clinicalCases, setClinicalCases] = useState([]);

  const { data: patientData, isLoading } = useGetPatientInfo(patientId);
  const patientInfo = patientData?.data;

  /**권한 조회 */
  const role = useRecoilValue(userRole);

  /**환자 보관함에서 복원 */
  const restoreMutaion = useRestorePatientFromLocker();
  /**환자 보관함으로 보내기 */
  const storeMutaion = useStorePatientInLocker();

  /**환자 삭제 요청 */
  const handleOnClickDelete = useCallback(() => {
    Notiflix.Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => {
      deletePatient(patientId)
        .then((res) => {
          Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate("/patient/list"));
        })
        .catch((err) => {
          if (err?.response?.status !== 403) {
            Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
          }
        });
    });
  }, [patientId, navigate, t]);

  /** 환자 상태 수정 */
  const [patientModalOpen, setPatientModalOpen] = useState(false);

  const patientModalOpenHandler = () => {
    setPatientModalOpen(true);
  };
  /** 환자 정보 수정 */
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [memoUpdate, setMemoUpdate] = useState("");

  useEffect(() => {
    if (patientInfo && patientInfo.memo !== undefined) {
      setMemoUpdate(patientInfo.memo || "");
    }
  }, [patientInfo]);

  /** 메모팝업 */
  const [memoModalOpen, setMemoModalOpen] = useState(false);
  /** 임상 증례 제출 */
  const [clinicalCasesModalOpen, setClinicalCasesModalOpen] = useState(false);
  /** 환자 의무 정보 제공 동의서 */
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);

  /**환자 보관함으로 보내기 */
  const handleOnClickStoreBtn = () => {
    patientInfo.status === "PS99" ? restoreMutaion.mutate(patientId) : storeMutaion.mutate(patientId);
  };

  /**추가 처방전 작성 */
  const handleOnClickAdditionalStudy = () => {
    return Confirm.show(
      btnInfo.additionalPayYn === "Y" ? t(IK.additional_need_pay_1) : t(IK.additional_pop_up),
      btnInfo.additionalPayYn === "Y" ? t(IK.additional_need_pay_2) : "",
      t(IK.confirm),
      t(IK.cancel),
      () => {
        createAdditionalStudy({
          studyId: patientInfo.latestStudyId,
          patientId: patientId,
        })
          .then((res) => {
            navigate(`/patient/${patientId}/study/${patientInfo.latestStudyId}/additional/${res.data.data}`);
          })
          .catch((err) => Notiflix.Notify.failure(t(IK.again_message)));
      },
      () => {}
    );
  };

  /**대체 처방전 작성 */
  const handleOnClickReplaceStudy = () => {
    Confirm.show(
      t(IK.replace_pop_up),
      "",
      t(IK.confirm),
      t(IK.cancel),
      () => {
        createReplacementStudy({
          studyId: patientInfo.latestStudyId,
          patientId: patientId,
        })
          .then((res) => {
            navigate(`/patient/${patientId}/study/${patientInfo.latestStudyId}/replace/${res.data.data}`);
          })
          .catch((err) => Notiflix.Notify.failure(t(IK.again_message)));
      },
      () => {}
    );
  };

  /**제출한 의무 정보 제공 동의서 다운로드 */
  const downloadAgreement = (fileInfo) => {
    downloadFile(fileInfo.fileId, fileInfo.orgFileName, t);
  };

  /**스터디 버튼, 환자 수정 삭제 버튼, 임상증례 여부 판단 */
  useEffect(() => {
    getStudyPermission(patientId).then((response) => {
      setBtnInfo(response.data.data);
    });
    checkDeleteModifyBtn(patientId).then((response) => {
      setModifyBtn(response.data.data);
    });
    getClinicCaseList(patientId).then((response) => {
      setClinicalCases(response.data.data);
    });
  }, [patientId]);

  const cardInfo = [
    {
      title: t(IK.gender),
      text: patientInfo?.gender === "male" ? t(IK.male) : t(IK.female),
    },
    {
      title: t(IK.birthday),
      text: patientInfo?.birthday,
    },
    {
      title: t(IK.clinic_hospital),
      text: patientInfo?.patientAddressDTO?.shipAddressName,
    },
    {
      title: t(IK.treatment_option),
      text: t(IK[patientInfo?.packages]) || "-",
    },
    {
      title: t(IK.end_date),
      text: patientInfo?.endDate ? dayjs(patientInfo.endDate).format("YYYY-MM-DD") : "TBD",
    },
    {
      title: t(IK.clinical_condition),
      text: getConditionItem(patientInfo?.conditionItemList, t),
    },
  ];

  return (
    <>
      {isLoading ? (
        <SkeletonProfileCard />
      ) : (
        <S2.CardProfileWrapper>
          <S2.CardProfileBox>
            <S2.CardProfileHead>
              <S2.CardProfileHeadBtnTop>
                {!patientInfo?.latestStudyId && (
                  <li>
                    <button type="button" onClick={patientModalOpenHandler}>
                      <i className="ri-user-settings-fill"></i>
                      {t(IK.update_patient)}
                    </button>
                  </li>
                )}
                {!role?.STAFF && modifyBtn && (
                  <li>
                    <button type="button" onClick={handleOnClickDelete}>
                      <i className="ri-user-unfollow-fill"></i>
                      {t(IK.patient_delete)}
                    </button>
                  </li>
                )}
              </S2.CardProfileHeadBtnTop>
              <S2.CardProfileHeadInfo>
                <S2.CardProfileHeadInfoImg>
                  <div>
                    <ProfileImage profile={patientInfo?.profile} />
                  </div>
                  <S.ProfileEditBtn as="button" type="button" onClick={() => setProfileModalOpen(true)}>
                    <i className="ri-pencil-fill" />
                  </S.ProfileEditBtn>
                </S2.CardProfileHeadInfoImg>
                <S2.CardProfileHeadInfoTxt>
                  <h2>
                    {patientInfo.firstName + " " + patientInfo.lastName} {patientInfo.koreaName ? ` (${patientInfo.koreaName})` : ""}
                  </h2>
                  <p>{patientInfo.patientNumber}</p>
                </S2.CardProfileHeadInfoTxt>
              </S2.CardProfileHeadInfo>

              <S2.CardProfileHeadBtnBottom>
                <button type="button" className={clinicalCases.length === 0 ? "hide" : ""} onClick={() => setClinicalCasesModalOpen(true)}>
                  {t(IK.clinical_cases_submission)}
                </button>
                {btnInfo.storeYn === "Y" && (
                  <button type="button" onClick={handleOnClickStoreBtn}>
                    {patientInfo.status === "PS99" ? t(IK.restore_locker) : t(IK.storagebox_btn)}
                  </button>
                )}
              </S2.CardProfileHeadBtnBottom>
            </S2.CardProfileHead>
            <S2.CardProfileBodyInfo>
              {cardInfo.map((info) => (
                <CardText title={info.title} text={info.text} key={info.title} />
              ))}
              <dl>
                <S.ProfileEditBox>
                  {t(IK.patient_memo)}
                  <S.ProfileEditBtn as="button" type="button" onClick={() => setMemoModalOpen(true)}>
                    <i className="ri-pencil-fill"></i>
                  </S.ProfileEditBtn>
                </S.ProfileEditBox>
                <S4.ContentScroll as="dd" $small>
                  <S4.TextDiv $textpreline>{memoUpdate}</S4.TextDiv>
                </S4.ContentScroll>
              </dl>
              <dl>
                <S.ProfileEditBox>
                  {t(IK.patient_consent_form)}
                  <S.ProfileEditBtn to={patientAgreement} download target="_blank">
                    <i className="ri-download-2-fill"></i>
                  </S.ProfileEditBtn>
                </S.ProfileEditBox>
                <dd>
                  {patientInfo.agreementYn === "Y" ? (
                    <S3.ButtonFlex>
                      <S3.StyledButton as="button" type="button" $secondary onClick={() => setAgreementModalOpen(true)}>
                        {t(IK.reregistration)}
                      </S3.StyledButton>
                      <S3.StyledButton $primary onClick={() => downloadAgreement(patientInfo?.patientAgreement)}>
                        {t(IK.submitted)} {t(IK.download)}
                      </S3.StyledButton>
                    </S3.ButtonFlex>
                  ) : (
                    <S3.ButtonFlex>
                      <S3.StyledButton as="button" type="button" $secondary onClick={() => setAgreementModalOpen(true)}>
                        {t(IK.upload)}
                      </S3.StyledButton>
                    </S3.ButtonFlex>
                  )}
                </dd>
              </dl>
            </S2.CardProfileBodyInfo>
          </S2.CardProfileBox>

          <S.ProfileBtnBox>
            {btnInfo.writeStudyYn === "Y" && (
              <Link to={`/patient/${patientId}/study`}>
                <h3>{t(IK.prescription_write)}</h3>
                <i className="ri-arrow-right-s-line"></i>
              </Link>
            )}
            {btnInfo.reOrderYn === "Y" && (
              <Link to={`/patient/${patientId}/study`}>
                <h3>{t(IK.reorder)}</h3>
                <i className="ri-arrow-right-s-line"></i>
              </Link>
            )}
            {btnInfo.additionalYn === "Y" && (
              <Link onClick={handleOnClickAdditionalStudy}>
                <h3>
                  {t(IK.additional_device)} {btnInfo.additionalPayYn === "Y" && `(${t(IK.pay_need)}/ 10${t(IK.level)})`}
                </h3>
                <i className="ri-arrow-right-s-line" />
              </Link>
            )}
            {btnInfo.replaceMentYn === "Y" && (
              <Link onClick={handleOnClickReplaceStudy}>
                <h3>{t(IK.alternative_correction_devices)}</h3>
                <p>{t(IK.alternative_correction_devices_message)}</p>
                <i className="ri-arrow-right-s-line" />
              </Link>
            )}
          </S.ProfileBtnBox>
        </S2.CardProfileWrapper>
      )}

      {/* 환자 상태 수정 */}
      <PatientEditModal patientModalOpen={patientModalOpen} patientId={patientId} setPatientModalOpen={setPatientModalOpen} />

      {/* 환자 정보 수정 */}
      <PatientInfoEditModal profileModalOpen={profileModalOpen} patientInfo={patientInfo} patientId={patientId} setProfileModalOpen={setProfileModalOpen} />

      {/* 메모 수정 */}
      <PatientMemoModal setMemoModalOpen={setMemoModalOpen} memoModalOpen={memoModalOpen} patientId={patientId} setMemoUpdate={setMemoUpdate} memoUpdate={memoUpdate} />

      {/* 임상증례 제출 */}
      <PatientClinicalCasesModal setClinicalCasesModalOpen={setClinicalCasesModalOpen} clinicalCases={clinicalCases} clinicalCasesModalOpen={clinicalCasesModalOpen} />

      {/* 환자 의무 정보 제공 동의서 */}
      <PatientAgreementModal setAgreementModalOpen={setAgreementModalOpen} patientId={patientId} agreementModalOpen={agreementModalOpen} />
    </>
  );
}

export default ProfileCard;

export const CardText = ({ title, text }) => {
  return (
    <dl>
      <dt>{title}</dt>
      <dd>
        <span>{text}</span>
      </dd>
    </dl>
  );
};
