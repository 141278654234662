import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

export const useGetClinicItem = (type, patientId) => {
  return useQuery(["ClinicItem", patientId], async () => {
    const { data } = await axios.get(`/api/clinic-item/${type}`, {
      params: {
        patientId,
      },
    });
    return data;
  });
};

/**의사 임상환경설정 저장 */
export const useSaveDoctorClinicItem = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await axios.post(`/api/clinic-item/doctor`, data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ClinicItem");
      },
    }
  );
};

/**환자 임상환경설정 저장 */
export const useSavePatientClinicItem = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      const response = await axios.post(`/api/clinic-item/patient`, payload.data, {
        params: {
          patientId: payload.patientId,
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ClinicItem");
      },
    }
  );
};

/**프리시젼 컷 조회 */
export const useGetPreCut = (type, patientId) => {
  return useQuery(["PreCut", patientId], async () => {
    const { data } = await axios.get(`/api/clinic-item/${type}/elastic`, {
      params: {
        patientId,
      },
    });
    return data;
  });
};

/**프리시젼 컷 등록 */
export const useSavePreCut = () => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async ({ type, data, patientId }) => {
      const response = await axios.put(`/api/clinic-item/${type}/elastic`, data, {
        params: {
          patientId: patientId,
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.fail_save), "", t(IK.confirm));
      },
    }
  );
};
