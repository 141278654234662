import { Confirm } from "notiflix";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useDeleteAlarm, useGetAlarmSearchCount, useGetInfinityAlarmList } from "../../../apis/alarmApi";
import { IK } from "../../../utils/i18n/keyStore";
import LoadingSpinner from "../../loading/LoadingSpinner/LoadingSpinner";
import AlarmFilter from "./AlarmFilter";
import AlarmList from "./AlarmList";

import AlarmSkeleton from "./AlarmSkeleton";
import * as S from "./style/AlarmPopup.style";

const AlarmPopup = ({ handleCloseAlarmPopup }) => {
  const { t } = useTranslation(["translation"]);

  /**알림 검색 필터 */
  const [alarmParams, setAlarmParams] = useState({
    alarmCode: "VIP",
    alarmFilterCode: "",
    size: 20, //한 페이지에 보여줄 데이터 수
  });

  const [alarmList, setAlarmList] = useState([]);
  const [alarmLoading, setAlarmLoading] = useState(true);

  /**알림 리스트 무한 스크롤 조회 */
  const { isFetchingNextPage, fetchNextPage, hasNextPage } = useGetInfinityAlarmList(alarmParams, setAlarmList, setAlarmLoading);
  /**알림 검색 카운트 조회 */
  const { data: alarmCount } = useGetAlarmSearchCount(alarmParams);

  /* 사용자가 바닥에 도착하면 inView가 true, 안보면 false로 자동으로 변경 */
  const { ref, inView } = useInView();

  /**스크롤 위치 조절 */
  const scrollRef = useRef();

  useEffect(() => {
    if (inView && hasNextPage) {
      //바닥에 도착하고 다음 페이지가 있는 경우 다음 페이지 조회
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  /**알람 전체 삭제 */
  const deleteAlarmMutation = useDeleteAlarm();

  /**알림 전체 삭제 */
  const handleClickALlDelete = () => {
    if (alarmList.length === 0) return false;
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        setAlarmLoading(true);
        deleteAlarmMutation.mutate("list", {
          onSuccess: () => {
            setAlarmLoading(false);
            setAlarmList([]);
          },
        });
      },
      () => {}
    );
  };

  return (
    <S.AlarmPop>
      <S.AlarmPopTop>
        <h3>{t(IK.alarm)}</h3>
        <button type="button" onClick={handleCloseAlarmPopup}>
          <i className="ri-close-line" />
        </button>
      </S.AlarmPopTop>
      <S.AlarmPopSection>
        <AlarmFilter t={t} alarmParams={alarmParams} alarmCount={alarmCount?.data} setAlarmParams={setAlarmParams} scrollRef={scrollRef} />
        {alarmLoading ? <AlarmSkeleton /> : <AlarmList alarmList={alarmList} handleCloseAlarmPopup={handleCloseAlarmPopup} scrollRef={scrollRef} viewRef={ref} />}
        {isFetchingNextPage && <LoadingSpinner />}
      </S.AlarmPopSection>
      <S.AlarmPopBottom>
        <button type="button" onClick={handleClickALlDelete}>
          <i className="ri-delete-bin-fill"></i>
          {t(IK.delete_all_notifications)}
        </button>
      </S.AlarmPopBottom>
    </S.AlarmPop>
  );
};

export default AlarmPopup;
