import { useTranslation } from "react-i18next";
import { SingleLayoutTail } from "../../components/Layout/SingleLayout/SingleLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { FormConfirm } from "../../components/element/Form/FormLayout";
import * as S from "../../components/element/Step/style/Step.style";
import { IK } from "../../utils/i18n/keyStore";

const JoinConfirm = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <S.StepType1>
        <ol>
          <li className="active">
            <span>1</span>
            <h3>{t(IK.country_code)}</h3>
          </li>
          {/* <li className="active">
            <span>2</span>
            <h3>{t(IK.agree_form_title)}</h3>
          </li> */}
          <li className="active">
            <span>2</span>
            <h3>{t(IK.clinic_form_title)}</h3>
          </li>
          <li className="active">
            <span>3</span>
            <h3>{t(IK.doctor_form_title)}</h3>
          </li>
          <li className="active">
            <span>4</span>
            <h3>{t(IK.etc_form_title)}</h3>
          </li>
          <li className="active">
            <span>5</span>
            <h3>{t(IK.waiting_for_approval)}</h3>
          </li>
        </ol>
      </S.StepType1>
      <FormConfirm title={t(IK.join_comfirm_message_1)}>
        <p>{t(IK.join_comfirm_message_2)}</p>
      </FormConfirm>
      <SingleLayoutTail>
        <S2.StyledButton to="/" $primary>
          {t(IK.confirm)}
        </S2.StyledButton>
      </SingleLayoutTail>
    </>
  );
};

export default JoinConfirm;
