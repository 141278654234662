import { useTranslation } from "react-i18next";
import { SingleLayoutTail } from "../../../components/Layout/SingleLayout/SingleLayout";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { FormConfirm } from "../../../components/element/Form/FormLayout";
import { IK } from "../../../utils/i18n/keyStore";

function Success() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <FormConfirm title={t(IK.find_pw_title_success_txt_1)}>
        <p>
          {t(IK.find_pw_title_success_txt_2)}
          <br />
          <strong>{t(IK.find_pw_title_success_txt_3)}</strong>
        </p>
      </FormConfirm>

      <SingleLayoutTail>
        <S.StyledButton to="/" $primary>
          {t(IK.login)}
        </S.StyledButton>
      </SingleLayoutTail>
    </>
  );
}

export default Success;
