import axios from "axios";
import Notiflix from "notiflix";
import { IK } from "../utils/i18n/keyStore";
import { deleteRefreshToken } from "./joinApi";

let isTokenRefreshing = false; // 토큰 갱신 중 여부를 나타내는 변수
let refreshSubscribers = []; // 실패한 요청들의 배열

// 실패한 요청들을 배열에 추가해주는 함수
const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// 실패한 요청들을 다시 실행시켜주는 함수
const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.forEach((callback) => callback(accessToken));
  refreshSubscribers = []; // 배열 초기화
};

const SetupInterceptors = (navigate, t) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response: { status },
      } = error;

      const originalRequest = config;

      if (status === 401) {
        //refresh 있는데 access token 만료, 토큰 재발급
        if (!isTokenRefreshing) {
          isTokenRefreshing = true; // 토큰 갱신 중 표시

          const { data } = await axios.get(`/api/auth/refresh-token`);
          const token = data?.data?.accessToken;
          localStorage.setItem("accessToken", token);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          originalRequest.headers.Authorization = `Bearer ${token}`;

          isTokenRefreshing = false; // 토큰 갱신 완료 후 변수 초기화

          // 새로운 토큰으로 지연되었던 요청 진행
          onTokenRefreshed(token);

          return axios(originalRequest);
        }
        // token이 재발급 되는 동안의 요청은 refreshSubscribers에 추가
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber((accessToken) => {
            // 갱신한 accessToken으로 재설정
            originalRequest.headers.Authorization = "Bearer " + accessToken;
            // originalRequest를 다시 실행
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      }

      if (status === 440) {
        //refresh  토큰 만료
        localStorage.removeItem("accessToken");
        localStorage.removeItem("recoil-persist");
        deleteRefreshToken();
        navigate("/");
      }

      if (status === 403) {
        //권한 없음
        return Notiflix.Report.info("", t(IK.authority_message), t(IK.confirm), () => navigate("/patient/list"));
      }

      if (status === 456) {
        //잘못된 접근 입니다.
        return Notiflix.Report.info("", t(IK.Invalid_approach), t(IK.confirm), () => navigate("/patient/list"));
      }

      if (status === 504) {
        //서버 재기동. 재배포
        Notiflix.Report.info(t(IK.server_error), t(IK.server_error1), t(IK.confirm), () => {});
      }

      return Promise.reject(error);
    }
  );
};

export default SetupInterceptors;
