import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal/Modal";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import * as S from "../../../components/element/Table/style/table.style";
import LoadingSpinner from "../../../components/loading/LoadingSpinner/LoadingSpinner";
import { IK } from "../../../utils/i18n/keyStore";
import { formatCurrency } from "../../../utils/paymentUtils";

const ApplyCouponModal = ({ couponModal, setCouponModal, isCouponLoading, couponList }) => {
  const { t } = useTranslation(["translation"]);
  /**모달 닫기 */
  const onCloseModal = () => setCouponModal(false);

  return (
    <Modal open={couponModal} close={onCloseModal} header={`${t(IK.available_coupons)} ${t(IK.list)}`}>
      {isCouponLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <S.TableType1>
            <colgroup>
              <col width="200px"></col>
              <col width="200px"></col>
              <col width="200px"></col>
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t(IK.coupon_name)}</th>
                <th scope="col">{t(IK.pay_discount)}</th>
                <th scope="col">{t(IK.pay_end_date)}</th>
              </tr>
            </thead>
            <tbody>
              {couponList?.length > 0 ? (
                couponList?.map((coupon) => (
                  <tr key={coupon.couponId}>
                    <td>{coupon.couponName}</td>
                    <td>{formatCurrency(coupon.amount)}</td>
                    <td>{coupon.noLimitYn === "N" ? `${coupon.startDate}~${coupon.endDate}` : t(IK.pay_no_end_date)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <EmptyCard />
                  </td>
                </tr>
              )}
            </tbody>
          </S.TableType1>
        </>
      )}
    </Modal>
  );
};

export default ApplyCouponModal;
