import styled from "styled-components";

export const DownloadBox = styled.div`
  padding: 40px;
  margin-bottom: 30px;
  text-align: center;
  border: 3px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  h3 {
    margin: 20px 0 15px;
    font-size: 24px;
  }
  p {
    font-size: 18px;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const DownloadBoxIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 70px;
  color: ${(props) => props.theme.colors.blue400};
`;
