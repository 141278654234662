import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useAddAddress, useEditAddress } from "../../../apis/addressApi";
import { userInfo } from "../../../atoms/userAtom";
import Modal from "../../../components/Modal/Modal";
import * as S from "../../../components/Modal/style/Modal.style";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../components/element/Form/style/Form.style";
import { FieldMessageErrorClick } from "../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../components/element/Form/FormLayout";
import AddressPopupBtn from "../../../components/popup/AddressPopup";
import { IK } from "../../../utils/i18n/keyStore";

const AddressModal = ({ addressModalOpen, closeAddressModal, editAddressData }) => {
  const { t } = useTranslation(["translation"]);
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  const addAddressMutation = useAddAddress();
  const editAddressMutaion = useEditAddress();

  /**주소지 등록, 수정 */
  const onAddressSubmit = (value) => {
    if (editAddressData) {
      editAddressMutaion.mutate(
        { value, id: editAddressData.addressId },
        {
          onSuccess: () => {
            closeAddressModal();
            Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
          },
          onError: () => {
            Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm));
          },
        }
      );
    } else {
      addAddressMutation.mutate(value, {
        onSuccess: () => {
          closeAddressModal();
          Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
        },
        onError: (error) => {
          const errorMessage = error.response.data.message || "again_message";
          Notiflix.Report.failure(t(IK.fail_save), t(IK[errorMessage]), t(IK.confirm));
        },
      });
    }
  };

  return (
    <Modal open={addressModalOpen} close={closeAddressModal} header={t(IK.registration_address)}>
      <Formik
        initialValues={{
          addressName: editAddressData?.addressName || "",
          postalCode: editAddressData?.postalCode || "",
          address: editAddressData?.address || "",
          addressDetail: editAddressData?.addressDetail || "",
        }}
        validationSchema={Yup.object().shape({
          addressName: Yup.string().required(t(IK.clinic_name_required)),
          postalCode: Yup.string().required(t(IK.postcode_required)),
          address: Yup.string().required(t(IK.address_required)),
        })}
        onSubmit={onAddressSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <S3.FormControlsColumn $large>
              {/* 치과명 */}
              <FormControlsBox required regular title={t(IK.clinic_name)}>
                <Field as={S3.FormControls} type="text" name="addressName" maxLength="40" />
                <FieldMessageErrorClick name="addressName" />
              </FormControlsBox>

              {/* 치과주소 */}
              <FormControlsBox required regular title={t(IK.clinic_address)}>
                <S3.FormControlsColumn>
                  {/* 우편번호 */}
                  <div>
                    <S3.FormControlsFlex>
                      <Field as={S3.FormControls} $full type="text" name="postalCode" placeholder={t(IK.postcode)} maxLength="8" />
                      <AddressPopupBtn countryCode={countryCode} setFieldValue={setFieldValue} basicAddressId={"address"} zoneCodeId={"postalCode"} />
                    </S3.FormControlsFlex>
                    <FieldMessageErrorClick name="postalCode" />
                  </div>

                  {/* 기본주소 */}
                  <div>
                    <Field as={S3.FormControls} type="text" name="address" placeholder={t(IK.address)} maxLength="100" />
                    <FieldMessageErrorClick name="address" />
                  </div>

                  {/* 상세주소 */}
                  <div>
                    <Field as={S3.FormControls} type="text" name="addressDetail" placeholder={t(IK.detailed_address)} maxLength="100" />
                  </div>
                </S3.FormControlsColumn>
              </FormControlsBox>
            </S3.FormControlsColumn>

            <S.ModalBoxBtn>
              <S2.ButtonFlex>
                <S2.StyledButton as="button" type="button" onClick={closeAddressModal}>
                  {t(IK.cancel)}
                </S2.StyledButton>
                <S2.StyledButton as="button" type="submit" $primary>
                  {t(IK.compelete)}
                </S2.StyledButton>
              </S2.ButtonFlex>
            </S.ModalBoxBtn>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddressModal;
