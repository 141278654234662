import { css, styled } from "styled-components";

export const SummaryList = styled.ul`
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const SummaryListItem = styled.li`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-top: 0;

  ${(props) =>
    props.$printshow &&
    css`
      display: none;
      @media print {
        display: block;
      }
    `};

  ${(props) =>
    props.$endline &&
    css`
      page-break-before: always;
    `};
  dl {
    position: relative;
    display: flex;
    &:before {
      content: "";
      position: absolute;
      width: 250px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray300};
    }

    &:after {
      content: "";
      position: absolute;
      left: 250px;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray200};
    }

    dt {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 5px;
      width: 250px;
      padding: 15px;
      h2 {
        flex: 1;
        font-size: 15px;
        word-break: break-all;
      }
      button {
        display: flex;
        gap: 3px;
        font-size: 15px;
      }
    }

    dd {
      flex: 1;
      padding: 15px;
      font-size: 15px;
    }
  }
`;

export const PrintWrapper = styled.div`
  @media print {
    * {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }

    ${SummaryListItem} {
      page-break-inside: avoid;
      page-break-after: auto;
      dl {
        &:before {
          width: 160px;
        }
        &:after {
          left: 160px;
        }
        dt {
          width: 160px;
        }
      }
    }

    ${SummaryListItem} dt button {
      display: none;
    }
  }
`;
