import Notiflix, { Confirm } from "notiflix";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeletePreferredPlan, useGetPreferredPlanDetail, useSelectPreferredPlan } from "../../apis/preferredTreatmentApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import * as S4 from "../../components/element/Form/style/Form.style";
import { TitleV3 } from "../../components/element/Title/TitleText";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import * as S3 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { getArchLabel, getSummaryLabel, getTeethLabel, seraphinOption, studyFormConfig } from "../../utils/studyUtils";
import * as S from "../Medit/style/Medit.style";

const PreferStudyDetail = () => {
  document.getElementById("wrapper").style.minWidth = "630px";
  const { t } = useTranslation(["translation"]);
  const { state } = useLocation();
  const { studyPlanId, studyId } = useParams();
  const navigate = useNavigate();

  /**상세 조회 데이터 */
  const { data: studyPlan, isLoading } = useGetPreferredPlanDetail({
    studyPlanId,
    studyId,
  });

  const planDetail = studyPlan?.data;

  const [studyInfo, setStudyInfo] = useState({});
  /**받은 데이터를 하나의 객체로 정리 */
  useEffect(() => {
    if (!isLoading && planDetail) {
      const obj = {};
      const data = planDetail?.studyPlanItem;
      for (const key in data) {
        Object.assign(obj, data[key]);
      }
      setStudyInfo(obj);
    }
  }, [isLoading, planDetail]);

  const config = studyFormConfig[planDetail?.packages];
  const studyItem = planDetail?.studyPlanItem;

  const deletePlanMutation = useDeletePreferredPlan();
  /**선호 치료계획 단건 삭제 */
  const handleDeletePlan = () => {
    Confirm.show(
      t(IK.pick_study_delete_txt1),
      t(IK.pick_study_delete_txt2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        deletePlanMutation.mutate(
          { studyId, studyPlanId },
          {
            onSuccess: () => {
              Notiflix.Notify.success(t(IK.delete_success));
              navigate(`/study-plan/${studyId}`);
            },
          }
        );
      },
      () => {}
    );
  };
  const queryClient = useQueryClient();
  const choicePlanMutation = useSelectPreferredPlan();
  /**선호 치료계획 선택 */
  const handleChoicePlan = () => {
    Confirm.show(
      t(IK.pick_study_choice_txt1),
      t(IK.pick_study_choice_txt2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        choicePlanMutation.mutate(
          { studyId, studyPlanId },
          {
            onSuccess: () => {
              window.opener.skipPlan(studyPlanId);
              window.close();
            },
            onError: (res) => {
              if (res?.response?.data?.code === 441) {
                Notiflix.Report.failure(t(IK.pick_study_error_txt1), t(IK.pick_study_error_txt2), t(IK.confirm), () => {
                  queryClient.invalidateQueries("StudyPlan");
                  navigate(`/study-plan/${studyId}`);
                });
              }
            },
          }
        );
      },
      () => {}
    );
  };

  return (
    <S.MeditBox>
      {!isLoading ? (
        <CardInnerLayout>
          <CardTitle title={`${planDetail.name}`} subTitle={`${seraphinOption[planDetail.packages]} (${getArchLabel(planDetail.arch, t)})`} />
          {/* 치료해야 할 악궁 */}
          {config.DentalArchTreated && (
            <>
              <TitleV3 title={t(IK.dental_arch_treated)} />
              <S.MeditInfo>
                <li>
                  {getSummaryLabel(studyItem.SI01, "SI01", t)}
                  {studyInfo?.SI01 === "2" && !!studyInfo.SI01_2_01 && `(${getSummaryLabel(studyInfo, "SI01_2_01", t)})`}
                  {studyInfo?.SI01 === "3" && !!studyInfo.SI01_3_01 && `(${getSummaryLabel(studyInfo, "SI01_3_01", t)})`}
                </li>
              </S.MeditInfo>
            </>
          )}
          {/* 발치 할 치아 */}
          {config.Extraction && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.extraction)} />
              <S.MeditInfo>
                {studyInfo?.SI12 === "1" && <li>{t(IK.extraction_item1)}</li>}
                {studyInfo?.SI12 === "2" && !!studyInfo?.SI12_2_01 && <li>{getTeethLabel(studyInfo?.SI12_2_01)}</li>}
                {studyInfo?.SI12 === "2" && !!studyInfo?.SI12_2_02 && <li>{getTeethLabel(studyInfo?.SI12_2_02)}</li>}
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 이동에 제한이 있는 치아 */}
          {config.TeethLimitedMobility && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.teeth_limited_mobility)} />
              <S.MeditInfo>
                {studyInfo?.SI02 === "1" && <li>{t(IK.teeth_limited_mobility_item1)}</li>}
                {studyInfo?.SI02 === "2" && !!studyInfo.SI02_2_01 && <li>{getTeethLabel(studyInfo?.SI02_2_01)}</li>}
                {studyInfo?.SI02 === "2" && !!studyInfo.SI02_2_02 && <li>{getTeethLabel(studyInfo?.SI02_2_02)}</li>}
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 어태치먼트 부여를 원치 않는 치아 */}
          {config.Attachment && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.attachment)} />
              <S.MeditInfo>
                {studyInfo?.SI03 === "1" && <li>{t(IK.attachment_item1)}</li>}
                {studyInfo?.SI03 === "2" && !!studyInfo.SI03_2_01 && <li>{getTeethLabel(studyInfo?.SI03_2_01)}</li>}
                {studyInfo?.SI03 === "2" && !!studyInfo.SI03_2_02 && <li>{getTeethLabel(studyInfo?.SI03_2_02)}</li>}
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 어태치먼트 AP*/}
          {config.ApAttachment && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.ap_attachment)} />
              <S.MeditInfo>{<li>{getSummaryLabel(studyInfo, "SI16", t)}</li>}</S.MeditInfo>
            </S3.ContentBox>
          )}

          {/* 악궁 확장 */}
          {config.DentalArchExtension && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.dental_arch_extension)} />
              <S.MeditInfo>
                {studyInfo?.SI04_01 && (
                  <li>
                    {t(IK.maxilla)} : {getSummaryLabel(studyInfo, "SI04_01", t)}
                    {studyInfo?.SI04_01 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_01_01} mm`}
                  </li>
                )}
                {studyInfo?.SI04_02 && (
                  <li>
                    {t(IK.mandible)} : {getSummaryLabel(studyInfo, "SI04_02", t)}
                    {studyInfo?.SI04_02 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_02_01} mm`}
                  </li>
                )}
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 전후방관계(A-P) */}
          {config.AP && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.ap_relation)} />
              <S.MeditInfo>
                <div className="frm_column">
                  {studyInfo?.SI05_01 && (
                    <li>
                      {`${t(IK.ap_item1_title)} : ${getSummaryLabel(studyInfo, "SI05_01", t)}`}
                      {studyInfo["SI05_01"] === "2" && `(${studyInfo["SI05_01_2_01"]}mm)`}
                      {studyInfo["SI05_01"] === "3" && `(${studyInfo["SI05_01_3_01"]}mm)`}
                    </li>
                  )}
                  {studyInfo["SI05_05"] === "1" ? (
                    <li>{t(IK.ap_item2_title)}</li>
                  ) : (
                    <div>
                      {studyInfo["SI05_02"] && <li>{getSummaryLabel(studyInfo, "SI05_02", t)}</li>}
                      {studyInfo["SI05_03"] && <li>{getSummaryLabel(studyInfo, "SI05_03", t)}</li>}
                    </div>
                  )}
                  {studyInfo["SI05_04"] && (
                    <div>
                      <li>
                        {t(IK.teeth_move_option)} : {getSummaryLabel(studyInfo, "SI05_04", t)}
                      </li>
                    </div>
                  )}
                </div>
              </S.MeditInfo>
            </S3.ContentBox>
          )}

          {/* 수평피개(Overjet) */}
          {config.Overjet && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.overjet)} />
              <S.MeditInfo>
                <li>
                  {getSummaryLabel(studyInfo, "SI06", t)}
                  {studyInfo?.SI06 === "3" && `(${studyInfo.SI06_3_01}mm)`}
                </li>
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 수직피개(Overbite) */}
          {config.Deepbite && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.deepbite_title)} />
              <S.MeditInfo>
                <div className="frm_column">
                  {studyInfo?.SI07_01 && (
                    <li>
                      {t(IK.deepbite_item1)} : {getSummaryLabel(studyInfo, "SI07_01", t)}
                      {studyInfo?.SI07_01 === "2" && `(${studyInfo.SI07_01_2_01}mm)`}
                      {studyInfo?.SI07_01 === "3" && `(${studyInfo.SI07_01_3_01}mm)`}
                    </li>
                  )}
                  {studyInfo?.SI07_02 && (
                    <li>
                      {t(IK.overbite_item)} : {getSummaryLabel(studyInfo, "SI07_02", t)}
                      {studyInfo?.SI07_02 === "3" && `(${studyInfo.SI07_02_3_01}mm)`}
                    </li>
                  )}
                  {studyInfo?.SI07_03 && (
                    <li>
                      {t(IK.deepbite_item3)}
                      {studyInfo["SI07_03"] < 5 ? (
                        <>
                          {`: ${getSummaryLabel(studyInfo, "SI07_03", t)}`}
                          {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("1") && `(${t(IK.maxilla)})`}
                          {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("2") && `(${t(IK.mandible)})`}
                        </>
                      ) : studyInfo?.SI07_03_5_01 ? (
                        `: ${studyInfo?.SI07_03_5_01}(${getSummaryLabel(studyInfo, "SI07_03", t)})`
                      ) : (
                        ""
                      )}
                    </li>
                  )}
                  {studyInfo?.SI07_04 && (
                    <li>
                      {t(IK.deepbite_item4)}
                      {studyInfo["SI07_04"] < 5 ? (
                        <>
                          {`: ${getSummaryLabel(studyInfo, "SI07_04", t)}`}
                          {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("1") && `(${t(IK.maxilla)})`}
                          {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("2") && `(${t(IK.mandible)})`}
                        </>
                      ) : studyInfo?.SI07_04_5_01 ? (
                        `: ${studyInfo.SI07_04_5_01}(${getSummaryLabel(studyInfo, "SI07_04", t)})`
                      ) : (
                        ""
                      )}
                    </li>
                  )}
                </div>
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 교합이개장치(Bite Ramps) */}
          {config.Biteramp && !!studyInfo.SI10 && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.biteramp)} />
              <S.MeditInfo>
                <li>
                  {getSummaryLabel(studyInfo, "SI10", t)}
                  {studyInfo["SI10"] === "2" && `(${getSummaryLabel(studyInfo, "SI10_2_01", t)}${studyInfo["SI10_2_01"] === "1" ? " : " + getSummaryLabel(studyInfo, "SI10_2_01_1_01", t) : ""})`}
                </li>
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 정중선(Midline) */}
          {config.Midline && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.midline)} />
              <S.MeditInfo>
                <li>
                  {getSummaryLabel(studyInfo, "SI08", t)} {studyInfo.SI08_3_02 ? `: ${getSummaryLabel(studyInfo, "SI08_3_02", t)}` : ""}
                  {studyInfo["SI08"] === "3" && studyInfo["SI08_3_02"] === "3" && (
                    <>
                      {studyInfo["SI08_3_01"].split(",").includes("1") && <p>{`- ${t(IK.maxilla)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_1_01"]}mm)`}</p>}
                      {studyInfo["SI08_3_01"].split(",").includes("2") && <p>{`- ${t(IK.maxilla)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_2_01"]}mm)`}</p>}
                      {studyInfo["SI08_3_01"].split(",").includes("3") && <p>{`- ${t(IK.mandible)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_3_01"]}mm)`}</p>}
                      {studyInfo["SI08_3_01"].split(",").includes("4") && <p>{`- ${t(IK.mandible)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_4_01"]}mm)`}</p>}
                    </>
                  )}
                </li>
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* Spacing */}
          {config.Spacing && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.spacing)} />
              <S.MeditInfo>
                <li>{getSummaryLabel(studyInfo, "SI09_01", t)}</li>
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* Crowding */}
          {config.Crowding && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.crowding)} />
              <S.MeditInfo>
                {(studyInfo?.SI01 === "1" || studyInfo?.SI01 === "2") && (
                  <li>
                    <S4.FormControlsColumn>
                      <FormControlsBox title={`${t(IK.maxilla)} ${t(IK.crowding_item1_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_01", t)}</FormControlsBox>
                      <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item2_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_02", t)}</FormControlsBox>
                      <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item3_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_03", t)}</FormControlsBox>
                      <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item4_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_01_04", t)}</FormControlsBox>
                    </S4.FormControlsColumn>
                  </li>
                )}
                {(studyInfo?.SI01 === "1" || studyInfo?.SI01 === "3") && (
                  <li>
                    <S4.FormControlsColumn>
                      <FormControlsBox title={`${t(IK.mandible)} ${t(IK.crowding_item5_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_01", t)}</FormControlsBox>
                      <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item6_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_02", t)}</FormControlsBox>
                      <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item7_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_03", t)}</FormControlsBox>
                      <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item8_title)}`}>{getSummaryLabel(studyInfo, "SI09_02_02_04", t)}</FormControlsBox>
                    </S4.FormControlsColumn>
                  </li>
                )}
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 구치부 반대교합(Crossbite) */}
          {config.Crossbite && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.posterior_crossbite)} />
              <S.MeditInfo>
                <li>{getSummaryLabel(studyInfo, "SI11", t)}</li>
              </S.MeditInfo>
            </S3.ContentBox>
          )}
          {/* 영구치예측 */}
          {config.PermanentTooth && (
            <S3.ContentBox $regular>
              <TitleV3 title={t(IK.prmanent_tooth)} />
              <S.MeditInfo>
                {studyInfo["SI14_01"] === "2" ? (
                  <div className="frm_column">
                    <li>
                      {t(IK.prmanent_tooth_item1_2)}
                      {!!studyInfo.SI14_01_2_01 && <p>-{getTeethLabel(studyInfo.SI14_01_2_01)}</p>}
                      {[studyInfo.SI14_02_2_01, studyInfo.SI14_02_2_02, studyInfo.SI14_02_2_03, studyInfo.SI14_02_2_04].filter((x) => !!x).length > 0 && (
                        <p>
                          -
                          {[studyInfo.SI14_02_2_01, studyInfo.SI14_02_2_02, studyInfo.SI14_02_2_03, studyInfo.SI14_02_2_04]
                            .filter((x) => !!x)
                            .map((x) => getTeethLabel(x))
                            .join(", ")}
                        </p>
                      )}
                    </li>
                    <li>
                      {t(IK.prmanent_tooth_item2_title)}
                      {!!studyInfo["SI14_03"] ? `: ${studyInfo["SI14_03"] + t(IK.level)}` : `: ${t(IK.not_applicable)}`}
                    </li>
                  </div>
                ) : (
                  <li>{t(IK.not_applicable)}</li>
                )}
              </S.MeditInfo>
            </S3.ContentBox>
          )}

          <CardTail line>
            <S2.StyledButton as="button" $secondary type="button" onClick={handleDeletePlan}>
              {t(IK.delete)}
            </S2.StyledButton>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={() => navigate(`/study-plan/${studyId}?page=${state.page}`)}>
                {t(IK.list)}
              </S2.StyledButton>
              <S2.StyledButton as="button" $primary type="button" onClick={handleChoicePlan}>
                {t(IK.select)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      ) : (
        <LoadingIndicator />
      )}
    </S.MeditBox>
  );
};

export default PreferStudyDetail;
