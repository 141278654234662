import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "../element/Table/style/table.style";
import * as S2 from "../../styles/Common";
import { TitleV3 } from "../element/Title/TitleText";

function Privacy() {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <S2.ContentBox $large>
        <p>{t(IK.privacy_content_txt1)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content1_title)} />
        <ol>
          <li>
            {t(IK.privacy_content1_txt1)}
            <p>{t(IK.privacy_content1_txt1_1)}</p>
            <p>{t(IK.privacy_content1_txt1_2)}</p>
            <p>{t(IK.privacy_content1_txt1_3)}</p>
            <p>{t(IK.privacy_content1_txt1_4)}</p>
            <p>{t(IK.privacy_content1_txt1_4_1)}</p>
            <p>{t(IK.privacy_content1_txt1_4_2)}</p>
          </li>
          <S2.ContentBox $small as="li">
            {t(IK.privacy_content1_txt2)}
            <p>{t(IK.privacy_content1_txt2_1)}</p>
            <p>{t(IK.privacy_content1_txt2_2)}</p>
            <p>{t(IK.privacy_content1_txt2_3)}</p>
            <p>{t(IK.privacy_content1_txt2_4)}</p>
            <p>{t(IK.privacy_content1_txt2_5)}</p>
            <p>{t(IK.privacy_content1_txt2_6)}</p>
          </S2.ContentBox>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content2_title)} />
        <p>{t(IK.privacy_content2_txt1)}</p>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content2_txt2)}
        </S2.ContentBox>
        <p>{t(IK.privacy_content2_txt3)}</p>
        <p>{t(IK.privacy_content2_txt4)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content3_title)} />
        <p>{t(IK.privacy_content3_txt1)}</p>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content3_txt2)}
          <br />
          {t(IK.privacy_content3_txt3)}
          <br />
          {t(IK.privacy_content3_txt4)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content3_txt5)}
          <br />
          {t(IK.privacy_content3_txt6)}
          <br />
          {t(IK.privacy_content3_txt7)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content3_txt8)}
          <br />
          {t(IK.privacy_content3_txt9)}
          <br />
          {t(IK.privacy_content3_txt10)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content3_txt11)}
          <br />
          {t(IK.privacy_content3_txt12)}
          <br />
          {t(IK.privacy_content3_txt13)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content3_txt14)}
          <br />
          {t(IK.privacy_content3_txt15)}
          <br />
          {t(IK.privacy_content3_txt16)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content3_txt17)}
          <br />
          {t(IK.privacy_content3_txt18)}
          <br />
          {t(IK.privacy_content3_txt19)}
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content4_title)} />
        <p>{t(IK.privacy_content4_txt1)}</p>
        <S2.ContentBox $small as="div">
          <S.TableType1>
            <caption>
              수집한 개인정보의 위탁 ㅣ 수탁업체, CJ 대한통운, 유영인포테크로
              구성
            </caption>
            <thead>
              <tr>
                <th scope="col">{t(IK.privacy_content4_txt1_th1)}</th>
                <th scope="col">{t(IK.privacy_content4_txt1_th2)}</th>
                <th scope="col">{t(IK.privacy_content4_txt1_th3)}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{t(IK.privacy_content4_txt1_td1)}</td>
                <td>{t(IK.privacy_content4_txt1_td2)}</td>
                <td>{t(IK.privacy_content4_txt1_td3)}</td>
              </tr>
            </tbody>
          </S.TableType1>
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content5_title)} />
        <p>{t(IK.privacy_content5_txt1)}</p>
        <S2.ContentBox $small as="div">
          <S.TableType1>
            <caption>
              개인정보의 제 3 자 제공 ㅣ 제공받는 자 (연락처), 제공하는 개인정보
              항목, 제공받는 자의 개인정보 이용목적, 보유 및 이용기간으로 구성
            </caption>
            <colgroup>
              <col width="20%" />
              <col width="25%" />
              <col width="25%" />
              <col width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t(IK.privacy_content5_txt1_th1)}</th>
                <th scope="col">{t(IK.privacy_content5_txt1_th2)}</th>
                <th scope="col">{t(IK.privacy_content5_txt1_th3)}</th>
                <th scope="col">{t(IK.privacy_content5_txt1_th4)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t(IK.privacy_content5_txt1_td1)}</td>
                <td>{t(IK.privacy_content5_txt1_td2)}</td>
                <td>{t(IK.privacy_content5_txt1_td3)}</td>
                <td>{t(IK.privacy_content5_txt1_td4)}</td>
              </tr>
            </tbody>
          </S.TableType1>
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content6_title)} />
        <ol>
          <li>{t(IK.privacy_content6_txt1)}</li>
          <li>{t(IK.privacy_content6_txt2)}</li>
          <li>{t(IK.privacy_content6_txt3)}</li>
        </ol>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content7_title)} />
        <p>{t(IK.privacy_content7_txt1)}</p>
        <S2.ContentBox $small as="ol">
          <li>{t(IK.privacy_content7_txt2)}</li>
          <li>{t(IK.privacy_content7_txt3)}</li>
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content8_title)} />
        <p>{t(IK.privacy_content8_txt1)}</p>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content8_txt2)}
        </S2.ContentBox>
        <p>{t(IK.privacy_content8_txt3)}</p>
        <p>{t(IK.privacy_content8_txt4)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content9_title)} />
        <p>{t(IK.privacy_content9_txt1)}</p>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content9_txt2)}
        </S2.ContentBox>
        <p>{t(IK.privacy_content9_txt3)}</p>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content10_title)} />
        <p>{t(IK.privacy_content10_txt1)}</p>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content10_txt2)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content10_txt3)}
          <br />
          {t(IK.privacy_content10_txt4)}
          <br />
          {t(IK.privacy_content10_txt5)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content10_txt6)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content10_txt7)}
          <br />
          {t(IK.privacy_content10_txt8)}
          <br />
          {t(IK.privacy_content10_txt9)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content10_txt10)}
          <br />
          {t(IK.privacy_content10_txt11)}
          <br />
          {t(IK.privacy_content10_txt12)}
        </S2.ContentBox>
        <S2.ContentBox $small as="p">
          {t(IK.privacy_content10_txt13)}
          <br />
          {t(IK.privacy_content10_txt14)}
          <br />
          {t(IK.privacy_content10_txt15)}
        </S2.ContentBox>
      </S2.ContentBox>

      <S2.ContentBox $large>
        <TitleV3 title={t(IK.privacy_content11_title)} />
        <p>{t(IK.privacy_content11_txt1)}</p>
      </S2.ContentBox>
    </>
  );
}

export default Privacy;
