import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Modal.style";

const MainModalWrapper = ({ modals }) => {
  return (
    <>
      <S.MainModalWrapper>
        {modals?.map(({ id, link, imageDistFileName, type }) => (
          <MainModal key={id} id={id} link={link} url={imageDistFileName} type={type} />
        ))}
      </S.MainModalWrapper>
    </>
  );
};

export default MainModalWrapper;

export const MainModal = ({ id, link, url, type }) => {
  const { t } = useTranslation(["translation"]);
  const [showMainModal, setShowMainModal] = useState(false);

  // 쿠키 만료
  useEffect(() => {
    const mainModalCookie = Cookies.get(`MainModal_${id}`);
    if (!mainModalCookie) {
      setShowMainModal(true);
    }
  }, [id]);

  // 오늘 하루 그만 보기
  const handleClose = () => {
    setShowMainModal(false);

    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);
    const timeUntilMidnight = midnight - now;

    Cookies.set(`MainModal_${id}`, "true", { expires: new Date(now.getTime() + timeUntilMidnight) });

    //Cookies.set(`MainModal_${id}`, "true", { expires: 1 });
  };

  // 팝업 닫기
  const handleDismiss = () => {
    setShowMainModal(false);
  };

  return (
    <>
      {showMainModal && (
        <>
          <S.MainModalBox>
            {type === "IMAGE" ? (
              <a href={link} target="_blank" title="새창열림" rel="noopener noreferrer">
                <img src={url} alt="" />
              </a>
            ) : (
              <iframe src={`https://www.youtube.com/embed/${link}`} title="YouTube video player" />
            )}
            <S.MainModalBottom>
              <button onClick={handleClose}>{t(IK.not_day_close)}</button>
              <button onClick={handleDismiss}>{t(IK.close)}</button>
            </S.MainModalBottom>
          </S.MainModalBox>
        </>
      )}
    </>
  );
};
