import { useTranslation } from "react-i18next";
import * as S from "../../../../../components/element/Step/style/Step.style";
import { IK } from "../../../../../utils/i18n/keyStore";

function Stpes({ currentStepIndex, currentStepList }) {
  const { t } = useTranslation(["translation"]);

  function removeDuplicateStepLabels(steps) {
    const uniqueStepLabels = {};

    for (const stepKey in steps) {
      const step = steps[stepKey];
      const { stepLabel } = step;

      if (!uniqueStepLabels[stepLabel]) {
        uniqueStepLabels[stepLabel] = step;
      }
    }
    return Object.values(uniqueStepLabels);
  }
  const stepList = removeDuplicateStepLabels(currentStepList).map(
    (item, index) => {
      return (
        <li
          key={index}
          className={
            currentStepList[currentStepIndex].stepLabel === item.stepLabel
              ? "active"
              : ""
          }
        >
          <h3>{t(IK[item.stepLabel])}</h3>
        </li>
      );
    }
  );

  return (
    <S.StepType2>
      <ol>{stepList}</ol>
    </S.StepType2>
  );
}

export default Stpes;
