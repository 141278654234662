import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useGetEventInfo } from "../../../apis/boardApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import { SkeletonBoardView } from "../../../components/element/BoardView/SkeletonBoardView";
import * as S from "../../../components/element/BoardView/style/BoardView.style";
import * as S2 from "../../../components/element/Button/style/ButtonLayout.style";
import "../../../styles/Editor.css";
import { IK } from "../../../utils/i18n/keyStore";
const EventInfo = () => {
  const { t } = useTranslation(["translation"]);
  const { eventBoardId } = useParams();
  const location = useLocation();

  /**이벤트 게시판 상세조회 */
  const { data, isLoading, isError } = useGetEventInfo(eventBoardId);
  const boardInfo = data?.data;

  return (
    <>
      {isLoading || isError ? (
        <SkeletonBoardView />
      ) : (
        <CardInnerLayout>
          <CardTitle title={t(IK.event)} />
          <S.BoardViewTop>
            <h3>{boardInfo?.title}</h3>
            <span>
              {dayjs(boardInfo?.startDate).format("YYYY.MM.DD")} - {dayjs(boardInfo?.endDate).format("YYYY.MM.DD")}
            </span>
          </S.BoardViewTop>
          <S.BoardViewCont className="ck-content" dangerouslySetInnerHTML={{ __html: boardInfo?.content }} />
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton to={`/support/event-list${location?.state?.herf || "?page=1&endEvent=N&size=8"}`}>{t(IK.list)}</S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      )}
    </>
  );
};

export default EventInfo;
