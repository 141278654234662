import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import TeethCheck from "../../../../../components/element/TeethTable/TeethCheck";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";

function TeethLimitedMobility({
  next,
  prev,
  currentStepType,
  stepLabel,
  currentStepList,
  currentStepIndex,
  setStudyInfo,
  studyInfo,
  patientId,
  isFinish,
  setCurrentStepIndex,
}) {
  const { t } = useTranslation(["translation"]);
  const saveStepMutation = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    SI02: Yup.string().required(t(IK.teeth_limited_mobility_required)),
    SI02_2_01: Yup.array().when("SI02", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { SI02_2_02 } = this.parent;
            return value.length > 0 || SI02_2_02.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
    SI02_2_02: Yup.array().when("SI02", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { SI02_2_01 } = this.parent;
            return value.length > 0 || SI02_2_01.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
  });

  const code = currentStepList[currentStepIndex].code;

  const handleSubmit = (values) => {
    const data = {
      SI02: values.SI02,
      SI02_2_01: values.SI02 === "2" ? values.SI02_2_01.join(",") : "",
      SI02_2_02: values.SI02 === "2" ? values.SI02_2_02.join(",") : "",
    };

    saveStepMutation.mutate(
      {
        step: code,
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip
            ? setCurrentStepIndex(currentStepList.length - 2)
            : next();
        },
      }
    );
  };

  const config = studyFormConfig[currentStepType][stepLabel];

  /**이전 버튼 눌러서 악궁선택으로 이동되기 전에 경고 */
  const prevCaution = () => {
    if (currentStepIndex === 3) {
      Notiflix.Confirm.show(
        t(IK.is_edit),
        t(IK.edit_dental_arch_treated),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          prev();
        },
        () => {}
      );
    } else {
      prev();
    }
  };

  return (
    <>
      <CardTitle
        title={studyInfo.patientName}
        subTitle={`${t(IK[studyInfo.packages])}`}
        required
      />

      <Formik
        initialValues={{
          SI02: studyInfo.SI02 || "",
          SI02_2_01: studyInfo.SI02_2_01 ? studyInfo.SI02_2_01.split(",") : [],
          SI02_2_02: studyInfo.SI02_2_02 ? studyInfo.SI02_2_02.split(",") : [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox
              title={t(IK.teeth_limited_mobility)}
              titleSub={t(IK.teeth_limited_mobility_ex)}
              required
              openModal={openModal}
            >
              <div className="frm_column">
                {config.teeth_limited_mobility_item1 && (
                  <div className="radio">
                    <label className="label_radio">
                      <Field
                        type="radio"
                        name="SI02"
                        className="input_radio"
                        value="1"
                      />
                      <span>{t(IK.teeth_limited_mobility_item1)}</span>
                    </label>
                  </div>
                )}
                {config.teeth_limited_mobility_item2 && (
                  <div className="radio">
                    <label className="label_radio">
                      <Field
                        type="radio"
                        name="SI02"
                        className="input_radio"
                        value="2"
                      />
                      <span>{t(IK.teeth_limited_mobility_item2)}</span>
                    </label>
                  </div>
                )}
                {config.teeth_limited_mobility_item2 && (
                  <div className="frm_radio_indent">
                    <TeethCheck
                      label="permanent"
                      baby
                      code={code}
                      studyInfo={studyInfo}
                      handleChange={handleChange}
                      values={values}
                      config={config.teeth_limited_mobility_item2_teeth}
                    />
                    <FieldMessageErrorClick name="SI02_2_01" />
                  </div>
                )}
              </div>
              <FieldMessageErrorClick name="SI02" />
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton
                  $secondary
                  as="button"
                  type="button"
                  onClick={() => setCurrentStepIndex(1)}
                >
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton
                    as="button"
                    type="button"
                    onClick={prevCaution}
                  >
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton
                      as="button"
                      type="submit"
                      onClick={() => setSkip(true)}
                      $primary
                    >
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.teeth_limited_mobility_pop_message)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default TeethLimitedMobility;
