import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { checkEmailUpdate, checkLicenseNumberUpdate, useUpdateDoctorDTO } from "../../apis/doctorApi";
import { IK } from "../../utils/i18n/keyStore";
import { FieldMessageError, FieldMessageErrorClick, FieldMessageSuccess } from "../element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../element/Form/FormLayout";
import AddressPopupBtn from "../popup/AddressPopup";
import Modal from "./Modal";
import * as S from "../../components/element/Button/style/ButtonLayout.style";
import * as S2 from "../../components/Modal/style/Modal.style";
import * as S3 from "../../components/element/Form/style/Form.style";

const AccountUpdateModal = ({ updateModal, setOpenUpdateModal, user }) => {
  const { t } = useTranslation();

  /**유효성검사 */
  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
      .required(t(IK.english_name_required))
      .max(20, t(IK.name_length_limit)),
    firstName: Yup.string()
      .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
      .required(t(IK.english_name_required))
      .max(20, t(IK.name_length_limit)),
    koreaName:
      user.countryCode === "01"
        ? Yup.string()
            .matches(/^[가-힣]*$/, t(IK.only_korean))
            .required(t(IK.korea_name_required))
            .max(10, t(IK.name_length_limit_10))
        : Yup.string(),
    clinicName: Yup.string().required(t(IK.clinic_name_required)),
    postCode: Yup.string().required(t(IK.postcode_required)),
    address: Yup.string().required(t(IK.address_required)),
    tel: Yup.string().required(t(IK.clinic_number_required)),
    phone: Yup.string().required(t(IK.number_required)),
    email: Yup.string()
      .email(t(IK.email_valid))
      .test("email", t(IK.email_valid), (value) => {
        if (value) {
          const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
          return regex.test(value);
        }
        return true;
      })
      .required(t(IK.email_required)),
    emailCheck: Yup.bool().isTrue().oneOf([true], t(IK.email_duplicate_check_required)),
    license: Yup.string().required(t(IK.license_required)).matches(/^\S+$/, t(IK.vaild_space)),
    licenseCheck: Yup.bool().isTrue().oneOf([true], t(IK.license_duplicate_check_required)),
  });

  const [emailCheckFlag, setEmailCheckFlag] = useState(false);
  const [licenseFlag, setLicenseCheckFlag] = useState(false);

  /**이메일 중복체크 */
  const handleClickEmailCheck = (values, setFieldValue, setFieldError) => {
    if (values.email) {
      checkEmailUpdate({ email: values.email })
        .then((response) => {
          setEmailCheckFlag(true);
          setFieldValue("emailCheck", true);
        })
        .catch((err) => {
          setFieldError("emailCheck", t(IK.duplicate_email));
        });
    }
  };

  /**면허증 중복 검사 */
  const handleOnClickLicense = (values, setFieldValue, setFieldError) => {
    checkLicenseNumberUpdate({
      licenseNumber: values.license,
      countryCode: user.countryCode,
    })
      .then((response) => {
        setLicenseCheckFlag(true);
        setFieldValue("licenseCheck", true);
      })
      .catch((err) => {
        setFieldError("license", t(IK.duplicate_license));
      });
  };

  /**중복검사 후 다시 기입할 경우 감지 */
  const handleKeyDown = (event, setFieldValue) => {
    setFieldValue(event.target.id + "Check", false);
    if (event.target.id === "license") {
      setLicenseCheckFlag(false);
    } else {
      setEmailCheckFlag(false);
    }
  };

  const doctorDTOMutation = useUpdateDoctorDTO();

  /**정보 업데이트 */
  const handleUpdate = (value) => {
    const { firstName, lastName, emailCheck, licenseCheck, ...rest } = value;
    const name = `${firstName} ${lastName}`;

    doctorDTOMutation.mutate(
      { ...rest, name },
      {
        onSuccess: (response) => {
          Notiflix.Report.success(t(IK.update_success), t(IK.update_success_doctor), t(IK.confirm), () => {
            setOpenUpdateModal(false);
          });
        },
        onError: (error) => {
          Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
        },
      }
    );
  };

  return (
    <Modal open={updateModal} header={t(IK.membership_info_update)}>
      <Formik
        initialValues={{
          lastName: "",
          firstName: "",
          koreaName: "",
          email: "",
          emailCheck: false,
          phone: "",
          license: "",
          licenseCheck: false,
          clinicName: "",
          postCode: "",
          address: "",
          detailAddress: "",
          tel: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ setFieldValue, values, errors, setFieldError }) => (
          <Form>
            <S3.FormControlsColumn $large>
              {/* 국가 */}
              {user.countryCode === "01" && (
                <FormControlsBox required regular title={t(IK.korea_name)}>
                  <Field as={S3.FormControls} $full type="text" name="koreaName" maxLength="10" />
                  <FieldMessageErrorClick name="koreaName" />
                </FormControlsBox>
              )}

              {/* 이름(영문) */}
              <FormControlsBox required regular title={t(IK.english_name)}>
                <S3.FormControlsFlex>
                  <div>
                    <Field as={S3.FormControls} $full type="text" name="firstName" placeholder={t(IK.first_name)} maxLength="20" />
                    <FieldMessageErrorClick name="firstName" />
                  </div>
                  <div>
                    <Field as={S3.FormControls} $full type="text" name="lastName" placeholder={t(IK.last_name)} maxLength="20" />
                    <FieldMessageErrorClick name="lastName" />
                  </div>
                </S3.FormControlsFlex>
              </FormControlsBox>

              {/* 이메일 */}
              <FormControlsBox required regular title={t(IK.email)}>
                <S3.FormControlsFlex>
                  <Field as={S3.FormControls} $full type="email" id="email" name="email" onKeyDown={(e) => handleKeyDown(e, setFieldValue)} />
                  <S3.FormControlsBtn type="button" onClick={() => handleClickEmailCheck(values, setFieldValue, setFieldError)}>
                    {t(IK.duplicate_btn)}
                  </S3.FormControlsBtn>
                </S3.FormControlsFlex>
                <FieldMessageErrorClick name="email" />
                {errors.emailCheck && <FieldMessageError text={errors.emailCheck} />}
                {emailCheckFlag && <FieldMessageSuccess text={t(IK.available_email_message)} />}
              </FormControlsBox>

              {/* 연락처/핸드폰 */}
              <FormControlsBox required regular title={t(IK.contact)}>
                <Field as={S3.FormControls} $full type="text" name="phone" maxLength="13" />
                <FieldMessageErrorClick name="phone" />
              </FormControlsBox>

              {/* 면허번호 */}
              <FormControlsBox required regular title={t(IK.license_number)}>
                <S3.FormControlsFlex>
                  <Field as={S3.FormControls} $full type="text" id="license" name="license" maxLength="20" onKeyDown={(e) => handleKeyDown(e, setFieldValue)} />
                  <S3.FormControlsBtn type="button" onClick={() => handleOnClickLicense(values, setFieldValue, setFieldError)}>
                    {t(IK.duplicate_btn)}
                  </S3.FormControlsBtn>
                </S3.FormControlsFlex>
                <FieldMessageErrorClick name="license" />
                {errors.licenseCheck && <FieldMessageError text={errors.licenseCheck} />}
                {licenseFlag && <FieldMessageSuccess text={t(IK.available_license_message)} />}
              </FormControlsBox>

              {/* 치과명 */}
              <FormControlsBox required regular title={t(IK.clinic_name)}>
                <Field as={S3.FormControls} $full type="text" name="clinicName" maxLength="40" />
                <FieldMessageErrorClick name="clinicName" />
              </FormControlsBox>

              {/* 치과주소 */}
              <FormControlsBox required regular title={t(IK.clinic_address)}>
                <S3.FormControlsColumn>
                  {/* 우편번호 */}
                  <div>
                    <S3.FormControlsFlex>
                      <Field as={S3.FormControls} $full type="text" name="postCode" placeholder={t(IK.postcode)} maxLength="8" readOnly={user.countryCode === "01"} />
                      <AddressPopupBtn setFieldValue={setFieldValue} basicAddressId="address" zoneCodeId="postCode" countryCode={user.countryCode} />
                    </S3.FormControlsFlex>
                    <FieldMessageErrorClick name="postCode" />
                  </div>

                  {/* 기본주소 */}
                  <div>
                    <Field as={S3.FormControls} $full type="text" name="address" placeholder={t(IK.address)} maxLength="100" readOnly={user.countryCode === "01"} />
                    <FieldMessageErrorClick name="address" />
                  </div>

                  {/* 상세주소 */}
                  <div>
                    <Field as={S3.FormControls} $full type="text" name="detailAddress" placeholder={t(IK.detailed_address)} maxLength="100" />
                  </div>
                </S3.FormControlsColumn>
              </FormControlsBox>

              {/* 치과연락처 */}
              <FormControlsBox required regular title={t(IK.clinic_number)}>
                <Field as={S3.FormControls} $full type="text" name="tel" maxLength="13" />
                <FieldMessageErrorClick name="tel" />
              </FormControlsBox>
            </S3.FormControlsColumn>

            <S2.ModalBoxBtn>
              <S.StyledButton as="button" $primary type="submit">
                {t(IK.save)}
              </S.StyledButton>
            </S2.ModalBoxBtn>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AccountUpdateModal;
