import Notiflix from "notiflix";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { deleteRefreshToken } from "../apis/joinApi";
import { postAdminOtt, postSeraquickOtt } from "../apis/ottApi";
import { userInfo } from "../atoms/userAtom";
import { setAllToken } from "../utils/handleToken";
import { IK } from "../utils/i18n/keyStore";

const AdminLogin = () => {
  const { t } = useTranslation(["translation"]);
  //ott 로그값
  const ott = new URL(window.location.href).searchParams.get("ott");
  //세라퀵 로그인 판별
  const seraquickId = new URL(window.location.href).searchParams.get("seraquickId");

  const setUserInfo = useSetRecoilState(userInfo);
  const navigate = useNavigate();

  /**세라핀 로그인 처리 */
  const serafinLogin = useCallback(
    (loginApi) => {
      loginApi(ott)
        .then((response) => {
          if (response.status === 200) {
            const Logindata = response.data.data;
            setAllToken(Logindata.accessToken);
            setUserInfo(Logindata.roleInfo?.DOCTOR);

            //세라퀵 로그인시
            seraquickId
              ? navigate("/patient/register", {
                  state: { seraquickId },
                })
              : navigate("/patient/list");
          }
        })
        .catch((err) => {
          Notiflix.Report.info("", t(IK.again_message), t(IK.confirm), () => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("recoil-persist");
            deleteRefreshToken();
            navigate("/");
          });
        });
    },
    [ott, setUserInfo, navigate, seraquickId, t]
  );

  useEffect(() => {
    seraquickId ? serafinLogin(postSeraquickOtt) : serafinLogin(postAdminOtt);
  }, [seraquickId, ott, serafinLogin]);

  return <div>{t(IK.admin_login_auth)}</div>;
};

export default AdminLogin;
