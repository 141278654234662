import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "../../../../../components/Modal/Modal";
import * as S from "../../../../../components/Modal/style/Modal.style";
import { IK } from "../../../../../utils/i18n/keyStore";

/** 환자 수정 */
export const PatientEditModal = ({
  patientModalOpen,
  setPatientModalOpen,
  patientId,
}) => {
  const { t } = useTranslation(["translation"]);

  const closePatientModal = () => setPatientModalOpen(false);

  const editList = [
    { nav: "", editTitle: t(IK.enter_patient_information) },
    { nav: "/clinic-condition", editTitle: t(IK.clinical_condition) },
    { nav: "/extraoral", editTitle: t(IK.extraoral) },
    { nav: "/intraoral", editTitle: t(IK.intraoral) },
    { nav: "/raditaion", editTitle: t(IK.radiograph) },
    { nav: "/scan", editTitle: t(IK.scan_file_registration) },
  ];

  return (
    <Modal
      open={patientModalOpen}
      close={closePatientModal}
      header={t(IK.update_patient)}
    >
      <S.ModalBoxNumList>
        {editList.map((edit, i) => (
          <li key={edit.nav}>
            <Link to={`/patient/${patientId}/update${edit.nav}`}>
              <h3>{edit.editTitle}</h3>
            </Link>
          </li>
        ))}
      </S.ModalBoxNumList>
    </Modal>
  );
};
