import { Field } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useGetAddress } from "../../../../apis/addressApi";
import { getDoctorFirstAccessInfo } from "../../../../apis/doctorApi";
import { userInfo } from "../../../../atoms/userAtom";
import ClinicalConfigurationsPopup from "../../../../components/ClinicalConfigurationsPopup/ClinicalConfigurationsPopup";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../../components/element/Form/FormLayout";
import AddressPopupBtn from "../../../../components/popup/AddressPopup";
import { formatDate } from "../../../../utils/dateUtils";
import { IK } from "../../../../utils/i18n/keyStore";
import AddressModal from "../../../Account/AddressManage/AddressModal";
import { CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../components/element/Form/style/Form.style";
import * as S2 from "../../../../components/element/SelectionControls/style/SelectionControls.style";

const Register = ({ setFieldValue, values, setFieldError }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  /**국가코드 조회 */
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  /**주소지 조회 */
  const { data: addressData } = useGetAddress();
  const addressList = [{ addressId: 0, addressName: t(IK.new_address_add), isDefault: true }, ...(addressData?.data || [])];

  /**선택된 주소로 변경 */
  const handleOnClickAddress = (item, setFieldValue, addressType) => {
    setFieldValue(`${addressType}AddressName`, item.addressId !== 0 ? item.addressName || "" : "");
    setFieldValue(`${addressType}Address`, item.addressId !== 0 ? item.address || "" : "");
    setFieldValue(`${addressType}PostalCode`, item.addressId !== 0 ? item.postalCode || "" : "");
    setFieldValue(`${addressType}AddressDetail`, item.addressId !== 0 ? item.addressDetail || "" : "");
  };

  /**주소지 선택 */
  const handleOnClickQuickShipAddress = (item, setFieldValue) => {
    handleOnClickAddress(item, setFieldValue, "ship");
  };

  /**청구지 선택 */
  const handleOnClickQuickBillAddress = (item, setFieldValue) => {
    handleOnClickAddress(item, setFieldValue, "bill");
  };

  /**주소지 등록 모달 상태관리 */
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  /**주소지 등록 모달 닫기*/
  const closeAddressModal = () => {
    setAddressModalOpen(false);
  };

  /**사용자 상태에 따른 조건 설정(영상미시청, 임상환경 미설정) */
  useEffect(() => {
    getDoctorFirstAccessInfo().then((res) => {
      if (res.data.data === "VIDEO") {
        Notiflix.Report.info(t(IK.video_call), t(IK.video_call_message), t(IK.confirm), () => {
          navigate("/education/certified-training");
        });
      }
      if (res.data.data === "CLINICAL_ENVIRONMENT") {
        setModalOpen(true);
      }
    });
  }, [navigate, t]);

  const onBlurDate = (e) => {
    const formatDated = formatDate(e.target.value);
    if (formatDated) {
      setFieldValue("birthday", formatDated);
    } else {
      setFieldValue("birthday", "");
      setFieldError("birthday", t(IK.date_format_valid));
    }
  };

  return (
    <>
      <CardTitle required />

      <S.FormControlsColumn $large>
        {countryCode === "01" && (
          // 이름(한글)
          <FormControlsBox required title={t(IK.korea_name)}>
            <Field as={S.FormControls} type="text" name="korName" maxLength="10" />
            <FieldMessageErrorClick name="korName" />
          </FormControlsBox>
        )}

        {/* 이름(영문) */}
        <FormControlsBox required title={t(IK.english_name)}>
          <S.FormControlsFlex>
            <div>
              <Field as={S.FormControls} type="text" name="firstName" placeholder={t(IK.first_name)} maxLength="20" />
              <FieldMessageErrorClick name="firstName" />
            </div>
            <div>
              <Field as={S.FormControls} type="text" name="lastName" placeholder={t(IK.last_name)} maxLength="20" />
              <FieldMessageErrorClick name="lastName" />
            </div>
          </S.FormControlsFlex>
        </FormControlsBox>

        {/* 성별 */}
        <FormControlsBox required title={t(IK.gender)}>
          <S2.SelectionItemList>
            <S2.SelectionItem>
              <S2.SelectionItemLabel>
                <Field as={S2.SelectionItemInput} $radio type="radio" name="gender" value="male" />
                <S2.SelectionItemSpan>{t(IK.male)}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
            <S2.SelectionItem>
              <S2.SelectionItemLabel>
                <Field as={S2.SelectionItemInput} $radio type="radio" name="gender" value="female" />
                <S2.SelectionItemSpan>{t(IK.female)}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
          </S2.SelectionItemList>
          <FieldMessageErrorClick name="gender" />
        </FormControlsBox>

        {/* 생년월일 */}
        <FormControlsBox required title={t(IK.birthday)}>
          <Field as={S.FormControls} type="text" name="birthday" onBlur={onBlurDate} placeholder="ex)20230425" />
          <FieldMessageErrorClick name="birthday" />
        </FormControlsBox>

        {/* 인종 */}
        <FormControlsBox required title={t(IK.race)}>
          <S2.SelectionItemList>
            <S2.SelectionItem>
              <S2.SelectionItemLabel>
                <Field as={S2.SelectionItemInput} $radio type="radio" name="race" value="Caucasian" />
                <S2.SelectionItemSpan>{t(IK.caucasian)}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
            <S2.SelectionItem>
              <S2.SelectionItemLabel>
                <Field as={S2.SelectionItemInput} $radio type="radio" name="race" value="Asian" />
                <S2.SelectionItemSpan>{t(IK.asian)}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
            <S2.SelectionItem>
              <S2.SelectionItemLabel>
                <Field as={S2.SelectionItemInput} $radio type="radio" name="race" value="African" />
                <S2.SelectionItemSpan>{t(IK.african)}</S2.SelectionItemSpan>
              </S2.SelectionItemLabel>
            </S2.SelectionItem>
          </S2.SelectionItemList>
          <FieldMessageErrorClick name="race" />
        </FormControlsBox>

        <FormControlsBox>
          <S.FormControlsBtn type="button" onClick={() => setAddressModalOpen(true)}>
            {t(IK.registration_address)}
          </S.FormControlsBtn>
        </FormControlsBox>

        {/* 배송지 선택 */}
        <FormControlsBox required title={`${t(IK.shipping_address)} ${t(IK.select)}`}>
          <S2.SelectionItemList $small>
            {addressList.map((item) => {
              return (
                <S2.SelectionItem key={item.addressId}>
                  <S2.SelectionItemLabel $btn>
                    <S2.SelectionItemInput
                      $btn
                      type="radio"
                      onClick={() => handleOnClickQuickShipAddress(item, setFieldValue)}
                      name="shippingAddressQuickSelect"
                      value={item.addressId}
                      defaultChecked={0 === item.addressId}
                    />
                    {item.addressName}
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
              );
            })}
          </S2.SelectionItemList>
        </FormControlsBox>

        {/* 배송지 */}
        <FormControlsBox required title={t(IK.shipping_address)}>
          <S.FormControlsColumn>
            {/* 배송지명 */}
            <div>
              <Field as={S.FormControls} type="text" id="shipAddressName" name="shipAddressName" placeholder={t(IK.ship_address_name)} maxLength="30" />
              <FieldMessageErrorClick name="shipAddressName" />
            </div>

            {/* 우편번호 */}
            <div>
              <S.FormControlsFlex>
                <Field as={S.FormControls} type="text" id="shipPostalCode" name="shipPostalCode" placeholder={t(IK.postcode)} maxLength="8" />
                <AddressPopupBtn countryCode={countryCode} setFieldValue={setFieldValue} basicAddressId={"shipAddress"} zoneCodeId={"shipPostalCode"} />
              </S.FormControlsFlex>
              <FieldMessageErrorClick name="shipPostalCode" />
            </div>

            {/* 기본주소 */}
            <div>
              <Field as={S.FormControls} type="text" id="shipAddress" name="shipAddress" placeholder={t(IK.address)} maxLength="100" />
              <FieldMessageErrorClick name="shipAddress" />
            </div>

            {/* 상세주소 */}
            <div>
              <Field as={S.FormControls} type="text" id="shipAddressDetail" name="shipAddressDetail" placeholder={t(IK.detailed_address)} maxLength="100" />
            </div>
          </S.FormControlsColumn>
        </FormControlsBox>

        {/* 청구지 선택 */}
        <FormControlsBox required title={`${t(IK.billing_address)} ${t(IK.select)}`}>
          <S2.SelectionItemList $small>
            {addressList.map((item) => {
              return (
                <S2.SelectionItem key={"bill_" + item.addressId}>
                  <S2.SelectionItemLabel $btn>
                    <S2.SelectionItemInput
                      $btn
                      type="radio"
                      onClick={() => handleOnClickQuickBillAddress(item, setFieldValue)}
                      name="billAddressQuickSelect"
                      value={item.addressId}
                      defaultChecked={0 === item.addressId}
                    />
                    {item.addressName}
                  </S2.SelectionItemLabel>
                </S2.SelectionItem>
              );
            })}
          </S2.SelectionItemList>
        </FormControlsBox>

        {/* 청구지 */}
        <FormControlsBox required title={t(IK.billing_address)}>
          <S.FormControlsColumn>
            {/* 청구지명 */}
            <div>
              <Field as={S.FormControls} type="text" id="billAddressName" name="billAddressName" placeholder={t(IK.bill_address_name)} maxLength="30" />
              <FieldMessageErrorClick name="billAddressName" />
            </div>

            {/* 우편번호 */}
            <div>
              <S.FormControlsFlex>
                <Field as={S.FormControls} type="text" id="billPostalCode" name="billPostalCode" placeholder={t(IK.postcode)} maxLength="8" />
                <AddressPopupBtn countryCode={countryCode} setFieldValue={setFieldValue} basicAddressId={"billAddress"} zoneCodeId={"billPostalCode"} />
              </S.FormControlsFlex>
              <FieldMessageErrorClick name="billPostalCode" />
            </div>

            {/* 기본주소 */}
            <div>
              <Field as={S.FormControls} type="text" id="billAddress" name="billAddress" placeholder={t(IK.address)} maxLength="100" />
              <FieldMessageErrorClick name="billAddress" />
            </div>

            {/* 상세주소 */}
            <div>
              <Field as={S.FormControls} type="text" id="billAddressDetail" name="billAddressDetail" placeholder={t(IK.detailed_address)} maxLength="100" />
            </div>
          </S.FormControlsColumn>
        </FormControlsBox>
      </S.FormControlsColumn>

      {/**임상환경 적용 모달 */}
      <ClinicalConfigurationsPopup open={modalOpen} close={closeModal} header={t(IK.clinical_configurations)} />

      {/**주소지 등록 모달 */}
      <AddressModal addressModalOpen={addressModalOpen} closeAddressModal={closeAddressModal} />
    </>
  );
};

export default Register;
