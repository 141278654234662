import { useCallback, useEffect, useState } from "react";

export const useSearchParam = (initParams) => {
  /** 검색 조건 초기 설정 */
  const [searchCondition, setSearchCondition] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(params);
    return { ...initParams, ...urlParams };
  });

  /** 검색 URL 업데이트 */
  const updateUrl = useCallback((params) => {
    // searchCondition의 값이 없는 경우 제외
    const filteredParams = Object.fromEntries(
      Object.entries(params)
        .filter(([, value]) => !!value)
        .map(([key, value]) => [key, String(value)])
    );

    const url = new URL(window.location.href);
    url.search = new URLSearchParams(filteredParams).toString();
    window.history.pushState(null, "", url);
  }, []);

  /** 검색할 때마다 업데이트 */
  useEffect(() => {
    updateUrl(searchCondition);
  }, [searchCondition, updateUrl]);

  return { searchCondition, setSearchCondition };
};
