import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 대체 처방전 작성 이동(대체처방전 아이디 생성) */
export const createReplacementStudy = async (data) => {
  const response = await axios.post("/api/replacement", null, {
    params: data,
  });
  return response;
};

/** 대체 처방전 스탭 작성 */
export const useReplacementStep = (replacementId, patientId) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async ({ step, data }) => {
      const response = await axios.post(`/api/replacement/${replacementId}/${step}`, data, {
        params: {
          patientId,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("StudySummary");
      },
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/**대체 처방전 제출 */
export const useReplacementSubmit = (replacementId, patientId) => {
  const { t } = useTranslation(["translation"]);
  return useMutation(
    async () => {
      const response = await axios.post(`/api/replacement/${replacementId}/submit`, null, {
        params: {
          patientId,
        },
      });
      return response.data;
    },
    {
      onError: (error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    }
  );
};

/** 대체 처방전 요약 조회 */
export const useReplacementSummary = (replacementId, patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudySummary", replacementId],
    async () => {
      const { data } = await axios.get(`/api/replacement/${replacementId}`, {
        params: {
          patientId,
        },
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};
