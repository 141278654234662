import { useTranslation } from "react-i18next";
import { IK } from "../../utils/i18n/keyStore";
import { useDaumPostcodePopup } from "react-daum-postcode";
import * as S from "../element/Form/style/Form.style";

function AddressPopupBtn({
  countryCode,
  zoneCodeId,
  basicAddressId,
  setFieldValue,
}) {
  const { t } = useTranslation(["translation"]);
  const open = useDaumPostcodePopup();
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    setFieldValue(zoneCodeId, data.zonecode);
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setFieldValue(basicAddressId, fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    countryCode === "01" && (
      <S.FormControlsBtn type="button" onClick={handleClick}>
        {t(IK.postcode)} {t(IK.search)}
      </S.FormControlsBtn>
    )
  );
}
export default AddressPopupBtn;
